// import { useParams } from "react-router-dom/cjs/react-router-dom.min";
// import useFetch from "../hooks/useFetch";

import { useState } from "react";
import useScrollToTop from "../../blocks/logic/usScrollToTop";
import PayPalClassicBuy from "./PayPalClassicBuy";

const ClassicProduct = ({ name, slug, image, description, price }) => {
  useScrollToTop();

  const [selectedAmount, setSelectedAmount] = useState(1);
  const [shipInternational, setShipInternational] = useState("false");
  const [priced, setPrice] = useState(25);
  const handlePriceChange = (event) => {
    setSelectedAmount(event.target.value);
    let intraprice = 25;
    if (parseInt(event.target.value) === 1) {
      // setPrice(25);
      intraprice = 25;
    } else if (parseInt(event.target.value) === 3) {
      // setPrice(60);
      intraprice = 60;
    } else if (parseInt(event.target.value) === 5) {
      // setPrice(95);
      intraprice = 95;
    }
    if (shipInternational === "true") {
      setPrice(intraprice + 25);
    } else {
      setPrice(intraprice);
    }
  };
  const handleShippingChange = (event) => {
    // console.log(
    //   "event.target.value",
    //   event.target.value,
    //   "===?",
    //   event.target.value === true,
    //   event.target.value === "true"
    // );
    setShipInternational(event.target.value);
    if (event.target.value === "true") {
      setPrice(priced + 25);
    } else {
      setPrice(priced - 25);
    }
  };

  // console.log(
  //   "priced",
  //   priced,
  //   "selectedAmount",
  //   selectedAmount,
  //   shipInternational
  // );
  return (
    <>
      <div>
        <div
          style={{
            backgroundImage: `url("https://res.cloudinary.com/dqy3fktlv/image/upload/v1700665979/early-summer-e1548458622345_m6ipud_x9zr95.jpg")`,
            height: "100vh",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}></div>
        <div
          className="classicProductContainer"
          style={{ display: "flex", justifyContent: "space-around" }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              margin: "1%",
            }}
          >
            <h1>{name}</h1>
            <p style={{ margin: "1%" }}>
              Discover Organization and Style with the New Calendar<br></br>
              <br></br>
              Effortlessly manage your schedule while adding a touch of elegance
              to your space with the New Calendar. Embrace the perfect blend of
              functionality and aesthetic appeal to keep your days organized and
              inspired.
              <br></br>
              <br></br>
              Key Features:
              <br></br>
              <br></br>
              Synchronize your time:<br></br>
              Plan your days, weeks, and months with ease. The New Calendar was
              designed to make time work for you. With fully compatible units
              that are divisible and uniform, you can organize your time how you
              want to.
              <br></br>
              <br></br>
              Fully Compatible:<br></br>
              Elevate your wallspace with the stylish design of our calendar.
              Featuring an apple tree changing through the seasons as well as
              average sunlight and temperature charts for 3 latitudes across the
              US. Each page is a work of art, blending form and function
              seamlessly to enhance your environment.
              <br></br>
              <br></br>
              Lasts forever:<br></br>
              Jot down notes, reminders, and goals effortlessly. The New
              Calendar is an anchored calendar system, meaning its days and
              dates will not shift from one year to the next. This allows you to
              write down birthdays, anniversaries, and other annual reminders
              with the knowledge that at the end of the year you can restart the
              calendar without having to fill it in again.
              <br></br>
              <br></br>
              Sunlight and Temperature Charts:<br></br>
              Built to last, our calendar is made with high-quality materials
              that withstand daily use. Enjoy the reliability of a calendar that
              complements your lifestyle.
              <br></br>
              <br></br>
              Year-Long Tips and Reminders:<br></br>
              Always be prepared for the season. Each month comes with a
              reminder of classic traditions, small talk, and holiday reminders.
              Every calendar also comes with a User Guide that dives into the
              history, science, and logic behind calendar systems, including a
              FAQ section.
              <br></br>
              <br></br>
              Why Choose the New Calendar?<br></br>
              <br></br>
              The New Calendar is more than a tool for time management; it's a
              statement of style and organization. Whether you're a busy
              professional, a student, or someone who appreciates a
              well-organized life, the New Calendar calendar is the perfect
              companion.
              <br></br>
              <br></br>
              Make each day count with the elegance and functionality of the New
              Calendar. Transform your scheduling experience and embrace a more
              organized, inspired life.
            </p>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h2>$ {priced}</h2>
            <div
              className="amountSelector"
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                margin: "5%",
                width: "50%",
              }}
            >
              <label htmlFor="quantity">Quantity: </label>
              <select
                id="quantity"
                value={selectedAmount}
                onChange={handlePriceChange}
              >
                {/* <option value="1">Select</option> */}
                <option value="1">1</option>
                <option value="3">3</option>
                <option value="5">5</option>
              </select>
            </div>
            <div
              className="amountSelector"
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                margin: "5%",
                width: "50%",
              }}
            >
              <label>Shipping outside US? </label>
              <div>
                <label htmlFor="shippingNo" className="radioLabel">
                  <input
                    type="radio"
                    id="shippingNo"
                    name="shipping"
                    value="false"
                    checked={shipInternational === "false"}
                    onChange={handleShippingChange}
                  />
                  No
                </label>
                <br></br>
                <label htmlFor="shippingYes" className="radioLabel">
                  <input
                    type="radio"
                    id="shippingYes"
                    name="shipping"
                    value="true"
                    checked={shipInternational === "true"}
                    onChange={handleShippingChange}
                  />
                  Yes
                </label>
              </div>
            </div>
            <PayPalClassicBuy newPrice={priced} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ClassicProduct;
