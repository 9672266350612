import React from "react";

const SaleButtons = ({ buttonA, buttonB, shoppingRoute, appRoute }) => {
  return (
    <>
      <div
        className="callToAction_container"
        style={{
          backgroundColor: "transparent",
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <div
          className="flex_container"
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            width: "100%",
            backgroundColor: "transparent",
          }}
        >
          <div
            className="button"
            style={{
              textAlign: "center",
              height: "10vh",
              width: "30%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "8%",
            }}
            onClick={shoppingRoute}
          >
            {buttonA}
          </div>
          {/* <div
            className="button"
            style={{
              textAlign: "center",
              height: "10vh",
              width: "30%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "8%",
            }}
            onClick={appRoute}
          >
            {buttonB}
          </div> */}
        </div>
      </div>
    </>
  );
};

export default SaleButtons;
