import React, { useEffect } from "react";
import ShopifyBuy from "@shopify/buy-button-js";

function ProductCompTwo() {
  useEffect(() => {
    const scriptURL =
      "https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js";

    if (window.ShopifyBuy) {
      if (window.ShopifyBuy.UI) {
        ShopifyBuyInit();
      } else {
        loadScript();
      }
    } else {
      loadScript();
    }

    function loadScript() {
      const script = document.createElement("script");
      script.async = true;
      script.src = scriptURL;
      (
        document.getElementsByTagName("head")[0] ||
        document.getElementsByTagName("body")[0]
      ).appendChild(script);
      script.onload = ShopifyBuyInit;
    }

    function ShopifyBuyInit() {
      const client = ShopifyBuy.buildClient({
        domain: "8f932e.myshopify.com",
        storefrontAccessToken: "302e2b9fd2581f32422c9edf466ec849",
      });
      ShopifyBuy.UI.onReady(client).then(function (ui) {
        ui.createComponent("product", {
          id: "8393098854712",
          node: document.getElementById("product-component-1687806002513"),
          moneyFormat: "%24%7B%7Bamount%7D%7D",
          options: {
            product: {
              styles: {
                product: {
                  "@media (min-width: 601px)": {
                    maxWidth: "calc(25% - 20px)",
                    marginLeft: "20px",
                    marginBottom: "50px",
                  },
                },
              },
              buttonDestination: "checkout",
              text: {
                button: "Buy now",
              },
            },
            productSet: {
              styles: {
                products: {
                  "@media (min-width: 601px)": {
                    marginLeft: "-20px",
                  },
                },
              },
            },
            modalProduct: {
              contents: {
                img: false,
                imgWithCarousel: true,
                button: false,
                buttonWithQuantity: true,
              },
              styles: {
                product: {
                  "@media (min-width: 601px)": {
                    maxWidth: "100%",
                    marginLeft: "0px",
                    marginBottom: "0px",
                  },
                },
              },
              text: {
                button: "Add to cart",
              },
            },
            option: {},
            cart: {
              text: {
                total: "Subtotal",
                button: "Checkout",
              },
            },
            toggle: {},
          },
        });
      });
    }
  }, []);

  return <div id="product-component-1687806002513"></div>;
}

export default ProductCompTwo;
