let fobj = [
  {
    fortsOyear: 1, // 20
    fortsOseason: 1, // 4
    fortsOmonth: 1, // 2
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "Early",
    startDayIndex: 0,
    endDayIndex: 17,
  },
  {
    fortsOyear: 2, // 20
    fortsOseason: 2, // 4
    fortsOmonth: 2, // 2
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "Late",
    startDayIndex: 18,
    endDayIndex: 35,
  },
  {
    fortsOyear: 3, // 20
    fortsOseason: 3, // 4
    fortsOmonth: 1, // 2
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "Early",
    startDayIndex: 37,
    endDayIndex: 54,
  },
  {
    fortsOyear: 4, // 20
    fortsOseason: 4, // 4
    fortsOmonth: 2, // 2
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "Late",
    startDayIndex: 55,
    endDayIndex: 72,
  },
  {
    fortsOyear: 5, // 20
    fortsOseason: 1, // 4
    fortsOmonth: 1, // 2
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "Early",
    startDayIndex: 73,
    endDayIndex: 90,
  },
  {
    fortsOyear: 6, // 20
    fortsOseason: 2, // 4
    fortsOmonth: 2, // 2
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "Late",
    startDayIndex: 91,
    endDayIndex: 108,
  },
  {
    fortsOyear: 7, // 20
    fortsOseason: 3, // 4
    fortsOmonth: 1, // 2
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "Early",
    startDayIndex: 110,
    endDayIndex: 127,
  },
  {
    fortsOyear: 8, // 20
    fortsOseason: 4, // 4
    fortsOmonth: 2, // 2
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "Late",
    startDayIndex: 128,
    endDayIndex: 145,
  },
  // SUMMER
  {
    fortsOyear: 9, // 20
    fortsOseason: 1, // 4
    fortsOmonth: 1, // 2
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "Early",
    startDayIndex: 146,
    endDayIndex: 163,
  },
  {
    fortsOyear: 10, // 20
    fortsOseason: 2, // 4
    fortsOmonth: 2, // 2
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "Late",
    startDayIndex: 164,
    endDayIndex: 181,
  },
  {
    fortsOyear: 11, // 20
    fortsOseason: 3, // 4
    fortsOmonth: 1, // 2
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "Early",
    startDayIndex: 183,
    endDayIndex: 200,
  },
  {
    fortsOyear: 12, // 20
    fortsOseason: 4, // 4
    fortsOmonth: 2, // 2
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "Late",
    startDayIndex: 201,
    endDayIndex: 218,
  },
  // AUTUMN
  {
    fortsOyear: 13, // 20
    fortsOseason: 1, // 4
    fortsOmonth: 1, // 2
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "Early",
    startDayIndex: 219,
    endDayIndex: 236,
  },
  {
    fortsOyear: 14, // 20
    fortsOseason: 2, // 4
    fortsOmonth: 2, // 2
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "Late",
    startDayIndex: 237,
    endDayIndex: 254,
  },
  {
    fortsOyear: 15, // 20
    fortsOseason: 3, // 4
    fortsOmonth: 1, // 2
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "Early",
    startDayIndex: 256,
    endDayIndex: 273,
  },
  {
    fortsOyear: 16, // 20
    fortsOseason: 4, // 4
    fortsOmonth: 2, // 2
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "Late",
    startDayIndex: 274,
    endDayIndex: 291,
  },
  // FALL
  {
    fortsOyear: 17, // 20
    fortsOseason: 1, // 4
    fortsOmonth: 1, // 2
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "Early",
    startDayIndex: 292,
    endDayIndex: 309,
  },
  {
    fortsOyear: 18, // 20
    fortsOseason: 2, // 4
    fortsOmonth: 2, // 2
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "Late",
    startDayIndex: 310,
    endDayIndex: 327,
  },
  {
    fortsOyear: 19, // 20
    fortsOseason: 3, // 4
    fortsOmonth: 1, // 2
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "Early",
    startDayIndex: 329,
    endDayIndex: 346,
  },
  {
    fortsOyear: 20, // 20
    fortsOseason: 4, // 4
    fortsOmonth: 2, // 2
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "Late",
    startDayIndex: 347,
    endDayIndex: 364,
  },
];

export default fobj;
