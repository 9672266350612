import React from "react";
import SaleButtons from "./SaleButtons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const AFeature = (props) => {
  const copy = props;
  //   console.log(copy);
  const history = useHistory();

  const shoppingRoute = (event) => {
    history.push(`/shop`);
    console.log("its clicked");
  };
  const appRoute = (event) => {
    history.push(`/newcalendar_mobileapp`);
  };

  return (
    <>
      <div
        className="featureA_copywriting"
        style={{
          backgroundImage: `url("${copy.backgroundImg}")`,
          // width: "50%",
          backgroundSize: "cover",
          height: "100vh",
          backgroundRepeat: "no-repeat",
          // backgroundPosition: "center bottom",
        }}
      >
        <div
          className="a_copy_container"
          style={
            {
              // display: "flex",
              // flexDirection: "column",
              // alignItems: "center",
            }
          }
        >
          <h2
            style={{
              width: "auto",
              padding: "1%",
              // display: "flex",
              // flexDirection: "column",
              // alignItems: "center",
              // justifyContent: "center",
              textAlign: "center",
            }}
          >
            {copy.tagline}
          </h2>
          <h4
            style={{
              width: "80%",
              padding: "1%",
              margin: "1%",
              // display: "flex",
              // flexDirection: "column",
              // alignItems: "center",
              // justifyContent: "center",
              textAlign: "center",
            }}
          >
            {copy.description}
          </h4>
          <SaleButtons
            buttonA={"Get a Copy"}
            shoppingRoute={shoppingRoute}
            appRoute={appRoute}
            buttonB={"pre-order mobile app"}
          />
        </div>
      </div>
    </>
  );
};

export default AFeature;
