// import axios from "axios";
// import { useEffect, useState } from "react";

import IconParagraph from "../components/IconParagraph";

import response_eucalyp from "../images/time/response_eucalyp.png";
import time_is_money_slidicon from "../images/time/time-is-money_slidicon.png";
import measure_creaticcacreativeagency from "../images/time/measure_creaticcacreativeagency.png";

const Blog = () => {
  // const [blog, setBlog] = useState([""]);
  // const [toggle, setToggle] = useState(false);
  // const [viewCard, setViewCard] = useState(false);

  // useEffect(() => {
  //   axios
  //     .get("https://whats-n-da-fridge.herokuapp.com/api/blog/")
  //     .then((response) => setBlog(response.data))
  //     .catch((error) => // // console.log(error.message, error.stack));
  // }, [toggle]);

  // const cardClick = () => {
  //   setViewCard(!viewCard);
  // };

  // let displayBlog = blog;

  return (
    <>
      <h2>It's About Time:</h2>

      <IconParagraph
        image={measure_creaticcacreativeagency}
        headline={"Time is MEASUREMENT"}
        description={`Time, in its simplest form, is a measurement of distance. Humans developed time as a concept in order to track, analyze, and record (i.e. measure) significant moments in Earth's orbit-- the day, the full moon, and the year-- all of which at their core, represent the distance it takes to a complete a full orbit. Until The New Calendar, time was the only measurement system built and last updated before the Scientific Revolution, which is why it seems more like a mystical substance than a true measurement.`}
      />
      <IconParagraph
        image={response_eucalyp}
        headline={"Time is DATA"}
        description={
          "Time is data. Time is the measurement we use to capture changes across long distances (weeks, months, years, decades). In order to capture significant data, a measurement system must contain uniform, logical units. Otherwise, if, say, a timekeeping system contains a sequence of the same unit that was 28 days long, then 31 days long, then 30 days long, any measurements recorded would be incomparable without painstaking adjustments."
        }
      />
      <IconParagraph
        image={time_is_money_slidicon}
        headline={"Time is MONEY"}
        description={
          "Time is money: measure it well and spend it wisely. Time is the most precious resource that every person has equal access to daily. How efficiently and effectively a person manages their time is so crucial to wealth, health, and happiness. The New Calendar helps users achieve this state by organizing time in the most precise, accurate, logical, and natural system ever developed in the history of time."
        }
      />
      {/* <div>
        {displayBlog.map((post) => {
          return (
            <div key={post.id}>
              <h2 onClick={cardClick}>{post.title}</h2>
              {viewCard ? (
                <div>
                  <h4>{post.date}</h4>
                  <p>{post.post}</p>
                </div>
              ) : null}
            </div>
          );
        })}
      </div> */}
    </>
  );
};

export default Blog;
