// import { useParams } from "react-router-dom/cjs/react-router-dom.min";
// import useFetch from "../hooks/useFetch";

import { useState } from "react";
import useScrollToTop from "../../blocks/logic/usScrollToTop";
import PayPalWholesaleBuy from "./PayPalWholesaleBuy";

const WholesaleProduct = ({ name, slug, image, description, price }) => {
  useScrollToTop();

  const [selectedAmount, setSelectedAmount] = useState(1);
  const [priced, setPrice] = useState(500);
  const handleSelectChange = (event) => {
    setSelectedAmount(event.target.value);
    if (parseInt(event.target.value) === 1) {
      setPrice(500);
    } else if (parseInt(event.target.value) === 3) {
      setPrice(1000);
    } else if (parseInt(event.target.value) === 5) {
      setPrice(1500);
    }
  };

  return (
    <>
      <div>
        <div
          style={{
            backgroundImage: `url("${image}")`,
            height: "100vh",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <h1>{name}</h1>
          {selectedAmount && <h2>$ {priced}</h2>}
        </div>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <p style={{ margin: "1%" }}>{description}</p>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              className="amountSelector"
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                margin: "5%",
                width: "50%",
              }}
            >
              <label htmlFor="quantity">Quantity: </label>
              <select
                id="quantity"
                value={selectedAmount}
                onChange={handleSelectChange}
              >
                <option value="1">Select</option>
                <option value="1">Case</option>
                <option value="3">5 Cases</option>
                <option value="5">10 Cases</option>
              </select>
            </div>
            <PayPalWholesaleBuy newPrice={priced} />
          </div>
        </div>
      </div>
    </>
  );
};

export default WholesaleProduct;
