const stNdTh = (number) => {
  let suffix = "th";

  if (
    number === 1 ||
    number === 21 ||
    number === 31 ||
    number === 41 ||
    number === 51 ||
    number === 61 ||
    number === 71
  ) {
    suffix = "st";
  } else if (
    number === 2 ||
    number === 22 ||
    number === 32 ||
    number === 42 ||
    number === 52 ||
    number === 62 ||
    number === 72
  ) {
    suffix = "nd";
  } else if (
    number === 3 ||
    number === 23 ||
    number === 33 ||
    number === 43 ||
    number === 53 ||
    number === 63 ||
    number === 73
  ) {
    suffix = "rd";
  }

  return suffix;
};

export default stNdTh;
