const colorCode = (array) => {
  let time = new Date();
  let month = time.getMonth();
  let day = time.getDay();

  if ((month === 12 && day >= 21) || month <= 2 || (month === 3 && day <= 3)) {
    array.push("winter");
    // // console.log("time", month, day, array);
  } else if (
    (month === 3 && day >= 4) ||
    month === 4 ||
    (month === 5 && day <= 15)
  ) {
    array.push("spring");
    // // console.log("time", month, day, array);
  } else if (
    (month === 5 && day >= 16) ||
    month === 6 ||
    (month === 7 && day <= 27)
  ) {
    array.push("summer");
    // // console.log("time", month, day, array);
  } else if (
    (month === 7 && day >= 28) ||
    month === 8 ||
    month === 9 ||
    (month === 10 && day <= 8)
  ) {
    array.push("autumn");
    // // console.log("time", month, day, array);
  } else if (
    (month === 10 && day >= 9) ||
    month === 11 ||
    (month === 12 && day <= 20)
  ) {
    array.push("fall");
    // // console.log("time", month, day, array);
  }

  let newClass = array.toString().split(",").join(" ");
  // // console.log(newClass);

  return newClass;
};
export default colorCode;
