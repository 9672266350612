let dobj = [
  {
    daysOyear: 1, // 365
    daysOseason: 1, // 73
    daySeasonComps: [0, 73, 146, 219, 292],
    daysOmonth: 1, // 36
    dayMonthComps: [0, 37, 73, 110, 146, 183, 219, 256, 292, 329],
    daysOfortnight: 1, // 18
    dayFortnightComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
    ],
    daysOweek: 1, // 9
    dayWeekComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: "New New Years",
    dayOfYearURL: "",
    dayNameWithinSeason: ["1st of Winter", "Winter 1", "Win 1"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["1st of Early Winter", "Early Winter 1", "E-Win 1"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mercury Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1707/mercuryflyby2_messenger_1024.png",

    dayOfTabURL: "",
  },
  {
    daysOyear: 2, // 365
    daysOseason: 2, // 73
    daySeasonComps: [1, 74, 147, 220, 293],
    daysOmonth: 2, // 36
    dayMonthComps: [1, 38, 74, 111, 147, 184, 220, 257, 293, 330],
    daysOfortnight: 2, // 18
    dayFortnightComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
    ],
    daysOweek: 2, // 9
    dayWeekComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["2nd of Winter", "Winter 2", "Win 2"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["2nd of Early Winter", "Early Winter 2", "E-Win 2"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Venus Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/2009/VenusClouds_Akatzuki_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 3, // 365
    daysOseason: 3, // 73
    daySeasonComps: [2, 75, 148, 221, 294],
    daysOmonth: 3, // 36
    dayMonthComps: [2, 39, 75, 112, 148, 185, 221, 258, 294, 331],
    daysOfortnight: 3, // 18
    dayFortnightComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
    ],
    daysOweek: 3, // 9
    dayWeekComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: "Festivus",
    dayOfYearURL: "",
    dayNameWithinSeason: ["3rd of Winter", "Winter 3", "Win 3"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["3rd of Early Winter", "Early Winter 3", "E-Win 3"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Earth Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1201/bluemarbleearth_npp_980.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 4, // 365
    daysOseason: 4, // 73
    daySeasonComps: [3, 76, 149, 222, 295],
    daysOmonth: 4, // 36
    dayMonthComps: [3, 40, 76, 113, 149, 186, 222, 259, 295, 332],
    daysOfortnight: 4, // 18
    dayFortnightComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
    ],
    daysOweek: 4, // 9
    dayWeekComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: "Christmas Eve",
    dayOfYearURL: "",
    dayNameWithinSeason: ["4th of Winter", "Winter 4", "Win 4"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["4th of Early Winter", "Early Winter 4", "E-Win 4"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mars Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/2010/m2020_09_22Adp.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 5, // 365
    daysOseason: 5, // 73
    daySeasonComps: [4, 77, 150, 223, 296],
    daysOmonth: 5, // 36
    dayMonthComps: [4, 41, 77, 114, 150, 187, 223, 260, 296, 333],
    daysOfortnight: 5, // 18
    dayFortnightComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
    ],
    daysOweek: 5, // 9
    dayWeekComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: "Christmas",
    dayOfYearURL: "",
    dayNameWithinSeason: ["5th of Winter", "Winter 5", "Win 5"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["5th of Early Winter", "Early Winter 5", "E-Win 5"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Jupiter Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1905/JupiterMarble_JunoGill_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 6, // 363
    daysOseason: 6, // 73
    daySeasonComps: [5, 78, 151, 224, 297],
    daysOmonth: 6, // 36
    dayMonthComps: [5, 42, 78, 115, 151, 188, 224, 261, 297, 334],
    daysOfortnight: 6, // 18
    dayFortnightComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
    ],
    daysOweek: 6, // 9
    dayWeekComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["6th of Winter", "Winter 6", "Win 6"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["6th of Early Winter", "Early Winter 6", "E-Win 6"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Saturn Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1609/SaturnAbove_Cassini_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 7, // 365
    daysOseason: 7, // 73
    daySeasonComps: [6, 79, 152, 225, 298],
    daysOmonth: 7, // 36
    dayMonthComps: [6, 43, 79, 116, 152, 189, 225, 262, 298, 335],
    daysOfortnight: 7, // 18
    dayFortnightComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
    ],
    daysOweek: 7, // 9
    dayWeekComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["7th of Winter", "Winter 7", "Win 7"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["7th of Early Winter", "Early Winter 7", "E-Win 7"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Uranus Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/0411/uranus_keck_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 8, // 365
    daysOseason: 8, // 73
    daySeasonComps: [7, 80, 153, 226, 299],
    daysOmonth: 8, // 36
    dayMonthComps: [7, 44, 80, 117, 153, 190, 226, 263, 299, 336],
    daysOfortnight: 8, // 18
    dayFortnightComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
    ],
    daysOweek: 8, // 9
    dayWeekComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["8th of Winter", "Winter 8", "Win 8"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["8th of Early Winter", "Early Winter 8", "E-Win 8"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Neptune Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/0406/nep2002_hst1pan_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 9, // 365
    daysOseason: 9, // 73
    daySeasonComps: [8, 81, 154, 227, 300],
    daysOmonth: 9, // 36
    dayMonthComps: [8, 45, 81, 118, 154, 191, 227, 264, 300, 337],
    daysOfortnight: 9, // 18
    dayFortnightComps: [
      8,
      26,
      45,
      63,
      81,
      99,
      118,
      136,
      154,
      172,
      191,
      209,
      227,
      245,
      264,
      282,
      300,
      318,
      337,
      355,
    ],
    daysOweek: 9, // 9
    dayWeekComps: [
      8,
      17,
      26,
      35,
      45,
      54,
      63,
      72,
      81,
      90,
      99,
      108,
      118,
      127,
      136,
      145,
      154,
      163,
      172,
      181,
      191,
      200,
      209,
      218,
      227,
      236,
      245,
      254,
      264,
      273,
      282,
      291,
      300,
      309,
      318,
      327,
      337,
      346,
      355,
      364,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["9th of Winter", "Winter 9", "Win 9"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["9th of Early Winter", "Early Winter 9", "E-Win 9"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Pluto Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1909/PlutoTrueColor_NewHorizons_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 10, // 365
    daysOseason: 10, // 73
    daySeasonComps: [9, 82, 155, 228, 301],
    daysOmonth: 10, // 36
    dayMonthComps: [9, 46, 82, 119, 155, 192, 228, 265, 301, 338],
    daysOfortnight: 10, // 18
    dayFortnightComps: [
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOweek: 1, // 9
    dayWeekComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["10th of Winter", "Winter 10", "Win 10"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["10th of Early Winter", "Early Winter 10", "E-Win 10"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mercury Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1707/mercuryflyby2_messenger_1024.png",

    dayOfTabURL: "",
  },
  {
    daysOyear: 11, // 365
    daysOseason: 11, // 73
    daySeasonComps: [10, 83, 156, 229, 302],
    daysOmonth: 11, // 36
    dayMonthComps: [10, 47, 83, 120, 156, 193, 229, 266, 302, 339],
    daysOfortnight: 11, // 18
    dayFortnightComps: [
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOweek: 2, // 9
    dayWeekComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["11th of Winter", "Winter 11", "Win 11"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["11th of Early Winter", "Early Winter 11", "E-Win 11"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Venus Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/2009/VenusClouds_Akatzuki_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 12, // 365
    daysOseason: 12, // 73
    daySeasonComps: [11, 84, 157, 230, 303],
    daysOmonth: 12, // 36
    dayMonthComps: [11, 48, 84, 121, 157, 194, 230, 267, 303, 340],
    daysOfortnight: 12, // 18
    dayFortnightComps: [
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOweek: 3, // 9
    dayWeekComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: "Old New Years",
    dayOfYearURL: "",
    dayNameWithinSeason: ["12th of Winter", "Winter 12", "Win 12"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["12th of Early Winter", "Early Winter 12", "E-Win 12"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Earth Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1201/bluemarbleearth_npp_980.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 13, // 365
    daysOseason: 13, // 73
    daySeasonComps: [12, 85, 158, 231, 304],
    daysOmonth: 13, // 36
    dayMonthComps: [12, 49, 85, 122, 158, 195, 231, 268, 304, 341],
    daysOfortnight: 13, // 18
    dayFortnightComps: [
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOweek: 4, // 9
    dayWeekComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["13th of Winter", "Winter 13", "Win 13"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["13th of Early Winter", "Early Winter 13", "E-Win 13"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mars Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/2010/m2020_09_22Adp.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 14, // 365
    daysOseason: 14, // 73
    daySeasonComps: [13, 86, 159, 232, 305],
    daysOmonth: 14, // 36
    dayMonthComps: [13, 50, 86, 123, 159, 196, 232, 269, 305, 342],
    daysOfortnight: 14, // 18
    dayFortnightComps: [
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOweek: 5, // 9
    dayWeekComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["14th of Winter", "Winter 14", "Win 14"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["14th of Early Winter", "Early Winter 14", "E-Win 14"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Jupiter Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1905/JupiterMarble_JunoGill_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 15, // 365
    daysOseason: 15, // 73
    daySeasonComps: [14, 87, 160, 233, 306],
    daysOmonth: 15, // 36
    dayMonthComps: [14, 51, 87, 124, 160, 197, 233, 270, 306, 343],
    daysOfortnight: 15, // 18
    dayFortnightComps: [
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOweek: 6, // 9
    dayWeekComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["15th of Winter", "Winter 15", "Win 15"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["15th of Early Winter", "Early Winter 15", "E-Win 15"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Saturn Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1609/SaturnAbove_Cassini_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 16, // 365
    daysOseason: 16, // 73
    daySeasonComps: [15, 88, 161, 234, 307],
    daysOmonth: 16, // 36
    dayMonthComps: [15, 52, 88, 125, 161, 198, 234, 271, 307, 344],
    daysOfortnight: 16, // 18
    dayFortnightComps: [
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOweek: 7, // 9
    dayWeekComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["16th of Winter", "Winter 16", "Win 16"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["16th of Early Winter", "Early Winter 16", "E-Win 16"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Uranus Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/0411/uranus_keck_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 17, // 365
    daysOseason: 17, // 73
    daySeasonComps: [16, 89, 162, 235, 308],
    daysOmonth: 17, // 36
    dayMonthComps: [16, 53, 89, 126, 162, 199, 235, 272, 308, 345],
    daysOfortnight: 17, // 18
    dayFortnightComps: [
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOweek: 8, // 9
    dayWeekComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["17th of Winter", "Winter 17", "Win 17"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["17th of Early Winter", "Early Winter 17", "E-Win 17"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Neptune Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/0406/nep2002_hst1pan_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 18, // 365
    daysOseason: 18, // 73
    daySeasonComps: [17, 90, 163, 236, 309],
    daysOmonth: 18, // 36
    dayMonthComps: [17, 54, 90, 127, 163, 200, 236, 273, 309, 346],
    daysOfortnight: 18, // 18
    dayFortnightComps: [
      17,
      35,
      54,
      72,
      90,
      108,
      127,
      145,
      163,
      181,
      200,
      218,
      236,
      254,
      273,
      291,
      309,
      327,
      346,
      364,
    ],
    daysOweek: 9, // 9
    dayWeekComps: [
      8,
      17,
      26,
      35,
      45,
      54,
      63,
      72,
      81,
      90,
      99,
      108,
      118,
      127,
      136,
      145,
      154,
      163,
      172,
      181,
      191,
      200,
      209,
      218,
      227,
      236,
      245,
      254,
      264,
      273,
      282,
      291,
      300,
      309,
      318,
      327,
      337,
      346,
      355,
      364,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["18th of Winter", "Winter 18", "Win 18"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["18th of Early Winter", "Early Winter 18", "E-Win 18"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Pluto Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1909/PlutoTrueColor_NewHorizons_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 19, // 365
    daysOseason: 19, // 73
    daySeasonComps: [18, 91, 164, 237, 310],
    daysOmonth: 19, // 36
    dayMonthComps: [18, 55, 91, 128, 164, 201, 237, 274, 310, 347],
    daysOfortnight: 1, // 18
    dayFortnightComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
    ],
    daysOweek: 1, // 9
    dayWeekComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["19th of Winter", "Winter 19", "Win 19"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["19th of Early Winter", "Early Winter 19", "E-Win 19"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mercury Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1707/mercuryflyby2_messenger_1024.png",

    dayOfTabURL: "",
  },
  {
    daysOyear: 20, // 365
    daysOseason: 20, // 73
    daySeasonComps: [19, 92, 165, 238, 311],
    daysOmonth: 20, // 36
    dayMonthComps: [19, 56, 92, 129, 165, 202, 238, 275, 311, 348],
    daysOfortnight: 2, // 18
    dayFortnightComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
    ],
    daysOweek: 2, // 9
    dayWeekComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["20th of Winter", "Winter 20", "Win 20"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["20th of Early Winter", "Early Winter 20", "E-Win 20"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Venus Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/2009/VenusClouds_Akatzuki_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 21, // 365
    daysOseason: 21, // 73
    daySeasonComps: [20, 93, 166, 239, 312],
    daysOmonth: 21, // 36
    dayMonthComps: [20, 57, 93, 130, 166, 203, 239, 276, 312, 349],
    daysOfortnight: 3, // 18
    dayFortnightComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
    ],
    daysOweek: 3, // 9
    dayWeekComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["21st of Winter", "Winter 21", "Win 21"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["21st of Early Winter", "Early Winter 21", "E-Win 21"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Earth Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1201/bluemarbleearth_npp_980.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 22, // 365
    daysOseason: 22, // 73
    daySeasonComps: [21, 94, 167, 240, 313],
    daysOmonth: 22, // 36
    dayMonthComps: [21, 58, 94, 131, 167, 204, 240, 277, 313, 350],
    daysOfortnight: 4, // 18
    dayFortnightComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
    ],
    daysOweek: 4, // 9
    dayWeekComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["22nd of Winter", "Winter 22", "Win 22"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["22nd of Early Winter", "Early Winter 22", "E-Win 22"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mars Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/2010/m2020_09_22Adp.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 23, // 365
    daysOseason: 23, // 73
    daySeasonComps: [22, 95, 168, 241, 314],
    daysOmonth: 23, // 36
    dayMonthComps: [22, 59, 95, 132, 168, 205, 241, 278, 314, 351],
    daysOfortnight: 5, // 18
    dayFortnightComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
    ],
    daysOweek: 5, // 9
    dayWeekComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["23rd of Winter", "Winter 23", "Win 23"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["23rd of Early Winter", "Early Winter 23", "E-Win 23"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Jupiter Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1905/JupiterMarble_JunoGill_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 24, // 363
    daysOseason: 24, // 73
    daySeasonComps: [23, 96, 169, 242, 315],
    daysOmonth: 24, // 36
    dayMonthComps: [23, 60, 96, 133, 169, 206, 242, 279, 315, 352],
    daysOfortnight: 6, // 18
    dayFortnightComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
    ],
    daysOweek: 6, // 9
    dayWeekComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["24th of Winter", "Winter 24", "Win 24"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["24th of Early Winter", "Early Winter 24", "E-Win 24"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Saturn Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1609/SaturnAbove_Cassini_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 25, // 365
    daysOseason: 25, // 73
    daySeasonComps: [24, 97, 170, 243, 316],
    daysOmonth: 25, // 36
    dayMonthComps: [24, 61, 97, 134, 170, 207, 243, 280, 316, 353],
    daysOfortnight: 7, // 18
    dayFortnightComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
    ],
    daysOweek: 7, // 9
    dayWeekComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["25th of Winter", "Winter 25", "Win 25"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["25th of Early Winter", "Early Winter 25", "E-Win 25"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Uranus Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/0411/uranus_keck_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 26, // 365
    daysOseason: 26, // 73
    daySeasonComps: [25, 98, 171, 244, 317],
    daysOmonth: 26, // 36
    dayMonthComps: [25, 62, 98, 135, 171, 208, 244, 281, 317, 354],
    daysOfortnight: 8, // 18
    dayFortnightComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
    ],
    daysOweek: 8, // 9
    dayWeekComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["26th of Winter", "Winter 26", "Win 26"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["26th of Early Winter", "Early Winter 26", "E-Win 26"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Neptune Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/0406/nep2002_hst1pan_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 27, // 365
    daysOseason: 27, // 73
    daySeasonComps: [26, 99, 172, 245, 318],
    daysOmonth: 27, // 36
    dayMonthComps: [26, 63, 99, 136, 172, 209, 245, 282, 318, 355],
    daysOfortnight: 9, // 18
    dayFortnightComps: [
      8,
      26,
      45,
      63,
      81,
      99,
      118,
      136,
      154,
      172,
      191,
      209,
      227,
      245,
      264,
      282,
      300,
      318,
      337,
      355,
    ],
    daysOweek: 9, // 9
    dayWeekComps: [
      8,
      17,
      26,
      35,
      45,
      54,
      63,
      72,
      81,
      90,
      99,
      108,
      118,
      127,
      136,
      145,
      154,
      163,
      172,
      181,
      191,
      200,
      209,
      218,
      227,
      236,
      245,
      254,
      264,
      273,
      282,
      291,
      300,
      309,
      318,
      327,
      337,
      346,
      355,
      364,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["27th of Winter", "Winter 27", "Win 27"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["27th of Early Winter", "Early Winter 27", "E-Win 27"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Pluto Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1909/PlutoTrueColor_NewHorizons_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 28, // 365
    daysOseason: 28, // 73
    daySeasonComps: [27, 100, 173, 246, 319],
    daysOmonth: 28, // 36
    dayMonthComps: [27, 64, 100, 137, 173, 210, 246, 283, 319, 356],
    daysOfortnight: 10, // 18
    dayFortnightComps: [
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOweek: 1, // 9
    dayWeekComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["28th of Winter", "Winter 28", "Win 28"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["28th of Early Winter", "Early Winter 28", "E-Win 28"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mercury Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1707/mercuryflyby2_messenger_1024.png",

    dayOfTabURL: "",
  },
  {
    daysOyear: 29, // 365
    daysOseason: 29, // 73
    daySeasonComps: [28, 101, 174, 247, 320],
    daysOmonth: 29, // 36
    dayMonthComps: [28, 65, 101, 138, 174, 211, 247, 284, 320, 357],
    daysOfortnight: 11, // 18
    dayFortnightComps: [
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOweek: 2, // 9
    dayWeekComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["29th of Winter", "Winter 29", "Win 29"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["29th of Early Winter", "Early Winter 29", "E-Win 29"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Venus Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/2009/VenusClouds_Akatzuki_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 30, // 365
    daysOseason: 30, // 73
    daySeasonComps: [29, 102, 175, 248, 321],
    daysOmonth: 30, // 36
    dayMonthComps: [29, 66, 102, 139, 175, 212, 248, 285, 321, 358],
    daysOfortnight: 12, // 18
    dayFortnightComps: [
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOweek: 3, // 9
    dayWeekComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["30th of Winter", "Winter 30", "Win 30"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["30th of Early Winter", "Early Winter 30", "E-Win 30"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Earth Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1201/bluemarbleearth_npp_980.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 31, // 365
    daysOseason: 31, // 73
    daySeasonComps: [30, 103, 176, 249, 322],
    daysOmonth: 31, // 36
    dayMonthComps: [30, 67, 103, 140, 176, 213, 249, 286, 322, 359],
    daysOfortnight: 13, // 18
    dayFortnightComps: [
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOweek: 4, // 9
    dayWeekComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["31st of Winter", "Winter 31", "Win 31"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["31st of Early Winter", "Early Winter 31", "E-Win 31"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mars Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/2010/m2020_09_22Adp.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 32, // 365
    daysOseason: 32, // 73
    daySeasonComps: [31, 104, 177, 250, 323],
    daysOmonth: 32, // 36
    dayMonthComps: [31, 68, 104, 141, 177, 214, 250, 287, 323, 360],
    daysOfortnight: 14, // 18
    dayFortnightComps: [
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOweek: 5, // 9
    dayWeekComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["32nd of Winter", "Winter 32", "Win 32"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["32nd of Early Winter", "Early Winter 32", "E-Win 32"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Jupiter Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1905/JupiterMarble_JunoGill_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 33, // 365
    daysOseason: 33, // 73
    daySeasonComps: [32, 105, 178, 251, 324],
    daysOmonth: 33, // 36
    dayMonthComps: [32, 69, 105, 142, 178, 215, 251, 288, 324, 361],
    daysOfortnight: 15, // 18
    dayFortnightComps: [
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOweek: 6, // 9
    dayWeekComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["33rd of Winter", "Winter 33", "Win 33"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["33rd of Early Winter", "Early Winter 33", "E-Win 33"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Saturn Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1609/SaturnAbove_Cassini_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 34, // 365
    daysOseason: 34, // 73
    daySeasonComps: [33, 106, 179, 252, 325],
    daysOmonth: 34, // 36
    dayMonthComps: [33, 70, 106, 143, 179, 216, 252, 289, 325, 362],
    daysOfortnight: 16, // 18
    dayFortnightComps: [
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOweek: 7, // 9
    dayWeekComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["34th of Winter", "Winter 34", "Win 34"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["34th of Early Winter", "Early Winter 34", "E-Win 34"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Uranus Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/0411/uranus_keck_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 35, // 365
    daysOseason: 35, // 73
    daySeasonComps: [34, 107, 180, 253, 326],
    daysOmonth: 35, // 36
    dayMonthComps: [34, 71, 107, 144, 180, 217, 253, 290, 326, 363],
    daysOfortnight: 17, // 18
    dayFortnightComps: [
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOweek: 8, // 9
    dayWeekComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["35th of Winter", "Winter 35", "Win 35"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["35th of Early Winter", "Early Winter 35", "E-Win 35"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Neptune Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/0406/nep2002_hst1pan_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 36, // 365
    daysOseason: 36, // 73
    daySeasonComps: [35, 108, 181, 254, 327],
    daysOmonth: 36, // 36
    dayMonthComps: [35, 72, 108, 145, 181, 218, 254, 291, 327, 364],
    daysOfortnight: 18, // 18
    dayFortnightComps: [
      17,
      35,
      54,
      72,
      90,
      108,
      127,
      145,
      163,
      181,
      200,
      218,
      236,
      254,
      273,
      291,
      309,
      327,
      346,
      364,
    ],
    daysOweek: 9, // 9
    dayWeekComps: [
      8,
      17,
      26,
      35,
      45,
      54,
      63,
      72,
      81,
      90,
      99,
      108,
      118,
      127,
      136,
      145,
      154,
      163,
      172,
      181,
      191,
      200,
      209,
      218,
      227,
      236,
      245,
      254,
      264,
      273,
      282,
      291,
      300,
      309,
      318,
      327,
      337,
      346,
      355,
      364,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["36th of Winter", "Winter 36", "Win 36"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["36th of Early Winter", "Early Winter 36", "E-Win 36"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Pluto Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1909/PlutoTrueColor_NewHorizons_960.jpg",

    dayOfTabURL: "",
  },

  {
    daysOyear: 37, // 365
    daysOseason: 37, // 73
    daySeasonComps: [36, 109, 182, 255, 328],
    daysOmidSeason: 1, // 5
    daysOmonth: null, // 36
    daysOfortnight: null, // 18
    daysOweek: null, // 9
    daysOtab: null, // 3
    dayNameWithinYear: ["Mid-Winter"],
    dayOfYearURL: "",
    dayNameWithinSeason: ["37th of Winter", "Winter 37", "Win 37"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["Mid-Winter"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: ["Mid-Winter"],
    dayOfFortnightURL: "",
    dayNameWithinWeek: ["Mid-Winter"],
    dayOfWeekURL: "",
    dayNameWithinTab: ["Mid-Winter"],
    dayOfTabURL: "",
  },

  {
    daysOyear: 38, // 365
    daysOseason: 38, // 73
    daySeasonComps: [37, 110, 183, 256, 329],
    daysOmonth: 1, // 36
    dayMonthComps: [0, 37, 73, 110, 146, 183, 219, 256, 292, 329],
    daysOfortnight: 1, // 18
    dayFortnightComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
    ],
    daysOweek: 1, // 9
    dayWeekComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["38th of Winter", "Winter 38", "Win 38"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["1st of Late Winter", "Late Winter 1", "L-Win 1"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mercury Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1707/mercuryflyby2_messenger_1024.png",

    dayOfTabURL: "",
  },
  {
    daysOyear: 39, // 365
    daysOseason: 39, // 73
    daySeasonComps: [38, 111, 184, 257, 330],
    daysOmonth: 2, // 36
    dayMonthComps: [1, 38, 74, 111, 147, 184, 220, 257, 293, 330],
    daysOfortnight: 2, // 18
    dayFortnightComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
    ],
    daysOweek: 2, // 9
    dayWeekComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["39th of Winter", "Winter 39", "Win 39"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["2nd of Late Winter", "Late Winter 2", "L-Win 2"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Venus Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/2009/VenusClouds_Akatzuki_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 40, // 365
    daysOseason: 40, // 73
    daySeasonComps: [39, 112, 185, 258, 331],
    daysOmonth: 3, // 36
    dayMonthComps: [2, 39, 75, 112, 148, 185, 221, 258, 294, 331],
    daysOfortnight: 3, // 18
    dayFortnightComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
    ],
    daysOweek: 3, // 9
    dayWeekComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["40th of Winter", "Winter 40", "Win 40"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["3rd of Late Winter", "Late Winter 3", "L-Win 3"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Earth Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1201/bluemarbleearth_npp_980.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 41, // 365
    daysOseason: 41, // 73
    daySeasonComps: [40, 113, 186, 259, 332],
    daysOmonth: 4, // 36
    dayMonthComps: [3, 40, 76, 113, 149, 186, 222, 259, 295, 332],
    daysOfortnight: 4, // 18
    dayFortnightComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
    ],
    daysOweek: 4, // 9
    dayWeekComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["41st of Winter", "Winter 41", "Win 41"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["4th of Late Winter", "Late Winter 4", "L-Win 4"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mars Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/2010/m2020_09_22Adp.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 42, // 365
    daysOseason: 42, // 73
    daySeasonComps: [41, 114, 187, 260, 333],
    daysOmonth: 5, // 36
    dayMonthComps: [4, 41, 77, 114, 150, 187, 223, 260, 296, 333],
    daysOfortnight: 5, // 18
    dayFortnightComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
    ],
    daysOweek: 5, // 9
    dayWeekComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["42nd of Winter", "Winter 42", "Win 42"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["5th of Late Winter", "Late Winter 5", "L-Win 5"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Jupiter Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1905/JupiterMarble_JunoGill_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 43, // 363
    daysOseason: 43, // 73
    daySeasonComps: [42, 115, 188, 261, 334],
    daysOmonth: 6, // 36
    dayMonthComps: [5, 42, 78, 115, 151, 188, 224, 261, 297, 334],
    daysOfortnight: 6, // 18
    dayFortnightComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
    ],
    daysOweek: 6, // 9
    dayWeekComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["43rd of Winter", "Winter 43", "Win 43"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["6th of Late Winter", "Late Winter 6", "L-Win 6"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Saturn Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1609/SaturnAbove_Cassini_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 44, // 365
    daysOseason: 44, // 73
    daySeasonComps: [43, 116, 189, 262, 335],
    daysOmonth: 7, // 36
    dayMonthComps: [6, 43, 79, 116, 152, 189, 225, 262, 298, 335],
    daysOfortnight: 7, // 18
    dayFortnightComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
    ],
    daysOweek: 7, // 9
    dayWeekComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: "Groundhog Day",
    dayOfYearURL: "",
    dayNameWithinSeason: ["44th of Winter", "Winter 44", "Win 44"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["7th of Late Winter", "Late Winter 7", "L-Win 7"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Uranus Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/0411/uranus_keck_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 45, // 365
    daysOseason: 45, // 73
    daySeasonComps: [44, 117, 190, 263, 336],
    daysOmonth: 8, // 36
    dayMonthComps: [7, 44, 80, 117, 153, 190, 226, 263, 299, 336],
    daysOfortnight: 8, // 18
    dayFortnightComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
    ],
    daysOweek: 8, // 9
    dayWeekComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["45th of Winter", "Winter 45", "Win 45"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["8th of Late Winter", "Late Winter 8", "L-Win 8"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Neptune Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/0406/nep2002_hst1pan_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 46, // 365
    daysOseason: 46, // 73
    daySeasonComps: [45, 118, 191, 264, 337],
    daysOmonth: 9, // 36
    dayMonthComps: [8, 45, 81, 118, 154, 191, 227, 264, 300, 337],
    daysOfortnight: 9, // 18
    dayFortnightComps: [
      8,
      26,
      45,
      63,
      81,
      99,
      118,
      136,
      154,
      172,
      191,
      209,
      227,
      245,
      264,
      282,
      300,
      318,
      337,
      355,
    ],
    daysOweek: 9, // 9
    dayWeekComps: [
      8,
      17,
      26,
      35,
      45,
      54,
      63,
      72,
      81,
      90,
      99,
      108,
      118,
      127,
      136,
      145,
      154,
      163,
      172,
      181,
      191,
      200,
      209,
      218,
      227,
      236,
      245,
      254,
      264,
      273,
      282,
      291,
      300,
      309,
      318,
      327,
      337,
      346,
      355,
      364,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["46th of Winter", "Winter 46", "Win 46"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["9th of Late Winter", "Late Winter 9", "L-Win 9"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Pluto Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1909/PlutoTrueColor_NewHorizons_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 47, // 365
    daysOseason: 47, // 73
    daySeasonComps: [46, 119, 192, 265, 338],
    daysOmonth: 10, // 36
    dayMonthComps: [9, 46, 82, 119, 155, 192, 228, 265, 301, 338],
    daysOfortnight: 10, // 18
    dayFortnightComps: [
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOweek: 1, // 9
    dayWeekComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["47th of Winter", "Winter 47", "Win 47"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["10th of Late Winter", "Late Winter 10", "L-Win 10"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mercury Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1707/mercuryflyby2_messenger_1024.png",

    dayOfTabURL: "",
  },
  {
    daysOyear: 48, // 365
    daysOseason: 48, // 73
    daySeasonComps: [47, 120, 193, 266, 339],
    daysOmonth: 11, // 36
    dayMonthComps: [10, 47, 83, 120, 156, 193, 229, 266, 302, 339],
    daysOfortnight: 11, // 18
    dayFortnightComps: [
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOweek: 2, // 9
    dayWeekComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["48th of Winter", "Winter 48", "Win 48"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["11th of Late Winter", "Late Winter 11", "L-Win 11"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Venus Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/2009/VenusClouds_Akatzuki_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 49, // 365
    daysOseason: 49, // 73
    daySeasonComps: [48, 121, 194, 267, 340],
    daysOmonth: 12, // 36
    dayMonthComps: [11, 48, 84, 121, 157, 194, 230, 267, 303, 340],
    daysOfortnight: 12, // 18
    dayFortnightComps: [
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOweek: 3, // 9
    dayWeekComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["49th of Winter", "Winter 49", "Win 49"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["12th of Late Winter", "Late Winter 12", "L-Win 12"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Earth Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1201/bluemarbleearth_npp_980.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 50, // 365
    daysOseason: 50, // 73
    daySeasonComps: [49, 122, 195, 268, 341],
    daysOmonth: 13, // 36
    dayMonthComps: [12, 49, 85, 122, 158, 195, 231, 268, 304, 341],
    daysOfortnight: 13, // 18
    dayFortnightComps: [
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOweek: 4, // 9
    dayWeekComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["50th of Winter", "Winter 50", "Win 50"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["13th of Late Winter", "Late Winter 13", "L-Win 13"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mars Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/2010/m2020_09_22Adp.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 51, // 365
    daysOseason: 51, // 73
    daySeasonComps: [50, 123, 196, 269, 342],
    daysOmonth: 14, // 36
    dayMonthComps: [13, 50, 86, 123, 159, 196, 232, 269, 305, 342],
    daysOfortnight: 14, // 18
    dayFortnightComps: [
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOweek: 5, // 9
    dayWeekComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["51st of Winter", "Winter 51", "Win 51"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["14th of Late Winter", "Late Winter 14", "L-Win 14"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Jupiter Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1905/JupiterMarble_JunoGill_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 52, // 365
    daysOseason: 52, // 73
    daySeasonComps: [51, 124, 197, 270, 343],
    daysOmonth: 15, // 36
    dayMonthComps: [14, 51, 87, 124, 160, 197, 233, 270, 306, 343],
    daysOfortnight: 15, // 18
    dayFortnightComps: [
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOweek: 6, // 9
    dayWeekComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["52nd of Winter", "Winter 52", "Win 52"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["15th of Late Winter", "Late Winter 15", "L-Win 15"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Saturn Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1609/SaturnAbove_Cassini_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 53, // 365
    daysOseason: 53, // 73
    daySeasonComps: [52, 125, 198, 271, 344],
    daysOmonth: 16, // 36
    dayMonthComps: [15, 52, 88, 125, 161, 198, 234, 271, 307, 344],
    daysOfortnight: 16, // 18
    dayFortnightComps: [
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOweek: 7, // 9
    dayWeekComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["53rd of Winter", "Winter 53", "Win 53"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["16th of Late Winter", "Late Winter 16", "L-Win 16"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Uranus Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/0411/uranus_keck_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 54, // 365
    daysOseason: 54, // 73
    daySeasonComps: [53, 126, 199, 272, 345],
    daysOmonth: 17, // 36
    dayMonthComps: [16, 53, 89, 126, 162, 199, 235, 272, 308, 345],
    daysOfortnight: 17, // 18
    dayFortnightComps: [
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOweek: 8, // 9
    dayWeekComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["54th of Winter", "Winter 54", "Win 54"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["17th of Late Winter", "Late Winter 17", "L-Win 17"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Neptune Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/0406/nep2002_hst1pan_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 55, // 365
    daysOseason: 55, // 73
    daySeasonComps: [54, 127, 200, 273, 346],
    daysOmonth: 18, // 36
    dayMonthComps: [17, 54, 90, 127, 163, 200, 236, 273, 309, 346],
    daysOfortnight: 18, // 18
    dayFortnightComps: [
      17,
      35,
      54,
      72,
      90,
      108,
      127,
      145,
      163,
      181,
      200,
      218,
      236,
      254,
      273,
      291,
      309,
      327,
      346,
      364,
    ],
    daysOweek: 9, // 9
    dayWeekComps: [
      8,
      17,
      26,
      35,
      45,
      54,
      63,
      72,
      81,
      90,
      99,
      108,
      118,
      127,
      136,
      145,
      154,
      163,
      172,
      181,
      191,
      200,
      209,
      218,
      227,
      236,
      245,
      254,
      264,
      273,
      282,
      291,
      300,
      309,
      318,
      327,
      337,
      346,
      355,
      364,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["55th of Winter", "Winter 55", "Win 55"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["18th of Late Winter", "Late Winter 18", "L-Win 18"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Pluto Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1909/PlutoTrueColor_NewHorizons_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 56, // 365
    daysOseason: 56, // 73
    daySeasonComps: [55, 128, 201, 274, 347],
    daysOmonth: 19, // 36
    dayMonthComps: [18, 55, 91, 128, 164, 201, 237, 274, 310, 347],
    daysOfortnight: 1, // 18
    dayFortnightComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
    ],
    daysOweek: 1, // 9
    dayWeekComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: "Valentine's Day",
    dayOfYearURL: "",
    dayNameWithinSeason: ["56th of Winter", "Winter 56", "Win 56"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["19th of Late Winter", "Late Winter 19", "L-Win 19"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mercury Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1707/mercuryflyby2_messenger_1024.png",

    dayOfTabURL: "",
  },
  {
    daysOyear: 57, // 365
    daysOseason: 57, // 73
    daySeasonComps: [56, 129, 202, 275, 348],
    daysOmonth: 20, // 36
    dayMonthComps: [19, 56, 92, 129, 165, 202, 238, 275, 311, 348],
    daysOfortnight: 2, // 18
    dayFortnightComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
    ],
    daysOweek: 2, // 9
    dayWeekComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["57th of Winter", "Winter 57", "Win 57"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["20th of Late Winter", "Late Winter 20", "L-Win 20"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Venus Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/2009/VenusClouds_Akatzuki_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 58, // 365
    daysOseason: 58, // 73
    daySeasonComps: [57, 130, 203, 276, 349],
    daysOmonth: 21, // 36
    dayMonthComps: [20, 57, 93, 130, 166, 203, 239, 276, 312, 349],
    daysOfortnight: 3, // 18
    dayFortnightComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
    ],
    daysOweek: 3, // 9
    dayWeekComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["58th of Winter", "Winter 58", "Win 58"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["21st of Late Winter", "Late Winter 21", "L-Win 21"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Earth Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1201/bluemarbleearth_npp_980.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 59, // 365
    daysOseason: 59, // 73
    daySeasonComps: [58, 131, 204, 277, 350],
    daysOmonth: 22, // 36
    dayMonthComps: [21, 58, 94, 131, 167, 204, 240, 277, 313, 350],
    daysOfortnight: 4, // 18
    dayFortnightComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
    ],
    daysOweek: 4, // 9
    dayWeekComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["59th of Winter", "Winter 59", "Win 59"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["22nd of Late Winter", "Late Winter 22", "L-Win 22"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mars Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/2010/m2020_09_22Adp.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 60, // 365
    daysOseason: 60, // 73
    daySeasonComps: [59, 132, 205, 278, 351],
    daysOmonth: 23, // 36
    dayMonthComps: [22, 59, 95, 132, 168, 205, 241, 278, 314, 351],
    daysOfortnight: 5, // 18
    dayFortnightComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
    ],
    daysOweek: 5, // 9
    dayWeekComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["60th of Winter", "Winter 60", "Win 60"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["23rd of Late Winter", "Late Winter 23", "L-Win 23"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Jupiter Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1905/JupiterMarble_JunoGill_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 61, // 363
    daysOseason: 61, // 73
    daySeasonComps: [60, 133, 206, 279, 352],
    daysOmonth: 24, // 36
    dayMonthComps: [23, 60, 96, 133, 169, 206, 242, 279, 315, 352],
    daysOfortnight: 6, // 18
    dayFortnightComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
    ],
    daysOweek: 6, // 9
    dayWeekComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["61st of Winter", "Winter 61", "Win 61"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["24th of Late Winter", "Late Winter 24", "L-Win 24"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Saturn Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1609/SaturnAbove_Cassini_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 62, // 365
    daysOseason: 62, // 73
    daySeasonComps: [61, 134, 207, 280, 353],
    daysOmonth: 25, // 36
    dayMonthComps: [24, 61, 97, 134, 170, 207, 243, 280, 316, 353],
    daysOfortnight: 7, // 18
    dayFortnightComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
    ],
    daysOweek: 7, // 9
    dayWeekComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["62nd of Winter", "Winter 62", "Win 62"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["25th of Late Winter", "Late Winter 25", "L-Win 25"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Uranus Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/0411/uranus_keck_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 63, // 365
    daysOseason: 63, // 73
    daySeasonComps: [62, 135, 208, 281, 354],
    daysOmonth: 26, // 36
    dayMonthComps: [25, 62, 98, 135, 171, 208, 244, 281, 317, 354],
    daysOfortnight: 8, // 18
    dayFortnightComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
    ],
    daysOweek: 8, // 9
    dayWeekComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["63rd of Winter", "Winter 63", "Win 63"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["26th of Late Winter", "Late Winter 26", "L-Win 26"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Neptune Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/0406/nep2002_hst1pan_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 64, // 365
    daysOseason: 64, // 73
    daySeasonComps: [63, 136, 209, 282, 355],
    daysOmonth: 27, // 36
    dayMonthComps: [26, 63, 99, 136, 172, 209, 245, 282, 318, 355],
    daysOfortnight: 9, // 18
    dayFortnightComps: [
      8,
      26,
      45,
      63,
      81,
      99,
      118,
      136,
      154,
      172,
      191,
      209,
      227,
      245,
      264,
      282,
      300,
      318,
      337,
      355,
    ],
    daysOweek: 9, // 9
    dayWeekComps: [
      8,
      17,
      26,
      35,
      45,
      54,
      63,
      72,
      81,
      90,
      99,
      108,
      118,
      127,
      136,
      145,
      154,
      163,
      172,
      181,
      191,
      200,
      209,
      218,
      227,
      236,
      245,
      254,
      264,
      273,
      282,
      291,
      300,
      309,
      318,
      327,
      337,
      346,
      355,
      364,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["64th of Winter", "Winter 64", "Win 64"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["27th of Late Winter", "Late Winter 27", "L-Win 27"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Pluto Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1909/PlutoTrueColor_NewHorizons_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 65, // 365
    daysOseason: 65, // 73
    daySeasonComps: [64, 137, 210, 283, 356],
    daysOmonth: 28, // 36
    dayMonthComps: [27, 64, 100, 137, 173, 210, 246, 283, 319, 356],
    daysOfortnight: 10, // 18
    dayFortnightComps: [
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOweek: 1, // 9
    dayWeekComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["65th of Winter", "Winter 65", "Win 65"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["28th of Late Winter", "Late Winter 28", "L-Win 28"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mercury Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1707/mercuryflyby2_messenger_1024.png",

    dayOfTabURL: "",
  },
  {
    daysOyear: 66, // 365
    daysOseason: 66, // 73
    daySeasonComps: [65, 138, 211, 284, 357],
    daysOmonth: 29, // 36
    dayMonthComps: [28, 65, 101, 138, 174, 211, 247, 284, 320, 357],
    daysOfortnight: 11, // 18
    dayFortnightComps: [
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOweek: 2, // 9
    dayWeekComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["66th of Winter", "Winter 66", "Win 66"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["29th of Late Winter", "Late Winter 29", "L-Win 29"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Venus Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/2009/VenusClouds_Akatzuki_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 67, // 365
    daysOseason: 67, // 73
    daySeasonComps: [66, 139, 212, 285, 358],
    daysOmonth: 30, // 36
    dayMonthComps: [29, 66, 102, 139, 175, 212, 248, 285, 321, 358],
    daysOfortnight: 12, // 18
    dayFortnightComps: [
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOweek: 3, // 9
    dayWeekComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["67th of Winter", "Winter 67", "Win 67"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["30th of Late Winter", "Late Winter 30", "L-Win 30"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Earth Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1201/bluemarbleearth_npp_980.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 68, // 365
    daysOseason: 68, // 73
    daySeasonComps: [67, 140, 213, 286, 359],
    daysOmonth: 31, // 36
    dayMonthComps: [30, 67, 103, 140, 176, 213, 249, 286, 322, 359],
    daysOfortnight: 13, // 18
    dayFortnightComps: [
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOweek: 4, // 9
    dayWeekComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["68th of Winter", "Winter 68", "Win 68"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["31st of Late Winter", "Late Winter 31", "L-Win 31"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mars Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/2010/m2020_09_22Adp.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 69, // 365
    daysOseason: 69, // 73
    daySeasonComps: [68, 141, 214, 287, 360],
    daysOmonth: 32, // 36
    dayMonthComps: [31, 68, 104, 141, 177, 214, 250, 287, 323, 360],
    daysOfortnight: 14, // 18
    dayFortnightComps: [
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOweek: 5, // 9
    dayWeekComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["69th of Winter", "Winter 69", "Win 69"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["32nd of Late Winter", "Late Winter 32", "L-Win 32"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Jupiter Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1905/JupiterMarble_JunoGill_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 70, // 365
    daysOseason: 70, // 73
    daySeasonComps: [69, 142, 215, 288, 361],
    daysOmonth: 33, // 36
    dayMonthComps: [32, 69, 105, 142, 178, 215, 251, 288, 324, 361],
    daysOfortnight: 15, // 18
    dayFortnightComps: [
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOweek: 6, // 9
    dayWeekComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["70th of Winter", "Winter 70", "Win 70"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["33rd of Late Winter", "Late Winter 33", "L-Win 33"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Saturn Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1609/SaturnAbove_Cassini_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 71, // 365
    daysOseason: 71, // 73
    daySeasonComps: [70, 143, 216, 289, 362],
    daysOmonth: 34, // 36
    dayMonthComps: [33, 70, 106, 143, 179, 216, 252, 289, 325, 362],
    daysOfortnight: 16, // 18
    dayFortnightComps: [
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOweek: 7, // 9
    dayWeekComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["71st of Winter", "Winter 71", "Win 71"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["34th of Late Winter", "Late Winter 34", "L-Win 34"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Uranus Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/0411/uranus_keck_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 72, // 365
    daysOseason: 72, // 73
    daySeasonComps: [71, 144, 217, 290, 363],
    daysOmonth: 35, // 36
    dayMonthComps: [34, 71, 107, 144, 180, 217, 253, 290, 326, 363],
    daysOfortnight: 17, // 18
    dayFortnightComps: [
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOweek: 8, // 9
    dayWeekComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["72nd of Winter", "Winter 72", "Win 72"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["35th of Late Winter", "Late Winter 35", "L-Win 35"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Neptune Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/0406/nep2002_hst1pan_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 73, // 365
    daysOseason: 73, // 73
    daySeasonComps: [72, 145, 218, 291, 364],
    daysOmonth: 36, // 36
    dayMonthComps: [35, 72, 108, 145, 181, 218, 254, 291, 327, 364],
    daysOfortnight: 18, // 18
    dayFortnightComps: [
      17,
      35,
      54,
      72,
      90,
      108,
      127,
      145,
      163,
      181,
      200,
      218,
      236,
      254,
      273,
      291,
      309,
      327,
      346,
      364,
    ],
    daysOweek: 9, // 9
    dayWeekComps: [
      8,
      17,
      26,
      35,
      45,
      54,
      63,
      72,
      81,
      90,
      99,
      108,
      118,
      127,
      136,
      145,
      154,
      163,
      172,
      181,
      191,
      200,
      209,
      218,
      227,
      236,
      245,
      254,
      264,
      273,
      282,
      291,
      300,
      309,
      318,
      327,
      337,
      346,
      355,
      364,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["73rd of Winter", "Winter 73", "Win 73"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["36th of Late Winter", "Late Winter 36", "L-Win 36"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Pluto Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1909/PlutoTrueColor_NewHorizons_960.jpg",

    dayOfTabURL: "",
  },
  // SPRING
  {
    daysOyear: 74, // 365
    daysOseason: 1, // 73
    daySeasonComps: [0, 73, 146, 219, 292],
    daysOmonth: 1, // 36
    dayMonthComps: [0, 37, 73, 110, 146, 183, 219, 256, 292, 329],
    daysOfortnight: 1, // 18
    dayFortnightComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
    ],
    daysOweek: 1, // 9
    dayWeekComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["1st of Spring", "Spring 1", "Spr 1"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["1st of Early Spring", "Early Spring 1", "E-Spr 1"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mercury Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1707/mercuryflyby2_messenger_1024.png",

    dayOfTabURL: "",
  },
  {
    daysOyear: 75, // 365
    daysOseason: 2, // 73
    daySeasonComps: [1, 74, 147, 220, 293],
    daysOmonth: 2, // 36
    dayMonthComps: [1, 38, 74, 111, 147, 184, 220, 257, 293, 330],
    daysOfortnight: 2, // 18
    dayFortnightComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
    ],
    daysOweek: 2, // 9
    dayWeekComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["2nd of Spring", "Spring 2", "Spr 2"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["2nd of Early Spring", "Early Spring 2", "E-Spr 2"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Venus Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/2009/VenusClouds_Akatzuki_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 76, // 365
    daysOseason: 3, // 73
    daySeasonComps: [2, 75, 148, 221, 294],
    daysOmonth: 3, // 36
    dayMonthComps: [2, 39, 75, 112, 148, 185, 221, 258, 294, 331],
    daysOfortnight: 3, // 18
    dayFortnightComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
    ],
    daysOweek: 3, // 9
    dayWeekComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["3rd of Spring", "Spring 3", "Spr 3"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["3rd of Early Spring", "Early Spring 3", "E-Spr 3"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Earth Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1201/bluemarbleearth_npp_980.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 77, // 365
    daysOseason: 4, // 73
    daySeasonComps: [3, 76, 149, 222, 295],
    daysOmonth: 4, // 36
    dayMonthComps: [3, 40, 76, 113, 149, 186, 222, 259, 295, 332],
    daysOfortnight: 4, // 18
    dayFortnightComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
    ],
    daysOweek: 4, // 9
    dayWeekComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["4th of Spring", "Spring 4", "Spr 4"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["4th of Early Spring", "Early Spring 4", "E-Spr 4"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mars Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/2010/m2020_09_22Adp.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 78, // 365
    daysOseason: 5, // 73
    daySeasonComps: [4, 77, 150, 223, 296],
    daysOmonth: 5, // 36
    dayMonthComps: [4, 41, 77, 114, 150, 187, 223, 260, 296, 333],
    daysOfortnight: 5, // 18
    dayFortnightComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
    ],
    daysOweek: 5, // 9
    dayWeekComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["5th of Spring", "Spring 5", "Spr 5"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["5th of Early Spring", "Early Spring 5", "E-Spr 5"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Jupiter Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1905/JupiterMarble_JunoGill_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 79, // 363
    daysOseason: 6, // 73
    daySeasonComps: [5, 78, 151, 224, 297],
    daysOmonth: 6, // 36
    dayMonthComps: [5, 42, 78, 115, 151, 188, 224, 261, 297, 334],
    daysOfortnight: 6, // 18
    dayFortnightComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
    ],
    daysOweek: 6, // 9
    dayWeekComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["6th of Spring", "Spring 6", "Spr 6"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["6th of Early Spring", "Early Spring 6", "E-Spr 6"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Saturn Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1609/SaturnAbove_Cassini_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 80, // 365
    daysOseason: 7, // 73
    daySeasonComps: [6, 79, 152, 225, 298],
    daysOmonth: 7, // 36
    dayMonthComps: [6, 43, 79, 116, 152, 189, 225, 262, 298, 335],
    daysOfortnight: 7, // 18
    dayFortnightComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
    ],
    daysOweek: 7, // 9
    dayWeekComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["7th of Spring", "Spring 7", "Spr 7"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["7th of Early Spring", "Early Spring 7", "E-Spr 7"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Uranus Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/0411/uranus_keck_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 81, // 365
    daysOseason: 8, // 73
    daySeasonComps: [7, 80, 153, 226, 299],
    daysOmonth: 8, // 36
    dayMonthComps: [7, 44, 80, 117, 153, 190, 226, 263, 299, 336],
    daysOfortnight: 8, // 18
    dayFortnightComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
    ],
    daysOweek: 8, // 9
    dayWeekComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["8th of Spring", "Spring 8", "Spr 8"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["8th of Early Spring", "Early Spring 8", "E-Spr 8"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Neptune Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/0406/nep2002_hst1pan_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 82, // 365
    daysOseason: 9, // 73
    daySeasonComps: [8, 81, 154, 227, 300],
    daysOmonth: 9, // 36
    dayMonthComps: [8, 45, 81, 118, 154, 191, 227, 264, 300, 337],
    daysOfortnight: 9, // 18
    dayFortnightComps: [
      8,
      26,
      45,
      63,
      81,
      99,
      118,
      136,
      154,
      172,
      191,
      209,
      227,
      245,
      264,
      282,
      300,
      318,
      337,
      355,
    ],
    daysOweek: 9, // 9
    dayWeekComps: [
      8,
      17,
      26,
      35,
      45,
      54,
      63,
      72,
      81,
      90,
      99,
      108,
      118,
      127,
      136,
      145,
      154,
      163,
      172,
      181,
      191,
      200,
      209,
      218,
      227,
      236,
      245,
      254,
      264,
      273,
      282,
      291,
      300,
      309,
      318,
      327,
      337,
      346,
      355,
      364,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["9th of Spring", "Spring 9", "Spr 9"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["9th of Early Spring", "Early Spring 9", "E-Spr 9"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Pluto Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1909/PlutoTrueColor_NewHorizons_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 83, // 365
    daysOseason: 10, // 73
    daySeasonComps: [9, 82, 155, 228, 301],
    daysOmonth: 10, // 36
    dayMonthComps: [9, 46, 82, 119, 155, 192, 228, 265, 301, 338],
    daysOfortnight: 10, // 18
    dayFortnightComps: [
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOweek: 1, // 9
    dayWeekComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["10th of Spring", "Spring 10", "Spr 10"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["10th of Early Spring", "Early Spring 10", "E-Spr 10"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mercury Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1707/mercuryflyby2_messenger_1024.png",

    dayOfTabURL: "",
  },
  {
    daysOyear: 84, // 365
    daysOseason: 11, // 73
    daySeasonComps: [10, 83, 156, 229, 302],
    daysOmonth: 11, // 36
    dayMonthComps: [10, 47, 83, 120, 156, 193, 229, 266, 302, 339],
    daysOfortnight: 11, // 18
    dayFortnightComps: [
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOweek: 2, // 9
    dayWeekComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["11th of Spring", "Spring 11", "Spr 11"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["11th of Early Spring", "Early Spring 11", "E-Spr 11"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Venus Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/2009/VenusClouds_Akatzuki_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 85, // 365
    daysOseason: 12, // 73
    daySeasonComps: [11, 84, 157, 230, 303],
    daysOmonth: 12, // 36
    dayMonthComps: [11, 48, 84, 121, 157, 194, 230, 267, 303, 340],
    daysOfortnight: 12, // 18
    dayFortnightComps: [
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOweek: 3, // 9
    dayWeekComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["12th of Spring", "Spring 12", "Spr 12"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["12th of Early Spring", "Early Spring 12", "E-Spr 12"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Earth Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1201/bluemarbleearth_npp_980.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 86, // 365
    daysOseason: 13, // 73
    daySeasonComps: [12, 85, 158, 231, 304],
    daysOmonth: 13, // 36
    dayMonthComps: [12, 49, 85, 122, 158, 195, 231, 268, 304, 341],
    daysOfortnight: 13, // 18
    dayFortnightComps: [
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOweek: 4, // 9
    dayWeekComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["13th of Spring", "Spring 13", "Spr 13"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["13th of Early Spring", "Early Spring 13", "E-Spr 13"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mars Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/2010/m2020_09_22Adp.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 87, // 365
    daysOseason: 14, // 73
    daySeasonComps: [13, 86, 159, 232, 305],
    daysOmonth: 14, // 36
    dayMonthComps: [13, 50, 86, 123, 159, 196, 232, 269, 305, 342],
    daysOfortnight: 14, // 18
    dayFortnightComps: [
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOweek: 5, // 9
    dayWeekComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: "St. Patrick's Day",
    dayOfYearURL: "",
    dayNameWithinSeason: ["14th of Spring", "Spring 14", "Spr 14"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["14th of Early Spring", "Early Spring 14", "E-Spr 14"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Jupiter Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1905/JupiterMarble_JunoGill_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 88, // 365
    daysOseason: 15, // 73
    daySeasonComps: [14, 87, 160, 233, 306],
    daysOmonth: 15, // 36
    dayMonthComps: [14, 51, 87, 124, 160, 197, 233, 270, 306, 343],
    daysOfortnight: 15, // 18
    dayFortnightComps: [
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOweek: 6, // 9
    dayWeekComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: "88s",
    dayOfYearURL: "",
    dayNameWithinSeason: ["15th of Spring", "Spring 15", "Spr 15"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["15th of Early Spring", "Early Spring 15", "E-Spr 15"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Saturn Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1609/SaturnAbove_Cassini_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 89, // 365
    daysOseason: 16, // 73
    daySeasonComps: [15, 88, 161, 234, 307],
    daysOmonth: 16, // 36
    dayMonthComps: [15, 52, 88, 125, 161, 198, 234, 271, 307, 344],
    daysOfortnight: 16, // 18
    dayFortnightComps: [
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOweek: 7, // 9
    dayWeekComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["16th of Spring", "Spring 16", "Spr 16"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["16th of Early Spring", "Early Spring 16", "E-Spr 16"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Uranus Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/0411/uranus_keck_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 90, // 365
    daysOseason: 17, // 73
    daySeasonComps: [16, 89, 162, 235, 308],
    daysOmonth: 17, // 36
    dayMonthComps: [16, 53, 89, 126, 162, 199, 235, 272, 308, 345],
    daysOfortnight: 17, // 18
    dayFortnightComps: [
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOweek: 8, // 9
    dayWeekComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["17th of Spring", "Spring 17", "Spr 17"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["17th of Early Spring", "Early Spring 17", "E-Spr 17"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Neptune Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/0406/nep2002_hst1pan_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 91, // 365
    daysOseason: 18, // 73
    daySeasonComps: [17, 90, 163, 236, 309],
    daysOmonth: 18, // 36
    dayMonthComps: [17, 54, 90, 127, 163, 200, 236, 273, 309, 346],
    daysOfortnight: 18, // 18
    dayFortnightComps: [
      17,
      35,
      54,
      72,
      90,
      108,
      127,
      145,
      163,
      181,
      200,
      218,
      236,
      254,
      273,
      291,
      309,
      327,
      346,
      364,
    ],
    daysOweek: 9, // 9
    dayWeekComps: [
      8,
      17,
      26,
      35,
      45,
      54,
      63,
      72,
      81,
      90,
      99,
      108,
      118,
      127,
      136,
      145,
      154,
      163,
      172,
      181,
      191,
      200,
      209,
      218,
      227,
      236,
      245,
      254,
      264,
      273,
      282,
      291,
      300,
      309,
      318,
      327,
      337,
      346,
      355,
      364,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["18th of Spring", "Spring 18", "Spr 18"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["18th of Early Spring", "Early Spring 18", "E-Spr 18"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Pluto Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1909/PlutoTrueColor_NewHorizons_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 92, // 365
    daysOseason: 19, // 73
    daySeasonComps: [18, 91, 164, 237, 310],
    daysOmonth: 19, // 36
    dayMonthComps: [18, 55, 91, 128, 164, 201, 237, 274, 310, 347],
    daysOfortnight: 1, // 18
    dayFortnightComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
    ],
    daysOweek: 1, // 9
    dayWeekComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["19th of Spring", "Spring 19", "Spr 19"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["19th of Early Spring", "Early Spring 19", "E-Spr 19"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mercury Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1707/mercuryflyby2_messenger_1024.png",

    dayOfTabURL: "",
  },
  {
    daysOyear: 93, // 365
    daysOseason: 20, // 73
    daySeasonComps: [19, 92, 165, 238, 311],
    daysOmonth: 20, // 36
    dayMonthComps: [19, 56, 92, 129, 165, 202, 238, 275, 311, 348],
    daysOfortnight: 2, // 18
    dayFortnightComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
    ],
    daysOweek: 2, // 9
    dayWeekComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["20th of Spring", "Spring 20", "Spr 20"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["20th of Early Spring", "Early Spring 20", "E-Spr 20"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Venus Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/2009/VenusClouds_Akatzuki_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 94, // 365
    daysOseason: 21, // 73
    daySeasonComps: [20, 93, 166, 239, 312],
    daysOmonth: 21, // 36
    dayMonthComps: [20, 57, 93, 130, 166, 203, 239, 276, 312, 349],
    daysOfortnight: 3, // 18
    dayFortnightComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
    ],
    daysOweek: 3, // 9
    dayWeekComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["21st of Spring", "Spring 21", "Spr 21"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["21st of Early Spring", "Early Spring 21", "E-Spr 21"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Earth Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1201/bluemarbleearth_npp_980.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 95, // 365
    daysOseason: 22, // 73
    daySeasonComps: [21, 94, 167, 240, 313],
    daysOmonth: 22, // 36
    dayMonthComps: [21, 58, 94, 131, 167, 204, 240, 277, 313, 350],
    daysOfortnight: 4, // 18
    dayFortnightComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
    ],
    daysOweek: 4, // 9
    dayWeekComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["22nd of Spring", "Spring 22", "Spr 22"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["22nd of Early Spring", "Early Spring 22", "E-Spr 22"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mars Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/2010/m2020_09_22Adp.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 96, // 365
    daysOseason: 23, // 73
    daySeasonComps: [22, 95, 168, 241, 314],
    daysOmonth: 23, // 36
    dayMonthComps: [22, 59, 95, 132, 168, 205, 241, 278, 314, 351],
    daysOfortnight: 5, // 18
    dayFortnightComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
    ],
    daysOweek: 5, // 9
    dayWeekComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["23rd of Spring", "Spring 23", "Spr 23"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["23rd of Early Spring", "Early Spring 23", "E-Spr 23"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Jupiter Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1905/JupiterMarble_JunoGill_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 97, // 363
    daysOseason: 24, // 73
    daySeasonComps: [23, 96, 169, 242, 315],
    daysOmonth: 24, // 36
    dayMonthComps: [23, 60, 96, 133, 169, 206, 242, 279, 315, 352],
    daysOfortnight: 6, // 18
    dayFortnightComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
    ],
    daysOweek: 6, // 9
    dayWeekComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["24th of Spring", "Spring 24", "Spr 24"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["24th of Early Spring", "Early Spring 24", "E-Spr 24"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Saturn Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1609/SaturnAbove_Cassini_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 98, // 365
    daysOseason: 25, // 73
    daySeasonComps: [24, 97, 170, 243, 316],
    daysOmonth: 25, // 36
    dayMonthComps: [24, 61, 97, 134, 170, 207, 243, 280, 316, 353],
    daysOfortnight: 7, // 18
    dayFortnightComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
    ],
    daysOweek: 7, // 9
    dayWeekComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["25th of Spring", "Spring 25", "Spr 25"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["25th of Early Spring", "Early Spring 25", "E-Spr 25"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Uranus Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/0411/uranus_keck_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 99, // 365
    daysOseason: 26, // 73
    daySeasonComps: [25, 98, 171, 244, 317],
    daysOmonth: 26, // 36
    dayMonthComps: [25, 62, 98, 135, 171, 208, 244, 281, 317, 354],
    daysOfortnight: 8, // 18
    dayFortnightComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
    ],
    daysOweek: 8, // 9
    dayWeekComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["26th of Spring", "Spring 26", "Spr 26"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["26th of Early Spring", "Early Spring 26", "E-Spr 26"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Neptune Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/0406/nep2002_hst1pan_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 100, // 365
    daysOseason: 27, // 73
    daySeasonComps: [26, 99, 172, 245, 318],
    daysOmonth: 27, // 36
    dayMonthComps: [26, 63, 99, 136, 172, 209, 245, 282, 318, 355],
    daysOfortnight: 9, // 18
    dayFortnightComps: [
      8,
      26,
      45,
      63,
      81,
      99,
      118,
      136,
      154,
      172,
      191,
      209,
      227,
      245,
      264,
      282,
      300,
      318,
      337,
      355,
    ],
    daysOweek: 9, // 9
    dayWeekComps: [
      8,
      17,
      26,
      35,
      45,
      54,
      63,
      72,
      81,
      90,
      99,
      108,
      118,
      127,
      136,
      145,
      154,
      163,
      172,
      181,
      191,
      200,
      209,
      218,
      227,
      236,
      245,
      254,
      264,
      273,
      282,
      291,
      300,
      309,
      318,
      327,
      337,
      346,
      355,
      364,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["27th of Spring", "Spring 27", "Spr 27"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["27th of Early Spring", "Early Spring 27", "E-Spr 27"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Pluto Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1909/PlutoTrueColor_NewHorizons_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 101, // 365
    daysOseason: 28, // 73
    daySeasonComps: [27, 100, 173, 246, 319],
    daysOmonth: 28, // 36
    dayMonthComps: [27, 64, 100, 137, 173, 210, 246, 283, 319, 356],
    daysOfortnight: 10, // 18
    dayFortnightComps: [
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOweek: 1, // 9
    dayWeekComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["28th of Spring", "Spring 28", "Spr 28"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["28th of Early Spring", "Early Spring 28", "E-Spr 28"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mercury Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1707/mercuryflyby2_messenger_1024.png",

    dayOfTabURL: "",
  },
  {
    daysOyear: 102, // 365
    daysOseason: 29, // 73
    daySeasonComps: [28, 101, 174, 247, 320],
    daysOmonth: 29, // 36
    dayMonthComps: [28, 65, 101, 138, 174, 211, 247, 284, 320, 357],
    daysOfortnight: 11, // 18
    dayFortnightComps: [
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOweek: 2, // 9
    dayWeekComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: "April Fool's Day",
    dayOfYearURL: "",
    dayNameWithinSeason: ["29th of Spring", "Spring 29", "Spr 29"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["29th of Early Spring", "Early Spring 29", "E-Spr 29"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Venus Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/2009/VenusClouds_Akatzuki_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 103, // 365
    daysOseason: 30, // 73
    daySeasonComps: [29, 102, 175, 248, 321],
    daysOmonth: 30, // 36
    dayMonthComps: [29, 66, 102, 139, 175, 212, 248, 285, 321, 358],
    daysOfortnight: 12, // 18
    dayFortnightComps: [
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOweek: 3, // 9
    dayWeekComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["30th of Spring", "Spring 30", "Spr 30"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["30th of Early Spring", "Early Spring 30", "E-Spr 30"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Earth Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1201/bluemarbleearth_npp_980.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 104, // 365
    daysOseason: 31, // 73
    daySeasonComps: [30, 103, 176, 249, 322],
    daysOmonth: 31, // 36
    dayMonthComps: [30, 67, 103, 140, 176, 213, 249, 286, 322, 359],
    daysOfortnight: 13, // 18
    dayFortnightComps: [
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOweek: 4, // 9
    dayWeekComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["31st of Spring", "Spring 31", "Spr 31"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["31st of Early Spring", "Early Spring 31", "E-Spr 31"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mars Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/2010/m2020_09_22Adp.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 105, // 365
    daysOseason: 32, // 73
    daySeasonComps: [31, 104, 177, 250, 323],
    daysOmonth: 32, // 36
    dayMonthComps: [31, 68, 104, 141, 177, 214, 250, 287, 323, 360],
    daysOfortnight: 14, // 18
    dayFortnightComps: [
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOweek: 5, // 9
    dayWeekComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["32nd of Spring", "Spring 32", "Spr 32"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["32nd of Early Spring", "Early Spring 32", "E-Spr 32"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Jupiter Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1905/JupiterMarble_JunoGill_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 106, // 365
    daysOseason: 33, // 73
    daySeasonComps: [32, 105, 178, 251, 324],
    daysOmonth: 33, // 36
    dayMonthComps: [32, 69, 105, 142, 178, 215, 251, 288, 324, 361],
    daysOfortnight: 15, // 18
    dayFortnightComps: [
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOweek: 6, // 9
    dayWeekComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["33rd of Spring", "Spring 33", "Spr 33"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["33rd of Early Spring", "Early Spring 33", "E-Spr 33"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Saturn Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1609/SaturnAbove_Cassini_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 107, // 365
    daysOseason: 34, // 73
    daySeasonComps: [33, 106, 179, 252, 325],
    daysOmonth: 34, // 36
    dayMonthComps: [33, 70, 106, 143, 179, 216, 252, 289, 325, 362],
    daysOfortnight: 16, // 18
    dayFortnightComps: [
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOweek: 7, // 9
    dayWeekComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["34th of Spring", "Spring 34", "Spr 34"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["34th of Early Spring", "Early Spring 34", "E-Spr 34"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Uranus Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/0411/uranus_keck_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 108, // 365
    daysOseason: 35, // 73
    daySeasonComps: [34, 107, 180, 253, 326],
    daysOmonth: 35, // 36
    dayMonthComps: [34, 71, 107, 144, 180, 217, 253, 290, 326, 363],
    daysOfortnight: 17, // 18
    dayFortnightComps: [
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOweek: 8, // 9
    dayWeekComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["35th of Spring", "Spring 35", "Spr 35"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["35th of Early Spring", "Early Spring 35", "E-Spr 35"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Neptune Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/0406/nep2002_hst1pan_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 109, // 365
    daysOseason: 36, // 73
    daySeasonComps: [35, 108, 181, 254, 327],
    daysOmonth: 36, // 36
    dayMonthComps: [35, 72, 108, 145, 181, 218, 254, 291, 327, 364],
    daysOfortnight: 18, // 18
    dayFortnightComps: [
      17,
      35,
      54,
      72,
      90,
      108,
      127,
      145,
      163,
      181,
      200,
      218,
      236,
      254,
      273,
      291,
      309,
      327,
      346,
      364,
    ],
    daysOweek: 9, // 9
    dayWeekComps: [
      8,
      17,
      26,
      35,
      45,
      54,
      63,
      72,
      81,
      90,
      99,
      108,
      118,
      127,
      136,
      145,
      154,
      163,
      172,
      181,
      191,
      200,
      209,
      218,
      227,
      236,
      245,
      254,
      264,
      273,
      282,
      291,
      300,
      309,
      318,
      327,
      337,
      346,
      355,
      364,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["36th of Spring", "Spring 36", "Spr 36"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["36th of Early Spring", "Early Spring 36", "E-Spr 36"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Pluto Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1909/PlutoTrueColor_NewHorizons_960.jpg",

    dayOfTabURL: "",
  },

  {
    daysOyear: 110, // 365
    daysOseason: 37, // 73
    daySeasonComps: [36, 109, 182, 255, 328],
    daysOmidSeason: 2, // 5
    daysOmonth: null, // 36
    daysOfortnight: null, // 18
    daysOweek: null, // 9
    daysOtab: null, // 3
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["37th of Spring", "Spring 37", "Spr 37"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: "Mid-Spring",
    dayOfMonthURL: "",
    dayNameWithinFortnight: ["Mid-Spring"],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mid-Spring",
    dayOfWeekURL: "",
    dayNameWithinTab: ["Mid-Spring"],
    dayOfTabURL: "",
  },

  {
    daysOyear: 111, // 365
    daysOseason: 38, // 73
    daySeasonComps: [37, 110, 183, 256, 329],
    daysOmonth: 1, // 36
    dayMonthComps: [0, 37, 73, 110, 146, 183, 219, 256, 292, 329],
    daysOfortnight: 1, // 18
    dayFortnightComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
    ],
    daysOweek: 1, // 9
    dayWeekComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["38th of Spring", "Spring 38", "Spr 38"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["1st of Late Spring", "Late Spring 1", "L-Spr 1"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mercury Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1707/mercuryflyby2_messenger_1024.png",

    dayOfTabURL: "",
  },
  {
    daysOyear: 112, // 365
    daysOseason: 39, // 73
    daySeasonComps: [38, 111, 184, 257, 330],
    daysOmonth: 2, // 36
    dayMonthComps: [1, 38, 74, 111, 147, 184, 220, 257, 293, 330],
    daysOfortnight: 2, // 18
    dayFortnightComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
    ],
    daysOweek: 2, // 9
    dayWeekComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["39th of Spring", "Spring 39", "Spr 39"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["2nd of Late Spring", "Late Spring 2", "L-Spr 2"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Venus Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/2009/VenusClouds_Akatzuki_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 113, // 365
    daysOseason: 40, // 73
    daySeasonComps: [39, 112, 185, 258, 331],
    daysOmonth: 3, // 36
    dayMonthComps: [2, 39, 75, 112, 148, 185, 221, 258, 294, 331],
    daysOfortnight: 3, // 18
    dayFortnightComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
    ],
    daysOweek: 3, // 9
    dayWeekComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["40th of Spring", "Spring 40", "Spr 40"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["3rd of Late Spring", "Late Spring 3", "L-Spr 3"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Earth Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1201/bluemarbleearth_npp_980.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 114, // 365
    daysOseason: 41, // 73
    daySeasonComps: [40, 113, 186, 259, 332],
    daysOmonth: 4, // 36
    dayMonthComps: [3, 40, 76, 113, 149, 186, 222, 259, 295, 332],
    daysOfortnight: 4, // 18
    dayFortnightComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
    ],
    daysOweek: 4, // 9
    dayWeekComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["41st of Spring", "Spring 41", "Spr 41"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["4th of Late Spring", "Late Spring 4", "L-Spr 4"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mars Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/2010/m2020_09_22Adp.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 115, // 365
    daysOseason: 42, // 73
    daySeasonComps: [41, 114, 187, 260, 333],
    daysOmonth: 5, // 36
    dayMonthComps: [4, 41, 77, 114, 150, 187, 223, 260, 296, 333],
    daysOfortnight: 5, // 18
    dayFortnightComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
    ],
    daysOweek: 5, // 9
    dayWeekComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["42nd of Spring", "Spring 42", "Spr 42"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["5th of Late Spring", "Late Spring 5", "L-Spr 5"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Jupiter Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1905/JupiterMarble_JunoGill_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 116, // 363
    daysOseason: 43, // 73
    daySeasonComps: [42, 115, 188, 261, 334],
    daysOmonth: 6, // 36
    dayMonthComps: [5, 42, 78, 115, 151, 188, 224, 261, 297, 334],
    daysOfortnight: 6, // 18
    dayFortnightComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
    ],
    daysOweek: 6, // 9
    dayWeekComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: "Tax Day",
    dayOfYearURL: "",
    dayNameWithinSeason: ["43rd of Spring", "Spring 43", "Spr 43"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["6th of Late Spring", "Late Spring 6", "L-Spr 6"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Saturn Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1609/SaturnAbove_Cassini_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 117, // 365
    daysOseason: 44, // 73
    daySeasonComps: [43, 116, 189, 262, 335],
    daysOmonth: 7, // 36
    dayMonthComps: [6, 43, 79, 116, 152, 189, 225, 262, 298, 335],
    daysOfortnight: 7, // 18
    dayFortnightComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
    ],
    daysOweek: 7, // 9
    dayWeekComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["44th of Spring", "Spring 44", "Spr 44"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["7th of Late Spring", "Late Spring 7", "L-Spr 7"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Uranus Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/0411/uranus_keck_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 118, // 365
    daysOseason: 45, // 73
    daySeasonComps: [44, 117, 190, 263, 336],
    daysOmonth: 8, // 36
    dayMonthComps: [7, 44, 80, 117, 153, 190, 226, 263, 299, 336],
    daysOfortnight: 8, // 18
    dayFortnightComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
    ],
    daysOweek: 8, // 9
    dayWeekComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["45th of Spring", "Spring 45", "Spr 45"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["8th of Late Spring", "Late Spring 8", "L-Spr 8"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Neptune Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/0406/nep2002_hst1pan_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 119, // 365
    daysOseason: 46, // 73
    daySeasonComps: [45, 118, 191, 264, 337],
    daysOmonth: 9, // 36
    dayMonthComps: [8, 45, 81, 118, 154, 191, 227, 264, 300, 337],
    daysOfortnight: 9, // 18
    dayFortnightComps: [
      8,
      26,
      45,
      63,
      81,
      99,
      118,
      136,
      154,
      172,
      191,
      209,
      227,
      245,
      264,
      282,
      300,
      318,
      337,
      355,
    ],
    daysOweek: 9, // 9
    dayWeekComps: [
      8,
      17,
      26,
      35,
      45,
      54,
      63,
      72,
      81,
      90,
      99,
      108,
      118,
      127,
      136,
      145,
      154,
      163,
      172,
      181,
      191,
      200,
      209,
      218,
      227,
      236,
      245,
      254,
      264,
      273,
      282,
      291,
      300,
      309,
      318,
      327,
      337,
      346,
      355,
      364,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["46th of Spring", "Spring 46", "Spr 46"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["9th of Late Spring", "Late Spring 9", "L-Spr 9"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Pluto Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1909/PlutoTrueColor_NewHorizons_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 120, // 365
    daysOseason: 47, // 73
    daySeasonComps: [46, 119, 192, 265, 338],
    daysOmonth: 10, // 36
    dayMonthComps: [9, 46, 82, 119, 155, 192, 228, 265, 301, 338],
    daysOfortnight: 10, // 18
    dayFortnightComps: [
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOweek: 1, // 9
    dayWeekComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["47th of Spring", "Spring 47", "Spr 47"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["10th of Late Spring", "Late Spring 10", "L-Spr 10"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mercury Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1707/mercuryflyby2_messenger_1024.png",

    dayOfTabURL: "",
  },
  {
    daysOyear: 121, // 365
    daysOseason: 48, // 73
    daySeasonComps: [47, 120, 193, 266, 339],
    daysOmonth: 11, // 36
    dayMonthComps: [10, 47, 83, 120, 156, 193, 229, 266, 302, 339],
    daysOfortnight: 11, // 18
    dayFortnightComps: [
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOweek: 2, // 9
    dayWeekComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["48th of Spring", "Spring 48", "Spr 48"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["11th of Late Spring", "Late Spring 11", "L-Spr 11"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Venus Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/2009/VenusClouds_Akatzuki_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 122, // 365
    daysOseason: 49, // 73
    daySeasonComps: [48, 121, 194, 267, 340],
    daysOmonth: 12, // 36
    dayMonthComps: [11, 48, 84, 121, 157, 194, 230, 267, 303, 340],
    daysOfortnight: 12, // 18
    dayFortnightComps: [
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOweek: 3, // 9
    dayWeekComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["49th of Spring", "Spring 49", "Spr 49"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["12th of Late Spring", "Late Spring 12", "L-Spr 12"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Earth Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1201/bluemarbleearth_npp_980.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 123, // 365
    daysOseason: 50, // 73
    daySeasonComps: [49, 122, 195, 268, 341],
    daysOmonth: 13, // 36
    dayMonthComps: [12, 49, 85, 122, 158, 195, 231, 268, 304, 341],
    daysOfortnight: 13, // 18
    dayFortnightComps: [
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOweek: 4, // 9
    dayWeekComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: "'Earth Day'",
    dayOfYearURL: "",
    dayNameWithinSeason: ["50th of Spring", "Spring 50", "Spr 50"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["13th of Late Spring", "Late Spring 13", "L-Spr 13"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mars Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/2010/m2020_09_22Adp.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 124, // 365
    daysOseason: 51, // 73
    daySeasonComps: [50, 123, 196, 269, 342],
    daysOmonth: 14, // 36
    dayMonthComps: [13, 50, 86, 123, 159, 196, 232, 269, 305, 342],
    daysOfortnight: 14, // 18
    dayFortnightComps: [
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOweek: 5, // 9
    dayWeekComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["51st of Spring", "Spring 51", "Spr 51"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["14th of Late Spring", "Late Spring 14", "L-Spr 14"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Jupiter Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1905/JupiterMarble_JunoGill_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 125, // 365
    daysOseason: 52, // 73
    daySeasonComps: [51, 124, 197, 270, 343],
    daysOmonth: 15, // 36
    dayMonthComps: [14, 51, 87, 124, 160, 197, 233, 270, 306, 343],
    daysOfortnight: 15, // 18
    dayFortnightComps: [
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOweek: 6, // 9
    dayWeekComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["52nd of Spring", "Spring 52", "Spr 52"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["15th of Late Spring", "Late Spring 15", "L-Spr 15"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Saturn Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1609/SaturnAbove_Cassini_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 126, // 365
    daysOseason: 53, // 73
    daySeasonComps: [52, 125, 198, 271, 344],
    daysOmonth: 16, // 36
    dayMonthComps: [15, 52, 88, 125, 161, 198, 234, 271, 307, 344],
    daysOfortnight: 16, // 18
    dayFortnightComps: [
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOweek: 7, // 9
    dayWeekComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["53rd of Spring", "Spring 53", "Spr 53"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["16th of Late Spring", "Late Spring 16", "L-Spr 16"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Uranus Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/0411/uranus_keck_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 127, // 365
    daysOseason: 54, // 73
    daySeasonComps: [53, 126, 199, 272, 345],
    daysOmonth: 17, // 36
    dayMonthComps: [16, 53, 89, 126, 162, 199, 235, 272, 308, 345],
    daysOfortnight: 17, // 18
    dayFortnightComps: [
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOweek: 8, // 9
    dayWeekComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["54th of Spring", "Spring 54", "Spr 54"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["17th of Late Spring", "Late Spring 17", "L-Spr 17"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Neptune Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/0406/nep2002_hst1pan_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 128, // 365
    daysOseason: 55, // 73
    daySeasonComps: [54, 127, 200, 273, 346],
    daysOmonth: 18, // 36
    dayMonthComps: [17, 54, 90, 127, 163, 200, 236, 273, 309, 346],
    daysOfortnight: 18, // 18
    dayFortnightComps: [
      17,
      35,
      54,
      72,
      90,
      108,
      127,
      145,
      163,
      181,
      200,
      218,
      236,
      254,
      273,
      291,
      309,
      327,
      346,
      364,
    ],
    daysOweek: 9, // 9
    dayWeekComps: [
      8,
      17,
      26,
      35,
      45,
      54,
      63,
      72,
      81,
      90,
      99,
      108,
      118,
      127,
      136,
      145,
      154,
      163,
      172,
      181,
      191,
      200,
      209,
      218,
      227,
      236,
      245,
      254,
      264,
      273,
      282,
      291,
      300,
      309,
      318,
      327,
      337,
      346,
      355,
      364,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["55th of Spring", "Spring 55", "Spr 55"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["18th of Late Spring", "Late Spring 18", "L-Spr 18"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Pluto Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1909/PlutoTrueColor_NewHorizons_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 129, // 365
    daysOseason: 56, // 73
    daySeasonComps: [55, 128, 201, 274, 347],
    daysOmonth: 19, // 36
    dayMonthComps: [18, 55, 91, 128, 164, 201, 237, 274, 310, 347],
    daysOfortnight: 1, // 18
    dayFortnightComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
    ],
    daysOweek: 1, // 9
    dayWeekComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["56th of Spring", "Spring 56", "Spr 56"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["19th of Late Spring", "Late Spring 19", "L-Spr 19"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mercury Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1707/mercuryflyby2_messenger_1024.png",

    dayOfTabURL: "",
  },
  {
    daysOyear: 130, // 365
    daysOseason: 57, // 73
    daySeasonComps: [56, 129, 202, 275, 348],
    daysOmonth: 20, // 36
    dayMonthComps: [19, 56, 92, 129, 165, 202, 238, 275, 311, 348],
    daysOfortnight: 2, // 18
    dayFortnightComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
    ],
    daysOweek: 2, // 9
    dayWeekComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["57th of Spring", "Spring 57", "Spr 57"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["20th of Late Spring", "Late Spring 20", "L-Spr 20"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Venus Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/2009/VenusClouds_Akatzuki_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 131, // 365
    daysOseason: 58, // 73
    daySeasonComps: [57, 130, 203, 276, 349],
    daysOmonth: 21, // 36
    dayMonthComps: [20, 57, 93, 130, 166, 203, 239, 276, 312, 349],
    daysOfortnight: 3, // 18
    dayFortnightComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
    ],
    daysOweek: 3, // 9
    dayWeekComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["58th of Spring", "Spring 58", "Spr 58"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["21st of Late Spring", "Late Spring 21", "L-Spr 21"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Earth Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1201/bluemarbleearth_npp_980.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 132, // 365
    daysOseason: 59, // 73
    daySeasonComps: [58, 131, 204, 277, 350],
    daysOmonth: 22, // 36
    dayMonthComps: [21, 58, 94, 131, 167, 204, 240, 277, 313, 350],
    daysOfortnight: 4, // 18
    dayFortnightComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
    ],
    daysOweek: 4, // 9
    dayWeekComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["59th of Spring", "Spring 59", "Spr 59"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["22nd of Late Spring", "Late Spring 22", "L-Spr 22"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mars Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/2010/m2020_09_22Adp.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 133, // 365
    daysOseason: 60, // 73
    daySeasonComps: [59, 132, 205, 278, 351],
    daysOmonth: 23, // 36
    dayMonthComps: [22, 59, 95, 132, 168, 205, 241, 278, 314, 351],
    daysOfortnight: 5, // 18
    dayFortnightComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
    ],
    daysOweek: 5, // 9
    dayWeekComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["60th of Spring", "Spring 60", "Spr 60"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["23rd of Late Spring", "Late Spring 23", "L-Spr 23"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Jupiter Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1905/JupiterMarble_JunoGill_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 134, // 363
    daysOseason: 61, // 73
    daySeasonComps: [60, 133, 206, 279, 352],
    daysOmonth: 24, // 36
    dayMonthComps: [23, 60, 96, 133, 169, 206, 242, 279, 315, 352],
    daysOfortnight: 6, // 18
    dayFortnightComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
    ],
    daysOweek: 6, // 9
    dayWeekComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["61st of Spring", "Spring 61", "Spr 61"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["24th of Late Spring", "Late Spring 24", "L-Spr 24"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Saturn Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1609/SaturnAbove_Cassini_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 135, // 365
    daysOseason: 62, // 73
    daySeasonComps: [61, 134, 207, 280, 353],
    daysOmonth: 25, // 36
    dayMonthComps: [24, 61, 97, 134, 170, 207, 243, 280, 316, 353],
    daysOfortnight: 7, // 18
    dayFortnightComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
    ],
    daysOweek: 7, // 9
    dayWeekComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["62nd of Spring", "Spring 62", "Spr 62"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["25th of Late Spring", "Late Spring 25", "L-Spr 25"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Uranus Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/0411/uranus_keck_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 136, // 365
    daysOseason: 63, // 73
    daySeasonComps: [62, 135, 208, 281, 354],
    daysOmonth: 26, // 36
    dayMonthComps: [25, 62, 98, 135, 171, 208, 244, 281, 317, 354],
    daysOfortnight: 8, // 18
    dayFortnightComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
    ],
    daysOweek: 8, // 9
    dayWeekComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["63rd of Spring", "Spring 63", "Spr 63"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["26th of Late Spring", "Late Spring 26", "L-Spr 26"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Neptune Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/0406/nep2002_hst1pan_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 137, // 365
    daysOseason: 64, // 73
    daySeasonComps: [63, 136, 209, 282, 355],
    daysOmonth: 27, // 36
    dayMonthComps: [26, 63, 99, 136, 172, 209, 245, 282, 318, 355],
    daysOfortnight: 9, // 18
    dayFortnightComps: [
      8,
      26,
      45,
      63,
      81,
      99,
      118,
      136,
      154,
      172,
      191,
      209,
      227,
      245,
      264,
      282,
      300,
      318,
      337,
      355,
    ],
    daysOweek: 9, // 9
    dayWeekComps: [
      8,
      17,
      26,
      35,
      45,
      54,
      63,
      72,
      81,
      90,
      99,
      108,
      118,
      127,
      136,
      145,
      154,
      163,
      172,
      181,
      191,
      200,
      209,
      218,
      227,
      236,
      245,
      254,
      264,
      273,
      282,
      291,
      300,
      309,
      318,
      327,
      337,
      346,
      355,
      364,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["64th of Spring", "Spring 64", "Spr 64"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["27th of Late Spring", "Late Spring 27", "L-Spr 27"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Pluto Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1909/PlutoTrueColor_NewHorizons_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 138, // 365
    daysOseason: 65, // 73
    daySeasonComps: [64, 137, 210, 283, 356],
    daysOmonth: 28, // 36
    dayMonthComps: [27, 64, 100, 137, 173, 210, 246, 283, 319, 356],
    daysOfortnight: 10, // 18
    dayFortnightComps: [
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOweek: 1, // 9
    dayWeekComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["65th of Spring", "Spring 65", "Spr 65"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["28th of Late Spring", "Late Spring 28", "L-Spr 28"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mercury Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1707/mercuryflyby2_messenger_1024.png",

    dayOfTabURL: "",
  },
  {
    daysOyear: 139, // 365
    daysOseason: 66, // 73
    daySeasonComps: [65, 138, 211, 284, 357],
    daysOmonth: 29, // 36
    dayMonthComps: [28, 65, 101, 138, 174, 211, 247, 284, 320, 357],
    daysOfortnight: 11, // 18
    dayFortnightComps: [
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOweek: 2, // 9
    dayWeekComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["66th of Spring", "Spring 66", "Spr 66"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["29th of Late Spring", "Late Spring 29", "L-Spr 29"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Venus Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/2009/VenusClouds_Akatzuki_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 140, // 365
    daysOseason: 67, // 73
    daySeasonComps: [66, 139, 212, 285, 358],
    daysOmonth: 30, // 36
    dayMonthComps: [29, 66, 102, 139, 175, 212, 248, 285, 321, 358],
    daysOfortnight: 12, // 18
    dayFortnightComps: [
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOweek: 3, // 9
    dayWeekComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["67th of Spring", "Spring 67", "Spr 67"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["30th of Late Spring", "Late Spring 30", "L-Spr 30"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Earth Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1201/bluemarbleearth_npp_980.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 141, // 365
    daysOseason: 68, // 73
    daySeasonComps: [67, 140, 213, 286, 359],
    daysOmonth: 31, // 36
    dayMonthComps: [30, 67, 103, 140, 176, 213, 249, 286, 322, 359],
    daysOfortnight: 13, // 18
    dayFortnightComps: [
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOweek: 4, // 9
    dayWeekComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["68th of Spring", "Spring 68", "Spr 68"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["31st of Late Spring", "Late Spring 31", "L-Spr 31"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mars Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/2010/m2020_09_22Adp.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 142, // 365
    daysOseason: 69, // 73
    daySeasonComps: [68, 141, 214, 287, 360],
    daysOmonth: 32, // 36
    dayMonthComps: [31, 68, 104, 141, 177, 214, 250, 287, 323, 360],
    daysOfortnight: 14, // 18
    dayFortnightComps: [
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOweek: 5, // 9
    dayWeekComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["69th of Spring", "Spring 69", "Spr 69"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["32nd of Late Spring", "Late Spring 32", "L-Spr 32"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Jupiter Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1905/JupiterMarble_JunoGill_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 143, // 365
    daysOseason: 70, // 73
    daySeasonComps: [69, 142, 215, 288, 361],
    daysOmonth: 33, // 36
    dayMonthComps: [32, 69, 105, 142, 178, 215, 251, 288, 324, 361],
    daysOfortnight: 15, // 18
    dayFortnightComps: [
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOweek: 6, // 9
    dayWeekComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["70th of Spring", "Spring 70", "Spr 70"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["33rd of Late Spring", "Late Spring 33", "L-Spr 33"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Saturn Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1609/SaturnAbove_Cassini_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 144, // 365
    daysOseason: 71, // 73
    daySeasonComps: [70, 143, 216, 289, 362],
    daysOmonth: 34, // 36
    dayMonthComps: [33, 70, 106, 143, 179, 216, 252, 289, 325, 362],
    daysOfortnight: 16, // 18
    dayFortnightComps: [
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOweek: 7, // 9
    dayWeekComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["71st of Spring", "Spring 71", "Spr 71"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["34th of Late Spring", "Late Spring 34", "L-Spr 34"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Uranus Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/0411/uranus_keck_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 145, // 365
    daysOseason: 72, // 73
    daySeasonComps: [71, 144, 217, 290, 363],
    daysOmonth: 35, // 36
    dayMonthComps: [34, 71, 107, 144, 180, 217, 253, 290, 326, 363],
    daysOfortnight: 17, // 18
    dayFortnightComps: [
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOweek: 8, // 9
    dayWeekComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["72nd of Spring", "Spring 72", "Spr 72"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["35th of Late Spring", "Late Spring 35", "L-Spr 35"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Neptune Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/0406/nep2002_hst1pan_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 146, // 365
    daysOseason: 73, // 73
    daySeasonComps: [72, 145, 218, 291, 364],
    daysOmonth: 36, // 36
    dayMonthComps: [35, 72, 108, 145, 181, 218, 254, 291, 327, 364],
    daysOfortnight: 18, // 18
    dayFortnightComps: [
      17,
      35,
      54,
      72,
      90,
      108,
      127,
      145,
      163,
      181,
      200,
      218,
      236,
      254,
      273,
      291,
      309,
      327,
      346,
      364,
    ],
    daysOweek: 9, // 9
    dayWeekComps: [
      8,
      17,
      26,
      35,
      45,
      54,
      63,
      72,
      81,
      90,
      99,
      108,
      118,
      127,
      136,
      145,
      154,
      163,
      172,
      181,
      191,
      200,
      209,
      218,
      227,
      236,
      245,
      254,
      264,
      273,
      282,
      291,
      300,
      309,
      318,
      327,
      337,
      346,
      355,
      364,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["73rd of Spring", "Spring 73", "Spr 73"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["36th of Late Spring", "Late Spring 36", "L-Spr 36"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Pluto Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1909/PlutoTrueColor_NewHorizons_960.jpg",

    dayOfTabURL: "",
  },
  // SUMMER:
  {
    daysOyear: 147, // 365
    daysOseason: 1, // 73
    daySeasonComps: [0, 73, 146, 219, 292],
    daysOmonth: 1, // 36
    dayMonthComps: [0, 37, 73, 110, 146, 183, 219, 256, 292, 329],
    daysOfortnight: 1, // 18
    dayFortnightComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
    ],
    daysOweek: 1, // 9
    dayWeekComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["1st of Summer", "Summer 1", "Sum 1"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["1st of Early Summer", "Early Summer 1", "E-Sum 1"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mercury Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1707/mercuryflyby2_messenger_1024.png",

    dayOfTabURL: "",
  },
  {
    daysOyear: 148, // 365
    daysOseason: 2, // 73
    daySeasonComps: [1, 74, 147, 220, 293],
    daysOmonth: 2, // 36
    dayMonthComps: [1, 38, 74, 111, 147, 184, 220, 257, 293, 330],
    daysOfortnight: 2, // 18
    dayFortnightComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
    ],
    daysOweek: 2, // 9
    dayWeekComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["2nd of Summer", "Summer 2", "Sum 2"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["2nd of Early Summer", "Early Summer 2", "E-Sum 2"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Venus Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/2009/VenusClouds_Akatzuki_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 149, // 365
    daysOseason: 3, // 73
    daySeasonComps: [2, 75, 148, 221, 294],
    daysOmonth: 3, // 36
    dayMonthComps: [2, 39, 75, 112, 148, 185, 221, 258, 294, 331],
    daysOfortnight: 3, // 18
    dayFortnightComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
    ],
    daysOweek: 3, // 9
    dayWeekComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["3rd of Summer", "Summer 3", "Sum 3"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["3rd of Early Summer", "Early Summer 3", "E-Sum 3"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Earth Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1201/bluemarbleearth_npp_980.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 150, // 365
    daysOseason: 4, // 73
    daySeasonComps: [3, 76, 149, 222, 295],
    daysOmonth: 4, // 36
    dayMonthComps: [3, 40, 76, 113, 149, 186, 222, 259, 295, 332],
    daysOfortnight: 4, // 18
    dayFortnightComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
    ],
    daysOweek: 4, // 9
    dayWeekComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["4th of Summer", "Summer 4", "Sum 4"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["4th of Early Summer", "Early Summer 4", "E-Sum 4"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mars Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/2010/m2020_09_22Adp.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 151, // 365
    daysOseason: 5, // 73
    daySeasonComps: [4, 77, 150, 223, 296],
    daysOmonth: 5, // 36
    dayMonthComps: [4, 41, 77, 114, 150, 187, 223, 260, 296, 333],
    daysOfortnight: 5, // 18
    dayFortnightComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
    ],
    daysOweek: 5, // 9
    dayWeekComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["5th of Summer", "Summer 5", "Sum 5"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["5th of Early Summer", "Early Summer 5", "E-Sum 5"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Jupiter Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1905/JupiterMarble_JunoGill_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 152, // 363
    daysOseason: 6, // 73
    daySeasonComps: [5, 78, 151, 224, 297],
    daysOmonth: 6, // 36
    dayMonthComps: [5, 42, 78, 115, 151, 188, 224, 261, 297, 334],
    daysOfortnight: 6, // 18
    dayFortnightComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
    ],
    daysOweek: 6, // 9
    dayWeekComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["6th of Summer", "Summer 6", "Sum 6"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["6th of Early Summer", "Early Summer 6", "E-Sum 6"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Saturn Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1609/SaturnAbove_Cassini_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 153, // 365
    daysOseason: 7, // 73
    daySeasonComps: [6, 79, 152, 225, 298],
    daysOmonth: 7, // 36
    dayMonthComps: [6, 43, 79, 116, 152, 189, 225, 262, 298, 335],
    daysOfortnight: 7, // 18
    dayFortnightComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
    ],
    daysOweek: 7, // 9
    dayWeekComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["7th of Summer", "Summer 7", "Sum 7"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["7th of Early Summer", "Early Summer 7", "E-Sum 7"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Uranus Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/0411/uranus_keck_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 154, // 365
    daysOseason: 8, // 73
    daySeasonComps: [7, 80, 153, 226, 299],
    daysOmonth: 8, // 36
    dayMonthComps: [7, 44, 80, 117, 153, 190, 226, 263, 299, 336],
    daysOfortnight: 8, // 18
    dayFortnightComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
    ],
    daysOweek: 8, // 9
    dayWeekComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["8th of Summer", "Summer 8", "Sum 8"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["8th of Early Summer", "Early Summer 8", "E-Sum 8"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Neptune Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/0406/nep2002_hst1pan_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 155, // 365
    daysOseason: 9, // 73
    daySeasonComps: [8, 81, 154, 227, 300],
    daysOmonth: 9, // 36
    dayMonthComps: [8, 45, 81, 118, 154, 191, 227, 264, 300, 337],
    daysOfortnight: 9, // 18
    dayFortnightComps: [
      8,
      26,
      45,
      63,
      81,
      99,
      118,
      136,
      154,
      172,
      191,
      209,
      227,
      245,
      264,
      282,
      300,
      318,
      337,
      355,
    ],
    daysOweek: 9, // 9
    dayWeekComps: [
      8,
      17,
      26,
      35,
      45,
      54,
      63,
      72,
      81,
      90,
      99,
      108,
      118,
      127,
      136,
      145,
      154,
      163,
      172,
      181,
      191,
      200,
      209,
      218,
      227,
      236,
      245,
      254,
      264,
      273,
      282,
      291,
      300,
      309,
      318,
      327,
      337,
      346,
      355,
      364,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["9th of Summer", "Summer 9", "Sum 9"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["9th of Early Summer", "Early Summer 9", "E-Sum 9"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Pluto Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1909/PlutoTrueColor_NewHorizons_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 156, // 365
    daysOseason: 10, // 73
    daySeasonComps: [9, 82, 155, 228, 301],
    daysOmonth: 10, // 36
    dayMonthComps: [9, 46, 82, 119, 155, 192, 228, 265, 301, 338],
    daysOfortnight: 10, // 18
    dayFortnightComps: [
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOweek: 1, // 9
    dayWeekComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["10th of Summer", "Summer 10", "Sum 10"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["10th of Early Summer", "Early Summer 10", "E-Sum 10"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mercury Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1707/mercuryflyby2_messenger_1024.png",

    dayOfTabURL: "",
  },
  {
    daysOyear: 157, // 365
    daysOseason: 11, // 73
    daySeasonComps: [10, 83, 156, 229, 302],
    daysOmonth: 11, // 36
    dayMonthComps: [10, 47, 83, 120, 156, 193, 229, 266, 302, 339],
    daysOfortnight: 11, // 18
    dayFortnightComps: [
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOweek: 2, // 9
    dayWeekComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["11th of Summer", "Summer 11", "Sum 11"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["11th of Early Summer", "Early Summer 11", "E-Sum 11"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Venus Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/2009/VenusClouds_Akatzuki_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 158, // 365
    daysOseason: 12, // 73
    daySeasonComps: [11, 84, 157, 230, 303],
    daysOmonth: 12, // 36
    dayMonthComps: [11, 48, 84, 121, 157, 194, 230, 267, 303, 340],
    daysOfortnight: 12, // 18
    dayFortnightComps: [
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOweek: 3, // 9
    dayWeekComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["12th of Summer", "Summer 12", "Sum 12"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["12th of Early Summer", "Early Summer 12", "E-Sum 12"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Earth Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1201/bluemarbleearth_npp_980.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 159, // 365
    daysOseason: 13, // 73
    daySeasonComps: [12, 85, 158, 231, 304],
    daysOmonth: 13, // 36
    dayMonthComps: [12, 49, 85, 122, 158, 195, 231, 268, 304, 341],
    daysOfortnight: 13, // 18
    dayFortnightComps: [
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOweek: 4, // 9
    dayWeekComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["13th of Summer", "Summer 13", "Sum 13"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["13th of Early Summer", "Early Summer 13", "E-Sum 13"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mars Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/2010/m2020_09_22Adp.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 160, // 365
    daysOseason: 14, // 73
    daySeasonComps: [13, 86, 159, 232, 305],
    daysOmonth: 14, // 36
    dayMonthComps: [13, 50, 86, 123, 159, 196, 232, 269, 305, 342],
    daysOfortnight: 14, // 18
    dayFortnightComps: [
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOweek: 5, // 9
    dayWeekComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["14th of Summer", "Summer 14", "Sum 14"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["14th of Early Summer", "Early Summer 14", "E-Sum 14"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Jupiter Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1905/JupiterMarble_JunoGill_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 161, // 365
    daysOseason: 15, // 73
    daySeasonComps: [14, 87, 160, 233, 306],
    daysOmonth: 15, // 36
    dayMonthComps: [14, 51, 87, 124, 160, 197, 233, 270, 306, 343],
    daysOfortnight: 15, // 18
    dayFortnightComps: [
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOweek: 6, // 9
    dayWeekComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["15th of Summer", "Summer 15", "Sum 15"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["15th of Early Summer", "Early Summer 15", "E-Sum 15"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Saturn Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1609/SaturnAbove_Cassini_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 162, // 365
    daysOseason: 16, // 73
    daySeasonComps: [15, 88, 161, 234, 307],
    daysOmonth: 16, // 36
    dayMonthComps: [15, 52, 88, 125, 161, 198, 234, 271, 307, 344],
    daysOfortnight: 16, // 18
    dayFortnightComps: [
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOweek: 7, // 9
    dayWeekComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["16th of Summer", "Summer 16", "Sum 16"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["16th of Early Summer", "Early Summer 16", "E-Sum 16"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Uranus Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/0411/uranus_keck_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 163, // 365
    daysOseason: 17, // 73
    daySeasonComps: [16, 89, 162, 235, 308],
    daysOmonth: 17, // 36
    dayMonthComps: [16, 53, 89, 126, 162, 199, 235, 272, 308, 345],
    daysOfortnight: 17, // 18
    dayFortnightComps: [
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOweek: 8, // 9
    dayWeekComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["17th of Summer", "Summer 17", "Sum 17"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["17th of Early Summer", "Early Summer 17", "E-Sum 17"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Neptune Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/0406/nep2002_hst1pan_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 164, // 365
    daysOseason: 18, // 73
    daySeasonComps: [17, 90, 163, 236, 309],
    daysOmonth: 18, // 36
    dayMonthComps: [17, 54, 90, 127, 163, 200, 236, 273, 309, 346],
    daysOfortnight: 18, // 18
    dayFortnightComps: [
      17,
      35,
      54,
      72,
      90,
      108,
      127,
      145,
      163,
      181,
      200,
      218,
      236,
      254,
      273,
      291,
      309,
      327,
      346,
      364,
    ],
    daysOweek: 9, // 9
    dayWeekComps: [
      8,
      17,
      26,
      35,
      45,
      54,
      63,
      72,
      81,
      90,
      99,
      108,
      118,
      127,
      136,
      145,
      154,
      163,
      172,
      181,
      191,
      200,
      209,
      218,
      227,
      236,
      245,
      254,
      264,
      273,
      282,
      291,
      300,
      309,
      318,
      327,
      337,
      346,
      355,
      364,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["18th of Summer", "Summer 18", "Sum 18"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["18th of Early Summer", "Early Summer 18", "E-Sum 18"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Pluto Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1909/PlutoTrueColor_NewHorizons_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 165, // 365
    daysOseason: 19, // 73
    daySeasonComps: [18, 91, 164, 237, 310],
    daysOmonth: 19, // 36
    dayMonthComps: [18, 55, 91, 128, 164, 201, 237, 274, 310, 347],
    daysOfortnight: 1, // 18
    dayFortnightComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
    ],
    daysOweek: 1, // 9
    dayWeekComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["19th of Summer", "Summer 19", "Sum 19"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["19th of Early Summer", "Early Summer 19", "E-Sum 19"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mercury Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1707/mercuryflyby2_messenger_1024.png",

    dayOfTabURL: "",
  },
  {
    daysOyear: 166, // 365
    daysOseason: 20, // 73
    daySeasonComps: [19, 92, 165, 238, 311],
    daysOmonth: 20, // 36
    dayMonthComps: [19, 56, 92, 129, 165, 202, 238, 275, 311, 348],
    daysOfortnight: 2, // 18
    dayFortnightComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
    ],
    daysOweek: 2, // 9
    dayWeekComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["20th of Summer", "Summer 20", "Sum 20"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["20th of Early Summer", "Early Summer 20", "E-Sum 20"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Venus Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/2009/VenusClouds_Akatzuki_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 167, // 365
    daysOseason: 21, // 73
    daySeasonComps: [20, 93, 166, 239, 312],
    daysOmonth: 21, // 36
    dayMonthComps: [20, 57, 93, 130, 166, 203, 239, 276, 312, 349],
    daysOfortnight: 3, // 18
    dayFortnightComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
    ],
    daysOweek: 3, // 9
    dayWeekComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["21st of Summer", "Summer 21", "Sum 21"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["21st of Early Summer", "Early Summer 21", "E-Sum 21"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Earth Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1201/bluemarbleearth_npp_980.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 168, // 365
    daysOseason: 22, // 73
    daySeasonComps: [21, 94, 167, 240, 313],
    daysOmonth: 22, // 36
    dayMonthComps: [21, 58, 94, 131, 167, 204, 240, 277, 313, 350],
    daysOfortnight: 4, // 18
    dayFortnightComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
    ],
    daysOweek: 4, // 9
    dayWeekComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["22nd of Summer", "Summer 22", "Sum 22"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["22nd of Early Summer", "Early Summer 22", "E-Sum 22"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mars Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/2010/m2020_09_22Adp.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 169, // 365
    daysOseason: 23, // 73
    daySeasonComps: [22, 95, 168, 241, 314],
    daysOmonth: 23, // 36
    dayMonthComps: [22, 59, 95, 132, 168, 205, 241, 278, 314, 351],
    daysOfortnight: 5, // 18
    dayFortnightComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
    ],
    daysOweek: 5, // 9
    dayWeekComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["23rd of Summer", "Summer 23", "Sum 23"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["23rd of Early Summer", "Early Summer 23", "E-Sum 23"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Jupiter Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1905/JupiterMarble_JunoGill_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 170, // 363
    daysOseason: 24, // 73
    daySeasonComps: [23, 96, 169, 242, 315],
    daysOmonth: 24, // 36
    dayMonthComps: [23, 60, 96, 133, 169, 206, 242, 279, 315, 352],
    daysOfortnight: 6, // 18
    dayFortnightComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
    ],
    daysOweek: 6, // 9
    dayWeekComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["24th of Summer", "Summer 24", "Sum 24"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["24th of Early Summer", "Early Summer 24", "E-Sum 24"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Saturn Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1609/SaturnAbove_Cassini_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 171, // 365
    daysOseason: 25, // 73
    daySeasonComps: [24, 97, 170, 243, 316],
    daysOmonth: 25, // 36
    dayMonthComps: [24, 61, 97, 134, 170, 207, 243, 280, 316, 353],
    daysOfortnight: 7, // 18
    dayFortnightComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
    ],
    daysOweek: 7, // 9
    dayWeekComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["25th of Summer", "Summer 25", "Sum 25"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["25th of Early Summer", "Early Summer 25", "E-Sum 25"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Uranus Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/0411/uranus_keck_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 172, // 365
    daysOseason: 26, // 73
    daySeasonComps: [25, 98, 171, 244, 317],
    daysOmonth: 26, // 36
    dayMonthComps: [25, 62, 98, 135, 171, 208, 244, 281, 317, 354],
    daysOfortnight: 8, // 18
    dayFortnightComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
    ],
    daysOweek: 8, // 9
    dayWeekComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["26th of Summer", "Summer 26", "Sum 26"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["26th of Early Summer", "Early Summer 26", "E-Sum 26"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Neptune Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/0406/nep2002_hst1pan_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 173, // 365
    daysOseason: 27, // 73
    daySeasonComps: [26, 99, 172, 245, 318],
    daysOmonth: 27, // 36
    dayMonthComps: [26, 63, 99, 136, 172, 209, 245, 282, 318, 355],
    daysOfortnight: 9, // 18
    dayFortnightComps: [
      8,
      26,
      45,
      63,
      81,
      99,
      118,
      136,
      154,
      172,
      191,
      209,
      227,
      245,
      264,
      282,
      300,
      318,
      337,
      355,
    ],
    daysOweek: 9, // 9
    dayWeekComps: [
      8,
      17,
      26,
      35,
      45,
      54,
      63,
      72,
      81,
      90,
      99,
      108,
      118,
      127,
      136,
      145,
      154,
      163,
      172,
      181,
      191,
      200,
      209,
      218,
      227,
      236,
      245,
      254,
      264,
      273,
      282,
      291,
      300,
      309,
      318,
      327,
      337,
      346,
      355,
      364,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["27th of Summer", "Summer 27", "Sum 27"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["27th of Early Summer", "Early Summer 27", "E-Sum 27"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Pluto Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1909/PlutoTrueColor_NewHorizons_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 174, // 365
    daysOseason: 28, // 73
    daySeasonComps: [27, 100, 173, 246, 319],
    daysOmonth: 28, // 36
    dayMonthComps: [27, 64, 100, 137, 173, 210, 246, 283, 319, 356],
    daysOfortnight: 10, // 18
    dayFortnightComps: [
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOweek: 1, // 9
    dayWeekComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["28th of Summer", "Summer 28", "Sum 28"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["28th of Early Summer", "Early Summer 28", "E-Sum 28"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mercury Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1707/mercuryflyby2_messenger_1024.png",

    dayOfTabURL: "",
  },
  {
    daysOyear: 175, // 365
    daysOseason: 29, // 73
    daySeasonComps: [28, 101, 174, 247, 320],
    daysOmonth: 29, // 36
    dayMonthComps: [28, 65, 101, 138, 174, 211, 247, 284, 320, 357],
    daysOfortnight: 11, // 18
    dayFortnightComps: [
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOweek: 2, // 9
    dayWeekComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["29th of Summer", "Summer 29", "Sum 29"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["29th of Early Summer", "Early Summer 29", "E-Sum 29"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Venus Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/2009/VenusClouds_Akatzuki_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 176, // 365
    daysOseason: 30, // 73
    daySeasonComps: [29, 102, 175, 248, 321],
    daysOmonth: 30, // 36
    dayMonthComps: [29, 66, 102, 139, 175, 212, 248, 285, 321, 358],
    daysOfortnight: 12, // 18
    dayFortnightComps: [
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOweek: 3, // 9
    dayWeekComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: "Flag Day",
    dayOfYearURL: "",
    dayNameWithinSeason: ["30th of Summer", "Summer 30", "Sum 30"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["30th of Early Summer", "Early Summer 30", "E-Sum 30"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Earth Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1201/bluemarbleearth_npp_980.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 177, // 365
    daysOseason: 31, // 73
    daySeasonComps: [30, 103, 176, 249, 322],
    daysOmonth: 31, // 36
    dayMonthComps: [30, 67, 103, 140, 176, 213, 249, 286, 322, 359],
    daysOfortnight: 13, // 18
    dayFortnightComps: [
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOweek: 4, // 9
    dayWeekComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["31st of Summer", "Summer 31", "Sum 31"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["31st of Early Summer", "Early Summer 31", "E-Sum 31"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mars Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/2010/m2020_09_22Adp.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 178, // 365
    daysOseason: 32, // 73
    daySeasonComps: [31, 104, 177, 250, 323],
    daysOmonth: 32, // 36
    dayMonthComps: [31, 68, 104, 141, 177, 214, 250, 287, 323, 360],
    daysOfortnight: 14, // 18
    dayFortnightComps: [
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOweek: 5, // 9
    dayWeekComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["32nd of Summer", "Summer 32", "Sum 32"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["32nd of Early Summer", "Early Summer 32", "E-Sum 32"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Jupiter Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1905/JupiterMarble_JunoGill_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 179, // 365
    daysOseason: 33, // 73
    daySeasonComps: [32, 105, 178, 251, 324],
    daysOmonth: 33, // 36
    dayMonthComps: [32, 69, 105, 142, 178, 215, 251, 288, 324, 361],
    daysOfortnight: 15, // 18
    dayFortnightComps: [
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOweek: 6, // 9
    dayWeekComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["33rd of Summer", "Summer 33", "Sum 33"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["33rd of Early Summer", "Early Summer 33", "E-Sum 33"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Saturn Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1609/SaturnAbove_Cassini_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 180, // 365
    daysOseason: 34, // 73
    daySeasonComps: [33, 106, 179, 252, 325],
    daysOmonth: 34, // 36
    dayMonthComps: [33, 70, 106, 143, 179, 216, 252, 289, 325, 362],
    daysOfortnight: 16, // 18
    dayFortnightComps: [
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOweek: 7, // 9
    dayWeekComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["34th of Summer", "Summer 34", "Sum 34"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["34th of Early Summer", "Early Summer 34", "E-Sum 34"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Uranus Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/0411/uranus_keck_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 181, // 365
    daysOseason: 35, // 73
    daySeasonComps: [34, 107, 180, 253, 326],
    daysOmonth: 35, // 36
    dayMonthComps: [34, 71, 107, 144, 180, 217, 253, 290, 326, 363],
    daysOfortnight: 17, // 18
    dayFortnightComps: [
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOweek: 8, // 9
    dayWeekComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: "Juneteenth",
    dayOfYearURL: "",
    dayNameWithinSeason: ["35th of Summer", "Summer 35", "Sum 35"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["35th of Early Summer", "Early Summer 35", "E-Sum 35"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Neptune Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/0406/nep2002_hst1pan_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 182, // 365
    daysOseason: 36, // 73
    daySeasonComps: [35, 108, 181, 254, 327],
    daysOmonth: 36, // 36
    dayMonthComps: [35, 72, 108, 145, 181, 218, 254, 291, 327, 364],
    daysOfortnight: 18, // 18
    dayFortnightComps: [
      17,
      35,
      54,
      72,
      90,
      108,
      127,
      145,
      163,
      181,
      200,
      218,
      236,
      254,
      273,
      291,
      309,
      327,
      346,
      364,
    ],
    daysOweek: 9, // 9
    dayWeekComps: [
      8,
      17,
      26,
      35,
      45,
      54,
      63,
      72,
      81,
      90,
      99,
      108,
      118,
      127,
      136,
      145,
      154,
      163,
      172,
      181,
      191,
      200,
      209,
      218,
      227,
      236,
      245,
      254,
      264,
      273,
      282,
      291,
      300,
      309,
      318,
      327,
      337,
      346,
      355,
      364,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["36th of Summer", "Summer 36", "Sum 36"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["36th of Early Summer", "Early Summer 36", "E-Sum 36"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Pluto Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1909/PlutoTrueColor_NewHorizons_960.jpg",

    dayOfTabURL: "",
  },

  {
    daysOyear: 183, // 365
    daysOseason: 37, // 73
    daySeasonComps: [36, 109, 182, 255, 328],
    daysOmidSeason: 3, // 5
    daysOmonth: null, // 36
    daysOfortnight: null, // 18
    daysOweek: null, // 9
    daysOtab: null, // 3
    dayNameWithinYear: "Summer Solstice",
    dayOfYearURL: "",
    dayNameWithinSeason: ["37th of Summer", "Summer 37", "Sum 37"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: "Mid-Summer",
    dayOfMonthURL: "",
    dayNameWithinFortnight: ["Mid-Summer"],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mid-Summer",
    dayOfWeekURL: "",
    dayNameWithinTab: ["Mid-Summer"],
    dayOfTabURL: "",
  },

  {
    daysOyear: 184, // 365
    daysOseason: 38, // 73
    daySeasonComps: [37, 110, 183, 256, 329],
    daysOmonth: 1, // 36
    dayMonthComps: [0, 37, 73, 110, 146, 183, 219, 256, 292, 329],
    daysOfortnight: 1, // 18
    dayFortnightComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
    ],
    daysOweek: 1, // 9
    dayWeekComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["38th of Summer", "Summer 38", "Sum 38"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["1st of Late Summer", "Late Summer 1", "L-Sum 1"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mercury Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1707/mercuryflyby2_messenger_1024.png",

    dayOfTabURL: "",
  },
  {
    daysOyear: 185, // 365
    daysOseason: 39, // 73
    daySeasonComps: [38, 111, 184, 257, 330],
    daysOmonth: 2, // 36
    dayMonthComps: [1, 38, 74, 111, 147, 184, 220, 257, 293, 330],
    daysOfortnight: 2, // 18
    dayFortnightComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
    ],
    daysOweek: 2, // 9
    dayWeekComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["39th of Summer", "Summer 39", "Sum 39"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["2nd of Late Summer", "Late Summer 2", "L-Sum 2"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Venus Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/2009/VenusClouds_Akatzuki_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 186, // 365
    daysOseason: 40, // 73
    daySeasonComps: [39, 112, 185, 258, 331],
    daysOmonth: 3, // 36
    dayMonthComps: [2, 39, 75, 112, 148, 185, 221, 258, 294, 331],
    daysOfortnight: 3, // 18
    dayFortnightComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
    ],
    daysOweek: 3, // 9
    dayWeekComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["40th of Summer", "Summer 40", "Sum 40"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["3rd of Late Summer", "Late Summer 3", "L-Sum 3"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Earth Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1201/bluemarbleearth_npp_980.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 187, // 365
    daysOseason: 41, // 73
    daySeasonComps: [40, 113, 186, 259, 332],
    daysOmonth: 4, // 36
    dayMonthComps: [3, 40, 76, 113, 149, 186, 222, 259, 295, 332],
    daysOfortnight: 4, // 18
    dayFortnightComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
    ],
    daysOweek: 4, // 9
    dayWeekComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["41st of Summer", "Summer 41", "Sum 41"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["4th of Late Summer", "Late Summer 4", "L-Sum 4"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mars Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/2010/m2020_09_22Adp.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 188, // 365
    daysOseason: 42, // 73
    daySeasonComps: [41, 114, 187, 260, 333],
    daysOmonth: 5, // 36
    dayMonthComps: [4, 41, 77, 114, 150, 187, 223, 260, 296, 333],
    daysOfortnight: 5, // 18
    dayFortnightComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
    ],
    daysOweek: 5, // 9
    dayWeekComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["42nd of Summer", "Summer 42", "Sum 42"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["5th of Late Summer", "Late Summer 5", "L-Sum 5"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Jupiter Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1905/JupiterMarble_JunoGill_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 189, // 363
    daysOseason: 43, // 73
    daySeasonComps: [42, 115, 188, 261, 334],
    daysOmonth: 6, // 36
    dayMonthComps: [5, 42, 78, 115, 151, 188, 224, 261, 297, 334],
    daysOfortnight: 6, // 18
    dayFortnightComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
    ],
    daysOweek: 6, // 9
    dayWeekComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["43rd of Summer", "Summer 43", "Sum 43"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["6th of Late Summer", "Late Summer 6", "L-Sum 6"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Saturn Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1609/SaturnAbove_Cassini_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 190, // 365
    daysOseason: 44, // 73
    daySeasonComps: [43, 116, 189, 262, 335],
    daysOmonth: 7, // 36
    dayMonthComps: [6, 43, 79, 116, 152, 189, 225, 262, 298, 335],
    daysOfortnight: 7, // 18
    dayFortnightComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
    ],
    daysOweek: 7, // 9
    dayWeekComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["44th of Summer", "Summer 44", "Sum 44"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["7th of Late Summer", "Late Summer 7", "L-Sum 7"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Uranus Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/0411/uranus_keck_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 191, // 365
    daysOseason: 45, // 73
    daySeasonComps: [44, 117, 190, 263, 336],
    daysOmonth: 8, // 36
    dayMonthComps: [7, 44, 80, 117, 153, 190, 226, 263, 299, 336],
    daysOfortnight: 8, // 18
    dayFortnightComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
    ],
    daysOweek: 8, // 9
    dayWeekComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayOfYearURL: "",
    dayNameWithinSeason: ["45th of Summer", "Summer 45", "Sum 45"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["8th of Late Summer", "Late Summer 8", "L-Sum 8"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Neptune Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/0406/nep2002_hst1pan_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 192, // 365
    daysOseason: 46, // 73
    daySeasonComps: [45, 118, 191, 264, 337],
    daysOmonth: 9, // 36
    dayMonthComps: [8, 45, 81, 118, 154, 191, 227, 264, 300, 337],
    daysOfortnight: 9, // 18
    dayFortnightComps: [
      8,
      26,
      45,
      63,
      81,
      99,
      118,
      136,
      154,
      172,
      191,
      209,
      227,
      245,
      264,
      282,
      300,
      318,
      337,
      355,
    ],
    daysOweek: 9, // 9
    dayWeekComps: [
      8,
      17,
      26,
      35,
      45,
      54,
      63,
      72,
      81,
      90,
      99,
      108,
      118,
      127,
      136,
      145,
      154,
      163,
      172,
      181,
      191,
      200,
      209,
      218,
      227,
      236,
      245,
      254,
      264,
      273,
      282,
      291,
      300,
      309,
      318,
      327,
      337,
      346,
      355,
      364,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["46th of Summer", "Summer 46", "Sum 46"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["9th of Late Summer", "Late Summer 9", "L-Sum 9"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Pluto Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1909/PlutoTrueColor_NewHorizons_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 193, // 365
    daysOseason: 47, // 73
    daySeasonComps: [46, 119, 192, 265, 338],
    daysOmonth: 10, // 36
    dayMonthComps: [9, 46, 82, 119, 155, 192, 228, 265, 301, 338],
    daysOfortnight: 10, // 18
    dayFortnightComps: [
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOweek: 1, // 9
    dayWeekComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["47th of Summer", "Summer 47", "Sum 47"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["10th of Late Summer", "Late Summer 10", "L-Sum 10"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mercury Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1707/mercuryflyby2_messenger_1024.png",

    dayOfTabURL: "",
  },
  {
    daysOyear: 194, // 365
    daysOseason: 48, // 73
    daySeasonComps: [47, 120, 193, 266, 339],
    daysOmonth: 11, // 36
    dayMonthComps: [10, 47, 83, 120, 156, 193, 229, 266, 302, 339],
    daysOfortnight: 11, // 18
    dayFortnightComps: [
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOweek: 2, // 9
    dayWeekComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["48th of Summer", "Summer 48", "Sum 48"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["11th of Late Summer", "Late Summer 11", "L-Sum 11"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Venus Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/2009/VenusClouds_Akatzuki_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 195, // 365
    daysOseason: 49, // 73
    daySeasonComps: [48, 121, 194, 267, 340],
    daysOmonth: 12, // 36
    dayMonthComps: [11, 48, 84, 121, 157, 194, 230, 267, 303, 340],
    daysOfortnight: 12, // 18
    dayFortnightComps: [
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOweek: 3, // 9
    dayWeekComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["49th of Summer", "Summer 49", "Sum 49"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["12th of Late Summer", "Late Summer 12", "L-Sum 12"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Earth Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1201/bluemarbleearth_npp_980.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 196, // 365
    daysOseason: 50, // 73
    daySeasonComps: [49, 122, 195, 268, 341],
    daysOmonth: 13, // 36
    dayMonthComps: [12, 49, 85, 122, 158, 195, 231, 268, 304, 341],
    daysOfortnight: 13, // 18
    dayFortnightComps: [
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOweek: 4, // 9
    dayWeekComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: "Independence Day",
    dayOfYearURL: "",
    dayNameWithinSeason: ["50th of Summer", "Summer 50", "Sum 50"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["13th of Late Summer", "Late Summer 13", "L-Sum 13"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mars Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/2010/m2020_09_22Adp.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 197, // 365
    daysOseason: 51, // 73
    daySeasonComps: [50, 123, 196, 269, 342],
    daysOmonth: 14, // 36
    dayMonthComps: [13, 50, 86, 123, 159, 196, 232, 269, 305, 342],
    daysOfortnight: 14, // 18
    dayFortnightComps: [
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOweek: 5, // 9
    dayWeekComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["51st of Summer", "Summer 51", "Sum 51"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["14th of Late Summer", "Late Summer 14", "L-Sum 14"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Jupiter Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1905/JupiterMarble_JunoGill_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 198, // 365
    daysOseason: 52, // 73
    daySeasonComps: [51, 124, 197, 270, 343],
    daysOmonth: 15, // 36
    dayMonthComps: [14, 51, 87, 124, 160, 197, 233, 270, 306, 343],
    daysOfortnight: 15, // 18
    dayFortnightComps: [
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOweek: 6, // 9
    dayWeekComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["52nd of Summer", "Summer 52", "Sum 52"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["15th of Late Summer", "Late Summer 15", "L-Sum 15"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Saturn Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1609/SaturnAbove_Cassini_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 199, // 365
    daysOseason: 53, // 73
    daySeasonComps: [52, 125, 198, 271, 344],
    daysOmonth: 16, // 36
    dayMonthComps: [15, 52, 88, 125, 161, 198, 234, 271, 307, 344],
    daysOfortnight: 16, // 18
    dayFortnightComps: [
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOweek: 7, // 9
    dayWeekComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["53rd of Summer", "Summer 53", "Sum 53"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["16th of Late Summer", "Late Summer 16", "L-Sum 16"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Uranus Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/0411/uranus_keck_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 200, // 365
    daysOseason: 54, // 73
    daySeasonComps: [53, 126, 199, 272, 345],
    daysOmonth: 17, // 36
    dayMonthComps: [16, 53, 89, 126, 162, 199, 235, 272, 308, 345],
    daysOfortnight: 17, // 18
    dayFortnightComps: [
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOweek: 8, // 9
    dayWeekComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["54th of Summer", "Summer 54", "Sum 54"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["17th of Late Summer", "Late Summer 17", "L-Sum 17"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Neptune Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/0406/nep2002_hst1pan_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 201, // 365
    daysOseason: 55, // 73
    daySeasonComps: [54, 127, 200, 273, 346],
    daysOmonth: 18, // 36
    dayMonthComps: [17, 54, 90, 127, 163, 200, 236, 273, 309, 346],
    daysOfortnight: 18, // 18
    dayFortnightComps: [
      17,
      35,
      54,
      72,
      90,
      108,
      127,
      145,
      163,
      181,
      200,
      218,
      236,
      254,
      273,
      291,
      309,
      327,
      346,
      364,
    ],
    daysOweek: 9, // 9
    dayWeekComps: [
      8,
      17,
      26,
      35,
      45,
      54,
      63,
      72,
      81,
      90,
      99,
      108,
      118,
      127,
      136,
      145,
      154,
      163,
      172,
      181,
      191,
      200,
      209,
      218,
      227,
      236,
      245,
      254,
      264,
      273,
      282,
      291,
      300,
      309,
      318,
      327,
      337,
      346,
      355,
      364,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["55th of Summer", "Summer 55", "Sum 55"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["18th of Late Summer", "Late Summer 18", "L-Sum 18"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Pluto Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1909/PlutoTrueColor_NewHorizons_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 202, // 365
    daysOseason: 56, // 73
    daySeasonComps: [55, 128, 201, 274, 347],
    daysOmonth: 19, // 36
    dayMonthComps: [18, 55, 91, 128, 164, 201, 237, 274, 310, 347],
    daysOfortnight: 1, // 18
    dayFortnightComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
    ],
    daysOweek: 1, // 9
    dayWeekComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["56th of Summer", "Summer 56", "Sum 56"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["19th of Late Summer", "Late Summer 19", "L-Sum 19"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mercury Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1707/mercuryflyby2_messenger_1024.png",

    dayOfTabURL: "",
  },
  {
    daysOyear: 203, // 365
    daysOseason: 57, // 73
    daySeasonComps: [56, 129, 202, 275, 348],
    daysOmonth: 20, // 36
    dayMonthComps: [19, 56, 92, 129, 165, 202, 238, 275, 311, 348],
    daysOfortnight: 2, // 18
    dayFortnightComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
    ],
    daysOweek: 2, // 9
    dayWeekComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["57th of Summer", "Summer 57", "Sum 57"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["20th of Late Summer", "Late Summer 20", "L-Sum 20"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Venus Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/2009/VenusClouds_Akatzuki_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 204, // 365
    daysOseason: 58, // 73
    daySeasonComps: [57, 130, 203, 276, 349],
    daysOmonth: 21, // 36
    dayMonthComps: [20, 57, 93, 130, 166, 203, 239, 276, 312, 349],
    daysOfortnight: 3, // 18
    dayFortnightComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
    ],
    daysOweek: 3, // 9
    dayWeekComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["58th of Summer", "Summer 58", "Sum 58"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["21st of Late Summer", "Late Summer 21", "L-Sum 21"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Earth Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1201/bluemarbleearth_npp_980.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 205, // 365
    daysOseason: 59, // 73
    daySeasonComps: [58, 131, 204, 277, 350],
    daysOmonth: 22, // 36
    dayMonthComps: [21, 58, 94, 131, 167, 204, 240, 277, 313, 350],
    daysOfortnight: 4, // 18
    dayFortnightComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
    ],
    daysOweek: 4, // 9
    dayWeekComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["59th of Summer", "Summer 59", "Sum 59"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["22nd of Late Summer", "Late Summer 22", "L-Sum 22"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mars Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/2010/m2020_09_22Adp.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 206, // 365
    daysOseason: 60, // 73
    daySeasonComps: [59, 132, 205, 278, 351],
    daysOmonth: 23, // 36
    dayMonthComps: [22, 59, 95, 132, 168, 205, 241, 278, 314, 351],
    daysOfortnight: 5, // 18
    dayFortnightComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
    ],
    daysOweek: 5, // 9
    dayWeekComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["60th of Summer", "Summer 60", "Sum 60"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["23rd of Late Summer", "Late Summer 23", "L-Sum 23"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Jupiter Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1905/JupiterMarble_JunoGill_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 207, // 363
    daysOseason: 61, // 73
    daySeasonComps: [60, 133, 206, 279, 352],
    daysOmonth: 24, // 36
    dayMonthComps: [23, 60, 96, 133, 169, 206, 242, 279, 315, 352],
    daysOfortnight: 6, // 18
    dayFortnightComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
    ],
    daysOweek: 6, // 9
    dayWeekComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["61st of Summer", "Summer 61", "Sum 61"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["24th of Late Summer", "Late Summer 24", "L-Sum 24"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Saturn Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1609/SaturnAbove_Cassini_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 208, // 365
    daysOseason: 62, // 73
    daySeasonComps: [61, 134, 207, 280, 353],
    daysOmonth: 25, // 36
    dayMonthComps: [24, 61, 97, 134, 170, 207, 243, 280, 316, 353],
    daysOfortnight: 7, // 18
    dayFortnightComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
    ],
    daysOweek: 7, // 9
    dayWeekComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["62nd of Summer", "Summer 62", "Sum 62"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["25th of Late Summer", "Late Summer 25", "L-Sum 25"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Uranus Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/0411/uranus_keck_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 209, // 365
    daysOseason: 63, // 73
    daySeasonComps: [62, 135, 208, 281, 354],
    daysOmonth: 26, // 36
    dayMonthComps: [25, 62, 98, 135, 171, 208, 244, 281, 317, 354],
    daysOfortnight: 8, // 18
    dayFortnightComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
    ],
    daysOweek: 8, // 9
    dayWeekComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["63rd of Summer", "Summer 63", "Sum 63"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["26th of Late Summer", "Late Summer 26", "L-Sum 26"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Neptune Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/0406/nep2002_hst1pan_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 210, // 365
    daysOseason: 64, // 73
    daySeasonComps: [63, 136, 209, 282, 355],
    daysOmonth: 27, // 36
    dayMonthComps: [26, 63, 99, 136, 172, 209, 245, 282, 318, 355],
    daysOfortnight: 9, // 18
    dayFortnightComps: [
      8,
      26,
      45,
      63,
      81,
      99,
      118,
      136,
      154,
      172,
      191,
      209,
      227,
      245,
      264,
      282,
      300,
      318,
      337,
      355,
    ],
    daysOweek: 9, // 9
    dayWeekComps: [
      8,
      17,
      26,
      35,
      45,
      54,
      63,
      72,
      81,
      90,
      99,
      108,
      118,
      127,
      136,
      145,
      154,
      163,
      172,
      181,
      191,
      200,
      209,
      218,
      227,
      236,
      245,
      254,
      264,
      273,
      282,
      291,
      300,
      309,
      318,
      327,
      337,
      346,
      355,
      364,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["64th of Summer", "Summer 64", "Sum 64"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["27th of Late Summer", "Late Summer 27", "L-Sum 27"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Pluto Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1909/PlutoTrueColor_NewHorizons_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 211, // 365
    daysOseason: 65, // 73
    daySeasonComps: [64, 137, 210, 283, 356],
    daysOmonth: 28, // 36
    dayMonthComps: [27, 64, 100, 137, 173, 210, 246, 283, 319, 356],
    daysOfortnight: 10, // 18
    dayFortnightComps: [
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOweek: 1, // 9
    dayWeekComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["65th of Summer", "Summer 65", "Sum 65"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["28th of Late Summer", "Late Summer 28", "L-Sum 28"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mercury Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1707/mercuryflyby2_messenger_1024.png",

    dayOfTabURL: "",
  },
  {
    daysOyear: 212, // 365
    daysOseason: 66, // 73
    daySeasonComps: [65, 138, 211, 284, 357],
    daysOmonth: 29, // 36
    dayMonthComps: [28, 65, 101, 138, 174, 211, 247, 284, 320, 357],
    daysOfortnight: 11, // 18
    dayFortnightComps: [
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOweek: 2, // 9
    dayWeekComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["66th of Summer", "Summer 66", "Sum 66"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["29th of Late Summer", "Late Summer 29", "L-Sum 29"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Venus Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/2009/VenusClouds_Akatzuki_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 213, // 365
    daysOseason: 67, // 73
    daySeasonComps: [66, 139, 212, 285, 358],
    daysOmonth: 30, // 36
    dayMonthComps: [29, 66, 102, 139, 175, 212, 248, 285, 321, 358],
    daysOfortnight: 12, // 18
    dayFortnightComps: [
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOweek: 3, // 9
    dayWeekComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["67th of Summer", "Summer 67", "Sum 67"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["30th of Late Summer", "Late Summer 30", "L-Sum 30"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Earth Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1201/bluemarbleearth_npp_980.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 214, // 365
    daysOseason: 68, // 73
    daySeasonComps: [67, 140, 213, 286, 359],
    daysOmonth: 31, // 36
    dayMonthComps: [30, 67, 103, 140, 176, 213, 249, 286, 322, 359],
    daysOfortnight: 13, // 18
    dayFortnightComps: [
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOweek: 4, // 9
    dayWeekComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["68th of Summer", "Summer 68", "Sum 68"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["31st of Late Summer", "Late Summer 31", "L-Sum 31"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mars Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/2010/m2020_09_22Adp.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 215, // 365
    daysOseason: 69, // 73
    daySeasonComps: [68, 141, 214, 287, 360],
    daysOmonth: 32, // 36
    dayMonthComps: [31, 68, 104, 141, 177, 214, 250, 287, 323, 360],
    daysOfortnight: 14, // 18
    dayFortnightComps: [
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOweek: 5, // 9
    dayWeekComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["69th of Summer", "Summer 69", "Sum 69"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["32nd of Late Summer", "Late Summer 32", "L-Sum 32"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Jupiter Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1905/JupiterMarble_JunoGill_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 216, // 365
    daysOseason: 70, // 73
    daySeasonComps: [69, 142, 215, 288, 361],
    daysOmonth: 33, // 36
    dayMonthComps: [32, 69, 105, 142, 178, 215, 251, 288, 324, 361],
    daysOfortnight: 15, // 18
    dayFortnightComps: [
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOweek: 6, // 9
    dayWeekComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["70th of Summer", "Summer 70", "Sum 70"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["33rd of Late Summer", "Late Summer 33", "L-Sum 33"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Saturn Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1609/SaturnAbove_Cassini_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 217, // 365
    daysOseason: 71, // 73
    daySeasonComps: [70, 143, 216, 289, 362],
    daysOmonth: 34, // 36
    dayMonthComps: [33, 70, 106, 143, 179, 216, 252, 289, 325, 362],
    daysOfortnight: 16, // 18
    dayFortnightComps: [
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOweek: 7, // 9
    dayWeekComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["71st of Summer", "Summer 71", "Sum 71"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["34th of Late Summer", "Late Summer 34", "L-Sum 34"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Uranus Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/0411/uranus_keck_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 218, // 365
    daysOseason: 72, // 73
    daySeasonComps: [71, 144, 217, 290, 363],
    daysOmonth: 35, // 36
    dayMonthComps: [34, 71, 107, 144, 180, 217, 253, 290, 326, 363],
    daysOfortnight: 17, // 18
    dayFortnightComps: [
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOweek: 8, // 9
    dayWeekComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["72nd of Summer", "Summer 72", "Sum 72"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["35th of Late Summer", "Late Summer 35", "L-Sum 35"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Neptune Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/0406/nep2002_hst1pan_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 219, // 365
    daysOseason: 73, // 73
    daySeasonComps: [72, 145, 218, 291, 364],
    daysOmonth: 36, // 36
    dayMonthComps: [35, 72, 108, 145, 181, 218, 254, 291, 327, 364],
    daysOfortnight: 18, // 18
    dayFortnightComps: [
      17,
      35,
      54,
      72,
      90,
      108,
      127,
      145,
      163,
      181,
      200,
      218,
      236,
      254,
      273,
      291,
      309,
      327,
      346,
      364,
    ],
    daysOweek: 9, // 9
    dayWeekComps: [
      8,
      17,
      26,
      35,
      45,
      54,
      63,
      72,
      81,
      90,
      99,
      108,
      118,
      127,
      136,
      145,
      154,
      163,
      172,
      181,
      191,
      200,
      209,
      218,
      227,
      236,
      245,
      254,
      264,
      273,
      282,
      291,
      300,
      309,
      318,
      327,
      337,
      346,
      355,
      364,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["73rd of Summer", "Summer 73", "Sum 73"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["36th of Late Summer", "Late Summer 36", "L-Sum 36"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Pluto Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1909/PlutoTrueColor_NewHorizons_960.jpg",

    dayOfTabURL: "",
  },
  //AUTUMN:
  {
    daysOyear: 220, // 365
    daysOseason: 1, // 73
    daySeasonComps: [0, 73, 146, 219, 292],
    daysOmonth: 1, // 36
    dayMonthComps: [0, 37, 73, 110, 146, 183, 219, 256, 292, 329],
    daysOfortnight: 1, // 18
    dayFortnightComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
    ],
    daysOweek: 1, // 9
    dayWeekComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: "New New Years",
    dayOfYearURL: "",
    dayNameWithinSeason: ["1st of Autumn", "Autumn 1", "Aut 1"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["1st of Early Autumn", "Early Autumn 1", "E-Aut 1"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mercury Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1707/mercuryflyby2_messenger_1024.png",

    dayOfTabURL: "",
  },
  {
    daysOyear: 221, // 365
    daysOseason: 2, // 73
    daySeasonComps: [1, 74, 147, 220, 293],
    daysOmonth: 2, // 36
    dayMonthComps: [1, 38, 74, 111, 147, 184, 220, 257, 293, 330],
    daysOfortnight: 2, // 18
    dayFortnightComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
    ],
    daysOweek: 2, // 9
    dayWeekComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["2nd of Autumn", "Autumn 2", "Aut 2"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["2nd of Early Autumn", "Early Autumn 2", "E-Aut 2"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Venus Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/2009/VenusClouds_Akatzuki_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 222, // 365
    daysOseason: 3, // 73
    daySeasonComps: [2, 75, 148, 221, 294],
    daysOmonth: 3, // 36
    dayMonthComps: [2, 39, 75, 112, 148, 185, 221, 258, 294, 331],
    daysOfortnight: 3, // 18
    dayFortnightComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
    ],
    daysOweek: 3, // 9
    dayWeekComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["3rd of Autumn", "Autumn 3", "Aut 3"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["3rd of Early Autumn", "Early Autumn 3", "E-Aut 3"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Earth Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1201/bluemarbleearth_npp_980.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 223, // 365
    daysOseason: 4, // 73
    daySeasonComps: [3, 76, 149, 222, 295],
    daysOmonth: 4, // 36
    dayMonthComps: [3, 40, 76, 113, 149, 186, 222, 259, 295, 332],
    daysOfortnight: 4, // 18
    dayFortnightComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
    ],
    daysOweek: 4, // 9
    dayWeekComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["4th of Autumn", "Autumn 4", "Aut 4"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["4th of Early Autumn", "Early Autumn 4", "E-Aut 4"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mars Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/2010/m2020_09_22Adp.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 224, // 365
    daysOseason: 5, // 73
    daySeasonComps: [4, 77, 150, 223, 296],
    daysOmonth: 5, // 36
    dayMonthComps: [4, 41, 77, 114, 150, 187, 223, 260, 296, 333],
    daysOfortnight: 5, // 18
    dayFortnightComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
    ],
    daysOweek: 5, // 9
    dayWeekComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["5th of Autumn", "Autumn 5", "Aut 5"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["5th of Early Autumn", "Early Autumn 5", "E-Aut 5"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Jupiter Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1905/JupiterMarble_JunoGill_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 225, // 363
    daysOseason: 6, // 73
    daySeasonComps: [5, 78, 151, 224, 297],
    daysOmonth: 6, // 36
    dayMonthComps: [5, 42, 78, 115, 151, 188, 224, 261, 297, 334],
    daysOfortnight: 6, // 18
    dayFortnightComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
    ],
    daysOweek: 6, // 9
    dayWeekComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["6th of Autumn", "Autumn 6", "Aut 6"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["6th of Early Autumn", "Early Autumn 6", "E-Aut 6"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Saturn Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1609/SaturnAbove_Cassini_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 226, // 365
    daysOseason: 7, // 73
    daySeasonComps: [6, 79, 152, 225, 298],
    daysOmonth: 7, // 36
    dayMonthComps: [6, 43, 79, 116, 152, 189, 225, 262, 298, 335],
    daysOfortnight: 7, // 18
    dayFortnightComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
    ],
    daysOweek: 7, // 9
    dayWeekComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["7th of Autumn", "Autumn 7", "Aut 7"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["7th of Early Autumn", "Early Autumn 7", "E-Aut 7"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Uranus Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/0411/uranus_keck_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 227, // 365
    daysOseason: 8, // 73
    daySeasonComps: [7, 80, 153, 226, 299],
    daysOmonth: 8, // 36
    dayMonthComps: [7, 44, 80, 117, 153, 190, 226, 263, 299, 336],
    daysOfortnight: 8, // 18
    dayFortnightComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
    ],
    daysOweek: 8, // 9
    dayWeekComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["8th of Autumn", "Autumn 8", "Aut 8"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["8th of Early Autumn", "Early Autumn 8", "E-Aut 8"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Neptune Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/0406/nep2002_hst1pan_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 228, // 365
    daysOseason: 9, // 73
    daySeasonComps: [8, 81, 154, 227, 300],
    daysOmonth: 9, // 36
    dayMonthComps: [8, 45, 81, 118, 154, 191, 227, 264, 300, 337],
    daysOfortnight: 9, // 18
    dayFortnightComps: [
      8,
      26,
      45,
      63,
      81,
      99,
      118,
      136,
      154,
      172,
      191,
      209,
      227,
      245,
      264,
      282,
      300,
      318,
      337,
      355,
    ],
    daysOweek: 9, // 9
    dayWeekComps: [
      8,
      17,
      26,
      35,
      45,
      54,
      63,
      72,
      81,
      90,
      99,
      108,
      118,
      127,
      136,
      145,
      154,
      163,
      172,
      181,
      191,
      200,
      209,
      218,
      227,
      236,
      245,
      254,
      264,
      273,
      282,
      291,
      300,
      309,
      318,
      327,
      337,
      346,
      355,
      364,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["9th of Autumn", "Autumn 9", "Aut 9"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["9th of Early Autumn", "Early Autumn 9", "E-Aut 9"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Pluto Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1909/PlutoTrueColor_NewHorizons_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 229, // 365
    daysOseason: 10, // 73
    daySeasonComps: [9, 82, 155, 228, 301],
    daysOmonth: 10, // 36
    dayMonthComps: [9, 46, 82, 119, 155, 192, 228, 265, 301, 338],
    daysOfortnight: 10, // 18
    dayFortnightComps: [
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOweek: 1, // 9
    dayWeekComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["10th of Autumn", "Autumn 10", "Aut 10"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["10th of Early Autumn", "Early Autumn 10", "E-Aut 10"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mercury Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1707/mercuryflyby2_messenger_1024.png",

    dayOfTabURL: "",
  },
  {
    daysOyear: 230, // 365
    daysOseason: 11, // 73
    daySeasonComps: [10, 83, 156, 229, 302],
    daysOmonth: 11, // 36
    dayMonthComps: [10, 47, 83, 120, 156, 193, 229, 266, 302, 339],
    daysOfortnight: 11, // 18
    dayFortnightComps: [
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOweek: 2, // 9
    dayWeekComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["11th of Autumn", "Autumn 11", "Aut 11"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["11th of Early Autumn", "Early Autumn 11", "E-Aut 11"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Venus Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/2009/VenusClouds_Akatzuki_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 231, // 365
    daysOseason: 12, // 73
    daySeasonComps: [11, 84, 157, 230, 303],
    daysOmonth: 12, // 36
    dayMonthComps: [11, 48, 84, 121, 157, 194, 230, 267, 303, 340],
    daysOfortnight: 12, // 18
    dayFortnightComps: [
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOweek: 3, // 9
    dayWeekComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["12th of Autumn", "Autumn 12", "Aut 12"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["12th of Early Autumn", "Early Autumn 12", "E-Aut 12"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Earth Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1201/bluemarbleearth_npp_980.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 232, // 365
    daysOseason: 13, // 73
    daySeasonComps: [12, 85, 158, 231, 304],
    daysOmonth: 13, // 36
    dayMonthComps: [12, 49, 85, 122, 158, 195, 231, 268, 304, 341],
    daysOfortnight: 13, // 18
    dayFortnightComps: [
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOweek: 4, // 9
    dayWeekComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["13th of Autumn", "Autumn 13", "Aut 13"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["13th of Early Autumn", "Early Autumn 13", "E-Aut 13"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mars Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/2010/m2020_09_22Adp.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 233, // 365
    daysOseason: 14, // 73
    daySeasonComps: [13, 86, 159, 232, 305],
    daysOmonth: 14, // 36
    dayMonthComps: [13, 50, 86, 123, 159, 196, 232, 269, 305, 342],
    daysOfortnight: 14, // 18
    dayFortnightComps: [
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOweek: 5, // 9
    dayWeekComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["14th of Autumn", "Autumn 14", "Aut 14"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["14th of Early Autumn", "Early Autumn 14", "E-Aut 14"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Jupiter Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1905/JupiterMarble_JunoGill_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 234, // 365
    daysOseason: 15, // 73
    daySeasonComps: [14, 87, 160, 233, 306],
    daysOmonth: 15, // 36
    dayMonthComps: [14, 51, 87, 124, 160, 197, 233, 270, 306, 343],
    daysOfortnight: 15, // 18
    dayFortnightComps: [
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOweek: 6, // 9
    dayWeekComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["15th of Autumn", "Autumn 15", "Aut 15"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["15th of Early Autumn", "Early Autumn 15", "E-Aut 15"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Saturn Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1609/SaturnAbove_Cassini_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 235, // 365
    daysOseason: 16, // 73
    daySeasonComps: [15, 88, 161, 234, 307],
    daysOmonth: 16, // 36
    dayMonthComps: [15, 52, 88, 125, 161, 198, 234, 271, 307, 344],
    daysOfortnight: 16, // 18
    dayFortnightComps: [
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOweek: 7, // 9
    dayWeekComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["16th of Autumn", "Autumn 16", "Aut 16"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["16th of Early Autumn", "Early Autumn 16", "E-Aut 16"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Uranus Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/0411/uranus_keck_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 236, // 365
    daysOseason: 17, // 73
    daySeasonComps: [16, 89, 162, 235, 308],
    daysOmonth: 17, // 36
    dayMonthComps: [16, 53, 89, 126, 162, 199, 235, 272, 308, 345],
    daysOfortnight: 17, // 18
    dayFortnightComps: [
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOweek: 8, // 9
    dayWeekComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["17th of Autumn", "Autumn 17", "Aut 17"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["17th of Early Autumn", "Early Autumn 17", "E-Aut 17"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Neptune Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/0406/nep2002_hst1pan_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 237, // 365
    daysOseason: 18, // 73
    daySeasonComps: [17, 90, 163, 236, 309],
    daysOmonth: 18, // 36
    dayMonthComps: [17, 54, 90, 127, 163, 200, 236, 273, 309, 346],
    daysOfortnight: 18, // 18
    dayFortnightComps: [
      17,
      35,
      54,
      72,
      90,
      108,
      127,
      145,
      163,
      181,
      200,
      218,
      236,
      254,
      273,
      291,
      309,
      327,
      346,
      364,
    ],
    daysOweek: 9, // 9
    dayWeekComps: [
      8,
      17,
      26,
      35,
      45,
      54,
      63,
      72,
      81,
      90,
      99,
      108,
      118,
      127,
      136,
      145,
      154,
      163,
      172,
      181,
      191,
      200,
      209,
      218,
      227,
      236,
      245,
      254,
      264,
      273,
      282,
      291,
      300,
      309,
      318,
      327,
      337,
      346,
      355,
      364,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["18th of Autumn", "Autumn 18", "Aut 18"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["18th of Early Autumn", "Early Autumn 18", "E-Aut 18"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Pluto Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1909/PlutoTrueColor_NewHorizons_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 238, // 365
    daysOseason: 19, // 73
    daySeasonComps: [18, 91, 164, 237, 310],
    daysOmonth: 19, // 36
    dayMonthComps: [18, 55, 91, 128, 164, 201, 237, 274, 310, 347],
    daysOfortnight: 1, // 18
    dayFortnightComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
    ],
    daysOweek: 1, // 9
    dayWeekComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["19th of Autumn", "Autumn 19", "Aut 19"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["19th of Early Autumn", "Early Autumn 19", "E-Aut 19"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mercury Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1707/mercuryflyby2_messenger_1024.png",

    dayOfTabURL: "",
  },
  {
    daysOyear: 239, // 365
    daysOseason: 20, // 73
    daySeasonComps: [19, 92, 165, 238, 311],
    daysOmonth: 20, // 36
    dayMonthComps: [19, 56, 92, 129, 165, 202, 238, 275, 311, 348],
    daysOfortnight: 2, // 18
    dayFortnightComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
    ],
    daysOweek: 2, // 9
    dayWeekComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["20th of Autumn", "Autumn 20", "Aut 20"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["20th of Early Autumn", "Early Autumn 20", "E-Aut 20"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Venus Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/2009/VenusClouds_Akatzuki_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 240, // 365
    daysOseason: 21, // 73
    daySeasonComps: [20, 93, 166, 239, 312],
    daysOmonth: 21, // 36
    dayMonthComps: [20, 57, 93, 130, 166, 203, 239, 276, 312, 349],
    daysOfortnight: 3, // 18
    dayFortnightComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
    ],
    daysOweek: 3, // 9
    dayWeekComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["21st of Autumn", "Autumn 21", "Aut 21"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["21st of Early Autumn", "Early Autumn 21", "E-Aut 21"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Earth Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1201/bluemarbleearth_npp_980.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 241, // 365
    daysOseason: 22, // 73
    daySeasonComps: [21, 94, 167, 240, 313],
    daysOmonth: 22, // 36
    dayMonthComps: [21, 58, 94, 131, 167, 204, 240, 277, 313, 350],
    daysOfortnight: 4, // 18
    dayFortnightComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
    ],
    daysOweek: 4, // 9
    dayWeekComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["22nd of Autumn", "Autumn 22", "Aut 22"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["22nd of Early Autumn", "Early Autumn 22", "E-Aut 22"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mars Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/2010/m2020_09_22Adp.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 242, // 365
    daysOseason: 23, // 73
    daySeasonComps: [22, 95, 168, 241, 314],
    daysOmonth: 23, // 36
    dayMonthComps: [22, 59, 95, 132, 168, 205, 241, 278, 314, 351],
    daysOfortnight: 5, // 18
    dayFortnightComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
    ],
    daysOweek: 5, // 9
    dayWeekComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["23rd of Autumn", "Autumn 23", "Aut 23"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["23rd of Early Autumn", "Early Autumn 23", "E-Aut 23"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Jupiter Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1905/JupiterMarble_JunoGill_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 243, // 363
    daysOseason: 24, // 73
    daySeasonComps: [23, 96, 169, 242, 315],
    daysOmonth: 24, // 36
    dayMonthComps: [23, 60, 96, 133, 169, 206, 242, 279, 315, 352],
    daysOfortnight: 6, // 18
    dayFortnightComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
    ],
    daysOweek: 6, // 9
    dayWeekComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["24th of Autumn", "Autumn 24", "Aut 24"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["24th of Early Autumn", "Early Autumn 24", "E-Aut 24"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Saturn Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1609/SaturnAbove_Cassini_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 244, // 365
    daysOseason: 25, // 73
    daySeasonComps: [24, 97, 170, 243, 316],
    daysOmonth: 25, // 36
    dayMonthComps: [24, 61, 97, 134, 170, 207, 243, 280, 316, 353],
    daysOfortnight: 7, // 18
    dayFortnightComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
    ],
    daysOweek: 7, // 9
    dayWeekComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["25th of Autumn", "Autumn 25", "Aut 25"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["25th of Early Autumn", "Early Autumn 25", "E-Aut 25"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Uranus Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/0411/uranus_keck_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 245, // 365
    daysOseason: 26, // 73
    daySeasonComps: [25, 98, 171, 244, 317],
    daysOmonth: 26, // 36
    dayMonthComps: [25, 62, 98, 135, 171, 208, 244, 281, 317, 354],
    daysOfortnight: 8, // 18
    dayFortnightComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
    ],
    daysOweek: 8, // 9
    dayWeekComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["26th of Autumn", "Autumn 26", "Aut 26"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["26th of Early Autumn", "Early Autumn 26", "E-Aut 26"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Neptune Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/0406/nep2002_hst1pan_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 246, // 365
    daysOseason: 27, // 73
    daySeasonComps: [26, 99, 172, 245, 318],
    daysOmonth: 27, // 36
    dayMonthComps: [26, 63, 99, 136, 172, 209, 245, 282, 318, 355],
    daysOfortnight: 9, // 18
    dayFortnightComps: [
      8,
      26,
      45,
      63,
      81,
      99,
      118,
      136,
      154,
      172,
      191,
      209,
      227,
      245,
      264,
      282,
      300,
      318,
      337,
      355,
    ],
    daysOweek: 9, // 9
    dayWeekComps: [
      8,
      17,
      26,
      35,
      45,
      54,
      63,
      72,
      81,
      90,
      99,
      108,
      118,
      127,
      136,
      145,
      154,
      163,
      172,
      181,
      191,
      200,
      209,
      218,
      227,
      236,
      245,
      254,
      264,
      273,
      282,
      291,
      300,
      309,
      318,
      327,
      337,
      346,
      355,
      364,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: "National Ride the Autd Day",
    dayOfYearURL: "https://pbs.twimg.com/media/DlTECI4UwAAHawp.jpg",
    dayNameWithinSeason: [
      "27th of Autumn",
      "Autumn 27",
      "Aut 27",
      "3/8ths Day",
    ],
    dayOfSeasonURL: "",
    dayNameWithinMonth: [
      "27th of Early Autumn",
      "Early Autumn 27",
      "E-Aut 27",
      "Three-Quarters Gone",
    ],
    dayOfMonthURL: "",
    dayNameWithinFortnight: "Halfway Point",
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Pluto Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1909/PlutoTrueColor_NewHorizons_960.jpg",
    dayNameWithinTab: "Final Day",
    dayOfTabURL: "",
  },
  {
    daysOyear: 247, // 365
    daysOseason: 28, // 73
    daySeasonComps: [27, 100, 173, 246, 319],
    daysOmonth: 28, // 36
    dayMonthComps: [27, 64, 100, 137, 173, 210, 246, 283, 319, 356],
    daysOfortnight: 10, // 18
    dayFortnightComps: [
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOweek: 1, // 9
    dayWeekComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["28th of Autumn", "Autumn 28", "Aut 28"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["28th of Early Autumn", "Early Autumn 28", "E-Aut 28"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: ["beginning of the beginning of the end"],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mercury Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1707/mercuryflyby2_messenger_1024.png",

    dayOfTabURL: "",
  },
  {
    daysOyear: 248, // 365
    daysOseason: 29, // 73
    daySeasonComps: [28, 101, 174, 247, 320],
    daysOmonth: 29, // 36
    dayMonthComps: [28, 65, 101, 138, 174, 211, 247, 284, 320, 357],
    daysOfortnight: 11, // 18
    dayFortnightComps: [
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOweek: 2, // 9
    dayWeekComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["29th of Autumn", "Autumn 29", "Aut 29"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["29th of Early Autumn", "Early Autumn 29", "E-Aut 29"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: ["middle of the beginning of the end"],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Venus Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/2009/VenusClouds_Akatzuki_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 249, // 365
    daysOseason: 30, // 73
    daySeasonComps: [29, 102, 175, 248, 321],
    daysOmonth: 30, // 36
    dayMonthComps: [29, 66, 102, 139, 175, 212, 248, 285, 321, 358],
    daysOfortnight: 12, // 18
    dayFortnightComps: [
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOweek: 3, // 9
    dayWeekComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["30th of Autumn", "Autumn 30", "Aut 30"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["30th of Early Autumn", "Early Autumn 30", "E-Aut 30"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: ["end of the beginning of the end"],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Earth Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1201/bluemarbleearth_npp_980.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 250, // 365
    daysOseason: 31, // 73
    daySeasonComps: [30, 103, 176, 249, 322],
    daysOmonth: 31, // 36
    dayMonthComps: [30, 67, 103, 140, 176, 213, 249, 286, 322, 359],
    daysOfortnight: 13, // 18
    dayFortnightComps: [
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOweek: 4, // 9
    dayWeekComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["31st of Autumn", "Autumn 31", "Aut 31"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["31st of Early Autumn", "Early Autumn 31", "E-Aut 31"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: ["beginning of the middle of the end"],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mars Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/2010/m2020_09_22Adp.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 251, // 365
    daysOseason: 32, // 73
    daySeasonComps: [31, 104, 177, 250, 323],
    daysOmonth: 32, // 36
    dayMonthComps: [31, 68, 104, 141, 177, 214, 250, 287, 323, 360],
    daysOfortnight: 14, // 18
    dayFortnightComps: [
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOweek: 5, // 9
    dayWeekComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["32nd of Autumn", "Autumn 32", "Aut 32"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["32nd of Early Autumn", "Early Autumn 32", "E-Aut 32"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: ["middle of the middle of the end"],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Jupiter Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1905/JupiterMarble_JunoGill_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 252, // 365
    daysOseason: 33, // 73
    daySeasonComps: [32, 105, 178, 251, 324],
    daysOmonth: 33, // 36
    dayMonthComps: [32, 69, 105, 142, 178, 215, 251, 288, 324, 361],
    daysOfortnight: 15, // 18
    dayFortnightComps: [
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOweek: 6, // 9
    dayWeekComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["33rd of Autumn", "Autumn 33", "Aut 33"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["33rd of Early Autumn", "Early Autumn 33", "E-Aut 33"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: ["end of the middle of the end"],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Saturn Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1609/SaturnAbove_Cassini_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 253, // 365
    daysOseason: 34, // 73
    daySeasonComps: [33, 106, 179, 252, 325],
    daysOmonth: 34, // 36
    dayMonthComps: [33, 70, 106, 143, 179, 216, 252, 289, 325, 362],
    daysOfortnight: 16, // 18
    dayFortnightComps: [
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOweek: 7, // 9
    dayWeekComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["34th of Autumn", "Autumn 34", "Aut 34"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["34th of Early Autumn", "Early Autumn 34", "E-Aut 34"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: ["beginning of the end of the end"],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Uranus Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/0411/uranus_keck_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 254, // 365
    daysOseason: 35, // 73
    daySeasonComps: [34, 107, 180, 253, 326],
    daysOmonth: 35, // 36
    dayMonthComps: [34, 71, 107, 144, 180, 217, 253, 290, 326, 363],
    daysOfortnight: 17, // 18
    dayFortnightComps: [
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOweek: 8, // 9
    dayWeekComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["35th of Autumn", "Autumn 35", "Aut 35"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["35th of Early Autumn", "Early Autumn 35", "E-Aut 35"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: ["middle of the end of the end"],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Neptune Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/0406/nep2002_hst1pan_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 255, // 365
    daysOseason: 36, // 73
    daySeasonComps: [35, 108, 181, 254, 327],
    daysOmonth: 36, // 36
    dayMonthComps: [35, 72, 108, 145, 181, 218, 254, 291, 327, 364],
    daysOfortnight: 18, // 18
    dayFortnightComps: [
      17,
      35,
      54,
      72,
      90,
      108,
      127,
      145,
      163,
      181,
      200,
      218,
      236,
      254,
      273,
      291,
      309,
      327,
      346,
      364,
    ],
    daysOweek: 9, // 9
    dayWeekComps: [
      8,
      17,
      26,
      35,
      45,
      54,
      63,
      72,
      81,
      90,
      99,
      108,
      118,
      127,
      136,
      145,
      154,
      163,
      172,
      181,
      191,
      200,
      209,
      218,
      227,
      236,
      245,
      254,
      264,
      273,
      282,
      291,
      300,
      309,
      318,
      327,
      337,
      346,
      355,
      364,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["36th of Autumn", "Autumn 36", "Aut 36"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["36th of Early Autumn", "Early Autumn 36", "E-Aut 36"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: ["end of the end of the end"],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Pluto Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1909/PlutoTrueColor_NewHorizons_960.jpg",

    dayOfTabURL: "",
  },

  {
    daysOyear: 256, // 365
    daysOseason: 37, // 73
    daySeasonComps: [36, 109, 182, 255, 328],
    daysOmidSeason: 4, // 5
    daysOmonth: null, // 36
    daysOfortnight: null, // 18
    daysOweek: null, // 9
    daysOtab: null, // 3
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["37th of Autumn", "Autumn 37", "Aut 37"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: "Mid-Autumn",
    dayOfMonthURL: "",
    dayNameWithinFortnight: ["Mid-Autumn"],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mid-Autumn",
    dayOfWeekURL: "",
    dayNameWithinTab: ["Mid-Autumn"],
    dayOfTabURL: "",
  },

  {
    daysOyear: 257, // 365
    daysOseason: 38, // 73
    daySeasonComps: [37, 110, 183, 256, 329],
    daysOmonth: 1, // 36
    dayMonthComps: [0, 37, 73, 110, 146, 183, 219, 256, 292, 329],
    daysOfortnight: 1, // 18
    dayFortnightComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
    ],
    daysOweek: 1, // 9
    dayWeekComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["38th of Autumn", "Autumn 38", "Aut 38"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["1st of Late Autumn", "Late Autumn 1", "L-Aut 1"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mercury Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1707/mercuryflyby2_messenger_1024.png",

    dayOfTabURL: "",
  },
  {
    daysOyear: 258, // 365
    daysOseason: 39, // 73
    daySeasonComps: [38, 111, 184, 257, 330],
    daysOmonth: 2, // 36
    dayMonthComps: [1, 38, 74, 111, 147, 184, 220, 257, 293, 330],
    daysOfortnight: 2, // 18
    dayFortnightComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
    ],
    daysOweek: 2, // 9
    dayWeekComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["39th of Autumn", "Autumn 39", "Aut 39"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["2nd of Late Autumn", "Late Autumn 2", "L-Aut 2"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Venus Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/2009/VenusClouds_Akatzuki_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 259, // 365
    daysOseason: 40, // 73
    daySeasonComps: [39, 112, 185, 258, 331],
    daysOmonth: 3, // 36
    dayMonthComps: [2, 39, 75, 112, 148, 185, 221, 258, 294, 331],
    daysOfortnight: 3, // 18
    dayFortnightComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
    ],
    daysOweek: 3, // 9
    dayWeekComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["40th of Autumn", "Autumn 1", "Aut 1"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["3rd of Late Autumn", "Late Autumn 3", "L-Aut 3"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Earth Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1201/bluemarbleearth_npp_980.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 260, // 365
    daysOseason: 41, // 73
    daySeasonComps: [40, 113, 186, 259, 332],
    daysOmonth: 4, // 36
    dayMonthComps: [3, 40, 76, 113, 149, 186, 222, 259, 295, 332],
    daysOfortnight: 4, // 18
    dayFortnightComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
    ],
    daysOweek: 4, // 9
    dayWeekComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["41st of Autumn", "Autumn 41", "Aut 41"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["4th of Late Autumn", "Late Autumn 4", "L-Aut 4"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mars Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/2010/m2020_09_22Adp.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 261, // 365
    daysOseason: 42, // 73
    daySeasonComps: [41, 114, 187, 260, 333],
    daysOmonth: 5, // 36
    dayMonthComps: [4, 41, 77, 114, 150, 187, 223, 260, 296, 333],
    daysOfortnight: 5, // 18
    dayFortnightComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
    ],
    daysOweek: 5, // 9
    dayWeekComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["42nd of Autumn", "Autumn 42", "Aut 42"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["5th of Late Autumn", "Late Autumn 5", "L-Aut 5"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Jupiter Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1905/JupiterMarble_JunoGill_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 262, // 363
    daysOseason: 43, // 73
    daySeasonComps: [42, 115, 188, 261, 334],
    daysOmonth: 6, // 36
    dayMonthComps: [5, 42, 78, 115, 151, 188, 224, 261, 297, 334],
    daysOfortnight: 6, // 18
    dayFortnightComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
    ],
    daysOweek: 6, // 9
    dayWeekComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["43rd of Autumn", "Autumn 43", "Aut 43"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["6th of Late Autumn", "Late Autumn 6", "L-Aut 6"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Saturn Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1609/SaturnAbove_Cassini_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 263, // 365
    daysOseason: 44, // 73
    daySeasonComps: [43, 116, 189, 262, 335],
    daysOmonth: 7, // 36
    dayMonthComps: [6, 43, 79, 116, 152, 189, 225, 262, 298, 335],
    daysOfortnight: 7, // 18
    dayFortnightComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
    ],
    daysOweek: 7, // 9
    dayWeekComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["44th of Autumn", "Autumn 44", "Aut 44"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["7th of Late Autumn", "Late Autumn 7", "L-Aut 7"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Uranus Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/0411/uranus_keck_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 264, // 365
    daysOseason: 45, // 73
    daySeasonComps: [44, 117, 190, 263, 336],
    daysOmonth: 8, // 36
    dayMonthComps: [7, 44, 80, 117, 153, 190, 226, 263, 299, 336],
    daysOfortnight: 8, // 18
    dayFortnightComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
    ],
    daysOweek: 8, // 9
    dayWeekComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["45th of Autumn", "Autumn 45", "Aut 45"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["8th of Late Autumn", "Late Autumn 8", "L-Aut 8"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Neptune Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/0406/nep2002_hst1pan_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 265, // 365
    daysOseason: 46, // 73
    daySeasonComps: [45, 118, 191, 264, 337],
    daysOmonth: 9, // 36
    dayMonthComps: [8, 45, 81, 118, 154, 191, 227, 264, 300, 337],
    daysOfortnight: 9, // 18
    dayFortnightComps: [
      8,
      26,
      45,
      63,
      81,
      99,
      118,
      136,
      154,
      172,
      191,
      209,
      227,
      245,
      264,
      282,
      300,
      318,
      337,
      355,
    ],
    daysOweek: 9, // 9
    dayWeekComps: [
      8,
      17,
      26,
      35,
      45,
      54,
      63,
      72,
      81,
      90,
      99,
      108,
      118,
      127,
      136,
      145,
      154,
      163,
      172,
      181,
      191,
      200,
      209,
      218,
      227,
      236,
      245,
      254,
      264,
      273,
      282,
      291,
      300,
      309,
      318,
      327,
      337,
      346,
      355,
      364,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["46th of Autumn", "Autumn 46", "Aut 46"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["9th of Late Autumn", "Late Autumn 9", "L-Aut 9"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Pluto Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1909/PlutoTrueColor_NewHorizons_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 266, // 365
    daysOseason: 47, // 73
    daySeasonComps: [46, 119, 192, 265, 338],
    daysOmonth: 10, // 36
    dayMonthComps: [9, 46, 82, 119, 155, 192, 228, 265, 301, 338],
    daysOfortnight: 10, // 18
    dayFortnightComps: [
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOweek: 1, // 9
    dayWeekComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["47th of Autumn", "Autumn 47", "Aut 47"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["10th of Late Autumn", "Late Autumn 10", "L-Aut 10"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mercury Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1707/mercuryflyby2_messenger_1024.png",

    dayOfTabURL: "",
  },
  {
    daysOyear: 267, // 365
    daysOseason: 48, // 73
    daySeasonComps: [47, 120, 193, 266, 339],
    daysOmonth: 11, // 36
    dayMonthComps: [10, 47, 83, 120, 156, 193, 229, 266, 302, 339],
    daysOfortnight: 11, // 18
    dayFortnightComps: [
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOweek: 2, // 9
    dayWeekComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["48th of Autumn", "Autumn 48", "Aut 48"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["11th of Late Autumn", "Late Autumn 11", "L-Aut 11"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Venus Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/2009/VenusClouds_Akatzuki_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 268, // 365
    daysOseason: 49, // 73
    daySeasonComps: [48, 121, 194, 267, 340],
    daysOmonth: 12, // 36
    dayMonthComps: [11, 48, 84, 121, 157, 194, 230, 267, 303, 340],
    daysOfortnight: 12, // 18
    dayFortnightComps: [
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOweek: 3, // 9
    dayWeekComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["49th of Autumn", "Autumn 49", "Aut 49"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["12th of Late Autumn", "Late Autumn 12", "L-Aut 12"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Earth Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1201/bluemarbleearth_npp_980.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 269, // 365
    daysOseason: 50, // 73
    daySeasonComps: [49, 122, 195, 268, 341],
    daysOmonth: 13, // 36
    dayMonthComps: [12, 49, 85, 122, 158, 195, 231, 268, 304, 341],
    daysOfortnight: 13, // 18
    dayFortnightComps: [
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOweek: 4, // 9
    dayWeekComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["50th of Autumn", "Autumn 50", "Aut 50"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["13th of Late Autumn", "Late Autumn 13", "L-Aut 13"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mars Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/2010/m2020_09_22Adp.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 270, // 365
    daysOseason: 51, // 73
    daySeasonComps: [50, 123, 196, 269, 342],
    daysOmonth: 14, // 36
    dayMonthComps: [13, 50, 86, 123, 159, 196, 232, 269, 305, 342],
    daysOfortnight: 14, // 18
    dayFortnightComps: [
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOweek: 5, // 9
    dayWeekComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["51st of Autumn", "Autumn 51", "Aut 51"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["14th of Late Autumn", "Late Autumn 14", "L-Aut 14"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Jupiter Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1905/JupiterMarble_JunoGill_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 271, // 365
    daysOseason: 52, // 73
    daySeasonComps: [51, 124, 197, 270, 343],
    daysOmonth: 15, // 36
    dayMonthComps: [14, 51, 87, 124, 160, 197, 233, 270, 306, 343],
    daysOfortnight: 15, // 18
    dayFortnightComps: [
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOweek: 6, // 9
    dayWeekComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["52nd of Autumn", "Autumn 52", "Aut 52"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["15th of Late Autumn", "Late Autumn 15", "L-Aut 15"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Saturn Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1609/SaturnAbove_Cassini_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 272, // 365
    daysOseason: 53, // 73
    daySeasonComps: [52, 125, 198, 271, 344],
    daysOmonth: 16, // 36
    dayMonthComps: [15, 52, 88, 125, 161, 198, 234, 271, 307, 344],
    daysOfortnight: 16, // 18
    dayFortnightComps: [
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOweek: 7, // 9
    dayWeekComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["53rd of Autumn", "Autumn 53", "Aut 53"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["16th of Late Autumn", "Late Autumn 16", "L-Aut 16"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Uranus Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/0411/uranus_keck_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 273, // 365
    daysOseason: 54, // 73
    daySeasonComps: [53, 126, 199, 272, 345],
    daysOmonth: 17, // 36
    dayMonthComps: [16, 53, 89, 126, 162, 199, 235, 272, 308, 345],
    daysOfortnight: 17, // 18
    dayFortnightComps: [
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOweek: 8, // 9
    dayWeekComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["54th of Autumn", "Autumn 54", "Aut 54"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["17th of Late Autumn", "Late Autumn 17", "L-Aut 17"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Neptune Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/0406/nep2002_hst1pan_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 274, // 365
    daysOseason: 55, // 73
    daySeasonComps: [54, 127, 200, 273, 346],
    daysOmonth: 18, // 36
    dayMonthComps: [17, 54, 90, 127, 163, 200, 236, 273, 309, 346],
    daysOfortnight: 18, // 18
    dayFortnightComps: [
      17,
      35,
      54,
      72,
      90,
      108,
      127,
      145,
      163,
      181,
      200,
      218,
      236,
      254,
      273,
      291,
      309,
      327,
      346,
      364,
    ],
    daysOweek: 9, // 9
    dayWeekComps: [
      8,
      17,
      26,
      35,
      45,
      54,
      63,
      72,
      81,
      90,
      99,
      108,
      118,
      127,
      136,
      145,
      154,
      163,
      172,
      181,
      191,
      200,
      209,
      218,
      227,
      236,
      245,
      254,
      264,
      273,
      282,
      291,
      300,
      309,
      318,
      327,
      337,
      346,
      355,
      364,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["55th of Autumn", "Autumn 55", "Aut 55"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["18th of Late Autumn", "Late Autumn 18", "L-Aut 18"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Pluto Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1909/PlutoTrueColor_NewHorizons_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 275, // 365
    daysOseason: 56, // 73
    daySeasonComps: [55, 128, 201, 274, 347],
    daysOmonth: 19, // 36
    dayMonthComps: [18, 55, 91, 128, 164, 201, 237, 274, 310, 347],
    daysOfortnight: 1, // 18
    dayFortnightComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
    ],
    daysOweek: 1, // 9
    dayWeekComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["56th of Autumn", "Autumn 56", "Aut 56"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["19th of Late Autumn", "Late Autumn 19", "L-Aut 19"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mercury Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1707/mercuryflyby2_messenger_1024.png",

    dayOfTabURL: "",
  },
  {
    daysOyear: 276, // 365
    daysOseason: 57, // 73
    daySeasonComps: [56, 129, 202, 275, 348],
    daysOmonth: 20, // 36
    dayMonthComps: [19, 56, 92, 129, 165, 202, 238, 275, 311, 348],
    daysOfortnight: 2, // 18
    dayFortnightComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
    ],
    daysOweek: 2, // 9
    dayWeekComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["57th of Autumn", "Autumn 57", "Aut 57"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["20th of Late Autumn", "Late Autumn 20", "L-Aut 20"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Venus Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/2009/VenusClouds_Akatzuki_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 277, // 365
    daysOseason: 58, // 73
    daySeasonComps: [57, 130, 203, 276, 349],
    daysOmonth: 21, // 36
    dayMonthComps: [20, 57, 93, 130, 166, 203, 239, 276, 312, 349],
    daysOfortnight: 3, // 18
    dayFortnightComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
    ],
    daysOweek: 3, // 9
    dayWeekComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["58th of Autumn", "Autumn 58", "Aut 58"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["21st of Late Autumn", "Late Autumn 21", "L-Aut 21"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Earth Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1201/bluemarbleearth_npp_980.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 278, // 365
    daysOseason: 59, // 73
    daySeasonComps: [58, 131, 204, 277, 350],
    daysOmonth: 22, // 36
    dayMonthComps: [21, 58, 94, 131, 167, 204, 240, 277, 313, 350],
    daysOfortnight: 4, // 18
    dayFortnightComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
    ],
    daysOweek: 4, // 9
    dayWeekComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["59th of Autumn", "Autumn 59", "Aut 59"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["22nd of Late Autumn", "Late Autumn 22", "L-Aut 22"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mars Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/2010/m2020_09_22Adp.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 279, // 365
    daysOseason: 60, // 73
    daySeasonComps: [59, 132, 205, 278, 351],
    daysOmonth: 23, // 36
    dayMonthComps: [22, 59, 95, 132, 168, 205, 241, 278, 314, 351],
    daysOfortnight: 5, // 18
    dayFortnightComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
    ],
    daysOweek: 5, // 9
    dayWeekComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["60th of Autumn", "Autumn 60", "Aut 60"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["23rd of Late Autumn", "Late Autumn 23", "L-Aut 23"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Jupiter Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1905/JupiterMarble_JunoGill_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 280, // 363
    daysOseason: 61, // 73
    daySeasonComps: [60, 133, 206, 279, 352],
    daysOmonth: 24, // 36
    dayMonthComps: [23, 60, 96, 133, 169, 206, 242, 279, 315, 352],
    daysOfortnight: 6, // 18
    dayFortnightComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
    ],
    daysOweek: 6, // 9
    dayWeekComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["61st of Autumn", "Autumn 61", "Aut 61"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["24th of Late Autumn", "Late Autumn 24", "L-Aut 24"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Saturn Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1609/SaturnAbove_Cassini_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 281, // 365
    daysOseason: 62, // 73
    daySeasonComps: [61, 134, 207, 280, 353],
    daysOmonth: 25, // 36
    dayMonthComps: [24, 61, 97, 134, 170, 207, 243, 280, 316, 353],
    daysOfortnight: 7, // 18
    dayFortnightComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
    ],
    daysOweek: 7, // 9
    dayWeekComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["62nd of Autumn", "Autumn 62", "Aut 62"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["25th of Late Autumn", "Late Autumn 25", "L-Aut 25"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Uranus Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/0411/uranus_keck_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 282, // 365
    daysOseason: 63, // 73
    daySeasonComps: [62, 135, 208, 281, 354],
    daysOmonth: 26, // 36
    dayMonthComps: [25, 62, 98, 135, 171, 208, 244, 281, 317, 354],
    daysOfortnight: 8, // 18
    dayFortnightComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
    ],
    daysOweek: 8, // 9
    dayWeekComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["63rd of Autumn", "Autumn 63", "Aut 63"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["26th of Late Autumn", "Late Autumn 26", "L-Aut 26"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Neptune Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/0406/nep2002_hst1pan_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 283, // 365
    daysOseason: 64, // 73
    daySeasonComps: [63, 136, 209, 282, 355],
    daysOmonth: 27, // 36
    dayMonthComps: [26, 63, 99, 136, 172, 209, 245, 282, 318, 355],
    daysOfortnight: 9, // 18
    dayFortnightComps: [
      8,
      26,
      45,
      63,
      81,
      99,
      118,
      136,
      154,
      172,
      191,
      209,
      227,
      245,
      264,
      282,
      300,
      318,
      337,
      355,
    ],
    daysOweek: 9, // 9
    dayWeekComps: [
      8,
      17,
      26,
      35,
      45,
      54,
      63,
      72,
      81,
      90,
      99,
      108,
      118,
      127,
      136,
      145,
      154,
      163,
      172,
      181,
      191,
      200,
      209,
      218,
      227,
      236,
      245,
      254,
      264,
      273,
      282,
      291,
      300,
      309,
      318,
      327,
      337,
      346,
      355,
      364,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["64th of Autumn", "Autumn 64", "Aut 64"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["27th of Late Autumn", "Late Autumn 27", "L-Aut 27"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Pluto Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1909/PlutoTrueColor_NewHorizons_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 284, // 365
    daysOseason: 65, // 73
    daySeasonComps: [64, 137, 210, 283, 356],
    daysOmonth: 28, // 36
    dayMonthComps: [27, 64, 100, 137, 173, 210, 246, 283, 319, 356],
    daysOfortnight: 10, // 18
    dayFortnightComps: [
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOweek: 1, // 9
    dayWeekComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["65th of Autumn", "Autumn 65", "Aut 65"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["28th of Late Autumn", "Late Autumn 28", "L-Aut 28"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mercury Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1707/mercuryflyby2_messenger_1024.png",

    dayOfTabURL: "",
  },
  {
    daysOyear: 285, // 365
    daysOseason: 66, // 73
    daySeasonComps: [65, 138, 211, 284, 357],
    daysOmonth: 29, // 36
    dayMonthComps: [28, 65, 101, 138, 174, 211, 247, 284, 320, 357],
    daysOfortnight: 11, // 18
    dayFortnightComps: [
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOweek: 2, // 9
    dayWeekComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["66th of Autumn", "Autumn 66", "Aut 66"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["29th of Late Autumn", "Late Autumn 29", "L-Aut 29"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Venus Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/2009/VenusClouds_Akatzuki_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 286, // 365
    daysOseason: 67, // 73
    daySeasonComps: [66, 139, 212, 285, 358],
    daysOmonth: 30, // 36
    dayMonthComps: [29, 66, 102, 139, 175, 212, 248, 285, 321, 358],
    daysOfortnight: 12, // 18
    dayFortnightComps: [
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOweek: 3, // 9
    dayWeekComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["67th of Autumn", "Autumn 67", "Aut 67"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["30th of Late Autumn", "Late Autumn 30", "L-Aut 30"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Earth Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1201/bluemarbleearth_npp_980.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 287, // 365
    daysOseason: 68, // 73
    daySeasonComps: [67, 140, 213, 286, 359],
    daysOmonth: 31, // 36
    dayMonthComps: [30, 67, 103, 140, 176, 213, 249, 286, 322, 359],
    daysOfortnight: 13, // 18
    dayFortnightComps: [
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOweek: 4, // 9
    dayWeekComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["68th of Autumn", "Autumn 68", "Aut 68"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["31st of Late Autumn", "Late Autumn 31", "L-Aut 31"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mars Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/2010/m2020_09_22Adp.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 288, // 365
    daysOseason: 69, // 73
    daySeasonComps: [68, 141, 214, 287, 360],
    daysOmonth: 32, // 36
    dayMonthComps: [31, 68, 104, 141, 177, 214, 250, 287, 323, 360],
    daysOfortnight: 14, // 18
    dayFortnightComps: [
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOweek: 5, // 9
    dayWeekComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["69th of Autumn", "Autumn 69", "Aut 69"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["32nd of Late Autumn", "Late Autumn 32", "L-Aut 32"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Jupiter Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1905/JupiterMarble_JunoGill_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 289, // 365
    daysOseason: 70, // 73
    daySeasonComps: [69, 142, 215, 288, 361],
    daysOmonth: 33, // 36
    dayMonthComps: [32, 69, 105, 142, 178, 215, 251, 288, 324, 361],
    daysOfortnight: 15, // 18
    dayFortnightComps: [
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOweek: 6, // 9
    dayWeekComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["70th of Autumn", "Autumn 70", "Aut 70"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["33rd of Late Autumn", "Late Autumn 33", "L-Aut 33"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Saturn Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1609/SaturnAbove_Cassini_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 290, // 365
    daysOseason: 71, // 73
    daySeasonComps: [70, 143, 216, 289, 362],
    daysOmonth: 34, // 36
    dayMonthComps: [33, 70, 106, 143, 179, 216, 252, 289, 325, 362],
    daysOfortnight: 16, // 18
    dayFortnightComps: [
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOweek: 7, // 9
    dayWeekComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["71st of Autumn", "Autumn 71", "Aut 71"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["34th of Late Autumn", "Late Autumn 34", "L-Aut 34"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Uranus Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/0411/uranus_keck_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 291, // 365
    daysOseason: 72, // 73
    daySeasonComps: [71, 144, 217, 290, 363],
    daysOmonth: 35, // 36
    dayMonthComps: [34, 71, 107, 144, 180, 217, 253, 290, 326, 363],
    daysOfortnight: 17, // 18
    dayFortnightComps: [
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOweek: 8, // 9
    dayWeekComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["72nd of Autumn", "Autumn 72", "Aut 72"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["35th of Late Autumn", "Late Autumn 35", "L-Aut 35"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Neptune Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/0406/nep2002_hst1pan_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 292, // 365
    daysOseason: 73, // 73
    daySeasonComps: [72, 145, 218, 291, 364],
    daysOmonth: 36, // 36
    dayMonthComps: [35, 72, 108, 145, 181, 218, 254, 291, 327, 364],
    daysOfortnight: 18, // 18
    dayFortnightComps: [
      17,
      35,
      54,
      72,
      90,
      108,
      127,
      145,
      163,
      181,
      200,
      218,
      236,
      254,
      273,
      291,
      309,
      327,
      346,
      364,
    ],
    daysOweek: 9, // 9
    dayWeekComps: [
      8,
      17,
      26,
      35,
      45,
      54,
      63,
      72,
      81,
      90,
      99,
      108,
      118,
      127,
      136,
      145,
      154,
      163,
      172,
      181,
      191,
      200,
      209,
      218,
      227,
      236,
      245,
      254,
      264,
      273,
      282,
      291,
      300,
      309,
      318,
      327,
      337,
      346,
      355,
      364,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["73rd of Autumn", "Autumn 73", "Aut 73"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["36th of Late Autumn", "Late Autumn 36", "L-Aut 36"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Pluto Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1909/PlutoTrueColor_NewHorizons_960.jpg",

    dayOfTabURL: "",
  },
  //FALL:
  {
    daysOyear: 293, // 365
    daysOseason: 1, // 73
    daySeasonComps: [0, 73, 146, 219, 292],
    daysOmonth: 1, // 36
    dayMonthComps: [0, 37, 73, 110, 146, 183, 219, 256, 292, 329],
    daysOfortnight: 1, // 18
    dayFortnightComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
    ],
    daysOweek: 1, // 9
    dayWeekComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: "",
    dayOfYearURL: "",
    dayNameWithinSeason: ["1st of Fall", "Fall 1", "Fal 1"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["1st of Early Fall", "Early Fall 1", "E-Fal 1"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mercury Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1707/mercuryflyby2_messenger_1024.png",

    dayOfTabURL: "",
  },
  {
    daysOyear: 294, // 365
    daysOseason: 2, // 73
    daySeasonComps: [1, 74, 147, 220, 293],
    daysOmonth: 2, // 36
    dayMonthComps: [1, 38, 74, 111, 147, 184, 220, 257, 293, 330],
    daysOfortnight: 2, // 18
    dayFortnightComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
    ],
    daysOweek: 2, // 9
    dayWeekComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["2nd of Fall", "Fall 2", "Fal 2"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["2nd of Early Fall", "Early Fall 2", "E-Fal 2"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Venus Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/2009/VenusClouds_Akatzuki_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 295, // 365
    daysOseason: 3, // 73
    daySeasonComps: [2, 75, 148, 221, 294],
    daysOmonth: 3, // 36
    dayMonthComps: [2, 39, 75, 112, 148, 185, 221, 258, 294, 331],
    daysOfortnight: 3, // 18
    dayFortnightComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
    ],
    daysOweek: 3, // 9
    dayWeekComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["3rd of Fall", "Fall 3", "Fal 3"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["3rd of Early Fall", "Early Fall 3", "E-Fal 3"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Earth Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1201/bluemarbleearth_npp_980.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 296, // 365
    daysOseason: 4, // 73
    daySeasonComps: [3, 76, 149, 222, 295],
    daysOmonth: 4, // 36
    dayMonthComps: [3, 40, 76, 113, 149, 186, 222, 259, 295, 332],
    daysOfortnight: 4, // 18
    dayFortnightComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
    ],
    daysOweek: 4, // 9
    dayWeekComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["4th of Fall", "Fall 4", "Fal 4"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["4th of Early Fall", "Early Fall 4", "E-Fal 4"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mars Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/2010/m2020_09_22Adp.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 297, // 365
    daysOseason: 5, // 73
    daySeasonComps: [4, 77, 150, 223, 296],
    daysOmonth: 5, // 36
    dayMonthComps: [4, 41, 77, 114, 150, 187, 223, 260, 296, 333],
    daysOfortnight: 5, // 18
    dayFortnightComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
    ],
    daysOweek: 5, // 9
    dayWeekComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["5th of Fall", "Fall 5", "Fal 5"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["5th of Early Fall", "Early Fall 5", "E-Fal 5"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Jupiter Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1905/JupiterMarble_JunoGill_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 298, // 363
    daysOseason: 6, // 73
    daySeasonComps: [5, 78, 151, 224, 297],
    daysOmonth: 6, // 36
    dayMonthComps: [5, 42, 78, 115, 151, 188, 224, 261, 297, 334],
    daysOfortnight: 6, // 18
    dayFortnightComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
    ],
    daysOweek: 6, // 9
    dayWeekComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["6th of Fall", "Fall 6", "Fal 6"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["6th of Early Fall", "Early Fall 6", "E-Fal 6"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Saturn Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1609/SaturnAbove_Cassini_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 299, // 365
    daysOseason: 7, // 73
    daySeasonComps: [6, 79, 152, 225, 298],
    daysOmonth: 7, // 36
    dayMonthComps: [6, 43, 79, 116, 152, 189, 225, 262, 298, 335],
    daysOfortnight: 7, // 18
    dayFortnightComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
    ],
    daysOweek: 7, // 9
    dayWeekComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["7th of Fall", "Fall 7", "Fal 7"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["7th of Early Fall", "Early Fall 7", "E-Fal 7"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Uranus Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/0411/uranus_keck_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 300, // 365
    daysOseason: 8, // 73
    daySeasonComps: [7, 80, 153, 226, 299],
    daysOmonth: 8, // 36
    dayMonthComps: [7, 44, 80, 117, 153, 190, 226, 263, 299, 336],
    daysOfortnight: 8, // 18
    dayFortnightComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
    ],
    daysOweek: 8, // 9
    dayWeekComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["8th of Fall", "Fall 8", "Fal 8"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["8th of Early Fall", "Early Fall 8", "E-Fal 8"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Neptune Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/0406/nep2002_hst1pan_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 301, // 365
    daysOseason: 9, // 73
    daySeasonComps: [8, 81, 154, 227, 300],
    daysOmonth: 9, // 36
    dayMonthComps: [8, 45, 81, 118, 154, 191, 227, 264, 300, 337],
    daysOfortnight: 9, // 18
    dayFortnightComps: [
      8,
      26,
      45,
      63,
      81,
      99,
      118,
      136,
      154,
      172,
      191,
      209,
      227,
      245,
      264,
      282,
      300,
      318,
      337,
      355,
    ],
    daysOweek: 9, // 9
    dayWeekComps: [
      8,
      17,
      26,
      35,
      45,
      54,
      63,
      72,
      81,
      90,
      99,
      108,
      118,
      127,
      136,
      145,
      154,
      163,
      172,
      181,
      191,
      200,
      209,
      218,
      227,
      236,
      245,
      254,
      264,
      273,
      282,
      291,
      300,
      309,
      318,
      327,
      337,
      346,
      355,
      364,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["9th of Fall", "Fall 9", "Fal 9"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["9th of Early Fall", "Early Fall 9", "E-Fal 9"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Pluto Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1909/PlutoTrueColor_NewHorizons_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 302, // 365
    daysOseason: 10, // 73
    daySeasonComps: [9, 82, 155, 228, 301],
    daysOmonth: 10, // 36
    dayMonthComps: [9, 46, 82, 119, 155, 192, 228, 265, 301, 338],
    daysOfortnight: 10, // 18
    dayFortnightComps: [
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOweek: 1, // 9
    dayWeekComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["10th of Fall", "Fall 10", "Fal 10"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["10th of Early Fall", "Early Fall 10", "E-Fal 10"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mercury Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1707/mercuryflyby2_messenger_1024.png",

    dayOfTabURL: "",
  },
  {
    daysOyear: 303, // 365
    daysOseason: 11, // 73
    daySeasonComps: [10, 83, 156, 229, 302],
    daysOmonth: 11, // 36
    dayMonthComps: [10, 47, 83, 120, 156, 193, 229, 266, 302, 339],
    daysOfortnight: 11, // 18
    dayFortnightComps: [
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOweek: 2, // 9
    dayWeekComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["11th of Fall", "Fall 11", "Fal 11"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["11th of Early Fall", "Early Fall 11", "E-Fal 11"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Venus Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/2009/VenusClouds_Akatzuki_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 304, // 365
    daysOseason: 12, // 73
    daySeasonComps: [11, 84, 157, 230, 303],
    daysOmonth: 12, // 36
    dayMonthComps: [11, 48, 84, 121, 157, 194, 230, 267, 303, 340],
    daysOfortnight: 12, // 18
    dayFortnightComps: [
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOweek: 3, // 9
    dayWeekComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["12th of Fall", "Fall 12", "Fal 12"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["12th of Early Fall", "Early Fall 12", "E-Fal 12"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Earth Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1201/bluemarbleearth_npp_980.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 305, // 365
    daysOseason: 13, // 73
    daySeasonComps: [12, 85, 158, 231, 304],
    daysOmonth: 13, // 36
    dayMonthComps: [12, 49, 85, 122, 158, 195, 231, 268, 304, 341],
    daysOfortnight: 13, // 18
    dayFortnightComps: [
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOweek: 4, // 9
    dayWeekComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["13th of Fall", "Fall 13", "Fal 13"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["13th of Early Fall", "Early Fall 13", "E-Fal 13"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mars Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/2010/m2020_09_22Adp.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 306, // 365
    daysOseason: 14, // 73
    daySeasonComps: [13, 86, 159, 232, 305],
    daysOmonth: 14, // 36
    dayMonthComps: [13, 50, 86, 123, 159, 196, 232, 269, 305, 342],
    daysOfortnight: 14, // 18
    dayFortnightComps: [
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOweek: 5, // 9
    dayWeekComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["14th of Fall", "Fall 14", "Fal 14"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["14th of Early Fall", "Early Fall 14", "E-Fal 14"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Jupiter Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1905/JupiterMarble_JunoGill_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 307, // 365
    daysOseason: 15, // 73
    daySeasonComps: [14, 87, 160, 233, 306],
    daysOmonth: 15, // 36
    dayMonthComps: [14, 51, 87, 124, 160, 197, 233, 270, 306, 343],
    daysOfortnight: 15, // 18
    dayFortnightComps: [
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOweek: 6, // 9
    dayWeekComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["15th of Fall", "Fall 15", "Fal 15"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["15th of Early Fall", "Early Fall 15", "E-Fal 15"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Saturn Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1609/SaturnAbove_Cassini_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 308, // 365
    daysOseason: 16, // 73
    daySeasonComps: [15, 88, 161, 234, 307],
    daysOmonth: 16, // 36
    dayMonthComps: [15, 52, 88, 125, 161, 198, 234, 271, 307, 344],
    daysOfortnight: 16, // 18
    dayFortnightComps: [
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOweek: 7, // 9
    dayWeekComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["16th of Fall", "Fall 16", "Fal 16"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["16th of Early Fall", "Early Fall 16", "E-Fal 16"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Uranus Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/0411/uranus_keck_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 309, // 365
    daysOseason: 17, // 73
    daySeasonComps: [16, 89, 162, 235, 308],
    daysOmonth: 17, // 36
    dayMonthComps: [16, 53, 89, 126, 162, 199, 235, 272, 308, 345],
    daysOfortnight: 17, // 18
    dayFortnightComps: [
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOweek: 8, // 9
    dayWeekComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["17th of Fall", "Fall 17", "Fal 17"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["17th of Early Fall", "Early Fall 17", "E-Fal 17"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Neptune Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/0406/nep2002_hst1pan_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 310, // 365
    daysOseason: 18, // 73
    daySeasonComps: [17, 90, 163, 236, 309],
    daysOmonth: 18, // 36
    dayMonthComps: [17, 54, 90, 127, 163, 200, 236, 273, 309, 346],
    daysOfortnight: 18, // 18
    dayFortnightComps: [
      17,
      35,
      54,
      72,
      90,
      108,
      127,
      145,
      163,
      181,
      200,
      218,
      236,
      254,
      273,
      291,
      309,
      327,
      346,
      364,
    ],
    daysOweek: 9, // 9
    dayWeekComps: [
      8,
      17,
      26,
      35,
      45,
      54,
      63,
      72,
      81,
      90,
      99,
      108,
      118,
      127,
      136,
      145,
      154,
      163,
      172,
      181,
      191,
      200,
      209,
      218,
      227,
      236,
      245,
      254,
      264,
      273,
      282,
      291,
      300,
      309,
      318,
      327,
      337,
      346,
      355,
      364,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["18th of Fall", "Fall 18", "Fal 18"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["18th of Early Fall", "Early Fall 18", "E-Fal 18"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Pluto Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1909/PlutoTrueColor_NewHorizons_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 311, // 365
    daysOseason: 19, // 73
    daySeasonComps: [18, 91, 164, 237, 310],
    daysOmonth: 19, // 36
    dayMonthComps: [18, 55, 91, 128, 164, 201, 237, 274, 310, 347],
    daysOfortnight: 1, // 18
    dayFortnightComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
    ],
    daysOweek: 1, // 9
    dayWeekComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["19th of Fall", "Fall 19", "Fal 19"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["19th of Early Fall", "Early Fall 19", "E-Fal 19"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mercury Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1707/mercuryflyby2_messenger_1024.png",

    dayOfTabURL: "",
  },
  {
    daysOyear: 312, // 365
    daysOseason: 20, // 73
    daySeasonComps: [19, 92, 165, 238, 311],
    daysOmonth: 20, // 36
    dayMonthComps: [19, 56, 92, 129, 165, 202, 238, 275, 311, 348],
    daysOfortnight: 2, // 18
    dayFortnightComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
    ],
    daysOweek: 2, // 9
    dayWeekComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["20th of Fall", "Fall 20", "Fal 20"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["20th of Early Fall", "Early Fall 20", "E-Fal 20"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Venus Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/2009/VenusClouds_Akatzuki_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 313, // 365
    daysOseason: 21, // 73
    daySeasonComps: [20, 93, 166, 239, 312],
    daysOmonth: 21, // 36
    dayMonthComps: [20, 57, 93, 130, 166, 203, 239, 276, 312, 349],
    daysOfortnight: 3, // 18
    dayFortnightComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
    ],
    daysOweek: 3, // 9
    dayWeekComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["21st of Fall", "Fall 21", "Fal 21"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["21st of Early Fall", "Early Fall 21", "E-Fal 21"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Earth Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1201/bluemarbleearth_npp_980.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 314, // 365
    daysOseason: 22, // 73
    daySeasonComps: [21, 94, 167, 240, 313],
    daysOmonth: 22, // 36
    dayMonthComps: [21, 58, 94, 131, 167, 204, 240, 277, 313, 350],
    daysOfortnight: 4, // 18
    dayFortnightComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
    ],
    daysOweek: 4, // 9
    dayWeekComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: ["Tom's Birthday"],
    dayOfYearURL: "",
    dayNameWithinSeason: ["22nd of Fall", "Fall 22", "Fal 22"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["22nd of Early Fall", "Early Fall 22", "E-Fal 22"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mars Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/2010/m2020_09_22Adp.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 315, // 365
    daysOseason: 23, // 73
    daySeasonComps: [22, 95, 168, 241, 314],
    daysOmonth: 23, // 36
    dayMonthComps: [22, 59, 95, 132, 168, 205, 241, 278, 314, 351],
    daysOfortnight: 5, // 18
    dayFortnightComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
    ],
    daysOweek: 5, // 9
    dayWeekComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: "Halloween",
    dayOfYearURL: "",
    dayNameWithinSeason: ["23rd of Fall", "Fall 23", "Fal 23"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["23rd of Early Fall", "Early Fall 23", "E-Fal 23"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Jupiter Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1905/JupiterMarble_JunoGill_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 316, // 363
    daysOseason: 24, // 73
    daySeasonComps: [23, 96, 169, 242, 315],
    daysOmonth: 24, // 36
    dayMonthComps: [23, 60, 96, 133, 169, 206, 242, 279, 315, 352],
    daysOfortnight: 6, // 18
    dayFortnightComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
    ],
    daysOweek: 6, // 9
    dayWeekComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["24th of Fall", "Fall 24", "Fal 24"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["24th of Early Fall", "Early Fall 24", "E-Fal 24"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Saturn Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1609/SaturnAbove_Cassini_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 317, // 365
    daysOseason: 25, // 73
    daySeasonComps: [24, 97, 170, 243, 316],
    daysOmonth: 25, // 36
    dayMonthComps: [24, 61, 97, 134, 170, 207, 243, 280, 316, 353],
    daysOfortnight: 7, // 18
    dayFortnightComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
    ],
    daysOweek: 7, // 9
    dayWeekComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["25th of Fall", "Fall 25", "Fal 25"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["25th of Early Fall", "Early Fall 25", "E-Fal 25"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Uranus Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/0411/uranus_keck_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 318, // 365
    daysOseason: 26, // 73
    daySeasonComps: [25, 98, 171, 244, 317],
    daysOmonth: 26, // 36
    dayMonthComps: [25, 62, 98, 135, 171, 208, 244, 281, 317, 354],
    daysOfortnight: 8, // 18
    dayFortnightComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
    ],
    daysOweek: 8, // 9
    dayWeekComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["26th of Fall", "Fall 26", "Fal 26"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["26th of Early Fall", "Early Fall 26", "E-Fal 26"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Neptune Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/0406/nep2002_hst1pan_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 319, // 365
    daysOseason: 27, // 73
    daySeasonComps: [26, 99, 172, 245, 318],
    daysOmonth: 27, // 36
    dayMonthComps: [26, 63, 99, 136, 172, 209, 245, 282, 318, 355],
    daysOfortnight: 9, // 18
    dayFortnightComps: [
      8,
      26,
      45,
      63,
      81,
      99,
      118,
      136,
      154,
      172,
      191,
      209,
      227,
      245,
      264,
      282,
      300,
      318,
      337,
      355,
    ],
    daysOweek: 9, // 9
    dayWeekComps: [
      8,
      17,
      26,
      35,
      45,
      54,
      63,
      72,
      81,
      90,
      99,
      108,
      118,
      127,
      136,
      145,
      154,
      163,
      172,
      181,
      191,
      200,
      209,
      218,
      227,
      236,
      245,
      254,
      264,
      273,
      282,
      291,
      300,
      309,
      318,
      327,
      337,
      346,
      355,
      364,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["27th of Fall", "Fall 27", "Fal 27"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["27th of Early Fall", "Early Fall 27", "E-Fal 27"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Pluto Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1909/PlutoTrueColor_NewHorizons_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 320, // 365
    daysOseason: 28, // 73
    daySeasonComps: [27, 100, 173, 246, 319],
    daysOmonth: 28, // 36
    dayMonthComps: [27, 64, 100, 137, 173, 210, 246, 283, 319, 356],
    daysOfortnight: 10, // 18
    dayFortnightComps: [
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOweek: 1, // 9
    dayWeekComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["28th of Fall", "Fall 28", "Fal 28"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["28th of Early Fall", "Early Fall 28", "E-Fal 28"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mercury Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1707/mercuryflyby2_messenger_1024.png",

    dayOfTabURL: "",
  },
  {
    daysOyear: 321, // 365
    daysOseason: 29, // 73
    daySeasonComps: [28, 101, 174, 247, 320],
    daysOmonth: 29, // 36
    dayMonthComps: [28, 65, 101, 138, 174, 211, 247, 284, 320, 357],
    daysOfortnight: 11, // 18
    dayFortnightComps: [
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOweek: 2, // 9
    dayWeekComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["29th of Fall", "Fall 29", "Fal 29"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["29th of Early Fall", "Early Fall 29", "E-Fal 29"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Venus Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/2009/VenusClouds_Akatzuki_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 322, // 365
    daysOseason: 30, // 73
    daySeasonComps: [29, 102, 175, 248, 321],
    daysOmonth: 30, // 36
    dayMonthComps: [29, 66, 102, 139, 175, 212, 248, 285, 321, 358],
    daysOfortnight: 12, // 18
    dayFortnightComps: [
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOweek: 3, // 9
    dayWeekComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["30th of Fall", "Fall 30", "Fal 30"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["30th of Early Fall", "Early Fall 30", "E-Fal 30"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Earth Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1201/bluemarbleearth_npp_980.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 323, // 365
    daysOseason: 31, // 73
    daySeasonComps: [30, 103, 176, 249, 322],
    daysOmonth: 31, // 36
    dayMonthComps: [30, 67, 103, 140, 176, 213, 249, 286, 322, 359],
    daysOfortnight: 13, // 18
    dayFortnightComps: [
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOweek: 4, // 9
    dayWeekComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["31st of Fall", "Fall 31", "Fal 31"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["31st of Early Fall", "Early Fall 31", "E-Fal 31"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mars Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/2010/m2020_09_22Adp.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 324, // 365
    daysOseason: 32, // 73
    daySeasonComps: [31, 104, 177, 250, 323],
    daysOmonth: 32, // 36
    dayMonthComps: [31, 68, 104, 141, 177, 214, 250, 287, 323, 360],
    daysOfortnight: 14, // 18
    dayFortnightComps: [
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOweek: 5, // 9
    dayWeekComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["32nd of Fall", "Fall 32", "Fal 32"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["32nd of Early Fall", "Early Fall 32", "E-Fal 32"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Jupiter Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1905/JupiterMarble_JunoGill_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 325, // 365
    daysOseason: 33, // 73
    daySeasonComps: [32, 105, 178, 251, 324],
    daysOmonth: 33, // 36
    dayMonthComps: [32, 69, 105, 142, 178, 215, 251, 288, 324, 361],
    daysOfortnight: 15, // 18
    dayFortnightComps: [
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOweek: 6, // 9
    dayWeekComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["33rd of Fall", "Fall 33", "Fal 33"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["33rd of Early Fall", "Early Fall 33", "E-Fal 33"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Saturn Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1609/SaturnAbove_Cassini_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 326, // 365
    daysOseason: 34, // 73
    daySeasonComps: [33, 106, 179, 252, 325],
    daysOmonth: 34, // 36
    dayMonthComps: [33, 70, 106, 143, 179, 216, 252, 289, 325, 362],
    daysOfortnight: 16, // 18
    dayFortnightComps: [
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOweek: 7, // 9
    dayWeekComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: "Veteran's Day",
    dayOfYearURL: "",
    dayNameWithinSeason: ["34th of Fall", "Fall 34", "Fal 34"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["34th of Early Fall", "Early Fall 34", "E-Fal 34"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Uranus Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/0411/uranus_keck_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 327, // 365
    daysOseason: 35, // 73
    daySeasonComps: [34, 107, 180, 253, 326],
    daysOmonth: 35, // 36
    dayMonthComps: [34, 71, 107, 144, 180, 217, 253, 290, 326, 363],
    daysOfortnight: 17, // 18
    dayFortnightComps: [
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOweek: 8, // 9
    dayWeekComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["35th of Fall", "Fall 35", "Fal 35"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["35th of Early Fall", "Early Fall 35", "E-Fal 35"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Neptune Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/0406/nep2002_hst1pan_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 328, // 365
    daysOseason: 36, // 73
    daySeasonComps: [35, 108, 181, 254, 327],
    daysOmonth: 36, // 36
    dayMonthComps: [35, 72, 108, 145, 181, 218, 254, 291, 327, 364],
    daysOfortnight: 18, // 18
    dayFortnightComps: [
      17,
      35,
      54,
      72,
      90,
      108,
      127,
      145,
      163,
      181,
      200,
      218,
      236,
      254,
      273,
      291,
      309,
      327,
      346,
      364,
    ],
    daysOweek: 9, // 9
    dayWeekComps: [
      8,
      17,
      26,
      35,
      45,
      54,
      63,
      72,
      81,
      90,
      99,
      108,
      118,
      127,
      136,
      145,
      154,
      163,
      172,
      181,
      191,
      200,
      209,
      218,
      227,
      236,
      245,
      254,
      264,
      273,
      282,
      291,
      300,
      309,
      318,
      327,
      337,
      346,
      355,
      364,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["36th of Fall", "Fall 36", "Fal 36"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["36th of Early Fall", "Early Fall 36", "E-Fal 36"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Pluto Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1909/PlutoTrueColor_NewHorizons_960.jpg",

    dayOfTabURL: "",
  },

  {
    daysOyear: 329, // 365
    daysOseason: 37, // 73
    daySeasonComps: [36, 109, 182, 255, 328],
    daysOmidSeason: 5, // 5
    daysOmonth: null, // 36
    daysOfortnight: null, // 18
    daysOweek: null, // 9
    daysOtab: null, // 3
    dayNameWithinYear: ["Mid-Fall"],
    dayOfYearURL: "",
    dayNameWithinSeason: ["37th of Fall", "Fall 37", "Fal 37"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: "Mid-Fall",
    dayOfMonthURL: "",
    dayNameWithinFortnight: ["Mid-Fall"],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mid-Fall",
    dayOfWeekURL: "",
    dayNameWithinTab: ["Mid-Fall"],
    dayOfTabURL: "",
  },

  {
    daysOyear: 330, // 365
    daysOseason: 38, // 73
    daySeasonComps: [37, 110, 183, 256, 329],
    daysOmonth: 1, // 36
    dayMonthComps: [0, 37, 73, 110, 146, 183, 219, 256, 292, 329],
    daysOfortnight: 1, // 18
    dayFortnightComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
    ],
    daysOweek: 1, // 9
    dayWeekComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["38th of Fall", "Fall 38", "Fal 38"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["1st of Late Fall", "Late Fall 1", "L-Fal 1"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mercury Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1707/mercuryflyby2_messenger_1024.png",

    dayOfTabURL: "",
  },
  {
    daysOyear: 331, // 365
    daysOseason: 39, // 73
    daySeasonComps: [38, 111, 184, 257, 330],
    daysOmonth: 2, // 36
    dayMonthComps: [1, 38, 74, 111, 147, 184, 220, 257, 293, 330],
    daysOfortnight: 2, // 18
    dayFortnightComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
    ],
    daysOweek: 2, // 9
    dayWeekComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["39th of Fall", "Fall 39", "Fal 39"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["2nd of Late Fall", "Late Fall 2", "L-Fal 2"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Venus Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/2009/VenusClouds_Akatzuki_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 332, // 365
    daysOseason: 40, // 73
    daySeasonComps: [39, 112, 185, 258, 331],
    daysOmonth: 3, // 36
    dayMonthComps: [2, 39, 75, 112, 148, 185, 221, 258, 294, 331],
    daysOfortnight: 3, // 18
    dayFortnightComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
    ],
    daysOweek: 3, // 9
    dayWeekComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["40th of Fall", "Fall 40", "Fal 40"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["3rd of Late Fall", "Late Fall 3", "L-Fal 3"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Earth Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1201/bluemarbleearth_npp_980.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 333, // 365
    daysOseason: 41, // 73
    daySeasonComps: [40, 113, 186, 259, 332],
    daysOmonth: 4, // 36
    dayMonthComps: [3, 40, 76, 113, 149, 186, 222, 259, 295, 332],
    daysOfortnight: 4, // 18
    dayFortnightComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
    ],
    daysOweek: 4, // 9
    dayWeekComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["41st of Fall", "Fall 41", "Fal 41"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["4th of Late Fall", "Late Fall 4", "L-Fal 4"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mars Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/2010/m2020_09_22Adp.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 334, // 365
    daysOseason: 42, // 73
    daySeasonComps: [41, 114, 187, 260, 333],
    daysOmonth: 5, // 36
    dayMonthComps: [4, 41, 77, 114, 150, 187, 223, 260, 296, 333],
    daysOfortnight: 5, // 18
    dayFortnightComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
    ],
    daysOweek: 5, // 9
    dayWeekComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["42nd of Fall", "Fall 42", "Fal 42"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["5th of Late Fall", "Late Fall 5", "L-Fal 5"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Jupiter Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1905/JupiterMarble_JunoGill_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 335, // 363
    daysOseason: 43, // 73
    daySeasonComps: [42, 115, 188, 261, 334],
    daysOmonth: 6, // 36
    dayMonthComps: [5, 42, 78, 115, 151, 188, 224, 261, 297, 334],
    daysOfortnight: 6, // 18
    dayFortnightComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
    ],
    daysOweek: 6, // 9
    dayWeekComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["43rd of Fall", "Fall 43", "Fal 43"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["6th of Late Fall", "Late Fall 6", "L-Fal 6"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Saturn Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1609/SaturnAbove_Cassini_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 336, // 365
    daysOseason: 44, // 73
    daySeasonComps: [43, 116, 189, 262, 335],
    daysOmonth: 7, // 36
    dayMonthComps: [6, 43, 79, 116, 152, 189, 225, 262, 298, 335],
    daysOfortnight: 7, // 18
    dayFortnightComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
    ],
    daysOweek: 7, // 9
    dayWeekComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["44th of Fall", "Fall 44", "Fal 44"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["7th of Late Fall", "Late Fall 7", "L-Fal 7"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Uranus Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/0411/uranus_keck_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 337, // 365
    daysOseason: 45, // 73
    daySeasonComps: [44, 117, 190, 263, 336],
    daysOmonth: 8, // 36
    dayMonthComps: [7, 44, 80, 117, 153, 190, 226, 263, 299, 336],
    daysOfortnight: 8, // 18
    dayFortnightComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
    ],
    daysOweek: 8, // 9
    dayWeekComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["45th of Fall", "Fall 45", "Fal 45"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["8th of Late Fall", "Late Fall 8", "L-Fal 8"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Neptune Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/0406/nep2002_hst1pan_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 338, // 365
    daysOseason: 46, // 73
    daySeasonComps: [45, 118, 191, 264, 337],
    daysOmonth: 9, // 36
    dayMonthComps: [8, 45, 81, 118, 154, 191, 227, 264, 300, 337],
    daysOfortnight: 9, // 18
    dayFortnightComps: [
      8,
      26,
      45,
      63,
      81,
      99,
      118,
      136,
      154,
      172,
      191,
      209,
      227,
      245,
      264,
      282,
      300,
      318,
      337,
      355,
    ],
    daysOweek: 9, // 9
    dayWeekComps: [
      8,
      17,
      26,
      35,
      45,
      54,
      63,
      72,
      81,
      90,
      99,
      108,
      118,
      127,
      136,
      145,
      154,
      163,
      172,
      181,
      191,
      200,
      209,
      218,
      227,
      236,
      245,
      254,
      264,
      273,
      282,
      291,
      300,
      309,
      318,
      327,
      337,
      346,
      355,
      364,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["46th of Fall", "Fall 46", "Fal 46"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["9th of Late Fall", "Late Fall 9", "L-Fal 9"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Pluto Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1909/PlutoTrueColor_NewHorizons_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 339, // 365
    daysOseason: 47, // 73
    daySeasonComps: [46, 119, 192, 265, 338],
    daysOmonth: 10, // 36
    dayMonthComps: [9, 46, 82, 119, 155, 192, 228, 265, 301, 338],
    daysOfortnight: 10, // 18
    dayFortnightComps: [
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOweek: 1, // 9
    dayWeekComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["47th of Fall", "Fall 47", "Fal 47"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["10th of Late Fall", "Late Fall 10", "L-Fal 10"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mercury Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1707/mercuryflyby2_messenger_1024.png",

    dayOfTabURL: "",
  },
  {
    daysOyear: 340, // 365
    daysOseason: 48, // 73
    daySeasonComps: [47, 120, 193, 266, 339],
    daysOmonth: 11, // 36
    dayMonthComps: [10, 47, 83, 120, 156, 193, 229, 266, 302, 339],
    daysOfortnight: 11, // 18
    dayFortnightComps: [
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOweek: 2, // 9
    dayWeekComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["48th of Fall", "Fall 48", "Fal 48"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["11th of Late Fall", "Late Fall 11", "L-Fal 11"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Venus Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/2009/VenusClouds_Akatzuki_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 341, // 365
    daysOseason: 49, // 73
    daySeasonComps: [48, 121, 194, 267, 340],
    daysOmonth: 12, // 36
    dayMonthComps: [11, 48, 84, 121, 157, 194, 230, 267, 303, 340],
    daysOfortnight: 12, // 18
    dayFortnightComps: [
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOweek: 3, // 9
    dayWeekComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["49th of Fall", "Fall 49", "Fal 49"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["12th of Late Fall", "Late Fall 12", "L-Fal 12"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Earth Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1201/bluemarbleearth_npp_980.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 342, // 365
    daysOseason: 50, // 73
    daySeasonComps: [49, 122, 195, 268, 341],
    daysOmonth: 13, // 36
    dayMonthComps: [12, 49, 85, 122, 158, 195, 231, 268, 304, 341],
    daysOfortnight: 13, // 18
    dayFortnightComps: [
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOweek: 4, // 9
    dayWeekComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["50th of Fall", "Fall 50", "Fal 50"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["13th of Late Fall", "Late Fall 13", "L-Fal 13"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mars Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/2010/m2020_09_22Adp.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 343, // 365
    daysOseason: 51, // 73
    daySeasonComps: [50, 123, 196, 269, 342],
    daysOmonth: 14, // 36
    dayMonthComps: [13, 50, 86, 123, 159, 196, 232, 269, 305, 342],
    daysOfortnight: 14, // 18
    dayFortnightComps: [
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOweek: 5, // 9
    dayWeekComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["51st of Fall", "Fall 51", "Fal 51"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["14th of Late Fall", "Late Fall 14", "L-Fal 14"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Jupiter Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1905/JupiterMarble_JunoGill_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 344, // 365
    daysOseason: 52, // 73
    daySeasonComps: [51, 124, 197, 270, 343],
    daysOmonth: 15, // 36
    dayMonthComps: [14, 51, 87, 124, 160, 197, 233, 270, 306, 343],
    daysOfortnight: 15, // 18
    dayFortnightComps: [
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOweek: 6, // 9
    dayWeekComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["52nd of Fall", "Fall 52", "Fal 52"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["15th of Late Fall", "Late Fall 15", "L-Fal 15"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Saturn Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1609/SaturnAbove_Cassini_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 345, // 365
    daysOseason: 53, // 73
    daySeasonComps: [52, 125, 198, 271, 344],
    daysOmonth: 16, // 36
    dayMonthComps: [15, 52, 88, 125, 161, 198, 234, 271, 307, 344],
    daysOfortnight: 16, // 18
    dayFortnightComps: [
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOweek: 7, // 9
    dayWeekComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["53rd of Fall", "Fall 53", "Fal 53"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["16th of Late Fall", "Late Fall 16", "L-Fal 16"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Uranus Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/0411/uranus_keck_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 346, // 365
    daysOseason: 54, // 73
    daySeasonComps: [53, 126, 199, 272, 345],
    daysOmonth: 17, // 36
    dayMonthComps: [16, 53, 89, 126, 162, 199, 235, 272, 308, 345],
    daysOfortnight: 17, // 18
    dayFortnightComps: [
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOweek: 8, // 9
    dayWeekComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["54th of Fall", "Fall 54", "Fal 54"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["17th of Late Fall", "Late Fall 17", "L-Fal 17"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Neptune Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/0406/nep2002_hst1pan_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 347, // 365
    daysOseason: 55, // 73
    daySeasonComps: [54, 127, 200, 273, 346],
    daysOmonth: 18, // 36
    dayMonthComps: [17, 54, 90, 127, 163, 200, 236, 273, 309, 346],
    daysOfortnight: 18, // 18
    dayFortnightComps: [
      17,
      35,
      54,
      72,
      90,
      108,
      127,
      145,
      163,
      181,
      200,
      218,
      236,
      254,
      273,
      291,
      309,
      327,
      346,
      364,
    ],
    daysOweek: 9, // 9
    dayWeekComps: [
      8,
      17,
      26,
      35,
      45,
      54,
      63,
      72,
      81,
      90,
      99,
      108,
      118,
      127,
      136,
      145,
      154,
      163,
      172,
      181,
      191,
      200,
      209,
      218,
      227,
      236,
      245,
      254,
      264,
      273,
      282,
      291,
      300,
      309,
      318,
      327,
      337,
      346,
      355,
      364,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["55th of Fall", "Fall 55", "Fal 55"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["18th of Late Fall", "Late Fall 18", "L-Fal 18"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Pluto Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1909/PlutoTrueColor_NewHorizons_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 348, // 365
    daysOseason: 56, // 73
    daySeasonComps: [55, 128, 201, 274, 347],
    daysOmonth: 19, // 36
    dayMonthComps: [18, 55, 91, 128, 164, 201, 237, 274, 310, 347],
    daysOfortnight: 1, // 18
    dayFortnightComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
    ],
    daysOweek: 1, // 9
    dayWeekComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["56th of Fall", "Fall 56", "Fal 56"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["19th of Late Fall", "Late Fall 19", "L-Fal 19"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mercury Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1707/mercuryflyby2_messenger_1024.png",

    dayOfTabURL: "",
  },
  {
    daysOyear: 349, // 365
    daysOseason: 57, // 73
    daySeasonComps: [56, 129, 202, 275, 348],
    daysOmonth: 20, // 36
    dayMonthComps: [19, 56, 92, 129, 165, 202, 238, 275, 311, 348],
    daysOfortnight: 2, // 18
    dayFortnightComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
    ],
    daysOweek: 2, // 9
    dayWeekComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["57th of Fall", "Fall 57", "Fal 57"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["20th of Late Fall", "Late Fall 20", "L-Fal 20"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Venus Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/2009/VenusClouds_Akatzuki_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 350, // 365
    daysOseason: 58, // 73
    daySeasonComps: [57, 130, 203, 276, 349],
    daysOmonth: 21, // 36
    dayMonthComps: [20, 57, 93, 130, 166, 203, 239, 276, 312, 349],
    daysOfortnight: 3, // 18
    dayFortnightComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
    ],
    daysOweek: 3, // 9
    dayWeekComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["58th of Fall", "Fall 58", "Fal 58"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["21st of Late Fall", "Late Fall 21", "L-Fal 21"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Earth Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1201/bluemarbleearth_npp_980.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 351, // 365
    daysOseason: 59, // 73
    daySeasonComps: [58, 131, 204, 277, 350],
    daysOmonth: 22, // 36
    dayMonthComps: [21, 58, 94, 131, 167, 204, 240, 277, 313, 350],
    daysOfortnight: 4, // 18
    dayFortnightComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
    ],
    daysOweek: 4, // 9
    dayWeekComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["59th of Fall", "Fall 59", "Fal 59"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["22nd of Late Fall", "Late Fall 22", "L-Fal 22"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mars Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/2010/m2020_09_22Adp.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 352, // 365
    daysOseason: 60, // 73
    daySeasonComps: [59, 132, 205, 278, 351],
    daysOmonth: 23, // 36
    dayMonthComps: [22, 59, 95, 132, 168, 205, 241, 278, 314, 351],
    daysOfortnight: 5, // 18
    dayFortnightComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
    ],
    daysOweek: 5, // 9
    dayWeekComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["60th of Fall", "Fall 60", "Fal 60"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["23rd of Late Fall", "Late Fall 23", "L-Fal 23"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Jupiter Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1905/JupiterMarble_JunoGill_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 353, // 363
    daysOseason: 61, // 73
    daySeasonComps: [60, 133, 206, 279, 352],
    daysOmonth: 24, // 36
    dayMonthComps: [23, 60, 96, 133, 169, 206, 242, 279, 315, 352],
    daysOfortnight: 6, // 18
    dayFortnightComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
    ],
    daysOweek: 6, // 9
    dayWeekComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["61st of Fall", "Fall 61", "Fal 61"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["24th of Late Fall", "Late Fall 24", "L-Fal 24"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Saturn Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1609/SaturnAbove_Cassini_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 354, // 365
    daysOseason: 62, // 73
    daySeasonComps: [61, 134, 207, 280, 353],
    daysOmonth: 25, // 36
    dayMonthComps: [24, 61, 97, 134, 170, 207, 243, 280, 316, 353],
    daysOfortnight: 7, // 18
    dayFortnightComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
    ],
    daysOweek: 7, // 9
    dayWeekComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["62nd of Fall", "Fall 62", "Fal 62"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["25th of Late Fall", "Late Fall 25", "L-Fal 25"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Uranus Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/0411/uranus_keck_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 355, // 365
    daysOseason: 63, // 73
    daySeasonComps: [62, 135, 208, 281, 354],
    daysOmonth: 26, // 36
    dayMonthComps: [25, 62, 98, 135, 171, 208, 244, 281, 317, 354],
    daysOfortnight: 8, // 18
    dayFortnightComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
    ],
    daysOweek: 8, // 9
    dayWeekComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["63rd of Fall", "Fall 63", "Fal 63"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["26th of Late Fall", "Late Fall 26", "L-Fal 26"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Neptune Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/0406/nep2002_hst1pan_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 356, // 365
    daysOseason: 64, // 73
    daySeasonComps: [63, 136, 209, 282, 355],
    daysOmonth: 27, // 36
    dayMonthComps: [26, 63, 99, 136, 172, 209, 245, 282, 318, 355],
    daysOfortnight: 9, // 18
    dayFortnightComps: [
      8,
      26,
      45,
      63,
      81,
      99,
      118,
      136,
      154,
      172,
      191,
      209,
      227,
      245,
      264,
      282,
      300,
      318,
      337,
      355,
    ],
    daysOweek: 9, // 9
    dayWeekComps: [
      8,
      17,
      26,
      35,
      45,
      54,
      63,
      72,
      81,
      90,
      99,
      108,
      118,
      127,
      136,
      145,
      154,
      163,
      172,
      181,
      191,
      200,
      209,
      218,
      227,
      236,
      245,
      254,
      264,
      273,
      282,
      291,
      300,
      309,
      318,
      327,
      337,
      346,
      355,
      364,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["64th of Fall", "Fall 64", "Fal 64"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["27th of Late Fall", "Late Fall 27", "L-Fal 27"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Pluto Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1909/PlutoTrueColor_NewHorizons_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 357, // 365
    daysOseason: 65, // 73
    daySeasonComps: [64, 137, 210, 283, 356],
    daysOmonth: 28, // 36
    dayMonthComps: [27, 64, 100, 137, 173, 210, 246, 283, 319, 356],
    daysOfortnight: 10, // 18
    dayFortnightComps: [
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOweek: 1, // 9
    dayWeekComps: [
      0,
      18,
      39,
      55,
      73,
      91,
      110,
      128,
      146,
      164,
      183,
      201,
      219,
      237,
      256,
      274,
      292,
      310,
      329,
      347,
      9,
      27,
      46,
      64,
      82,
      100,
      119,
      137,
      155,
      173,
      192,
      210,
      228,
      246,
      265,
      283,
      301,
      319,
      338,
      356,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["65th of Fall", "Fall 65", "Fal 65"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["28th of Late Fall", "Late Fall 28", "L-Fal 28"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mercury Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1707/mercuryflyby2_messenger_1024.png",

    dayOfTabURL: "",
  },
  {
    daysOyear: 358, // 365
    daysOseason: 66, // 73
    daySeasonComps: [65, 138, 211, 284, 357],
    daysOmonth: 29, // 36
    dayMonthComps: [28, 65, 101, 138, 174, 211, 247, 284, 320, 357],
    daysOfortnight: 11, // 18
    dayFortnightComps: [
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOweek: 2, // 9
    dayWeekComps: [
      1,
      19,
      40,
      56,
      74,
      92,
      111,
      129,
      147,
      165,
      184,
      202,
      220,
      238,
      257,
      275,
      293,
      311,
      330,
      348,
      10,
      28,
      47,
      65,
      83,
      101,
      120,
      138,
      156,
      174,
      193,
      211,
      229,
      247,
      266,
      284,
      302,
      320,
      339,
      357,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["66th of Fall", "Fall 66", "Fal 66"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["29th of Late Fall", "Late Fall 29", "L-Fal 29"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Venus Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/2009/VenusClouds_Akatzuki_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 359, // 365
    daysOseason: 67, // 73
    daySeasonComps: [66, 139, 212, 285, 358],
    daysOmonth: 30, // 36
    dayMonthComps: [29, 66, 102, 139, 175, 212, 248, 285, 321, 358],
    daysOfortnight: 12, // 18
    dayFortnightComps: [
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOweek: 3, // 9
    dayWeekComps: [
      2,
      20,
      41,
      57,
      75,
      93,
      112,
      130,
      148,
      166,
      185,
      203,
      221,
      239,
      258,
      276,
      294,
      312,
      331,
      349,
      11,
      29,
      48,
      66,
      84,
      102,
      121,
      139,
      157,
      175,
      194,
      212,
      230,
      248,
      267,
      285,
      303,
      321,
      340,
      358,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["67th of Fall", "Fall 67", "Fal 67"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["30th of Late Fall", "Late Fall 30", "L-Fal 30"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Earth Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1201/bluemarbleearth_npp_980.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 360, // 365
    daysOseason: 68, // 73
    daySeasonComps: [67, 140, 213, 286, 359],
    daysOmonth: 31, // 36
    dayMonthComps: [30, 67, 103, 140, 176, 213, 249, 286, 322, 359],
    daysOfortnight: 13, // 18
    dayFortnightComps: [
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOweek: 4, // 9
    dayWeekComps: [
      3,
      21,
      42,
      58,
      76,
      94,
      113,
      131,
      149,
      167,
      186,
      204,
      222,
      240,
      259,
      277,
      295,
      313,
      332,
      350,
      12,
      30,
      49,
      67,
      85,
      103,
      122,
      140,
      158,
      176,
      195,
      213,
      231,
      249,
      268,
      286,
      304,
      322,
      341,
      359,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["68th of Fall", "Fall 68", "Fal 68"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["31st of Late Fall", "Late Fall 31", "L-Fal 31"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Mars Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/2010/m2020_09_22Adp.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 361, // 365
    daysOseason: 69, // 73
    daySeasonComps: [68, 141, 214, 287, 360],
    daysOmonth: 32, // 36
    dayMonthComps: [31, 68, 104, 141, 177, 214, 250, 287, 323, 360],
    daysOfortnight: 14, // 18
    dayFortnightComps: [
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOweek: 5, // 9
    dayWeekComps: [
      4,
      22,
      41,
      59,
      77,
      95,
      114,
      132,
      150,
      168,
      187,
      205,
      223,
      241,
      260,
      278,
      296,
      314,
      333,
      351,
      13,
      31,
      50,
      68,
      86,
      104,
      123,
      141,
      159,
      177,
      196,
      214,
      232,
      250,
      269,
      287,
      305,
      323,
      342,
      360,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["69th of Fall", "Fall 69", "Fal 69"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["32nd of Late Fall", "Late Fall 32", "L-Fal 32"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Jupiter Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1905/JupiterMarble_JunoGill_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 362, // 365
    daysOseason: 70, // 73
    daySeasonComps: [69, 142, 215, 288, 361],
    daysOmonth: 33, // 36
    dayMonthComps: [32, 69, 105, 142, 178, 215, 251, 288, 324, 361],
    daysOfortnight: 15, // 18
    dayFortnightComps: [
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOweek: 6, // 9
    dayWeekComps: [
      5,
      23,
      42,
      60,
      78,
      96,
      115,
      133,
      151,
      169,
      188,
      206,
      224,
      242,
      261,
      279,
      297,
      315,
      334,
      352,
      14,
      32,
      51,
      69,
      87,
      107,
      124,
      142,
      160,
      178,
      197,
      215,
      233,
      251,
      270,
      288,
      306,
      324,
      343,
      361,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: "Saturnalia",
    dayOfYearURL: "",
    dayNameWithinSeason: ["70th of Fall", "Fall 70", "Fal 70"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["33rd of Late Fall", "Late Fall 33", "L-Fal 33"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Saturn Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1609/SaturnAbove_Cassini_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 363, // 365
    daysOseason: 71, // 73
    daySeasonComps: [70, 143, 216, 289, 362],
    daysOmonth: 34, // 36
    dayMonthComps: [33, 70, 106, 143, 179, 216, 252, 289, 325, 362],
    daysOfortnight: 16, // 18
    dayFortnightComps: [
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOweek: 7, // 9
    dayWeekComps: [
      6,
      24,
      43,
      61,
      79,
      97,
      116,
      134,
      152,
      170,
      189,
      207,
      225,
      243,
      262,
      280,
      298,
      316,
      335,
      353,
      15,
      33,
      52,
      70,
      88,
      108,
      125,
      143,
      161,
      179,
      198,
      216,
      234,
      252,
      271,
      289,
      307,
      325,
      344,
      362,
    ],
    daysOtab: 1, // 3
    dayNameWithinTab: ["start"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["71st of Fall", "Fall 71", "Fal 71"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["34th of Late Fall", "Late Fall 34", "L-Fal 34"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Uranus Day",
    dayOfWeekURL: "https://apod.nasa.gov/apod/image/0411/uranus_keck_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 364, // 365
    daysOseason: 72, // 73
    daySeasonComps: [71, 144, 217, 290, 363],
    daysOmonth: 35, // 36
    dayMonthComps: [34, 71, 107, 144, 180, 217, 253, 290, 326, 363],
    daysOfortnight: 17, // 18
    dayFortnightComps: [
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOweek: 8, // 9
    dayWeekComps: [
      7,
      25,
      44,
      62,
      80,
      98,
      117,
      135,
      153,
      171,
      190,
      208,
      226,
      244,
      263,
      281,
      299,
      317,
      336,
      354,
      16,
      34,
      53,
      71,
      89,
      109,
      126,
      144,
      162,
      180,
      199,
      217,
      235,
      253,
      272,
      290,
      308,
      326,
      345,
      363,
    ],
    daysOtab: 2, // 3
    dayNameWithinTab: ["middle"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["72nd of Fall", "Fall 72", "Fal 72"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["35th of Late Fall", "Late Fall 35", "L-Fal 35"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Neptune Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/0406/nep2002_hst1pan_c2.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 365, // 365
    daysOseason: 73, // 73
    daySeasonComps: [72, 145, 218, 291, 364],
    daysOmonth: 36, // 36
    dayMonthComps: [35, 72, 108, 145, 181, 218, 254, 291, 327, 364],
    daysOfortnight: 18, // 18
    dayFortnightComps: [
      17,
      35,
      54,
      72,
      90,
      108,
      127,
      145,
      163,
      181,
      200,
      218,
      236,
      254,
      273,
      291,
      309,
      327,
      346,
      364,
    ],
    daysOweek: 9, // 9
    dayWeekComps: [
      8,
      17,
      26,
      35,
      45,
      54,
      63,
      72,
      81,
      90,
      99,
      108,
      118,
      127,
      136,
      145,
      154,
      163,
      172,
      181,
      191,
      200,
      209,
      218,
      227,
      236,
      245,
      254,
      264,
      273,
      282,
      291,
      300,
      309,
      318,
      327,
      337,
      346,
      355,
      364,
    ],
    daysOtab: 3, // 3
    dayNameWithinTab: ["end"],
    dayNameWithinYear: [""],
    dayOfYearURL: "",
    dayNameWithinSeason: ["73rd of Fall", "Fall 73", "Fal 73"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["36th of Late Fall", "Late Fall 36", "L-Fal 36"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "Pluto Day",
    dayOfWeekURL:
      "https://apod.nasa.gov/apod/image/1909/PlutoTrueColor_NewHorizons_960.jpg",

    dayOfTabURL: "",
  },
  {
    daysOyear: 366, // 365
    daysOseason: null, // 73
    daysOmonth: null, // 36
    daysOfortnight: null, // 18
    daysOweek: null, // 9
    daysOtab: null, // 3
    dayNameWithinYear: "Leap Day",
    dayOfYearURL: "",
    dayNameWithinSeason: ["Leap Day"],
    dayOfSeasonURL: "",
    dayNameWithinMonth: ["Leap Day"],
    dayOfMonthURL: "",
    dayNameWithinFortnight: [""],
    dayOfFortnightURL: "",
    dayNameWithinWeek: "",
    dayOfWeekURL: "",

    dayOfTabURL: "",
  },
];

export default dobj;
