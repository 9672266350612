let sobj = [
  {
    seasonsOyear: 1,
    name: "Winter",
    url:
      "https://res.cloudinary.com/dzom9ahsn/image/upload/v1629729363/the%20new%20calendar/winter_kujdfx.svg",
  },
  {
    seasonsOyear: 2,
    name: "Spring",
    url:
      "https://res.cloudinary.com/dzom9ahsn/image/upload/v1629729363/the%20new%20calendar/spring_jpalfo.svg",
  },
  {
    seasonsOyear: 3,
    name: "Summer",
    url:
      "https://res.cloudinary.com/dzom9ahsn/image/upload/v1629729363/the%20new%20calendar/summer_gyvwwu.svg",
  },
  {
    seasonsOyear: 4,
    name: "Autumn",
    url:
      "https://res.cloudinary.com/dzom9ahsn/image/upload/v1629729363/the%20new%20calendar/autumn_vzkoqj.svg",
    startDayIndex: 219,
    endDayIndex: 291,
  },
  {
    seasonsOyear: 5,
    name: "Fall",
    url:
      "https://res.cloudinary.com/dzom9ahsn/image/upload/v1629729364/the%20new%20calendar/fall_pxf5f2.svg",
  },
];

export default sobj;
