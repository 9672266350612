let wobj = [
  // WINTER
  {
    weeksOyear: 1, // 40
    weeksOseason: 1, // 8
    weekSeasonComps: [0, 8, 16, 24, 32],
    weeksOmonth: 1, // 4
    weekMonthComps: [0, 4, 8, 12, 16, 20, 24, 28, 32, 36],

    weeksOfortnight: 1, // 2
    weekFortnightComps: [
      0,
      2,
      4,
      6,
      8,
      10,
      12,
      14,
      16,
      18,
      20,
      22,
      24,
      26,
      28,
      30,
      32,
      34,
      36,
      38,
    ],

    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    startDayIndex: 0,
    endDayIndex: 8,
  },
  {
    weeksOyear: 2, // 40
    weeksOseason: 2, // 8
    weekSeasonComps: [1, 9, 17, 25, 33],
    weeksOmonth: 2, // 4
    weekMonthComps: [1, 5, 9, 13, 17, 21, 25, 29, 33, 37],

    weeksOfortnight: 2, // 2
    weekFortnightComps: [
      1,
      3,
      5,
      7,
      9,
      11,
      13,
      15,
      17,
      19,
      21,
      23,
      25,
      27,
      29,
      31,
      33,
      35,
      37,
      39,
    ],

    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    startDayIndex: 9,
    endDayIndex: 17,
  },
  {
    weeksOyear: 3, // 40
    weeksOseason: 3, // 8
    weekSeasonComps: [2, 10, 18, 26, 34],
    weeksOmonth: 3, // 4
    weekMonthComps: [2, 6, 10, 14, 18, 22, 26, 30, 34, 38],
    weeksOfortnight: 1, // 2
    weekFortnightComps: [
      0,
      2,
      4,
      6,
      8,
      10,
      12,
      14,
      16,
      18,
      20,
      22,
      24,
      26,
      28,
      30,
      32,
      34,
      36,
      38,
    ],

    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    startDayIndex: 18,
    endDayIndex: 26,
  },
  {
    weeksOyear: 4, // 40
    weeksOseason: 4, // 8
    weekSeasonComps: [3, 11, 19, 27, 35],
    weeksOmonth: 4, // 4
    weekMonthComps: [3, 7, 11, 15, 19, 23, 27, 31, 35, 39],
    weeksOfortnight: 2, // 2
    weekFortnightComps: [
      1,
      3,
      5,
      7,
      9,
      11,
      13,
      15,
      17,
      19,
      21,
      23,
      25,
      27,
      29,
      31,
      33,
      35,
      37,
      39,
    ],

    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    startDayIndex: 27,
    endDayIndex: 35,
  },
  {
    weeksOyear: 5, // 40
    weeksOseason: 5, // 8
    weekSeasonComps: [4, 12, 20, 28, 36],

    weeksOmonth: 1, // 4
    weekMonthComps: [0, 4, 8, 12, 16, 20, 24, 28, 32, 36],

    weeksOfortnight: 1, // 2
    weekFortnightComps: [
      0,
      2,
      4,
      6,
      8,
      10,
      12,
      14,
      16,
      18,
      20,
      22,
      24,
      26,
      28,
      30,
      32,
      34,
      36,
      38,
    ],

    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    startDayIndex: 37,
    endDayIndex: 45,
  },
  {
    weeksOyear: 6, // 40
    weeksOseason: 6, // 8
    weekSeasonComps: [5, 13, 21, 29, 37],

    weeksOmonth: 2, // 4
    weekMonthComps: [1, 5, 9, 13, 17, 21, 25, 29, 33, 37],

    weeksOfortnight: 2, // 2
    weekFortnightComps: [
      1,
      3,
      5,
      7,
      9,
      11,
      13,
      15,
      17,
      19,
      21,
      23,
      25,
      27,
      29,
      31,
      33,
      35,
      37,
      39,
    ],

    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    startDayIndex: 46,
    endDayIndex: 54,
  },
  {
    weeksOyear: 7, // 40
    weeksOseason: 7, // 8
    weekSeasonComps: [6, 14, 22, 30, 38],

    weeksOmonth: 3, // 4
    weekMonthComps: [2, 6, 10, 14, 18, 22, 26, 30, 34, 38],

    weeksOfortnight: 1, // 2
    weekFortnightComps: [
      0,
      2,
      4,
      6,
      8,
      10,
      12,
      14,
      16,
      18,
      20,
      22,
      24,
      26,
      28,
      30,
      32,
      34,
      36,
      38,
    ],

    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    startDayIndex: 55,
    endDayIndex: 63,
  },
  {
    weeksOyear: 8, // 40
    weeksOseason: 8, // 8
    weekSeasonComps: [7, 15, 23, 31, 39],

    weeksOmonth: 4, // 4
    weekMonthComps: [3, 7, 11, 15, 19, 23, 27, 31, 35, 39],

    weeksOfortnight: 2, // 2
    weekFortnightComps: [
      1,
      3,
      5,
      7,
      9,
      11,
      13,
      15,
      17,
      19,
      21,
      23,
      25,
      27,
      29,
      31,
      33,
      35,
      37,
      39,
    ],

    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    startDayIndex: 64,
    endDayIndex: 72,
  },
  // SPRING
  {
    weeksOyear: 9, // 40
    weeksOseason: 1, // 8
    weekSeasonComps: [0, 8, 16, 24, 32],
    weeksOmonth: 1, // 4
    weekMonthComps: [0, 4, 8, 12, 16, 20, 24, 28, 32, 36],

    weeksOfortnight: 1, // 2
    weekFortnightComps: [
      0,
      2,
      4,
      6,
      8,
      10,
      12,
      14,
      16,
      18,
      20,
      22,
      24,
      26,
      28,
      30,
      32,
      34,
      36,
      38,
    ],

    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    startDayIndex: 73,
    endDayIndex: 81,
  },
  {
    weeksOyear: 10, // 40
    weeksOseason: 2, // 8
    weekSeasonComps: [1, 9, 17, 25, 33],

    weeksOmonth: 2, // 4
    weekMonthComps: [1, 5, 9, 13, 17, 21, 25, 29, 33, 37],

    weeksOfortnight: 2, // 2
    weekFortnightComps: [
      1,
      3,
      5,
      7,
      9,
      11,
      13,
      15,
      17,
      19,
      21,
      23,
      25,
      27,
      29,
      31,
      33,
      35,
      37,
      39,
    ],

    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    startDayIndex: 82,
    endDayIndex: 90,
  },
  {
    weeksOyear: 11, // 40
    weeksOseason: 3, // 8
    weekSeasonComps: [2, 10, 18, 26, 34],

    weeksOmonth: 3, // 4
    weekMonthComps: [2, 6, 10, 14, 18, 22, 26, 30, 34, 38],

    weeksOfortnight: 1, // 2
    weekFortnightComps: [
      0,
      2,
      4,
      6,
      8,
      10,
      12,
      14,
      16,
      18,
      20,
      22,
      24,
      26,
      28,
      30,
      32,
      34,
      36,
      38,
    ],

    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    startDayIndex: 91,
    endDayIndex: 99,
  },
  {
    weeksOyear: 12, // 40
    weeksOseason: 4, // 8
    weekSeasonComps: [3, 11, 19, 27, 35],

    weeksOmonth: 4, // 4
    weekMonthComps: [3, 7, 11, 15, 19, 23, 27, 31, 35, 39],

    weeksOfortnight: 2, // 2
    weekFortnightComps: [
      1,
      3,
      5,
      7,
      9,
      11,
      13,
      15,
      17,
      19,
      21,
      23,
      25,
      27,
      29,
      31,
      33,
      35,
      37,
      39,
    ],

    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    startDayIndex: 100,
    endDayIndex: 108,
  },
  {
    weeksOyear: 13, // 40
    weeksOseason: 5, // 8
    weekSeasonComps: [4, 12, 20, 28, 36],

    weeksOmonth: 1, // 4
    weekMonthComps: [0, 4, 8, 12, 16, 20, 24, 28, 32, 36],

    weeksOfortnight: 1, // 2
    weekFortnightComps: [
      0,
      2,
      4,
      6,
      8,
      10,
      12,
      14,
      16,
      18,
      20,
      22,
      24,
      26,
      28,
      30,
      32,
      34,
      36,
      38,
    ],

    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    startDayIndex: 110,
    endDayIndex: 118,
  },
  {
    weeksOyear: 14, // 40
    weeksOseason: 6, // 8
    weekSeasonComps: [5, 13, 21, 29, 37],

    weeksOmonth: 2, // 4
    weekMonthComps: [1, 5, 9, 13, 17, 21, 25, 29, 33, 37],

    weeksOfortnight: 2, // 2
    weekFortnightComps: [
      1,
      3,
      5,
      7,
      9,
      11,
      13,
      15,
      17,
      19,
      21,
      23,
      25,
      27,
      29,
      31,
      33,
      35,
      37,
      39,
    ],

    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    startDayIndex: 119,
    endDayIndex: 127,
  },
  {
    weeksOyear: 15, // 40
    weeksOseason: 7, // 8
    weekSeasonComps: [6, 14, 22, 30, 38],

    weeksOmonth: 3, // 4
    weekMonthComps: [2, 6, 10, 14, 18, 22, 26, 30, 34, 38],

    weeksOfortnight: 1, // 2
    weekFortnightComps: [
      0,
      2,
      4,
      6,
      8,
      10,
      12,
      14,
      16,
      18,
      20,
      22,
      24,
      26,
      28,
      30,
      32,
      34,
      36,
      38,
    ],

    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    startDayIndex: 128,
    endDayIndex: 136,
  },
  {
    weeksOyear: 16, // 40
    weeksOseason: 8, // 8
    weekSeasonComps: [7, 15, 23, 31, 39],

    weeksOmonth: 4, // 4
    weekMonthComps: [3, 7, 11, 15, 19, 23, 27, 31, 35, 39],

    weeksOfortnight: 2, // 2
    weekFortnightComps: [
      1,
      3,
      5,
      7,
      9,
      11,
      13,
      15,
      17,
      19,
      21,
      23,
      25,
      27,
      29,
      31,
      33,
      35,
      37,
      39,
    ],

    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    startDayIndex: 137,
    endDayIndex: 145,
  },

  //SUMMER
  {
    weeksOyear: 17, // 40
    weeksOseason: 1, // 8
    weekSeasonComps: [0, 8, 16, 24, 32],
    weeksOmonth: 1, // 4
    weekMonthComps: [0, 4, 8, 12, 16, 20, 24, 28, 32, 36],

    weeksOfortnight: 1, // 2
    weekFortnightComps: [
      0,
      2,
      4,
      6,
      8,
      10,
      12,
      14,
      16,
      18,
      20,
      22,
      24,
      26,
      28,
      30,
      32,
      34,
      36,
      38,
    ],

    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    startDayIndex: 146,
    endDayIndex: 154,
  },
  {
    weeksOyear: 18, // 40
    weeksOseason: 2, // 8
    weekSeasonComps: [1, 9, 17, 25, 33],

    weeksOmonth: 2, // 4
    weekMonthComps: [1, 5, 9, 13, 17, 21, 25, 29, 33, 37],

    weeksOfortnight: 2, // 2
    weekFortnightComps: [
      1,
      3,
      5,
      7,
      9,
      11,
      13,
      15,
      17,
      19,
      21,
      23,
      25,
      27,
      29,
      31,
      33,
      35,
      37,
      39,
    ],

    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    startDayIndex: 155,
    endDayIndex: 163,
  },
  {
    weeksOyear: 19, // 40
    weeksOseason: 3, // 8
    weekSeasonComps: [2, 10, 18, 26, 34],

    weeksOmonth: 3, // 4
    weekMonthComps: [2, 6, 10, 14, 18, 22, 26, 30, 34, 38],

    weeksOfortnight: 1, // 2
    weekFortnightComps: [
      0,
      2,
      4,
      6,
      8,
      10,
      12,
      14,
      16,
      18,
      20,
      22,
      24,
      26,
      28,
      30,
      32,
      34,
      36,
      38,
    ],

    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    startDayIndex: 164,
    endDayIndex: 172,
  },
  {
    weeksOyear: 20, // 40
    weeksOseason: 4, // 8
    weekSeasonComps: [3, 11, 19, 27, 35],

    weeksOmonth: 4, // 4
    weekMonthComps: [3, 7, 11, 15, 19, 23, 27, 31, 35, 39],

    weeksOfortnight: 2, // 2
    weekFortnightComps: [
      1,
      3,
      5,
      7,
      9,
      11,
      13,
      15,
      17,
      19,
      21,
      23,
      25,
      27,
      29,
      31,
      33,
      35,
      37,
      39,
    ],

    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    startDayIndex: 173,
    endDayIndex: 181,
  },
  {
    weeksOyear: 21, // 40
    weeksOseason: 5, // 8
    weekSeasonComps: [4, 12, 20, 28, 36],

    weeksOmonth: 1, // 4
    weekMonthComps: [0, 4, 8, 12, 16, 20, 24, 28, 32, 36],

    weeksOfortnight: 1, // 2
    weekFortnightComps: [
      0,
      2,
      4,
      6,
      8,
      10,
      12,
      14,
      16,
      18,
      20,
      22,
      24,
      26,
      28,
      30,
      32,
      34,
      36,
      38,
    ],

    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    startDayIndex: 183,
    endDayIndex: 191,
  },
  {
    weeksOyear: 22, // 40
    weeksOseason: 6, // 8
    weekSeasonComps: [5, 13, 21, 29, 37],

    weeksOmonth: 2, // 4
    weekMonthComps: [1, 5, 9, 13, 17, 21, 25, 29, 33, 37],

    weeksOfortnight: 2, // 2
    weekFortnightComps: [
      1,
      3,
      5,
      7,
      9,
      11,
      13,
      15,
      17,
      19,
      21,
      23,
      25,
      27,
      29,
      31,
      33,
      35,
      37,
      39,
    ],

    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    startDayIndex: 192,
    endDayIndex: 200,
  },
  {
    weeksOyear: 23, // 40
    weeksOseason: 7, // 8
    weekSeasonComps: [6, 14, 22, 30, 38],

    weeksOmonth: 3, // 4
    weekMonthComps: [2, 6, 10, 14, 18, 22, 26, 30, 34, 38],

    weeksOfortnight: 1, // 2
    weekFortnightComps: [
      0,
      2,
      4,
      6,
      8,
      10,
      12,
      14,
      16,
      18,
      20,
      22,
      24,
      26,
      28,
      30,
      32,
      34,
      36,
      38,
    ],

    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    startDayIndex: 201,
    endDayIndex: 209,
  },
  {
    weeksOyear: 24, // 40
    weeksOseason: 8, // 8
    weekSeasonComps: [7, 15, 23, 31, 39],

    weeksOmonth: 4, // 4
    weekMonthComps: [3, 7, 11, 15, 19, 23, 27, 31, 35, 39],

    weeksOfortnight: 2, // 2
    weekFortnightComps: [
      1,
      3,
      5,
      7,
      9,
      11,
      13,
      15,
      17,
      19,
      21,
      23,
      25,
      27,
      29,
      31,
      33,
      35,
      37,
      39,
    ],

    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    startDayIndex: 210,
    endDayIndex: 218,
  },
  // AUTUMN
  {
    weeksOyear: 25, // 40
    weeksOseason: 1, // 8
    weekSeasonComps: [0, 8, 16, 24, 32],
    weeksOmonth: 1, // 4
    weekMonthComps: [0, 4, 8, 12, 16, 20, 24, 28, 32, 36],

    weeksOfortnight: 1, // 2
    weekFortnightComps: [
      0,
      2,
      4,
      6,
      8,
      10,
      12,
      14,
      16,
      18,
      20,
      22,
      24,
      26,
      28,
      30,
      32,
      34,
      36,
      38,
    ],

    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    startDayIndex: 219,
    endDayIndex: 227,
  },
  {
    weeksOyear: 26, // 40
    weeksOseason: 2, // 8
    weekSeasonComps: [1, 9, 17, 25, 33],

    weeksOmonth: 2, // 4
    weekMonthComps: [1, 5, 9, 13, 17, 21, 25, 29, 33, 37],

    weeksOfortnight: 2, // 2
    weekFortnightComps: [
      1,
      3,
      5,
      7,
      9,
      11,
      13,
      15,
      17,
      19,
      21,
      23,
      25,
      27,
      29,
      31,
      33,
      35,
      37,
      39,
    ],

    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    startDayIndex: 228,
    endDayIndex: 236,
  },
  {
    weeksOyear: 27, // 40
    weeksOseason: 3, // 8
    weekSeasonComps: [2, 10, 18, 26, 34],

    weeksOmonth: 3, // 4
    weekMonthComps: [2, 6, 10, 14, 18, 22, 26, 30, 34, 38],

    weeksOfortnight: 1, // 2
    weekFortnightComps: [
      0,
      2,
      4,
      6,
      8,
      10,
      12,
      14,
      16,
      18,
      20,
      22,
      24,
      26,
      28,
      30,
      32,
      34,
      36,
      38,
    ],

    nameWithinYear: "",
    nameWithinSeason: "Early",
    nameWithinMonth: "Late",
    nameWithinFortnight: "Early",
    startDayIndex: 237,
    endDayIndex: 245,
  },
  {
    weeksOyear: 28, // 40
    weeksOseason: 4, // 8
    weekSeasonComps: [3, 11, 19, 27, 35],

    weeksOmonth: 4, // 4
    weekMonthComps: [3, 7, 11, 15, 19, 23, 27, 31, 35, 39],

    weeksOfortnight: 2, // 2
    weekFortnightComps: [
      1,
      3,
      5,
      7,
      9,
      11,
      13,
      15,
      17,
      19,
      21,
      23,
      25,
      27,
      29,
      31,
      33,
      35,
      37,
      39,
    ],

    nameWithinYear: "The Last Gasp",
    nameWithinSeason: "Early",
    nameWithinMonth: "Late",
    nameWithinFortnight: "week_4",
    startDayIndex: 246,
    endDayIndex: 254,
  },
  {
    weeksOyear: 29, // 40
    weeksOseason: 5, // 8
    weekSeasonComps: [4, 12, 20, 28, 36],

    weeksOmonth: 1, // 4
    weekMonthComps: [0, 4, 8, 12, 16, 20, 24, 28, 32, 36],

    weeksOfortnight: 1, // 2
    weekFortnightComps: [
      0,
      2,
      4,
      6,
      8,
      10,
      12,
      14,
      16,
      18,
      20,
      22,
      24,
      26,
      28,
      30,
      32,
      34,
      36,
      38,
    ],

    nameWithinYear: "",
    nameWithinSeason: "Late",
    nameWithinMonth: "Early",
    nameWithinFortnight: "week_1",
    startDayIndex: 256,
    endDayIndex: 264,
  },
  {
    weeksOyear: 30, // 40
    weeksOseason: 6, // 8
    weekSeasonComps: [5, 13, 21, 29, 37],

    weeksOmonth: 2, // 4
    weekMonthComps: [1, 5, 9, 13, 17, 21, 25, 29, 33, 37],

    weeksOfortnight: 2, // 2
    weekFortnightComps: [
      1,
      3,
      5,
      7,
      9,
      11,
      13,
      15,
      17,
      19,
      21,
      23,
      25,
      27,
      29,
      31,
      33,
      35,
      37,
      39,
    ],

    nameWithinYear: "",
    nameWithinSeason: "Late",
    nameWithinMonth: "Early",
    nameWithinFortnight: "week_2",
    startDayIndex: 265,
    endDayIndex: 273,
  },
  {
    weeksOyear: 31, // 40
    weeksOseason: 7, // 8
    weekSeasonComps: [6, 14, 22, 30, 38],

    weeksOmonth: 3, // 4
    weekMonthComps: [2, 6, 10, 14, 18, 22, 26, 30, 34, 38],

    weeksOfortnight: 1, // 2
    weekFortnightComps: [
      0,
      2,
      4,
      6,
      8,
      10,
      12,
      14,
      16,
      18,
      20,
      22,
      24,
      26,
      28,
      30,
      32,
      34,
      36,
      38,
    ],

    nameWithinYear: "",
    nameWithinSeason: "Late",
    nameWithinMonth: "Late",
    nameWithinFortnight: "week_3",
    startDayIndex: 274,
    endDayIndex: 282,
  },
  {
    weeksOyear: 32, // 40
    weeksOseason: 8, // 8
    weekSeasonComps: [7, 15, 23, 31, 39],

    weeksOmonth: 4, // 4
    weekMonthComps: [3, 7, 11, 15, 19, 23, 27, 31, 35, 39],

    weeksOfortnight: 2, // 2
    weekFortnightComps: [
      1,
      3,
      5,
      7,
      9,
      11,
      13,
      15,
      17,
      19,
      21,
      23,
      25,
      27,
      29,
      31,
      33,
      35,
      37,
      39,
    ],

    nameWithinYear: "",
    nameWithinSeason: "Late",
    nameWithinMonth: "Late",
    nameWithinFortnight: "week_4",
    startDayIndex: 283,
    endDayIndex: 291,
  },
  // FALL
  {
    weeksOyear: 33, // 40
    weeksOseason: 1, // 8
    weekSeasonComps: [0, 8, 16, 24, 32],
    weeksOmonth: 1, // 4
    weekMonthComps: [0, 4, 8, 12, 16, 20, 24, 28, 32, 36],

    weeksOfortnight: 1, // 2
    weekFortnightComps: [
      0,
      2,
      4,
      6,
      8,
      10,
      12,
      14,
      16,
      18,
      20,
      22,
      24,
      26,
      28,
      30,
      32,
      34,
      36,
      38,
    ],

    nameWithinYear: "",
    nameWithinSeason: "Early",
    nameWithinMonth: "Early",
    nameWithinFortnight: "Early",
    startDayIndex: 292,
    endDayIndex: 300,
  },
  {
    weeksOyear: 34, // 40
    weeksOseason: 2, // 8
    weekSeasonComps: [1, 9, 17, 25, 33],

    weeksOmonth: 2, // 4
    weekMonthComps: [1, 5, 9, 13, 17, 21, 25, 29, 33, 37],

    weeksOfortnight: 2, // 2
    weekFortnightComps: [
      1,
      3,
      5,
      7,
      9,
      11,
      13,
      15,
      17,
      19,
      21,
      23,
      25,
      27,
      29,
      31,
      33,
      35,
      37,
      39,
    ],

    nameWithinYear: "",
    nameWithinSeason: "Early",
    nameWithinMonth: "Early",
    nameWithinFortnight: "Late",
    startDayIndex: 301,
    endDayIndex: 309,
  },
  {
    weeksOyear: 35, // 40
    weeksOseason: 3, // 8
    weekSeasonComps: [2, 10, 18, 26, 34],

    weeksOmonth: 3, // 4
    weekMonthComps: [2, 6, 10, 14, 18, 22, 26, 30, 34, 38],

    weeksOfortnight: 1, // 2
    weekFortnightComps: [
      0,
      2,
      4,
      6,
      8,
      10,
      12,
      14,
      16,
      18,
      20,
      22,
      24,
      26,
      28,
      30,
      32,
      34,
      36,
      38,
    ],

    nameWithinYear: "",
    nameWithinSeason: "Early",
    nameWithinMonth: "Late",
    nameWithinFortnight: "Early",
    startDayIndex: 310,
    endDayIndex: 318,
  },
  {
    weeksOyear: 36, // 40
    weeksOseason: 4, // 8
    weekSeasonComps: [3, 11, 19, 27, 35],

    weeksOmonth: 4, // 4
    weekMonthComps: [3, 7, 11, 15, 19, 23, 27, 31, 35, 39],

    weeksOfortnight: 2, // 2
    weekFortnightComps: [
      1,
      3,
      5,
      7,
      9,
      11,
      13,
      15,
      17,
      19,
      21,
      23,
      25,
      27,
      29,
      31,
      33,
      35,
      37,
      39,
    ],

    nameWithinYear: "",
    nameWithinSeason: "Early",
    nameWithinMonth: "Late",
    nameWithinFortnight: "Late",
    startDayIndex: 319,
    endDayIndex: 327,
  },
  {
    weeksOyear: 37, // 40
    weeksOseason: 5, // 8
    weekSeasonComps: [4, 12, 20, 28, 36],

    weeksOmonth: 1, // 4
    weekMonthComps: [0, 4, 8, 12, 16, 20, 24, 28, 32, 36],

    weeksOfortnight: 1, // 2
    weekFortnightComps: [
      0,
      2,
      4,
      6,
      8,
      10,
      12,
      14,
      16,
      18,
      20,
      22,
      24,
      26,
      28,
      30,
      32,
      34,
      36,
      38,
    ],

    nameWithinYear: "",
    nameWithinSeason: "Late",
    nameWithinMonth: "Early",
    nameWithinFortnight: "Early",
    startDayIndex: 329,
    endDayIndex: 337,
  },
  {
    weeksOyear: 38, // 40
    weeksOseason: 6, // 8
    weekSeasonComps: [5, 13, 21, 29, 37],

    weeksOmonth: 2, // 4
    weekMonthComps: [1, 5, 9, 13, 17, 21, 25, 29, 33, 37],

    weeksOfortnight: 2, // 2
    weekFortnightComps: [
      1,
      3,
      5,
      7,
      9,
      11,
      13,
      15,
      17,
      19,
      21,
      23,
      25,
      27,
      29,
      31,
      33,
      35,
      37,
      39,
    ],

    nameWithinYear: "",
    nameWithinSeason: "Late",
    nameWithinMonth: "Early",
    nameWithinFortnight: "Late",
    startDayIndex: 338,
    endDayIndex: 346,
  },
  {
    weeksOyear: 39, // 40
    weeksOseason: 7, // 8
    weekSeasonComps: [6, 14, 22, 30, 38],

    weeksOmonth: 3, // 4
    weekMonthComps: [2, 6, 10, 14, 18, 22, 26, 30, 34, 38],

    weeksOfortnight: 1, // 2
    weekFortnightComps: [
      0,
      2,
      4,
      6,
      8,
      10,
      12,
      14,
      16,
      18,
      20,
      22,
      24,
      26,
      28,
      30,
      32,
      34,
      36,
      38,
    ],

    nameWithinYear: "",
    nameWithinSeason: "Late",
    nameWithinMonth: "Late",
    nameWithinFortnight: "Early",
    startDayIndex: 347,
    endDayIndex: 355,
  },
  {
    weeksOyear: 40, // 40
    weeksOseason: 8, // 8
    weekSeasonComps: [7, 15, 23, 31, 39],

    weeksOmonth: 4, // 4
    weekMonthComps: [3, 7, 11, 15, 19, 23, 27, 31, 35, 39],

    weeksOfortnight: 2, // 2
    weekFortnightComps: [
      1,
      3,
      5,
      7,
      9,
      11,
      13,
      15,
      17,
      19,
      21,
      23,
      25,
      27,
      29,
      31,
      33,
      35,
      37,
      39,
    ],

    nameWithinYear: "",
    nameWithinSeason: "Late",
    nameWithinMonth: "Late",
    nameWithinFortnight: "Late",
    startDayIndex: 356,
    endDayIndex: 364,
  },
];

export default wobj;
