import Time from "./Time";
import NewCalendarLite_v1 from "../media/NewCalendarLite_v1.pdf";

const PocketPDF = () => {
  return (
    <div>
      <h1>Pocket PDF</h1>
      <a
        href={NewCalendarLite_v1}
        download="New Calendar Lite v1.0"
        target="_blank"
        rel="noreferrer"
      >
        <button>Download the .pdf file</button>
      </a>
      <Time />
    </div>
  );
};

export default PocketPDF;
