import { useHistory } from "react-router-dom";
import colorCode from "../blocks/logic/colorCode";
import useScrollToTop from "../blocks/logic/usScrollToTop";
import ProductCompOne from "../components/ProductCompOne";
import ProductCompTwo from "../components/ProductCompTwo";
import { useState } from "react";

const AdventureSquare = ({ rabbitData }) => {
  useScrollToTop();
  const history = useHistory();
  const [shopToggle, setShopToggle] = useState(false);

  let seasonColor = rabbitData.season.seasonColor;

  let buttonColor = colorCode(["button"]);
  let flexButtonColor = colorCode(["button flex"]);
  let containerClass = ["adventureSquare", `${rabbitData.slug}`];
  let quoteClass = "quoteHeadline " + seasonColor;
  // let productOne = ["homeSquare", `${rabbitData.productOne}`];
  // let productTwo = ["homeSquare", `${rabbitData.productTwo}`];
  // let productThree = ["homeSquare", `${rabbitData.productThree}`];

  // console.log("buttonColor", quoteClass);
  containerClass = containerClass.toString().split(",").join(" ");

  const rabbitRoute = (event) => {
    history.push(`/rabbithole/${rabbitData.nextHole}`);
  };
  // const deepRoute1 = (event) => {
  //   history.push(`/rabbithole/${rabbitData.productOne}`);
  // };
  // const deepRoute2 = (event) => {
  //   // console.log(
  //   //   "clicker",
  //   //   `/rabbithole/${rabbitData.slug}/${rabbitData.productTwo}`
  //   // );
  //   history.push(`/rabbithole/${rabbitData.slug}/${rabbitData.productTwo}`);
  // };
  // const deepRoute3 = (event) => {
  //   history.push(`/rabbithole/${rabbitData.slug}/${rabbitData.productTwo}`);
  // };
  const sendEmail = (event) => {
    const email = "tom@thenewcalendar.com";
    const subject = rabbitData.quote;

    const mailto = `mailto:${email}?subject=${subject}`;

    window.location.href = mailto;
  };
  const buyToggle = (event) => {
    setShopToggle(!shopToggle);
    // console.log("shopToggle", shopToggle);
  };
  return (
    <div>
      {/* ICON AND HEADLINE */}
      <div className="adventureFlex" style={{ flexDirection: "reverse" }}>
        <div className={containerClass} style={{}}></div>{" "}
        <h3 className="adventureHeadline" style={{ width: "100%" }}>
          {rabbitData.quote}
        </h3>
      </div>
      {/* QUOTE AND IMAGE */}
      <div>
        <h4 className={quoteClass}>{rabbitData.quoteAttribute}</h4>
      </div>
      {/* PARAGRAPH */}
      <div>
        <p className="adventureP">{rabbitData.paragraph}</p>
      </div>
      {/* RELATED PRODuCTS */}
      {/* <div className="adventureFlex">
        <div
          className="adventureSquare"
          style={{ border: "1px solid black", backgroundColor: "pink" }}
          onClick={deepRoute1}
        >
          {rabbitData.productOne}
        </div>
        <div
          className="adventureSquare"
          style={{ border: "1px solid black", backgroundColor: "pink" }}
          onClick={deepRoute2}
        >
          {rabbitData.productTwo}
        </div>
        <div
          className="adventureSquare"
          style={{ border: "1px solid black", backgroundColor: "pink" }}
          onClick={deepRoute3}
        >
          {rabbitData.productThree}
        </div>
      </div> */}
      {/* CONTACT BUTTON */}
      <div>
        <div
          className={flexButtonColor}
          style={{ textAlign: "center", border: "1px solid black" }}
          onClick={buyToggle}
        >
          Get a Copy
        </div>
        {shopToggle ? (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <ProductCompOne />
            <ProductCompTwo />
          </div>
        ) : (
          <></>
        )}
        <div
          className={buttonColor}
          style={{ textAlign: "center", border: "1px solid black" }}
          onClick={sendEmail}
        >
          Contact tom@newcalendar.com
        </div>
        {/* BACK HOME */}
        <div
          className={buttonColor}
          style={{ textAlign: "center", border: "1px solid black" }}
          onClick={rabbitRoute}
        >
          continue down the rabbit hole...{" "}
        </div>{" "}
      </div>
    </div>
  );
};

export default AdventureSquare;
