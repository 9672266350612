import React, { useEffect } from "react";

const PayPalSubscribeBuy = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://www.paypal.com/sdk/js?client-id=ASzlnLJha0HiNJU8jsezHLu5FaqGKJB8u9wImXSOFnl0B1Sfc-YS-pqreny9K_1XQIm4WJsfzbJbm20W&vault=true&intent=subscription";
    script.async = true;
    script.onload = () => {
      window.paypal
        .Buttons({
          style: {
            shape: "rect",
            color: "gold",
            layout: "vertical",
            label: "subscribe",
          },
          createSubscription: function (data, actions) {
            return actions.subscription.create({
              plan_id: "P-7J894148LD731951HMVPH5IY",
              quantity: 1,
            });
          },
          onApprove: function (data, actions) {
            return actions.subscription.get().then(function (details) {
              alert("Subscription successful. Subscription ID: " + details.id);
            });
          },
        })
        .render("#paypal-button-container"); // Renders the PayPal button
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      id="paypal-button-container"
      style={{ width: "60%", margin: "1%" }}
    ></div>
  );
};

export default PayPalSubscribeBuy;
