import colorCode from "../blocks/logic/colorCode";

const Contact = () => {
  let seasonColor = colorCode([]);
  let buttonColor = colorCode(["button free"]);
  let emailColor = colorCode(["productText free button"]);

  const sendEmail = (event) => {
    const email = "tom@thenewcalendar.com";
    const subject = "ATTN: Tom --";

    const mailto = `mailto:${email}?subject=${subject}`;

    window.location.href = mailto;
  };

  const joinSlack = () => {
    const linkUrl =
      "https://join.slack.com/t/newcalendar/shared_invite/zt-22wm7e8tt-o9ZUHqW4~khWChdH390RcA"; // Replace with your desired URL
    window.open(linkUrl, "_blank");
  };
  const joinDiscord = () => {
    const linkUrl = "https://discord.gg/dMtAET3gQu"; // Replace with your desired URL
    window.open(linkUrl, "_blank");
  };

  const freeEmail = (event) => {
    const email = "tom@thenewcalendar.com";
    const subject = "Free Copy request";
    const body = `Hi Tom, I would like to request a free copy of The New Calendar and User Guide. I am interested in participating in this experiment because:`;
    const mailto = `mailto:${email}?subject=${subject}&body=${body}`;

    window.location.href = mailto;
  };

  return (
    <div>
      <h2>Get involved</h2>

      <div
        className={buttonColor}
        style={{
          // width: "100%",
          border: "10px dashed black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          // fontSize: "3rem",
        }}
        onClick={joinDiscord}
      >
        <div className={seasonColor}>
          <h2 style={{ backgroundColor: "transparent" }}>
            Join our Discord Server
          </h2>
          {/* <div style={{ fontSize: "1rem", backgroundColor: "transparent" }}>
            discord for public network
          </div> */}
        </div>
        <div
          style={{
            borderRadius: "0%",
            backgroundColor: "whitesmoke",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // width: "20%",
          }}
        >
          <img
            className="slackIcon"
            src="https://assets-global.website-files.com/6257adef93867e50d84d30e2/636e0a69f118df70ad7828d4_icon_clyde_blurple_RGB.svg"
            alt="discord"
            style={{ backgroundColor: "transparent" }}
          />
        </div>
      </div>

      {/* <div
        className={buttonColor}
        style={{
          // width: "100%",
          border: "10px dashed black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          // fontSize: "3rem",
        }}
        onClick={freeEmail}
      >
        <h2 className={seasonColor}>
          Click to get your Free Copy and User Guide
        </h2>
      </div> */}
      <div
        className={emailColor}
        style={{
          // width: "100%",
          border: "10px solid black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          // fontSize: "3rem",
        }}
        onClick={sendEmail}
      >
        email: tom@thenewcalendar.com
      </div>
    </div>
  );
};

export default Contact;
