let mobj = [
  {
    monthsOyear: 1,
    monthsOseason: 1,
    nameWithinYear: "March",
    nameWithinSeason: "Early",
  },
  {
    monthsOyear: 2,
    monthsOseason: 2,
    nameWithinYear: "April",
    nameWithinSeason: "Late",
  },
  {
    monthsOyear: 3,
    monthsOseason: 1,
    nameWithinYear: "May",
    nameWithinSeason: "Early",
  },
  {
    monthsOyear: 4,
    monthsOseason: 2,
    nameWithinYear: "June",
    nameWithinSeason: "Late",
  },
  {
    monthsOyear: 5,
    monthsOseason: 1,
    nameWithinYear: "Quintilis",
    nameWithinSeason: "Early",
  },
  {
    monthsOyear: 6,
    monthsOseason: 2,
    nameWithinYear: "Sextilis",
    nameWithinSeason: "Late",
  },
  {
    monthsOyear: 7,
    monthsOseason: 1,
    nameWithinYear: "September",
    nameWithinSeason: "Early",
    startDayIndex: 219,
    endDayIndex: 254,
  },
  {
    monthsOyear: 8,
    monthsOseason: 2,
    nameWithinYear: "October",
    nameWithinSeason: "Late",
    startDayIndex: 256,
    endDayIndex: 291,
  },
  {
    monthsOyear: 9,
    monthsOseason: 1,
    nameWithinYear: "November",
    nameWithinSeason: "Early",
  },
  {
    monthsOyear: 10,
    monthsOseason: 2,
    nameWithinYear: "December",
    nameWithinSeason: "Late",
  },
];

export default mobj;
