import fot from "./fot";

const calculator = (toConvert) => {
  let newDate = "";
  const calLang = fot().props.children;

  // console.log(toConvert, calLang);

  if (toConvert === "Dec 21") {
    newDate = "Winter 1";
  } else if (toConvert === "Dec 22") {
    newDate = "Winter 2";
  } else if (toConvert === "Dec 23") {
    newDate = "Winter 3";
  } else if (toConvert === "Dec 24") {
    newDate = "Winter 4";
  } else if (toConvert === "Dec 25") {
    newDate = "Winter 5";
  } else if (toConvert === "Dec 26") {
    newDate = "Winter 6";
  } else if (toConvert === "Dec 27") {
    newDate = "Winter 7";
  } else if (toConvert === "Dec 28") {
    newDate = "Winter 8";
  } else if (toConvert === "Dec 29") {
    newDate = "Winter 9";
  } else if (toConvert === "Dec 30") {
    newDate = "Winter 10";
  } else if (toConvert === "Dec 31") {
    newDate = "Winter 11";
  } else if (toConvert === "Jan 1") {
    newDate = "Winter 12";
  } else if (toConvert === "Jan 2") {
    newDate = "Winter 13";
  } else if (toConvert === "Jan 3") {
    newDate = "Winter 14";
  } else if (toConvert === "Jan 4") {
    newDate = "Winter 15";
  } else if (toConvert === "Jan 5") {
    newDate = "Winter 16";
  } else if (toConvert === "Jan 6") {
    newDate = "Winter 17";
  } else if (toConvert === "Jan 7") {
    newDate = "Winter 18";
  } else if (toConvert === "Jan 8") {
    newDate = "Winter 19";
  } else if (toConvert === "Jan 9") {
    newDate = "Winter 20";
  } else if (toConvert === "Jan 10") {
    newDate = "Winter 21";
  } else if (toConvert === "Jan 11") {
    newDate = "Winter 22";
  } else if (toConvert === "Jan 12") {
    newDate = "Winter 23";
  } else if (toConvert === "Jan 13") {
    newDate = "Winter 24";
  } else if (toConvert === "Jan 14") {
    newDate = "Winter 25";
  } else if (toConvert === "Jan 15") {
    newDate = "Winter 26";
  } else if (toConvert === "Jan 16") {
    newDate = "Winter 27";
  } else if (toConvert === "Jan 17") {
    newDate = "Winter 28";
  } else if (toConvert === "Jan 18") {
    newDate = "Winter 29";
  } else if (toConvert === "Jan 19") {
    newDate = "Winter 30";
  } else if (toConvert === "Jan 20") {
    newDate = "Winter 31";
  } else if (toConvert === "Jan 21") {
    newDate = "Winter 32";
  } else if (toConvert === "Jan 22") {
    newDate = "Winter 33";
  } else if (toConvert === "Jan 23") {
    newDate = "Winter 34";
  } else if (toConvert === "Jan 24") {
    newDate = "Winter 35";
  } else if (toConvert === "Jan 25") {
    newDate = "Winter 36";
  } else if (toConvert === "Jan 26") {
    newDate = "Winter 37";
  } else if (toConvert === "Jan 27") {
    newDate = "Winter 38";
  } else if (toConvert === "Jan 28") {
    newDate = "Winter 39";
  } else if (toConvert === "Jan 29") {
    newDate = "Winter 40";
  } else if (toConvert === "Jan 30") {
    newDate = "Winter 41";
  } else if (toConvert === "Jan 31") {
    newDate = "Winter 42";
  } else if (toConvert === "Feb 1") {
    newDate = "Winter 43";
  } else if (toConvert === "Feb 2") {
    newDate = "Winter 44";
  } else if (toConvert === "Feb 3") {
    newDate = "Winter 45";
  } else if (toConvert === "Feb 4") {
    newDate = "Winter 46";
  } else if (toConvert === "Feb 5") {
    newDate = "Winter 47";
  } else if (toConvert === "Feb 6") {
    newDate = "Winter 48";
  } else if (toConvert === "Feb 7") {
    newDate = "Winter 49";
  } else if (toConvert === "Feb 8") {
    newDate = "Winter 50";
  } else if (toConvert === "Feb 9") {
    newDate = "Winter 51";
  } else if (toConvert === "Feb 10") {
    newDate = "Winter 52";
  } else if (toConvert === "Feb 11") {
    newDate = "Winter 53";
  } else if (toConvert === "Feb 12") {
    newDate = "Winter 54";
  } else if (toConvert === "Feb 13") {
    newDate = "Winter 55";
  } else if (toConvert === "Feb 14") {
    newDate = "Winter 56";
  } else if (toConvert === "Feb 15") {
    newDate = "Winter 57";
  } else if (toConvert === "Feb 16") {
    newDate = "Winter 58";
  } else if (toConvert === "Feb 17") {
    newDate = "Winter 59";
  } else if (toConvert === "Feb 18") {
    newDate = "Winter 60";
  } else if (toConvert === "Feb 19") {
    newDate = "Winter 61";
  } else if (toConvert === "Feb 20") {
    newDate = "Winter 62";
  } else if (toConvert === "Feb 21") {
    newDate = "Winter 63";
  } else if (toConvert === "Feb 22") {
    newDate = "Winter 64";
  } else if (toConvert === "Feb 23") {
    newDate = "Winter 65";
  } else if (toConvert === "Feb 24") {
    newDate = "Winter 66";
  } else if (toConvert === "Feb 25") {
    newDate = "Winter 67";
  } else if (toConvert === "Feb 26") {
    newDate = "Winter 68";
  } else if (toConvert === "Feb 27") {
    newDate = "Winter 69";
  } else if (toConvert === "Feb 28") {
    newDate = "Winter 70";
  } else if (toConvert === "Mar 1") {
    newDate = "Winter 71";
  } else if (toConvert === "Mar 2") {
    newDate = "Winter 72";
  } else if (toConvert === "Mar 3") {
    newDate = "Winter 73";
  } else if (toConvert === "Mar 4") {
    newDate = "Spring 1";
  } else if (toConvert === "Mar 5") {
    newDate = "Spring 2";
  } else if (toConvert === "Mar 6") {
    newDate = "Spring 3";
  } else if (toConvert === "Mar 7") {
    newDate = "Spring 4";
  } else if (toConvert === "Mar 8") {
    newDate = "Spring 5";
  } else if (toConvert === "Mar 9") {
    newDate = "Spring 6";
  } else if (toConvert === "Mar 10") {
    newDate = "Spring 7";
  } else if (toConvert === "Mar 11") {
    newDate = "Spring 8";
  } else if (toConvert === "Mar 12") {
    newDate = "Spring 9";
  } else if (toConvert === "Mar 13") {
    newDate = "Spring 10";
  } else if (toConvert === "Mar 14") {
    newDate = "Spring 11";
  } else if (toConvert === "Mar 15") {
    newDate = "Spring 12";
  } else if (toConvert === "Mar 16") {
    newDate = "Spring 13";
  } else if (toConvert === "Mar 17") {
    newDate = "Spring 14";
  } else if (toConvert === "Mar 18") {
    newDate = "Spring 15";
  } else if (toConvert === "Mar 19") {
    newDate = "Spring 16";
  } else if (toConvert === "Mar 20") {
    newDate = "Spring 17";
  } else if (toConvert === "Mar 21") {
    newDate = "Spring 18";
  } else if (toConvert === "Mar 22") {
    newDate = "Spring 19";
  } else if (toConvert === "Mar 23") {
    newDate = "Spring 20";
  } else if (toConvert === "Mar 24") {
    newDate = "Spring 21";
  } else if (toConvert === "Mar 25") {
    newDate = "Spring 22";
  } else if (toConvert === "Mar 26") {
    newDate = "Spring 23";
  } else if (toConvert === "Mar 27") {
    newDate = "Spring 24";
  } else if (toConvert === "Mar 28") {
    newDate = "Spring 25";
  } else if (toConvert === "Mar 29") {
    newDate = "Spring 26";
  } else if (toConvert === "Mar 30") {
    newDate = "Spring 27";
  } else if (toConvert === "Mar 31") {
    newDate = "Spring 28";
  } else if (toConvert === "Apr 1") {
    newDate = "Spring 29";
  } else if (toConvert === "Apr 2") {
    newDate = "Spring 30";
  } else if (toConvert === "Apr 3") {
    newDate = "Spring 31";
  } else if (toConvert === "Apr 4") {
    newDate = "Spring 32";
  } else if (toConvert === "Apr 5") {
    newDate = "Spring 33";
  } else if (toConvert === "Apr 6") {
    newDate = "Spring 34";
  } else if (toConvert === "Apr 7") {
    newDate = "Spring 35";
  } else if (toConvert === "Apr 8") {
    newDate = "Spring 36";
  } else if (toConvert === "Apr 9") {
    newDate = "Spring 37";
  } else if (toConvert === "Apr 10") {
    newDate = "Spring 38";
  } else if (toConvert === "Apr 11") {
    newDate = "Spring 39";
  } else if (toConvert === "Apr 12") {
    newDate = "Spring 40";
  } else if (toConvert === "Apr 13") {
    newDate = "Spring 41";
  } else if (toConvert === "Apr 14") {
    newDate = "Spring 42";
  } else if (toConvert === "Apr 15") {
    newDate = "Spring 43";
  } else if (toConvert === "Apr 16") {
    newDate = "Spring 44";
  } else if (toConvert === "Apr 17") {
    newDate = "Spring 45";
  } else if (toConvert === "Apr 18") {
    newDate = "Spring 46";
  } else if (toConvert === "Apr 19") {
    newDate = "Spring 47";
  } else if (toConvert === "Apr 20") {
    newDate = "Spring 48";
  } else if (toConvert === "Apr 21") {
    newDate = "Spring 49";
  } else if (toConvert === "Apr 22") {
    newDate = "Spring 50";
  } else if (toConvert === "Apr 23") {
    newDate = "Spring 51";
  } else if (toConvert === "Apr 24") {
    newDate = "Spring 52";
  } else if (toConvert === "Apr 25") {
    newDate = "Spring 53";
  } else if (toConvert === "Apr 26") {
    newDate = "Spring 54";
  } else if (toConvert === "Apr 27") {
    newDate = "Spring 55";
  } else if (toConvert === "Apr 28") {
    newDate = "Spring 56";
  } else if (toConvert === "Apr 29") {
    newDate = "Spring 57";
  } else if (toConvert === "Apr 30") {
    newDate = "Spring 58";
  } else if (toConvert === "May 1") {
    newDate = "Spring 59";
  } else if (toConvert === "May 2") {
    newDate = "Spring 60";
  } else if (toConvert === "May 3") {
    newDate = "Spring 61";
  } else if (toConvert === "May 4") {
    newDate = "Spring 62";
  } else if (toConvert === "May 5") {
    newDate = "Spring 63";
  } else if (toConvert === "May 6") {
    newDate = "Spring 64";
  } else if (toConvert === "May 7") {
    newDate = "Spring 65";
  } else if (toConvert === "May 8") {
    newDate = "Spring 66";
  } else if (toConvert === "May 9") {
    newDate = "Spring 67";
  } else if (toConvert === "May 10") {
    newDate = "Spring 68";
  } else if (toConvert === "May 11") {
    newDate = "Spring 69";
  } else if (toConvert === "May 12") {
    newDate = "Spring 70";
  } else if (toConvert === "May 13") {
    newDate = "Spring 71";
  } else if (toConvert === "May 14") {
    newDate = "Spring 72";
  } else if (toConvert === "May 15") {
    newDate = "Spring 73";
  } else if (toConvert === "May 16") {
    newDate = "Summer 1";
  } else if (toConvert === "May 17") {
    newDate = "Summer 2";
  } else if (toConvert === "May 18") {
    newDate = "Summer 3";
  } else if (toConvert === "May 19") {
    newDate = "Summer 4";
  } else if (toConvert === "May 20") {
    newDate = "Summer 5";
  } else if (toConvert === "May 21") {
    newDate = "Summer 6";
  } else if (toConvert === "May 22") {
    newDate = "Summer 7";
  } else if (toConvert === "May 23") {
    newDate = "Summer 8";
  } else if (toConvert === "May 24") {
    newDate = "Summer 9";
  } else if (toConvert === "May 25") {
    newDate = "Summer 10";
  } else if (toConvert === "May 26") {
    newDate = "Summer 11";
  } else if (toConvert === "May 27") {
    newDate = "Summer 12";
  } else if (toConvert === "May 28") {
    newDate = "Summer 13";
  } else if (toConvert === "May 29") {
    newDate = "Summer 14";
  } else if (toConvert === "May 30") {
    newDate = "Summer 15";
  } else if (toConvert === "May 31") {
    newDate = "Summer 16";
  } else if (toConvert === "Jun 1") {
    newDate = "Summer 17";
  } else if (toConvert === "Jun 2") {
    newDate = "Summer 18";
  } else if (toConvert === "Jun 3") {
    newDate = "Summer 19";
  } else if (toConvert === "Jun 4") {
    newDate = "Summer 20";
  } else if (toConvert === "Jun 5") {
    newDate = "Summer 21";
  } else if (toConvert === "Jun 6") {
    newDate = "Summer 22";
  } else if (toConvert === "Jun 7") {
    newDate = "Summer 23";
  } else if (toConvert === "Jun 8") {
    newDate = "Summer 24";
  } else if (toConvert === "Jun 9") {
    newDate = "Summer 25";
  } else if (toConvert === "Jun 10") {
    newDate = "Summer 26";
  } else if (toConvert === "Jun 11") {
    newDate = "Summer 27";
  } else if (toConvert === "Jun 12") {
    newDate = "Summer 28";
  } else if (toConvert === "Jun 13") {
    newDate = "Summer 29";
  } else if (toConvert === "Jun 14") {
    newDate = "Summer 30";
  } else if (toConvert === "Jun 15") {
    newDate = "Summer 31";
  } else if (toConvert === "Jun 16") {
    newDate = "Summer 32";
  } else if (toConvert === "Jun 17") {
    newDate = "Summer 33";
  } else if (toConvert === "Jun 18") {
    newDate = "Summer 34";
  } else if (toConvert === "Jun 19") {
    newDate = "Summer 35";
  } else if (toConvert === "Jun 20") {
    newDate = "Summer 36";
  } else if (toConvert === "Jun 21") {
    newDate = "Summer 37";
  } else if (toConvert === "Jun 22") {
    newDate = "Summer 38";
  } else if (toConvert === "Jun 23") {
    newDate = "Summer 39";
  } else if (toConvert === "Jun 24") {
    newDate = "Summer 40";
  } else if (toConvert === "Jun 25") {
    newDate = "Summer 41";
  } else if (toConvert === "Jun 26") {
    newDate = "Summer 42";
  } else if (toConvert === "Jun 27") {
    newDate = "Summer 43";
  } else if (toConvert === "Jun 28") {
    newDate = "Summer 44";
  } else if (toConvert === "Jun 29") {
    newDate = "Summer 45";
  } else if (toConvert === "Jun 30") {
    newDate = "Summer 46";
  } else if (toConvert === "Jul 1") {
    newDate = "Summer 47";
  } else if (toConvert === "Jul 2") {
    newDate = "Summer 48";
  } else if (toConvert === "Jul 3") {
    newDate = "Summer 49";
  } else if (toConvert === "Jul 4") {
    newDate = "Summer 50";
  } else if (toConvert === "Jul 5") {
    newDate = "Summer 51";
  } else if (toConvert === "Jul 6") {
    newDate = "Summer 52";
  } else if (toConvert === "Jul 7") {
    newDate = "Summer 53";
  } else if (toConvert === "Jul 8") {
    newDate = "Summer 54";
  } else if (toConvert === "Jul 9") {
    newDate = "Summer 55";
  } else if (toConvert === "Jul 10") {
    newDate = "Summer 56";
  } else if (toConvert === "Jul 11") {
    newDate = "Summer 57";
  } else if (toConvert === "Jul 12") {
    newDate = "Summer 58";
  } else if (toConvert === "Jul 13") {
    newDate = "Summer 59";
  } else if (toConvert === "Jul 14") {
    newDate = "Summer 60";
  } else if (toConvert === "Jul 15") {
    newDate = "Summer 61";
  } else if (toConvert === "Jul 16") {
    newDate = "Summer 62";
  } else if (toConvert === "Jul 17") {
    newDate = "Summer 63";
  } else if (toConvert === "Jul 18") {
    newDate = "Summer 64";
  } else if (toConvert === "Jul 19") {
    newDate = "Summer 65";
  } else if (toConvert === "Jul 20") {
    newDate = "Summer 66";
  } else if (toConvert === "Jul 21") {
    newDate = "Summer 67";
  } else if (toConvert === "Jul 22") {
    newDate = "Summer 68";
  } else if (toConvert === "Jul 23") {
    newDate = "Summer 69";
  } else if (toConvert === "Jul 24") {
    newDate = "Summer 70";
  } else if (toConvert === "Jul 25") {
    newDate = "Summer 71";
  } else if (toConvert === "Jul 26") {
    newDate = "Summer 72";
  } else if (toConvert === "Jul 27") {
    newDate = "Summer 73";
  } else if (toConvert === "Jul 28") {
    newDate = "Autumn 1";
  } else if (toConvert === "Jul 29") {
    newDate = "Autumn 2";
  } else if (toConvert === "Jul 30") {
    newDate = "Autumn 3";
  } else if (toConvert === "Jul 31") {
    newDate = "Autumn 4";
  } else if (toConvert === "Aug 1") {
    newDate = "Autumn 5";
  } else if (toConvert === "Aug 2") {
    newDate = "Autumn 6";
  } else if (toConvert === "Aug 3") {
    newDate = "Autumn 7";
  } else if (toConvert === "Aug 4") {
    newDate = "Autumn 8";
  } else if (toConvert === "Aug 5") {
    newDate = "Autumn 9";
  } else if (toConvert === "Aug 6") {
    newDate = "Autumn 10";
  } else if (toConvert === "Aug 7") {
    newDate = "Autumn 11";
  } else if (toConvert === "Aug 8") {
    newDate = "Autumn 12";
  } else if (toConvert === "Aug 9") {
    newDate = "Autumn 13";
  } else if (toConvert === "Aug 10") {
    newDate = "Autumn 14";
  } else if (toConvert === "Aug 11") {
    newDate = "Autumn 15";
  } else if (toConvert === "Aug 12") {
    newDate = "Autumn 16";
  } else if (toConvert === "Aug 13") {
    newDate = "Autumn 17";
  } else if (toConvert === "Aug 14") {
    newDate = "Autumn 18";
  } else if (toConvert === "Aug 15") {
    newDate = "Autumn 19";
  } else if (toConvert === "Aug 16") {
    newDate = "Autumn 20";
  } else if (toConvert === "Aug 17") {
    newDate = "Autumn 21";
  } else if (toConvert === "Aug 18") {
    newDate = "Autumn 22";
  } else if (toConvert === "Aug 19") {
    newDate = "Autumn 23";
  } else if (toConvert === "Aug 20") {
    newDate = "Autumn 24";
  } else if (toConvert === "Aug 21") {
    newDate = "Autumn 25";
  } else if (toConvert === "Aug 22") {
    newDate = "Autumn 26";
  } else if (toConvert === "Aug 23") {
    newDate = "Autumn 27";
  } else if (toConvert === "Aug 24") {
    newDate = "Autumn 28";
  } else if (toConvert === "Aug 25") {
    newDate = "Autumn 29";
  } else if (toConvert === "Aug 26") {
    newDate = "Autumn 30";
  } else if (toConvert === "Aug 27") {
    newDate = "Autumn 31";
  } else if (toConvert === "Aug 28") {
    newDate = "Autumn 32";
  } else if (toConvert === "Aug 29") {
    newDate = "Autumn 33";
  } else if (toConvert === "Aug 30") {
    newDate = "Autumn 34";
  } else if (toConvert === "Aug 31") {
    newDate = "Autumn 35";
  } else if (toConvert === "Sep 1") {
    newDate = "Autumn 36";
  } else if (toConvert === "Sep 2") {
    newDate = "Autumn 37";
  } else if (toConvert === "Sep 3") {
    newDate = "Autumn 38";
  } else if (toConvert === "Sep 4") {
    newDate = "Autumn 39";
  } else if (toConvert === "Sep 5") {
    newDate = "Autumn 40";
  } else if (toConvert === "Sep 6") {
    newDate = "Autumn 41";
  } else if (toConvert === "Sep 7") {
    newDate = "Autumn 42";
  } else if (toConvert === "Sep 8") {
    newDate = "Autumn 43";
  } else if (toConvert === "Sep 9") {
    newDate = "Autumn 44";
  } else if (toConvert === "Sep 10") {
    newDate = "Autumn 45";
  } else if (toConvert === "Sep 11") {
    newDate = "Autumn 46";
  } else if (toConvert === "Sep 12") {
    newDate = "Autumn 47";
  } else if (toConvert === "Sep 13") {
    newDate = "Autumn 48";
  } else if (toConvert === "Sep 14") {
    newDate = "Autumn 49";
  } else if (toConvert === "Sep 15") {
    newDate = "Autumn 50";
  } else if (toConvert === "Sep 16") {
    newDate = "Autumn 51";
  } else if (toConvert === "Sep 17") {
    newDate = "Autumn 52";
  } else if (toConvert === "Sep 18") {
    newDate = "Autumn 53";
  } else if (toConvert === "Sep 19") {
    newDate = "Autumn 54";
  } else if (toConvert === "Sep 20") {
    newDate = "Autumn 55";
  } else if (toConvert === "Sep 21") {
    newDate = "Autumn 56";
  } else if (toConvert === "Sep 22") {
    newDate = "Autumn 57";
  } else if (toConvert === "Sep 23") {
    newDate = "Autumn 58";
  } else if (toConvert === "Sep 24") {
    newDate = "Autumn 59";
  } else if (toConvert === "Sep 25") {
    newDate = "Autumn 60";
  } else if (toConvert === "Sep 26") {
    newDate = "Autumn 61";
  } else if (toConvert === "Sep 27") {
    newDate = "Autumn 62";
  } else if (toConvert === "Sep 28") {
    newDate = "Autumn 63";
  } else if (toConvert === "Sep 29") {
    newDate = "Autumn 64";
  } else if (toConvert === "Sep 30") {
    newDate = "Autumn 65";
  } else if (toConvert === "Oct 1") {
    newDate = "Autumn 66";
  } else if (toConvert === "Oct 2") {
    newDate = "Autumn 67";
  } else if (toConvert === "Oct 3") {
    newDate = "Autumn 68";
  } else if (toConvert === "Oct 4") {
    newDate = "Autumn 69";
  } else if (toConvert === "Oct 5") {
    newDate = "Autumn 70";
  } else if (toConvert === "Oct 6") {
    newDate = "Autumn 71";
  } else if (toConvert === "Oct 7") {
    newDate = "Autumn 72";
  } else if (toConvert === "Oct 8") {
    newDate = "Autumn 73";
  } else if (toConvert === "Oct 9") {
    newDate = "Fall 1";
  } else if (toConvert === "Oct 10") {
    newDate = "Fall 2";
  } else if (toConvert === "Oct 11") {
    newDate = "Fall 3";
  } else if (toConvert === "Oct 12") {
    newDate = "Fall 4";
  } else if (toConvert === "Oct 13") {
    newDate = "Fall 5";
  } else if (toConvert === "Oct 14") {
    newDate = "Fall 6";
  } else if (toConvert === "Oct 15") {
    newDate = "Fall 7";
  } else if (toConvert === "Oct 16") {
    newDate = "Fall 8";
  } else if (toConvert === "Oct 17") {
    newDate = "Fall 9";
  } else if (toConvert === "Oct 18") {
    newDate = "Fall 10";
  } else if (toConvert === "Oct 19") {
    newDate = "Fall 11";
  } else if (toConvert === "Oct 20") {
    newDate = "Fall 12";
  } else if (toConvert === "Oct 21") {
    newDate = "Fall 13";
  } else if (toConvert === "Oct 22") {
    newDate = "Fall 14";
  } else if (toConvert === "Oct 23") {
    newDate = "Fall 15";
  } else if (toConvert === "Oct 24") {
    newDate = "Fall 16";
  } else if (toConvert === "Oct 25") {
    newDate = "Fall 17";
  } else if (toConvert === "Oct 26") {
    newDate = "Fall 18";
  } else if (toConvert === "Oct 27") {
    newDate = "Fall 19";
  } else if (toConvert === "Oct 28") {
    newDate = "Fall 20";
  } else if (toConvert === "Oct 29") {
    newDate = "Fall 21";
  } else if (toConvert === "Oct 30") {
    newDate = "Fall 22";
  } else if (toConvert === "Oct 31") {
    newDate = "Fall 23";
  } else if (toConvert === "Nov 1") {
    newDate = "Fall 24";
  } else if (toConvert === "Nov 2") {
    newDate = "Fall 25";
  } else if (toConvert === "Nov 3") {
    newDate = "Fall 26";
  } else if (toConvert === "Nov 4") {
    newDate = "Fall 27";
  } else if (toConvert === "Nov 5") {
    newDate = "Fall 28";
  } else if (toConvert === "Nov 6") {
    newDate = "Fall 29";
  } else if (toConvert === "Nov 7") {
    newDate = "Fall 30";
  } else if (toConvert === "Nov 8") {
    newDate = "Fall 31";
  } else if (toConvert === "Nov 9") {
    newDate = "Fall 32";
  } else if (toConvert === "Nov 10") {
    newDate = "Fall 33";
  } else if (toConvert === "Nov 11") {
    newDate = "Fall 34";
  } else if (toConvert === "Nov 12") {
    newDate = "Fall 35";
  } else if (toConvert === "Nov 13") {
    newDate = "Fall 36";
  } else if (toConvert === "Nov 14") {
    newDate = "Fall 37";
  } else if (toConvert === "Nov 15") {
    newDate = "Fall 38";
  } else if (toConvert === "Nov 16") {
    newDate = "Fall 39";
  } else if (toConvert === "Nov 17") {
    newDate = "Fall 40";
  } else if (toConvert === "Nov 18") {
    newDate = "Fall 41";
  } else if (toConvert === "Nov 19") {
    newDate = "Fall 42";
  } else if (toConvert === "Nov 20") {
    newDate = "Fall 43";
  } else if (toConvert === "Nov 21") {
    newDate = "Fall 44";
  } else if (toConvert === "Nov 22") {
    newDate = "Fall 45";
  } else if (toConvert === "Nov 23") {
    newDate = "Fall 46";
  } else if (toConvert === "Nov 24") {
    newDate = "Fall 47";
  } else if (toConvert === "Nov 25") {
    newDate = "Fall 48";
  } else if (toConvert === "Nov 26") {
    newDate = "Fall 49";
  } else if (toConvert === "Nov 27") {
    newDate = "Fall 50";
  } else if (toConvert === "Nov 28") {
    newDate = "Fall 51";
  } else if (toConvert === "Nov 29") {
    newDate = "Fall 52";
  } else if (toConvert === "Nov 30") {
    newDate = "Fall 53";
  } else if (toConvert === "Dec 1") {
    newDate = "Fall 54";
  } else if (toConvert === "Dec 2") {
    newDate = "Fall 55";
  } else if (toConvert === "Dec 3") {
    newDate = "Fall 56";
  } else if (toConvert === "Dec 4") {
    newDate = "Fall 57";
  } else if (toConvert === "Dec 5") {
    newDate = "Fall 58";
  } else if (toConvert === "Dec 6") {
    newDate = "Fall 59";
  } else if (toConvert === "Dec 7") {
    newDate = "Fall 60";
  } else if (toConvert === "Dec 8") {
    newDate = "Fall 61";
  } else if (toConvert === "Dec 9") {
    newDate = "Fall 62";
  } else if (toConvert === "Dec 10") {
    newDate = "Fall 63";
  } else if (toConvert === "Dec 11") {
    newDate = "Fall 64";
  } else if (toConvert === "Dec 12") {
    newDate = "Fall 65";
  } else if (toConvert === "Dec 13") {
    newDate = "Fall 66";
  } else if (toConvert === "Dec 14") {
    newDate = "Fall 67";
  } else if (toConvert === "Dec 15") {
    newDate = "Fall 68";
  } else if (toConvert === "Dec 16") {
    newDate = "Fall 69";
  } else if (toConvert === "Dec 17") {
    newDate = "Fall 70";
  } else if (toConvert === "Dec 18") {
    newDate = "Fall 71";
  } else if (toConvert === "Dec 19") {
    newDate = "Fall 72";
  } else if (toConvert === "Dec 20") {
    newDate = "Fall 73";
  }

  return newDate;
};
export default calculator;
