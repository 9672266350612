import React, { useEffect, useState } from "react";
import RabbitHole from "./RabbitHole";
import Time from "./Time";
import AFeature from "../components/newdev/AFeature";
import BFeature from "../components/newdev/BFeature";
import SaleButtons from "../components/newdev/SaleButtons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Home = () => {
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(seconds + 1);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  });

  const gregorian = new Date();
  let clockSeconds = gregorian.getSeconds();
  const secondsCircleStyle = {
    transform: `rotate(${clockSeconds * -6}deg)`,
    width: "40%",
    position: "relative",
    top: "-15%",
    left: "30%",
    backgroundColor: "transparent",
  };
  const history = useHistory();

  const shoppingRoute = (event) => {
    history.push(`/shop`);
    console.log("its clicked");
  };
  const appRoute = (event) => {
    history.push(`/newcalendar_mobileapp`);
  };
  return (
    <div className="center">
      {/* Hero shot */}
      <section className="hero_" style={{}}>
        <div className="heroDiv" style={{}}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "55%",
              backgroundColor: "transparent",
              alignItems: "center",
            }}
          >
            <h1
              style={{
                backgroundColor: "transparent",
                fontSize: "4rem",
                margin: "1%",
                padding: "1%",
                textAlign: "center",
              }}
            >
              The New Calendar
            </h1>
            <h2 style={{ backgroundColor: "transparent", textAlign: "center" }}>
              The best calendar in the history of time
            </h2>
            {/* <h3 style={{ backgroundColor: "transparent", textAlign: "center" }}>
              Unlock your time
            </h3> */}
            <SaleButtons
              buttonA={"Get a Copy"}
              shoppingRoute={shoppingRoute}
              appRoute={appRoute}
              buttonB={"pre-order mobile app"}
            />
          </div>
        </div>
      </section>
      <section className="mediabar_">
        <div className="mediabar" style={{}}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h3 style={{ fontSize: "2rem", margin: "0" }}>Featured on:</h3>
          </div>
          <div className="media_logos" style={{}}>
            <div
              className="medialogoicon"
              style={{
                backgroundImage: `url("https://res.cloudinary.com/dqy3fktlv/image/upload/v1700598946/New%20Calendar/bpbdyc2wnebxupwlkyss.jpg")`,
              }}
            ></div>
            <div
              className="medialogoicon"
              style={{
                backgroundImage: `url("https://res.cloudinary.com/dqy3fktlv/image/upload/v1700598765/New%20Calendar/vr8k31urxb0npsh5022b.png")`,
              }}
            ></div>
            <div
              className="medialogoicon"
              style={{
                backgroundImage: `url("https://res.cloudinary.com/dqy3fktlv/image/upload/v1700598753/New%20Calendar/evntcoe4y9cuvrxzxqxt.png")`,
              }}
            ></div>
            <div
              className="medialogoicon"
              style={{
                backgroundImage: `url("https://res.cloudinary.com/dqy3fktlv/image/upload/v1700599773/New%20Calendar/l7erdymgqadij3kvaube.png")`,
              }}
            ></div>
            <div
              className="medialogoicon"
              style={{
                backgroundImage: `url("https://res.cloudinary.com/dqy3fktlv/image/upload/v1700599973/New%20Calendar/q0gvoqs0kibatcd1ynov.png")`,
              }}
            ></div>
            <div
              className="medialogoicon"
              style={{
                backgroundImage: `url("https://res.cloudinary.com/dqy3fktlv/image/upload/v1700598759/New%20Calendar/ibe4nn7kgz1f1m3eflaj.webp")`,
              }}
            ></div>
          </div>
        </div>
      </section>
      <section
        className="featureA"
        style={{ display: "flex", flexDirection: "column", width: "100%" }}
      >
        <>
          <div
            className="featureAnimation_copywriting"
            style={{
              // width: "50%",
              // backgroundPosition: "center bottom",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "transparent",
              backgroundImage: `url("https://images-assets.nasa.gov/image/PIA03654/PIA03654~medium.jpg")`,
              backgroundSize: "cover",
              // height: "50vh",
            }}
          >
            <div
              className="a_copy_container"
              style={{
                // display: "flex",
                // flexDirection: "column",
                // alignItems: "center",
                backgroundColor: "transparent",
              }}
            >
              <h2
                style={{
                  backgroundColor: "transparent",
                  margin: "0% 0% 0% 0%",
                  padding: "0%",
                  color: "whitesmoke",
                  textAlign: "center",
                }}
              >
                A calendar that lasts forever
              </h2>
              <h4
                style={{
                  backgroundColor: "transparent",
                  textAlign: "center",
                  // padding: "0%",
                  // margin: "0",
                  color: "whitesmoke",
                }}
              >
                The New Calendar unravels the secrets of time and space to
                reveal a timekeeping mechanism that will be operational from the
                moment you get it until 9999.{" "}
              </h4>
            </div>
            <div
              className="hero"
              style={{
                margin: "1%",
                padding: "3%",
                backgroundColor: "transparent",
              }}
            >
              <img
                src="https://res.cloudinary.com/dqy3fktlv/image/upload/v1701098907/seasongeometry_izizge.png"
                alt=""
                style={secondsCircleStyle}
              />
            </div>
            <SaleButtons
              buttonA={"Explore the Shop"}
              shoppingRoute={shoppingRoute}
              appRoute={appRoute}
              buttonB={"pre-order mobile app"}
            />
          </div>
        </>
      </section>
      <section className="featureB" style={{ width: "100%" }}>
        <BFeature
          tagline={"Discover the 5th Season"}
          description={
            "Our scientists put the calendar under a microscope to understand what exactly time is and figure out how to engineer a calendar that works for us instead of against us. As a result, The New Calendar is built to divide the year into its true natural units of 5 seasons."
          }
          featureImage={
            "https://res.cloudinary.com/dqy3fktlv/image/upload/v1700665978/EKJClknWsAEevFk_xvgfxo.jpg"
          }
          leftImage={
            "https://res.cloudinary.com/dqy3fktlv/image/upload/v1701101919/analysis-2030261_640_mhwkfp.jpg"
          }
        />
      </section>
      <section
        className="featureA"
        style={{ display: "flex", flexDirection: "column", width: "100%" }}
      >
        <AFeature
          tagline={"Evergreen Scheduler"}
          description={
            "The New Calendar divides time into its natural, organic units for the first time in the history of time. Not only did our scientists discover the 5th season of the year, but also developed and implemented new units of time for users to better plan, track, and divide their months."
          }
          backgroundImg={
            "https://res.cloudinary.com/dqy3fktlv/image/upload/v1700618107/New%20Calendar/wnxes17kgb6gyygmk8uw.jpg"
          }
        />
      </section>
      <section className="featureB">
        <BFeature
          tagline={"100% Made in USA"}
          description={
            "The New Calendar is designed, engineered, and manufactured in the United States of America. It's because of this American engineering and quality that we can guarantee the calendar will work from 2019-9999 and beyond."
          }
          featureImage={
            "https://res.cloudinary.com/dqy3fktlv/image/upload/v1700669266/IMG_3243_arsv3q.jpg"
          }
          leftImage={
            "https://res.cloudinary.com/dqy3fktlv/image/upload/v1701101591/engineer-4922413_640_myb8a2.jpg"
          }
        />
      </section>
      <section
        className="featureA"
        style={{ display: "flex", flexDirection: "column", width: "100%" }}
      >
        <AFeature
          tagline={"Control the sands of time"}
          description={
            "The New Calendar is designed to allow its users full control of their most precious resource: time. With the units designed to work together seamlessly, users can plan, track, and divide their time with ease."
          }
          backgroundImg={
            "https://res.cloudinary.com/dqy3fktlv/image/upload/v1700715795/last_sand2_k0ayeo.jpg"
          }
        />
      </section>
      <section className="featureB">
        <BFeature
          tagline={"Precise, Accurate, and Reliable"}
          description={
            "The New Calendar ends the madness of days and dates slowly shifting throughout time, its like clockwork, but better."
          }
          featureImage={
            "https://res.cloudinary.com/dqy3fktlv/image/upload/v1700665987/IMG_0572_iqp4jb.jpg"
          }
          leftImage={
            "https://res.cloudinary.com/dqy3fktlv/image/upload/v1701100458/clock-2050857_1280_edqumn.jpg"
          }
        />
      </section>
      <section
        className="featureA"
        style={{ display: "flex", flexDirection: "column", width: "100%" }}
      >
        <AFeature
          tagline={"Get in tune with nature"}
          description={
            "Believe it or not, fish, birds, and animals don't use the Gregorian Calendar. What they do use is natural seasonal patterns to guide their lives. The New Calendar helps track those patterns within and between years, and comes with other helpful features like monthly sunlight and temperature charts for multiple latitudes across the US."
          }
          backgroundImg={
            "https://res.cloudinary.com/dqy3fktlv/image/upload/v1700618095/New%20Calendar/jkhvldmaxsgwkz9q4lgo.jpg"
          }
        />
      </section>
      <section className="featureB">
        <BFeature
          tagline={"This time, it's different."}
          description={
            "Do you want to escape society? Are you stuck in a rut? Are you looking to make a change? The New Calendar is the perfect way to start fresh and get your life back on track. Escape the traps of the Gregorian Calendar and start living your life the way you want to."
          }
          featureImage={
            "https://res.cloudinary.com/dqy3fktlv/image/upload/v1700667501/IMG_3228_fcirbp.jpg"
          }
          leftImage={
            "https://res.cloudinary.com/dqy3fktlv/image/upload/v1701100458/clock-2050857_1280_edqumn.jpg"
          }
        />
      </section>
      {/* <section className="reviews">
        <div
          className="review_container"
          style={{ display: "flex", justifyContent: "space-around" }}
        >
          <div
            className="reviewA"
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "1%",
              margin: "1%",
              flex: 1,
              justifyContent: "center",
            }}
          >
            <p>"The best thing since the written alphabet."</p>
            <p style={{ display: "flex", justifyContent: "end" }}>
              - Tom Clarke
            </p>
          </div>
          <div
            className="reviewA"
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "1%",
              margin: "1%",
              flex: 1,
              justifyContent: "center",
            }}
          >
            <p>
              "The New Calendar changed my life. I finally escaped the clutches
              of the Gregorian Calendar and can live freely. Thank you!"
            </p>
            <p style={{ display: "flex", justifyContent: "end" }}>
              - Vince Lombardi
            </p>
          </div>
          <div
            className="reviewA"
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "1%",
              margin: "1%",
              flex: 1,
              justifyContent: "center",
            }}
          >
            <p>"Timing is everything and to me it seems to be the key."</p>
            <p style={{ display: "flex", justifyContent: "end" }}>- Eminem</p>
          </div>
        </div>
      </section> */}

      {/* <div className="hero">
        <img
          src="https://res.cloudinary.com/dzom9ahsn/image/upload/v1693941910/cleaner_logo_dwmwkv.png"
          alt=""
          style={secondsCircleStyle}
        />
      </div> */}
      {/* <h2>This time, it's different.</h2>
      <p>
        The New Calendar is the revolutionary new timekeeping system you've
        heard about. This time is the first time in the history of time we've
        divided time evenly using math, science, and logic.
      </p> */}
      {/* Choose your own adventure squares */}
      {/* <section className="timerabbithole">
        <Time />
        <RabbitHole />
      </section> */}
    </div>
  );
};

export default Home;
