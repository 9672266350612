import React from "react";

const BFeature = (props) => {
  const copy = props;
  return (
    <>
      <div className="featureB_copywriting" style={{}}>
        <div
          className="b_copy_container"
          style={{
            backgroundImage: `url("${copy.leftImage}")`,
            backgroundSize: "cover",
          }}
        >
          <div
            className="bCopy"
            style={{
              width: "80%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "transparent",
            }}
          >
            <h2
              style={{
                width: "80%",
                padding: "1%",
                margin: "1%",
                // display: "flex",
                // flexDirection: "column",
                // alignItems: "center",
                // justifyContent: "center",
                textAlign: "center",
              }}
            >
              {copy.tagline}
            </h2>
            <h3
              style={{
                width: "80%",
                padding: "1%",
                margin: "1%",
                // display: "flex",
                // flexDirection: "column",
                // alignItems: "center",
                // justifyContent: "center",
                textAlign: "center",
              }}
            >
              {copy.description}
            </h3>
          </div>
        </div>
        <div
          className="b_feature_image"
          style={{
            backgroundImage: `url("${copy.featureImage}")`,
          }}
        ></div>
      </div>
    </>
  );
};

export default BFeature;
