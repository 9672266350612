// import { useParams } from "react-router-dom/cjs/react-router-dom.min";
// import useFetch from "../hooks/useFetch";

import { useState } from "react";
import useScrollToTop from "../blocks/logic/usScrollToTop";
import ProductCompOne from "../components/ProductCompOne";
// import PayPalButtonComponent from "./products/PayPalSubscribeBuy";
import PayPalOneTimeButton from "./products/PayPalOneTimeButton";
import PayPalClassicBuy from "./products/PayPalClassicBuy";

const ProductPage = ({ name, slug, image, description, price }) => {
  useScrollToTop();

  const [selectedAmount, setSelectedAmount] = useState("");
  const [priced, setPrice] = useState(price);
  //   const { id } = useParams();
  //   const { data: product, error, isLoading } = useFetch(`products/${id}`);

  //   if (isLoading) return <div>Loading...</div>;
  //   if (error) return <div>{error}</div>;
  const handleSelectChange = (event) => {
    setSelectedAmount(event.target.value);
    setPrice(event.target.value * price);
  };
  const newCalc = () => {
    setPrice(selectedAmount * priced);
  };
  // console.log(price, selectedAmount, totalAmount);
  return (
    <>
      <div>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <h1>{name}</h1>
          <h2>${price}</h2>
        </div>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <p style={{ margin: "1%" }}>{description}</p>
          </div>
          <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
            {/* <ProductCompOne /> */}
            {/* subscription button */}
            <div className="amountSelector">
              <label htmlFor="quantity">Select Quantity: </label>
              <select
                id="quantity"
                value={selectedAmount}
                onChange={handleSelectChange}
              >
                <option value="0">Select</option>
                <option value="1">1</option>
                <option value="3">3</option>
                <option value="5">5</option>
              </select>

              {selectedAmount && <p>You selected: {selectedAmount * price}</p>}
            </div>
            {/* <PayPalButtonComponent /> */}
            {/* single payment */}
            <PayPalClassicBuy
              price={price}
              amount={parseInt(selectedAmount)}
              // newCalc={newCalc}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductPage;
