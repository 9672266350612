import React from "react";
import SingleCTAButton from "./SingleCTAButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ShopCard = ({ name, image, description, slug }) => {
  const history = useHistory();

  const productCardRoute = (event) => {
    history.push(`/${slug}`);
  };
  return (
    <>
      <div
        className="shopCard"
        style={{
          backgroundImage: `url(${image})`,
        }}
      >
        <h3 className="shopCardName" style={{}}>
          {name}
        </h3>
        {/* </div> */}
        <p
          style={{
            backgroundColor: "whitesmoke",
            border: "1px solid black",
            width: "60%",
            display: "flex",
            justifyContent: "center",
            padding: "1%",
          }}
        >
          {description}
        </p>
        <div style={{}} className="shopCardButtons">
          <SingleCTAButton
            buttonA={"Learn More"}
            name={name}
            productCardRoute={productCardRoute}
          />
          <SingleCTAButton
            buttonA={"Buy Now"}
            productCardRoute={productCardRoute}
          />
        </div>
      </div>
    </>
  );
};

export default ShopCard;
