import colorCode from "../blocks/logic/colorCode";

const Enterprise = () => {
  let buttonColor = colorCode(["button"]);

  const sendEmail = (event) => {
    const email = "tom@thenewcalendar.com";
    const subject = "New Calendar Enterprise Solutions";

    const mailto = `mailto:${email}?subject=${subject}`;

    window.location.href = mailto;
  };
  return (
    <>
      <section className="enterprise-solutions">
        <h1>Unlock Enterprise Success with The New Calendar</h1>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p>
            This revolutionary timekeeping system combines the power of precise,
            accurate, and consistent organizational management with a unique,
            eye-catching, client-friendly interface, creating an organic,
            social, grassroots marketing campaign. Learn how you can get time on
            your side with The New Calendar.
          </p>
          <img
            src="https://res.cloudinary.com/dzom9ahsn/image/upload/v1694097205/Distributablescrop_nww3og.png"
            alt="enterprise"
            style={{ width: "80%" }}
          />
        </div>
        <div className="why-time-matters">
          <h2>Why Time Management Matters for Your Company</h2>
          <ul>
            <li>
              Efficiently allocate your valuable resources – from personnel to
              equipment – with precision, ensuring every asset is utilized to
              its maximum potential.
            </li>
            <li>
              Elevate your team's output through intelligent time management.
              Achieve more in less time and boost your enterprise's overall
              performance.
            </li>
            <li>
              Meet critical project deadlines consistently. Avoid missed
              opportunities, client dissatisfaction, and costly penalties.
            </li>
            <li>
              Streamline operations, minimize downtime, and reduce operational
              costs. Make your enterprise more financially resilient.
            </li>
            <li>
              Deliver products and services on time, building trust, and
              fostering long-term customer loyalty.
            </li>
          </ul>
        </div>

        <div className="competitive-edge">
          <h2>Gain the Competitive Edge</h2>
          <p>
            In today's fast-paced business landscape, agility and adaptability
            are paramount. The New Calendar can provide your enterprise with a
            competitive advantage by:
          </p>
          <ul>
            <li>Enabling rapid responses to market changes.</li>
            <li>Facilitating innovation and product development.</li>
            <li>Supporting strategic planning for future success.</li>
          </ul>
        </div>

        <div className="empower-workforce">
          <h2>Empower Your Workforce</h2>
          <p>
            Effective time management isn't just about processes; it's about
            people too. The New Calendar promotes:
          </p>
          <ul>
            <li>Employee engagement and motivation.</li>
            <li>A healthier work-life balance.</li>
            <li>
              Reduced stress, leading to a happier, more productive workforce.
            </li>
          </ul>
        </div>

        <div className="compliance-info">
          <h2>Stay informed to make key decisions</h2>
          <p>
            Stay at the forefront of industry trends and insights by maintaining
            accurate timekeeping and harnessing the power of data analysis. The
            New Calendar empowers you to make informed decisions, proactively
            manage risks, and optimize financial strategies. Be equipped to
            navigate the ever-evolving business landscape with confidence.
          </p>
        </div>

        <div className="cta-section">
          <h3>Discover how The New Calendar can transform your enterprise</h3>
          <p>
            Unlock the full potential of your enterprise with the New Calendar.
            See why businesses across the country, from farms in Arkansas to
            software firms in New York City have decided to switch their
            operations who have harnessed the power of The New Calendar to drive
            growth and success.
          </p>
          <div
            className={buttonColor}
            style={{ textAlign: "center", border: "1px solid black" }}
            onClick={sendEmail}
          >
            Contact tom@newcalendar.com for more info
          </div>
        </div>
      </section>
    </>
  );
};
export default Enterprise;
