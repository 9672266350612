import { useHistory } from "react-router-dom";
import colorCode from "../blocks/logic/colorCode";

const RabbitHole = () => {
  const history = useHistory();
  let seasonColor = colorCode(["homeSquareText"]);

  const moneyRoute = (event) => {
    history.push(`/rabbithole/money`);
  };
  const measurementRoute = (event) => {
    history.push(`/rabbithole/measurement`);
  };
  const mysteryRoute = (event) => {
    history.push(`/rabbithole/mystery`);
  };
  const confusingRoute = (event) => {
    history.push(`/rabbithole/confusing`);
  };
  const preciousResourceRoute = (event) => {
    history.push(`/rabbithole/preciousResource`);
  };
  const frequencyRoute = (event) => {
    history.push(`/rabbithole/frequency`);
  };
  const humanConstructionRoute = (event) => {
    history.push(`/rabbithole/humanConstruction`);
  };
  const flatCircleRoute = (event) => {
    history.push(`/rabbithole/flatCircle`);
  };
  const toolRoute = (event) => {
    history.push(`/rabbithole/tool`);
  };
  const dimensionRoute = (event) => {
    history.push(`/rabbithole/dimension`);
  };
  const scienceRoute = (event) => {
    history.push(`/rabbithole/science`);
  };
  const everythingRoute = (event) => {
    history.push(`/rabbithole/everything`);
  };
  const scamRoute = (event) => {
    history.push(`/rabbithole/scam`);
  };
  const flyingRoute = (event) => {
    history.push(`/rabbithole/flying`);
  };
  const natureRoute = (event) => {
    history.push(`/rabbithole/nature`);
  };
  const revolutionRoute = (event) => {
    history.push(`/rabbithole/revolution`);
  };
  const rulerRoute = (event) => {
    history.push(`/rabbithole/ruler`);
  };

  return (
    <>
      {/* Choose your own adventure squares */}
      <div
        className="homeSquareContainer"
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          // padding: "5%",
          // margin: "5%",
        }}
      >
        {/* MONEY */}
        <div className="homeSquare money" onClick={moneyRoute}>
          <h3 className={seasonColor}>Time is money</h3>
        </div>

        {/* MEASURMENT */}
        <div className="homeSquare measurement" onClick={measurementRoute}>
          <h3 className={seasonColor}>Time is a measurement</h3>
        </div>

        {/* MAGIC */}
        <div className="homeSquare mystery" onClick={mysteryRoute}>
          <h3 className={seasonColor}>Time is a mystery</h3>
        </div>

        {/* MAKES NO SENSE (FAQS) */}
        <div className="homeSquare confusing" onClick={confusingRoute}>
          <h3 className={seasonColor}>Time makes no sense</h3>
        </div>

        {/* TIME IS A SCAM */}
        <div className="homeSquare scam" onClick={scamRoute}>
          <h3 className={seasonColor}>Time is a scam</h3>
        </div>

        {/* PRECIOUS RESOURCE */}
        <div
          className="homeSquare preciousResource"
          onClick={preciousResourceRoute}
        >
          <h3 className={seasonColor}>Time is a precious resource</h3>
        </div>

        {/* FREQUENCY */}
        <div className="homeSquare frequency" onClick={frequencyRoute}>
          <h3 className={seasonColor}>Time is a frequency</h3>
        </div>

        {/* HUMAN CONSTRUCTION */}
        <div
          className="homeSquare humanConstruction"
          onClick={humanConstructionRoute}
        >
          <h3 className={seasonColor}>Time is a human construction</h3>
        </div>

        {/* TIME IS A FLAT CIRCLE */}
        <div className="homeSquare flatCircle" onClick={flatCircleRoute}>
          <h3 className={seasonColor}>Time is a flat circle</h3>
        </div>

        {/* TIME IS A TOOL */}
        <div className="homeSquare tool" onClick={toolRoute}>
          <h3 className={seasonColor}>Time is a tool</h3>
        </div>

        {/* TIME IS A DIMENSION */}
        <div className="homeSquare dimension" onClick={dimensionRoute}>
          <h3 className={seasonColor}>Time is a dimension</h3>
        </div>

        {/* TIME IS A SCIENCE */}
        <div className="homeSquare science" onClick={scienceRoute}>
          <h3 className={seasonColor}>Time is a science</h3>
        </div>

        {/* TIME IS EVERYTHING */}
        <div className="homeSquare everything" onClick={everythingRoute}>
          <h3 className={seasonColor}>Time is everything</h3>
        </div>
        {/* TIME FLIES */}
        <div className="homeSquare flying" onClick={flyingRoute}>
          <h3 className={seasonColor}>Time flies</h3>
        </div>
        {/* TIME IS NATURE */}
        <div className="homeSquare nature" onClick={natureRoute}>
          <h3 className={seasonColor}>Time is nature</h3>
        </div>
        {/* TIME IS RULER */}
        <div className="homeSquare ruler" onClick={rulerRoute}>
          <h3 className={seasonColor}>Time is a ruler</h3>
        </div>
        {/* TIME IS REVOLUTION */}
        <div className="homeSquare revolution" onClick={revolutionRoute}>
          <h3 className={seasonColor}>Time is a revolution</h3>
        </div>
      </div>
    </>
  );
};
export default RabbitHole;
