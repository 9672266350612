import React, { useEffect } from "react";

const PayPalDeskpadBuy = ({ newPrice }) => {
  //   const newPrice = newCalc();
  console.log("newPrice", newPrice);
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://www.paypal.com/sdk/js?client-id=ASzlnLJha0HiNJU8jsezHLu5FaqGKJB8u9wImXSOFnl0B1Sfc-YS-pqreny9K_1XQIm4WJsfzbJbm20W";
    script.async = true;
    script.onload = () => {
      window.paypal
        .Buttons({
          style: {
            shape: "rect",
            color: "gold",
            layout: "vertical",
            label: "checkout",
          },
          createOrder: function (data, actions) {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    currency_code: "USD",
                    value: newPrice, // Set the amount you want to charge
                  },
                },
              ],
            });
          },
          onApprove: function (data, actions) {
            return actions.order.capture().then(function (details) {
              alert(
                "Transaction completed by " + details.payer.name.given_name
              );
            });
          },
          onError: function (err) {
            console.error(err);
            alert("An error occurred, please try again.");
          },
        })
        .render("#paypal-button-container"); // Renders the PayPal button
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [newPrice]);

  return (
    <div
      id="paypal-button-container"
      style={{ width: "60%", margin: "1%" }}
    ></div>
  );
};

export default PayPalDeskpadBuy;
