import React from "react";

const IconParagraph = ({
  image,
  headline,
  description,
  description2,
  color,
}) => {
  return (
    <div className="flex" style={{ backgroundColor: color }}>
      <img
        className="half icon"
        src={image}
        alt="time is measurement new calendar"
      />
      <div className="column half">
        <h3>{headline}</h3>
        <p className="">{description} </p>
        <p className="">{description2} </p>
      </div>
    </div>
  );
};

export default IconParagraph;
