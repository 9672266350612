let tobj = [
  {
    tabsOyear: 1, // 120
    tabsOseason: 1, // 24
    tabSeasonComps: [0, 24, 48, 72, 96],
    tabsOmonth: 1, // 12
    tabsOfortnight: 1, // 6
    tabsOweek: 1, // 3
    nameWithinYear: "New New Years",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "starting",
    startDayIndex: 0,
    endDayIndex: 2,
  },
  {
    tabsOyear: 2, // 120
    tabsOseason: 2, // 24
    tabSeasonComps: [1, 25, 49, 73, 97],

    tabsOmonth: 2, // 12
    tabsOfortnight: 2, // 6
    tabsOweek: 2, // 3
    nameWithinYear: "Christmas",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "middling",
    startDayIndex: 3,
    endDayIndex: 5,
  },
  {
    tabsOyear: 3, // 120
    tabsOseason: 3, // 24
    tabSeasonComps: [2, 26, 50, 74, 98],
    tabsOmonth: 3, // 12
    tabsOfortnight: 3, // 6
    tabsOweek: 3, // 3
    nameWithinYear: "Holiday lull",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "ending",
    startDayIndex: 6,
    endDayIndex: 8,
  },
  {
    tabsOyear: 4, // 120
    tabsOseason: 4, // 24
    tabSeasonComps: [3, 27, 51, 75, 99],
    tabsOmonth: 4, // 12
    tabsOfortnight: 4, // 6
    tabsOweek: 1, // 3
    nameWithinYear: "Old New Years",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "starting",
    startDayIndex: 9,
    endDayIndex: 11,
  },
  {
    tabsOyear: 5, // 120
    tabsOseason: 5, // 24
    tabSeasonComps: [4, 28, 52, 76, 100],
    tabsOmonth: 5, // 12
    tabsOfortnight: 5, // 6
    tabsOweek: 2, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "middling",
    startDayIndex: 12,
    endDayIndex: 14,
  },
  {
    tabsOyear: 6, // 120
    tabsOseason: 6, // 24
    tabSeasonComps: [5, 29, 53, 77, 101],
    tabsOmonth: 6, // 12
    tabsOfortnight: 6, // 6
    tabsOweek: 3, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "ending",
    startDayIndex: 15,
    endDayIndex: 17,
  },
  {
    tabsOyear: 7, // 120
    tabsOseason: 7, // 24
    tabSeasonComps: [6, 30, 54, 78, 102],
    tabsOmonth: 7, // 12
    tabsOfortnight: 1, // 6
    tabsOweek: 1, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "starting",
    startDayIndex: 18,
    endDayIndex: 20,
  },
  {
    tabsOyear: 8, // 120
    tabsOseason: 8, // 24
    tabSeasonComps: [7, 31, 55, 79, 103],
    tabsOmonth: 8, // 12
    tabsOfortnight: 2, // 6
    tabsOweek: 2, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "middling",
    startDayIndex: 21,
    endDayIndex: 23,
  },
  {
    tabsOyear: 9, // 120
    tabsOseason: 9, // 24
    tabSeasonComps: [8, 32, 56, 80, 104],
    tabsOmonth: 9, // 12
    tabsOfortnight: 3, // 6
    tabsOweek: 3, // 3
    nameWithinYear: "",
    nameWithinSeason: "Early",
    nameWithinMonth: "Late",
    nameWithinFortnight: "Mid",
    nameWithinWeek: "ending",
    startDayIndex: 24,
    endDayIndex: 26,
  },
  {
    tabsOyear: 10, // 120
    tabsOseason: 10, // 24
    tabSeasonComps: [9, 33, 57, 81, 105],
    tabsOmonth: 10, // 12
    tabsOfortnight: 4, // 6
    tabsOweek: 1, // 3
    nameWithinYear: "",
    nameWithinSeason: "Early",
    nameWithinMonth: "Late",
    nameWithinFortnight: "Mid",
    nameWithinWeek: "starting",
    startDayIndex: 27,
    endDayIndex: 29,
  },
  {
    tabsOyear: 11, // 120
    tabsOseason: 11, // 24
    tabSeasonComps: [10, 34, 58, 82, 106],
    tabsOmonth: 11, // 12
    tabsOfortnight: 5, // 6
    tabsOweek: 2, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "middling",
    startDayIndex: 30,
    endDayIndex: 32,
  },
  {
    tabsOyear: 12, // 120
    tabsOseason: 12, // 24
    tabSeasonComps: [11, 35, 59, 83, 107],
    tabsOmonth: 12, // 12
    tabsOfortnight: 6, // 6
    tabsOweek: 3, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "ending",
    startDayIndex: 33,
    endDayIndex: 35,
  },
  {
    tabsOyear: 13, // 120
    tabsOseason: 13, // 24
    tabSeasonComps: [12, 36, 60, 84, 108],
    tabsOmonth: 1, // 12
    tabsOfortnight: 1, // 6
    tabsOweek: 1, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "starting",
    startDayIndex: 37,
    endDayIndex: 39,
  },
  {
    tabsOyear: 14, // 120
    tabsOseason: 14, // 24
    tabSeasonComps: [13, 37, 61, 85, 109],
    tabsOmonth: 2, // 12
    tabsOfortnight: 2, // 6
    tabsOweek: 2, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "middling",
    startDayIndex: 40,
    endDayIndex: 42,
  },
  {
    tabsOyear: 15, // 120
    tabsOseason: 15, // 24
    tabSeasonComps: [14, 38, 62, 86, 110],
    tabsOmonth: 3, // 12
    tabsOfortnight: 3, // 6
    tabsOweek: 3, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "ending",
    startDayIndex: 43,
    endDayIndex: 45,
  },
  {
    tabsOyear: 16, // 120
    tabsOseason: 16, // 24
    tabSeasonComps: [15, 39, 63, 87, 111],

    tabsOmonth: 4, // 12
    tabsOfortnight: 4, // 6
    tabsOweek: 1, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "starting",
    startDayIndex: 46,
    endDayIndex: 48,
  },
  {
    tabsOyear: 17, // 120
    tabsOseason: 17, // 24
    tabSeasonComps: [16, 40, 64, 88, 112],

    tabsOmonth: 5, // 12
    tabsOfortnight: 5, // 6
    tabsOweek: 2, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "middling",
    startDayIndex: 49,
    endDayIndex: 51,
  },
  {
    tabsOyear: 18, // 120
    tabsOseason: 18, // 24
    tabSeasonComps: [17, 41, 65, 89, 113],

    tabsOmonth: 6, // 12
    tabsOfortnight: 6, // 6
    tabsOweek: 3, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "ending",
    startDayIndex: 52,
    endDayIndex: 54,
  },
  {
    tabsOyear: 19, // 120
    tabsOseason: 19, // 24
    tabSeasonComps: [18, 42, 66, 90, 114],

    tabsOmonth: 7, // 12
    tabsOfortnight: 1, // 6
    tabsOweek: 1, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "starting",
    startDayIndex: 55,
    endDayIndex: 57,
  },
  {
    tabsOyear: 20, // 120
    tabsOseason: 20, // 24
    tabSeasonComps: [19, 39, 67, 91, 115],

    tabsOmonth: 8, // 12
    tabsOfortnight: 2, // 6
    tabsOweek: 2, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "middling",
    startDayIndex: 58,
    endDayIndex: 60,
  },
  {
    tabsOyear: 21, // 120
    tabsOseason: 21, // 24
    tabSeasonComps: [20, 40, 68, 92, 116],

    tabsOmonth: 9, // 12
    tabsOfortnight: 3, // 6
    tabsOweek: 3, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "ending",
    startDayIndex: 61,
    endDayIndex: 63,
  },
  {
    tabsOyear: 22, // 120
    tabsOseason: 22, // 24
    tabSeasonComps: [21, 41, 69, 93, 117],

    tabsOmonth: 10, // 12
    tabsOfortnight: 4, // 6
    tabsOweek: 1, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "starting",
    startDayIndex: 64,
    endDayIndex: 66,
  },
  {
    tabsOyear: 23, // 120
    tabsOseason: 23, // 24
    tabSeasonComps: [22, 42, 70, 94, 118],

    tabsOmonth: 11, // 12
    tabsOfortnight: 5, // 6
    tabsOweek: 2, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "middling",
    startDayIndex: 67,
    endDayIndex: 69,
  },
  {
    tabsOyear: 24, // 120
    tabsOseason: 24, // 24
    tabSeasonComps: [23, 43, 71, 95, 119],

    tabsOmonth: 12, // 12
    tabsOfortnight: 6, // 6
    tabsOweek: 3, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "ending",
    startDayIndex: 70,
    endDayIndex: 72,
  },
  {
    tabsOyear: 25, // 120
    tabsOseason: 1, // 24
    tabSeasonComps: [0, 24, 48, 72, 96],
    tabsOmonth: 1, // 12
    tabsOfortnight: 1, // 6
    tabsOweek: 1, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "",
    startDayIndex: 73,
    endDayIndex: 75,
  },
  {
    tabsOyear: 26, // 120
    tabsOseason: 2, // 24
    tabSeasonComps: [1, 25, 49, 73, 97],

    tabsOmonth: 2, // 12
    tabsOfortnight: 2, // 6
    tabsOweek: 2, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "middling",
    startDayIndex: 76,
    endDayIndex: 78,
  },
  {
    tabsOyear: 27, // 120
    tabsOseason: 3, // 24
    tabSeasonComps: [2, 26, 50, 74, 98],
    tabsOmonth: 3, // 12
    tabsOfortnight: 3, // 6
    tabsOweek: 3, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "ending",
    startDayIndex: 79,
    endDayIndex: 81,
  },
  {
    tabsOyear: 28, // 120
    tabsOseason: 4, // 24
    tabSeasonComps: [3, 27, 51, 75, 99],
    tabsOmonth: 4, // 12
    tabsOfortnight: 4, // 6
    tabsOweek: 1, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "starting",
    startDayIndex: 82,
    endDayIndex: 84,
  },
  {
    tabsOyear: 29, // 120
    tabsOseason: 5, // 24
    tabSeasonComps: [4, 28, 52, 76, 100],
    tabsOmonth: 5, // 12
    tabsOfortnight: 5, // 6
    tabsOweek: 2, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "middling",
    startDayIndex: 85,
    endDayIndex: 87,
  },
  {
    tabsOyear: 30, // 120
    tabsOseason: 6, // 24
    tabSeasonComps: [5, 29, 53, 77, 101],
    tabsOmonth: 6, // 12
    tabsOfortnight: 6, // 6
    tabsOweek: 3, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "ending",
    startDayIndex: 88,
    endDayIndex: 90,
  },
  {
    tabsOyear: 31, // 120
    tabsOseason: 7, // 24
    tabSeasonComps: [6, 30, 54, 78, 102],
    tabsOmonth: 7, // 12
    tabsOfortnight: 1, // 6
    tabsOweek: 1, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "starting",
    startDayIndex: 91,
    endDayIndex: 93,
  },
  {
    tabsOyear: 32, // 120
    tabsOseason: 8, // 24
    tabSeasonComps: [7, 31, 55, 79, 103],
    tabsOmonth: 8, // 12
    tabsOfortnight: 2, // 6
    tabsOweek: 2, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "middling",
    startDayIndex: 94,
    endDayIndex: 96,
  },
  {
    tabsOyear: 33, // 120
    tabsOseason: 9, // 24
    tabSeasonComps: [8, 32, 56, 80, 104],
    tabsOmonth: 9, // 12
    tabsOfortnight: 3, // 6
    tabsOweek: 3, // 3
    nameWithinYear: "",
    nameWithinSeason: "Early",
    nameWithinMonth: "Late",
    nameWithinFortnight: "Mid",
    nameWithinWeek: "ending",
    startDayIndex: 97,
    endDayIndex: 99,
  },
  {
    tabsOyear: 34, // 120
    tabsOseason: 10, // 24
    tabSeasonComps: [9, 33, 57, 81, 105],
    tabsOmonth: 10, // 12
    tabsOfortnight: 4, // 6
    tabsOweek: 1, // 3
    nameWithinYear: "",
    nameWithinSeason: "Early",
    nameWithinMonth: "Late",
    nameWithinFortnight: "Mid",
    nameWithinWeek: "starting",
    startDayIndex: 100,
    endDayIndex: 102,
  },
  {
    tabsOyear: 35, // 120
    tabsOseason: 11, // 24
    tabSeasonComps: [10, 34, 58, 82, 106],
    tabsOmonth: 11, // 12
    tabsOfortnight: 5, // 6
    tabsOweek: 2, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "middling",
    startDayIndex: 103,
    endDayIndex: 105,
  },
  {
    tabsOyear: 36, // 120
    tabsOseason: 12, // 24
    tabSeasonComps: [11, 35, 59, 83, 107],
    tabsOmonth: 12, // 12
    tabsOfortnight: 6, // 6
    tabsOweek: 3, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "ending",
    startDayIndex: 106,
    endDayIndex: 108,
  },
  {
    tabsOyear: 37, // 120
    tabsOseason: 13, // 24
    tabSeasonComps: [12, 36, 60, 84, 108],
    tabsOmonth: 1, // 12
    tabsOfortnight: 1, // 6
    tabsOweek: 1, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "starting",
    startDayIndex: 110,
    endDayIndex: 112,
  },
  {
    tabsOyear: 38, // 120
    tabsOseason: 14, // 24
    tabSeasonComps: [13, 37, 61, 85, 109],
    tabsOmonth: 2, // 12
    tabsOfortnight: 2, // 6
    tabsOweek: 2, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "middling",
    startDayIndex: 113,
    endDayIndex: 115,
  },
  {
    tabsOyear: 39, // 120
    tabsOseason: 15, // 24
    tabSeasonComps: [14, 38, 62, 86, 110],
    tabsOmonth: 3, // 12
    tabsOfortnight: 3, // 6
    tabsOweek: 3, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "ending",
    startDayIndex: 116,
    endDayIndex: 118,
  },
  {
    tabsOyear: 40, // 120
    tabsOseason: 16, // 24
    tabSeasonComps: [15, 39, 63, 87, 111],

    tabsOmonth: 4, // 12
    tabsOfortnight: 4, // 6
    tabsOweek: 1, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "starting",
    startDayIndex: 119,
    endDayIndex: 121,
  },
  {
    tabsOyear: 41, // 120
    tabsOseason: 17, // 24
    tabSeasonComps: [16, 40, 64, 88, 112],

    tabsOmonth: 5, // 12
    tabsOfortnight: 5, // 6
    tabsOweek: 2, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "middling",
    startDayIndex: 122,
    endDayIndex: 124,
  },
  {
    tabsOyear: 42, // 120
    tabsOseason: 18, // 24
    tabSeasonComps: [17, 41, 65, 89, 113],

    tabsOmonth: 6, // 12
    tabsOfortnight: 6, // 6
    tabsOweek: 3, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "ending",
    startDayIndex: 125,
    endDayIndex: 127,
  },
  {
    tabsOyear: 43, // 120
    tabsOseason: 19, // 24
    tabSeasonComps: [18, 42, 66, 90, 114],

    tabsOmonth: 7, // 12
    tabsOfortnight: 1, // 6
    tabsOweek: 1, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "starting",
    startDayIndex: 128,
    endDayIndex: 130,
  },
  {
    tabsOyear: 44, // 120
    tabsOseason: 20, // 24
    tabSeasonComps: [19, 39, 67, 91, 115],

    tabsOmonth: 8, // 12
    tabsOfortnight: 2, // 6
    tabsOweek: 2, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "middling",
    startDayIndex: 131,
    endDayIndex: 133,
  },
  {
    tabsOyear: 45, // 120
    tabsOseason: 21, // 24
    tabSeasonComps: [20, 40, 68, 92, 116],

    tabsOmonth: 9, // 12
    tabsOfortnight: 3, // 6
    tabsOweek: 3, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "ending",
    startDayIndex: 134,
    endDayIndex: 136,
  },
  {
    tabsOyear: 46, // 120
    tabsOseason: 22, // 24
    tabSeasonComps: [21, 41, 69, 93, 117],

    tabsOmonth: 10, // 12
    tabsOfortnight: 4, // 6
    tabsOweek: 1, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "starting",
    startDayIndex: 137,
    endDayIndex: 139,
  },
  {
    tabsOyear: 47, // 120
    tabsOseason: 23, // 24
    tabSeasonComps: [22, 42, 70, 94, 118],

    tabsOmonth: 11, // 12
    tabsOfortnight: 5, // 6
    tabsOweek: 2, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "middling",
    startDayIndex: 140,
    endDayIndex: 142,
  },
  {
    tabsOyear: 48, // 120
    tabsOseason: 24, // 24
    tabSeasonComps: [23, 43, 71, 95, 119],

    tabsOmonth: 12, // 12
    tabsOfortnight: 6, // 6
    tabsOweek: 3, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "ending",
    startDayIndex: 143,
    endDayIndex: 145,
  },
  // SUMMER
  {
    tabsOyear: 49, // 120
    tabsOseason: 1, // 24
    tabSeasonComps: [0, 24, 48, 72, 96],
    tabsOmonth: 1, // 12
    tabsOfortnight: 1, // 6
    tabsOweek: 1, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "starting",
    startDayIndex: 146,
    endDayIndex: 148,
  },
  {
    tabsOyear: 50, // 120
    tabsOseason: 2, // 24
    tabSeasonComps: [1, 25, 49, 73, 97],

    tabsOmonth: 2, // 12
    tabsOfortnight: 2, // 6
    tabsOweek: 2, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "middling",
    startDayIndex: 149,
    endDayIndex: 151,
  },
  {
    tabsOyear: 51, // 120
    tabsOseason: 3, // 24
    tabSeasonComps: [2, 26, 50, 74, 98],
    tabsOmonth: 3, // 12
    tabsOfortnight: 3, // 6
    tabsOweek: 3, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "ending",
    startDayIndex: 152,
    endDayIndex: 154,
  },
  {
    tabsOyear: 52, // 120
    tabsOseason: 4, // 24
    tabSeasonComps: [3, 27, 51, 75, 99],
    tabsOmonth: 4, // 12
    tabsOfortnight: 4, // 6
    tabsOweek: 1, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "starting",
    startDayIndex: 155,
    endDayIndex: 157,
  },
  {
    tabsOyear: 53, // 120
    tabsOseason: 5, // 24
    tabSeasonComps: [4, 28, 52, 76, 100],
    tabsOmonth: 5, // 12
    tabsOfortnight: 5, // 6
    tabsOweek: 2, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "middling",
    startDayIndex: 158,
    endDayIndex: 160,
  },
  {
    tabsOyear: 54, // 120
    tabsOseason: 6, // 24
    tabSeasonComps: [5, 29, 53, 77, 101],
    tabsOmonth: 6, // 12
    tabsOfortnight: 6, // 6
    tabsOweek: 3, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "ending",
    startDayIndex: 161,
    endDayIndex: 163,
  },
  {
    tabsOyear: 55, // 120
    tabsOseason: 7, // 24
    tabSeasonComps: [6, 30, 54, 78, 102],
    tabsOmonth: 7, // 12
    tabsOfortnight: 1, // 6
    tabsOweek: 1, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "starting",
    startDayIndex: 164,
    endDayIndex: 166,
  },
  {
    tabsOyear: 56, // 120
    tabsOseason: 8, // 24
    tabSeasonComps: [7, 31, 55, 79, 103],
    tabsOmonth: 8, // 12
    tabsOfortnight: 2, // 6
    tabsOweek: 2, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "middling",
    startDayIndex: 167,
    endDayIndex: 169,
  },
  {
    tabsOyear: 57, // 120
    tabsOseason: 9, // 24
    tabSeasonComps: [8, 32, 56, 80, 104],
    tabsOmonth: 9, // 12
    tabsOfortnight: 3, // 6
    tabsOweek: 3, // 3
    nameWithinYear: "",
    nameWithinSeason: "Early",
    nameWithinMonth: "Late",
    nameWithinFortnight: "Mid",
    nameWithinWeek: "ending",
    startDayIndex: 170,
    endDayIndex: 172,
  },
  {
    tabsOyear: 58, // 120
    tabsOseason: 10, // 24
    tabSeasonComps: [9, 33, 57, 81, 105],
    tabsOmonth: 10, // 12
    tabsOfortnight: 4, // 6
    tabsOweek: 1, // 3
    nameWithinYear: "",
    nameWithinSeason: "Early",
    nameWithinMonth: "Late",
    nameWithinFortnight: "Mid",
    nameWithinWeek: "starting",
    startDayIndex: 173,
    endDayIndex: 175,
  },
  {
    tabsOyear: 59, // 120
    tabsOseason: 11, // 24
    tabSeasonComps: [10, 34, 58, 82, 106],
    tabsOmonth: 11, // 12
    tabsOfortnight: 5, // 6
    tabsOweek: 2, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "middling",
    startDayIndex: 176,
    endDayIndex: 178,
  },
  {
    tabsOyear: 60, // 120
    tabsOseason: 12, // 24
    tabSeasonComps: [11, 35, 59, 83, 107],
    tabsOmonth: 12, // 12
    tabsOfortnight: 6, // 6
    tabsOweek: 3, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "ending",
    startDayIndex: 179,
    endDayIndex: 181,
  },
  {
    tabsOyear: 61, // 120
    tabsOseason: 13, // 24
    tabSeasonComps: [12, 36, 60, 84, 108],
    tabsOmonth: 1, // 12
    tabsOfortnight: 1, // 6
    tabsOweek: 1, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "starting",
    startDayIndex: 183,
    endDayIndex: 185,
  },
  {
    tabsOyear: 62, // 120
    tabsOseason: 14, // 24
    tabSeasonComps: [13, 37, 61, 85, 109],
    tabsOmonth: 2, // 12
    tabsOfortnight: 2, // 6
    tabsOweek: 2, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "middling",
    startDayIndex: 186,
    endDayIndex: 188,
  },
  {
    tabsOyear: 63, // 120
    tabsOseason: 15, // 24
    tabSeasonComps: [14, 38, 62, 86, 110],
    tabsOmonth: 3, // 12
    tabsOfortnight: 3, // 6
    tabsOweek: 3, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "ending",
    startDayIndex: 189,
    endDayIndex: 191,
  },
  {
    tabsOyear: 64, // 120
    tabsOseason: 16, // 24
    tabSeasonComps: [15, 39, 63, 87, 111],

    tabsOmonth: 4, // 12
    tabsOfortnight: 4, // 6
    tabsOweek: 1, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "starting",
    startDayIndex: 192,
    endDayIndex: 194,
  },
  {
    tabsOyear: 65, // 120
    tabsOseason: 17, // 24
    tabSeasonComps: [16, 40, 64, 88, 112],

    tabsOmonth: 5, // 12
    tabsOfortnight: 5, // 6
    tabsOweek: 2, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "middling",
    startDayIndex: 195,
    endDayIndex: 197,
  },
  {
    tabsOyear: 66, // 120
    tabsOseason: 18, // 24
    tabSeasonComps: [17, 41, 65, 89, 113],

    tabsOmonth: 6, // 12
    tabsOfortnight: 6, // 6
    tabsOweek: 3, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "ending",
    startDayIndex: 198,
    endDayIndex: 200,
  },
  {
    tabsOyear: 67, // 120
    tabsOseason: 19, // 24
    tabSeasonComps: [18, 42, 66, 90, 114],

    tabsOmonth: 7, // 12
    tabsOfortnight: 1, // 6
    tabsOweek: 1, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "starting",
    startDayIndex: 201,
    endDayIndex: 203,
  },
  {
    tabsOyear: 68, // 120
    tabsOseason: 20, // 24
    tabSeasonComps: [19, 39, 67, 91, 115],

    tabsOmonth: 8, // 12
    tabsOfortnight: 2, // 6
    tabsOweek: 2, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "middling",
    startDayIndex: 204,
    endDayIndex: 206,
  },
  {
    tabsOyear: 69, // 120
    tabsOseason: 21, // 24
    tabSeasonComps: [20, 40, 68, 92, 116],

    tabsOmonth: 9, // 12
    tabsOfortnight: 3, // 6
    tabsOweek: 3, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "ending",
    startDayIndex: 207,
    endDayIndex: 209,
  },
  {
    tabsOyear: 70, // 120
    tabsOseason: 22, // 24
    tabSeasonComps: [21, 41, 69, 93, 117],

    tabsOmonth: 10, // 12
    tabsOfortnight: 4, // 6
    tabsOweek: 1, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "starting",
    startDayIndex: 210,
    endDayIndex: 212,
  },
  {
    tabsOyear: 71, // 120
    tabsOseason: 23, // 24
    tabSeasonComps: [22, 42, 70, 94, 118],

    tabsOmonth: 11, // 12
    tabsOfortnight: 5, // 6
    tabsOweek: 2, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "middling",
    startDayIndex: 213,
    endDayIndex: 215,
  },
  {
    tabsOyear: 72, // 120
    tabsOseason: 24, // 24
    tabSeasonComps: [23, 43, 71, 95, 119],

    tabsOmonth: 12, // 12
    tabsOfortnight: 6, // 6
    tabsOweek: 3, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "ending",
    startDayIndex: 216,
    endDayIndex: 218,
  },
  //AUTUMN
  {
    tabsOyear: 73, // 120
    tabsOseason: 1, // 24
    tabSeasonComps: [0, 24, 48, 72, 96],
    tabsOmonth: 1, // 12
    tabsOfortnight: 1, // 6
    tabsOweek: 1, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "starting",
    startDayIndex: 219,
    endDayIndex: 221,
  },
  {
    tabsOyear: 74, // 120
    tabsOseason: 2, // 24
    tabSeasonComps: [1, 25, 49, 73, 97],

    tabsOmonth: 2, // 12
    tabsOfortnight: 2, // 6
    tabsOweek: 2, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "middling",
    startDayIndex: 222,
    endDayIndex: 224,
  },
  {
    tabsOyear: 75, // 120
    tabsOseason: 3, // 24
    tabSeasonComps: [2, 26, 50, 74, 98],
    tabsOmonth: 3, // 12
    tabsOfortnight: 3, // 6
    tabsOweek: 3, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "ending",
    startDayIndex: 225,
    endDayIndex: 227,
  },
  {
    tabsOyear: 76, // 120
    tabsOseason: 4, // 24
    tabSeasonComps: [3, 27, 51, 75, 99],
    tabsOmonth: 4, // 12
    tabsOfortnight: 4, // 6
    tabsOweek: 1, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "starting",
    startDayIndex: 228,
    endDayIndex: 230,
  },
  {
    tabsOyear: 77, // 120
    tabsOseason: 5, // 24
    tabSeasonComps: [4, 28, 52, 76, 100],
    tabsOmonth: 5, // 12
    tabsOfortnight: 5, // 6
    tabsOweek: 2, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "middling",
    startDayIndex: 231,
    endDayIndex: 233,
  },
  {
    tabsOyear: 78, // 120
    tabsOseason: 6, // 24
    tabSeasonComps: [5, 29, 53, 77, 101],
    tabsOmonth: 6, // 12
    tabsOfortnight: 6, // 6
    tabsOweek: 3, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "ending",
    startDayIndex: 234,
    endDayIndex: 236,
  },
  {
    tabsOyear: 79, // 120
    tabsOseason: 7, // 24
    tabSeasonComps: [6, 30, 54, 78, 102],
    tabsOmonth: 7, // 12
    tabsOfortnight: 1, // 6
    tabsOweek: 1, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "starting",
    startDayIndex: 237,
    endDayIndex: 239,
  },
  {
    tabsOyear: 80, // 120
    tabsOseason: 8, // 24
    tabSeasonComps: [7, 31, 55, 79, 103],
    tabsOmonth: 8, // 12
    tabsOfortnight: 2, // 6
    tabsOweek: 2, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "middling",
    startDayIndex: 240,
    endDayIndex: 242,
  },
  {
    tabsOyear: 81, // 120
    tabsOseason: 9, // 24
    tabSeasonComps: [8, 32, 56, 80, 104],
    tabsOmonth: 9, // 12
    tabsOfortnight: 3, // 6
    tabsOweek: 3, // 3
    nameWithinYear: "",
    nameWithinSeason: "Early",
    nameWithinMonth: "Late",
    nameWithinFortnight: "Mid",
    nameWithinWeek: "ending",
    startDayIndex: 243,
    endDayIndex: 245,
  },
  {
    tabsOyear: 82, // 120
    tabsOseason: 10, // 24
    tabSeasonComps: [9, 33, 57, 81, 105],
    tabsOmonth: 10, // 12
    tabsOfortnight: 4, // 6
    tabsOweek: 1, // 3
    nameWithinYear: "",
    nameWithinSeason: "Early",
    nameWithinMonth: "Late",
    nameWithinFortnight: "Mid",
    nameWithinWeek: "starting",
    startDayIndex: 246,
    endDayIndex: 248,
  },
  {
    tabsOyear: 83, // 120
    tabsOseason: 11, // 24
    tabSeasonComps: [10, 34, 58, 82, 106],
    tabsOmonth: 11, // 12
    tabsOfortnight: 5, // 6
    tabsOweek: 2, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "middling",
    startDayIndex: 249,
    endDayIndex: 251,
  },
  {
    tabsOyear: 84, // 120
    tabsOseason: 12, // 24
    tabSeasonComps: [11, 35, 59, 83, 107],
    tabsOmonth: 12, // 12
    tabsOfortnight: 6, // 6
    tabsOweek: 3, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "ending",
    startDayIndex: 252,
    endDayIndex: 254,
  },
  {
    tabsOyear: 85, // 120
    tabsOseason: 13, // 24
    tabSeasonComps: [12, 36, 60, 84, 108],
    tabsOmonth: 1, // 12
    tabsOfortnight: 1, // 6
    tabsOweek: 1, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "starting",
    startDayIndex: 256,
    endDayIndex: 258,
  },
  {
    tabsOyear: 86, // 120
    tabsOseason: 14, // 24
    tabSeasonComps: [13, 37, 61, 85, 109],
    tabsOmonth: 2, // 12
    tabsOfortnight: 2, // 6
    tabsOweek: 2, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "middling",
    startDayIndex: 259,
    endDayIndex: 261,
  },
  {
    tabsOyear: 87, // 120
    tabsOseason: 15, // 24
    tabSeasonComps: [14, 38, 62, 86, 110],
    tabsOmonth: 3, // 12
    tabsOfortnight: 3, // 6
    tabsOweek: 3, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "ending",
    startDayIndex: 262,
    endDayIndex: 264,
  },
  {
    tabsOyear: 88, // 120
    tabsOseason: 16, // 24
    tabSeasonComps: [15, 39, 63, 87, 111],

    tabsOmonth: 4, // 12
    tabsOfortnight: 4, // 6
    tabsOweek: 1, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "starting",
    startDayIndex: 265,
    endDayIndex: 267,
  },
  {
    tabsOyear: 89, // 120
    tabsOseason: 17, // 24
    tabSeasonComps: [16, 40, 64, 88, 112],

    tabsOmonth: 5, // 12
    tabsOfortnight: 5, // 6
    tabsOweek: 2, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "middling",
    startDayIndex: 268,
    endDayIndex: 270,
  },
  {
    tabsOyear: 90, // 120
    tabsOseason: 18, // 24
    tabSeasonComps: [17, 41, 65, 89, 113],

    tabsOmonth: 6, // 12
    tabsOfortnight: 6, // 6
    tabsOweek: 3, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "ending",
    startDayIndex: 271,
    endDayIndex: 273,
  },
  {
    tabsOyear: 91, // 120
    tabsOseason: 19, // 24
    tabSeasonComps: [18, 42, 66, 90, 114],

    tabsOmonth: 7, // 12
    tabsOfortnight: 1, // 6
    tabsOweek: 1, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "starting",
    startDayIndex: 274,
    endDayIndex: 276,
  },
  {
    tabsOyear: 92, // 120
    tabsOseason: 20, // 24
    tabSeasonComps: [19, 39, 67, 91, 115],

    tabsOmonth: 8, // 12
    tabsOfortnight: 2, // 6
    tabsOweek: 2, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "middling",
    startDayIndex: 277,
    endDayIndex: 279,
  },
  {
    tabsOyear: 93, // 120
    tabsOseason: 21, // 24
    tabSeasonComps: [20, 40, 68, 92, 116],

    tabsOmonth: 9, // 12
    tabsOfortnight: 3, // 6
    tabsOweek: 3, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "ending",
    startDayIndex: 280,
    endDayIndex: 282,
  },
  {
    tabsOyear: 94, // 120
    tabsOseason: 22, // 24
    tabSeasonComps: [21, 41, 69, 93, 117],

    tabsOmonth: 10, // 12
    tabsOfortnight: 4, // 6
    tabsOweek: 1, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "starting",
    startDayIndex: 283,
    endDayIndex: 285,
  },
  {
    tabsOyear: 95, // 120
    tabsOseason: 23, // 24
    tabSeasonComps: [22, 42, 70, 94, 118],

    tabsOmonth: 11, // 12
    tabsOfortnight: 5, // 6
    tabsOweek: 2, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "middling",
    startDayIndex: 286,
    endDayIndex: 288,
  },
  {
    tabsOyear: 96, // 120
    tabsOseason: 24, // 24
    tabSeasonComps: [23, 43, 71, 95, 119],

    tabsOmonth: 12, // 12
    tabsOfortnight: 6, // 6
    tabsOweek: 3, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "ending",
    startDayIndex: 289,
    endDayIndex: 291,
  },
  // FALL
  {
    tabsOyear: 97, // 120
    tabsOseason: 1, // 24
    tabSeasonComps: [0, 24, 48, 72, 96],
    tabsOmonth: 1, // 12
    tabsOfortnight: 1, // 6
    tabsOweek: 1, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "starting",
    startDayIndex: 292,
    endDayIndex: 294,
  },
  {
    tabsOyear: 98, // 120
    tabsOseason: 2, // 24
    tabSeasonComps: [1, 25, 49, 73, 97],

    tabsOmonth: 2, // 12
    tabsOfortnight: 2, // 6
    tabsOweek: 2, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "middling",
    startDayIndex: 295,
    endDayIndex: 297,
  },
  {
    tabsOyear: 99, // 120
    tabsOseason: 3, // 24
    tabSeasonComps: [2, 26, 50, 74, 98],
    tabsOmonth: 3, // 12
    tabsOfortnight: 3, // 6
    tabsOweek: 3, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "ending",
    startDayIndex: 298,
    endDayIndex: 300,
  },
  {
    tabsOyear: 100, // 120
    tabsOseason: 4, // 24
    tabSeasonComps: [3, 27, 51, 75, 99],
    tabsOmonth: 4, // 12
    tabsOfortnight: 4, // 6
    tabsOweek: 1, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "starting",
    startDayIndex: 301,
    endDayIndex: 303,
  },
  {
    tabsOyear: 101, // 120
    tabsOseason: 5, // 24
    tabSeasonComps: [4, 28, 52, 76, 100],
    tabsOmonth: 5, // 12
    tabsOfortnight: 5, // 6
    tabsOweek: 2, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "middling",
    startDayIndex: 304,
    endDayIndex: 306,
  },
  {
    tabsOyear: 102, // 120
    tabsOseason: 6, // 24
    tabSeasonComps: [5, 29, 53, 77, 101],
    tabsOmonth: 6, // 12
    tabsOfortnight: 6, // 6
    tabsOweek: 3, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "ending",
    startDayIndex: 307,
    endDayIndex: 309,
  },
  {
    tabsOyear: 103, // 120
    tabsOseason: 7, // 24
    tabSeasonComps: [6, 30, 54, 78, 102],
    tabsOmonth: 7, // 12
    tabsOfortnight: 1, // 6
    tabsOweek: 1, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "starting",
    startDayIndex: 310,
    endDayIndex: 312,
  },
  {
    tabsOyear: 104, // 120
    tabsOseason: 8, // 24
    tabSeasonComps: [7, 31, 55, 79, 103],
    tabsOmonth: 8, // 12
    tabsOfortnight: 2, // 6
    tabsOweek: 2, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "middling",
    startDayIndex: 313,
    endDayIndex: 315,
  },
  {
    tabsOyear: 105, // 120
    tabsOseason: 9, // 24
    tabSeasonComps: [8, 32, 56, 80, 104],
    tabsOmonth: 9, // 12
    tabsOfortnight: 3, // 6
    tabsOweek: 3, // 3
    nameWithinYear: "",
    nameWithinSeason: "Early",
    nameWithinMonth: "Late",
    nameWithinFortnight: "Mid",
    nameWithinWeek: "ending",
    startDayIndex: 316,
    endDayIndex: 318,
  },
  {
    tabsOyear: 106, // 120
    tabsOseason: 10, // 24
    tabSeasonComps: [9, 33, 57, 81, 105],
    tabsOmonth: 10, // 12
    tabsOfortnight: 4, // 6
    tabsOweek: 1, // 3
    nameWithinYear: "",
    nameWithinSeason: "Early",
    nameWithinMonth: "Late",
    nameWithinFortnight: "Mid",
    nameWithinWeek: "starting",
    startDayIndex: 319,
    endDayIndex: 321,
  },
  {
    tabsOyear: 107, // 120
    tabsOseason: 11, // 24
    tabSeasonComps: [10, 34, 58, 82, 106],
    tabsOmonth: 11, // 12
    tabsOfortnight: 5, // 6
    tabsOweek: 2, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "middling",
    startDayIndex: 322,
    endDayIndex: 324,
  },
  {
    tabsOyear: 108, // 120
    tabsOseason: 12, // 24
    tabSeasonComps: [11, 35, 59, 83, 107],
    tabsOmonth: 12, // 12
    tabsOfortnight: 6, // 6
    tabsOweek: 3, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "ending",
    startDayIndex: 325,
    endDayIndex: 327,
  },
  {
    tabsOyear: 109, // 120
    tabsOseason: 13, // 24
    tabSeasonComps: [12, 36, 60, 84, 108],
    tabsOmonth: 1, // 12
    tabsOfortnight: 1, // 6
    tabsOweek: 1, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "starting",
    startDayIndex: 329,
    endDayIndex: 331,
  },
  {
    tabsOyear: 110, // 120
    tabsOseason: 14, // 24
    tabSeasonComps: [13, 37, 61, 85, 109],
    tabsOmonth: 2, // 12
    tabsOfortnight: 2, // 6
    tabsOweek: 2, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "middling",
    startDayIndex: 332,
    endDayIndex: 334,
  },
  {
    tabsOyear: 111, // 120
    tabsOseason: 15, // 24
    tabSeasonComps: [14, 38, 62, 86, 110],
    tabsOmonth: 3, // 12
    tabsOfortnight: 3, // 6
    tabsOweek: 3, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "ending",
    startDayIndex: 335,
    endDayIndex: 337,
  },
  {
    tabsOyear: 112, // 120
    tabsOseason: 16, // 24
    tabSeasonComps: [15, 39, 63, 87, 111],

    tabsOmonth: 4, // 12
    tabsOfortnight: 4, // 6
    tabsOweek: 1, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "starting",
    startDayIndex: 338,
    endDayIndex: 340,
  },
  {
    tabsOyear: 113, // 120
    tabsOseason: 17, // 24
    tabSeasonComps: [16, 40, 64, 88, 112],

    tabsOmonth: 5, // 12
    tabsOfortnight: 5, // 6
    tabsOweek: 2, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "middling",
    startDayIndex: 341,
    endDayIndex: 343,
  },
  {
    tabsOyear: 114, // 120
    tabsOseason: 18, // 24
    tabSeasonComps: [17, 41, 65, 89, 113],

    tabsOmonth: 6, // 12
    tabsOfortnight: 6, // 6
    tabsOweek: 3, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "ending",
    startDayIndex: 344,
    endDayIndex: 346,
  },
  {
    tabsOyear: 115, // 120
    tabsOseason: 19, // 24
    tabSeasonComps: [18, 42, 66, 90, 114],

    tabsOmonth: 7, // 12
    tabsOfortnight: 1, // 6
    tabsOweek: 1, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "starting",
    startDayIndex: 347,
    endDayIndex: 349,
  },
  {
    tabsOyear: 116, // 120
    tabsOseason: 20, // 24
    tabSeasonComps: [19, 39, 67, 91, 115],

    tabsOmonth: 8, // 12
    tabsOfortnight: 2, // 6
    tabsOweek: 2, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "middling",
    startDayIndex: 350,
    endDayIndex: 352,
  },
  {
    tabsOyear: 117, // 120
    tabsOseason: 21, // 24
    tabSeasonComps: [20, 40, 68, 92, 116],

    tabsOmonth: 9, // 12
    tabsOfortnight: 3, // 6
    tabsOweek: 3, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "ending",
    startDayIndex: 353,
    endDayIndex: 355,
  },
  {
    tabsOyear: 118, // 120
    tabsOseason: 22, // 24
    tabSeasonComps: [21, 41, 69, 93, 117],

    tabsOmonth: 10, // 12
    tabsOfortnight: 4, // 6
    tabsOweek: 1, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "starting",
    startDayIndex: 356,
    endDayIndex: 358,
  },
  {
    tabsOyear: 119, // 120
    tabsOseason: 23, // 24
    tabSeasonComps: [22, 42, 70, 94, 118],

    tabsOmonth: 11, // 12
    tabsOfortnight: 5, // 6
    tabsOweek: 2, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "middling",
    startDayIndex: 359,
    endDayIndex: 361,
  },
  {
    tabsOyear: 120, // 120
    tabsOseason: 24, // 24
    tabSeasonComps: [23, 43, 71, 95, 119],

    tabsOmonth: 12, // 12
    tabsOfortnight: 6, // 6
    tabsOweek: 3, // 3
    nameWithinYear: "",
    nameWithinSeason: "",
    nameWithinMonth: "",
    nameWithinFortnight: "",
    nameWithinWeek: "ending",
    startDayIndex: 362,
    endDayIndex: 364,
  },
];

export default tobj;
