import React from "react";
import thePulse from "../images/media/thePulse.webp";
import wpviActionNews from "../images/media/wpviActionNews.webp";
import badBuzinessLogo from "../images/media/badBuzinessLogo.webp";
import pass2proff from "../images/media/pass2proff.webp";
import cape_gazette_logo from "../images/media/cape_gazette_logo.png";
import iheartmedia from "../images/media/iheartmedia.png";
import passageprofit from "../images/media/passageprofit.png";

import whyy_thePulse_newCalendar from "../media/whyy_thePulse_newCalendar.mp3";

const Media = () => {
  return (
    <div>
      <section className="learn_more">
        <h1>The New Calendar in the Media</h1>
        <p>
          The New Calendar has been under development by a team of American
          scientists over the past decade. These are their stories.
        </p>
      </section>
      <section className="team_descript">
        <div className="media_column">
          <div>
            <h2>NPR's The Pulse</h2>

            <div className="flexColumn">
              {/* <img
                className="half"
                src={thePulse}
                alt="WHYY NPR the Pulse logo"
                style={{ width: "40%" }}
              /> */}
              <div
                className="mediapagelogo"
                style={{
                  backgroundImage: `url(${thePulse})`,
                }}
              ></div>
              <div className="flexColumn">
                <p>
                  The Pulse tells stories at the heart of health, science and
                  innovation, heard weekly on WHYY and other NPR member
                  stations. The New Calendar is the first featured story in the
                  podcast below, or you can
                  <a
                    href="https://whyy.org/segments/time-to-rip-up-the-calendar-how-about-5-seasons-9-days-in-a-week/"
                    alt="the New Calendar WHYY the Pulse"
                    className="bio"
                  >
                    read the article on WHYY here.
                  </a>
                </p>
                <audio
                  className="full"
                  src={whyy_thePulse_newCalendar}
                  controls
                >
                  play audio?
                </audio>
              </div>
            </div>
          </div>

          <div>
            <h2>Passage to Profit Show</h2>

            <div className="flexColumn">
              {/* <img
                className="half"
                src={pass2proff}
                alt="new calendar wins passage to profit show logo"
                style={{ width: "50%" }}
              /> */}
              <div
                className="mediapagelogo"
                style={{
                  backgroundImage: `url(${iheartmedia})`,
                  // justifyContent: "center",
                  // alignItems: "center",
                }}
              ></div>
              <div
                className="mediapagelogo"
                style={{
                  backgroundImage: `url(${passageprofit})`,
                }}
              ></div>
              <div className="flexColumn">
                <p>
                  The New Calendar enters [update: and wins] its first pitch
                  competition!{" "}
                  <a
                    href="https://passagetoprofitshow.com/podcast/035-passage-to-profit-show-04-28-2019/"
                    alt="new calendar wins passage to profit show pitch"
                    className="bio"
                  >
                    Passage to Profit
                  </a>{" "}
                  is an iHeart radio show taped in New York City for
                  entrepreneurs that features an interview with an accomplished
                  speaker and a pitch contest. Listen to the pitch below (starts
                  around 33:44)
                </p>
                <iframe
                  style={{ width: "80%", height: "20vh" }}
                  // width="560"
                  // height="315"
                  src="https://www.youtube.com/embed/HtaJcgMn18M?start=2025"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>

          <div>
            <h2>Bad Buziness Podcast</h2>

            <div className="flexColumn">
              {/* <img
                className="half"
                src={badBuzinessLogo}
                alt="bad buziness logo"
                style={{ width: "50%" }}
              /> */}
              <div
                className="mediapagelogo"
                style={{
                  backgroundImage: `url(${badBuzinessLogo})`,
                }}
              ></div>
              <p>
                The New Calendar goes to the{" "}
                <a
                  href="https://www.facebook.com/Bad-Buziness-Podcast-598900610537294/"
                  alt="Bad Buziness Podcast Logo New Calendar"
                  className="bio"
                >
                  Bad Buziness Podcast
                </a>{" "}
                to see if it has what it takes to get the oft-sought title of
                “It’s a Thing” or get written off as “Not a Thing” from the
                folks at Bad Buziness.
              </p>
            </div>
          </div>
          <div>
            <h2>The Cape Gazette</h2>
            <div className="flexColumn">
              {/* <img
                className="half"
                src={cape_gazette_logo}
                alt="cape gazette logo"
              /> */}
              <div
                className="mediapagelogo"
                style={{
                  backgroundImage: `url(${cape_gazette_logo})`,
                }}
              ></div>
              <p>
                The New Calendar inventor Tom Sherman featured in The Cape
                Gazette.{" "}
                <a
                  href="https://www.capegazette.com/article/calendar-dated-thomas-sherman-thinks-so/176217"
                  alt="Cape Gazette New Calendar"
                  className="bio"
                >
                  Read the full story here.
                </a>
              </p>
            </div>
          </div>
          <div>
            <h2>6ABC Action News</h2>
            <div className="flexColumn">
              {/* <img
                className="half"
                src={wpviActionNews}
                alt="WPVI-TV 6 action news logo"
              /> */}
              <div
                className="mediapagelogo"
                style={{
                  backgroundImage: `url(${wpviActionNews})`,
                }}
              ></div>
              <p>
                The New Calendar featured on WPVI’s Big Talkers segment.{" "}
                <a
                  href="https://6abc.com/weather/local-scientists-say-theyve-discovered-a-5th-season/5167360/?fbclid=IwAR3mcYiD2X3HBCWUrO8PbabBDzGB4QN9G-iZliTpLtDXCAPkW60FGUyE5qs"
                  alt="WPVI 6 Action News"
                  className="bio"
                >
                  Read the full story here.
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Media;
