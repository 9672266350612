import React from "react";

const SingleCTAButton = ({ buttonA, productCardRoute }) => {
  return (
    <>
      <div
        className="callToAction_container"
        style={{
          backgroundColor: "transparent",
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
        onClick={productCardRoute}
      >
        <div
          className="button"
          style={{
            textAlign: "center",
            height: "10vh",
            width: "80%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "8%",
          }}
        >
          {buttonA}
        </div>
      </div>
    </>
  );
};

export default SingleCTAButton;
