import stNdTh from "../blocks/logic/stNdTH.js";
import fot from "../fot/fot.js";
import calculator from "../fot/calculator.js";
import { useState } from "react";
import colorCode from "../blocks/logic/colorCode.js";

const Time = () => {
  // console.log(fot().props.children);
  // const calMath = fot().props.children[0];
  const calLang = fot().props.children[1];
  // let dayOfSeason = calLang.dayNumS;
  let dayofMonth = calLang.dayNumM;
  // let monthOfSeason = calLang.monthNameS;
  let season = calLang.seasonNameY;
  let month = calLang.monthNameS;
  let dayOfWeek = calLang.dayNameW;
  let suffix = stNdTh(dayofMonth);
  let gregDate = new Date();

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [selectedMonth, setSelectedMonth] = useState(
    gregDate.toLocaleString("en-US", { month: "short" })
  );
  const [selectedlongMonth, setSelectedlongMonth] = useState(
    gregDate.toLocaleString("en-US", { month: "long" })
  );
  const [selectedDate, setSelectedDate] = useState(gregDate.getDate());

  //   setSelectedMonth(new Date().getMonth());
  //   setSelectedDate(new Date().getDate());

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
    setSelectedlongMonth(event.target.value);
    // console.log(selectedMonth, selectedlongMonth);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  let toConvert =
    selectedMonth[0] + selectedMonth[1] + selectedMonth[2] + " " + selectedDate;
  let conversion = calculator(toConvert);

  let seasonClass = `${conversion.split(" ")[0].toLowerCase()} bigFont`;
  let seasonColor = colorCode(["bigFont"]);

  // console.log(calLang.monthNameS[0], calLang.seasonNumY + 1, calLang);
  return (
    <div>
      <h2 style={{ fontSize: "2rem", textAlign: "center" }}>
        The New Calendar App
      </h2>
      <div className={seasonColor} style={{ padding: "5%" }}>
        <div
          className={seasonColor}
          style={{
            textAlign: "center",
            background: "whitesmoke",
            padding: "5%",
          }}
        >
          {" "}
          <p>Today is:</p>
          <p>
            the {dayofMonth + suffix} of {month + " " + season}{" "}
          </p>
          <p>a {dayOfWeek}</p>
          <p>
            Abbrs: {calLang.monthNameS[0]}
            {calLang.seasonNumY + 1}.{dayofMonth}
          </p>
        </div>
      </div>
      <div className={seasonClass} style={{ padding: "5%" }}>
        <div
          // className={seasonColor}
          style={{
            textAlign: "center",
            background: "whitesmoke",
            padding: "5%",
          }}
        >
          <h2
            style={{ fontSize: "2rem", marginTop: "5%", textAlign: "center" }}
          >
            When is my birthday?
          </h2>
          <div>
            <div className="datePicker">
              <div
                className="selectionBox"
                style={{
                  display: "flex",
                  width: "80%",
                  justifyContent: "space-around",
                }}
              >
                <label>Select Month:</label>
                <select
                  value={selectedlongMonth}
                  onChange={handleMonthChange}
                  style={{ fontSize: "1.4rem" }}
                >
                  {months.map((month, index) => (
                    <option
                      key={index}
                      value={month}
                      style={{ fontSize: "1rem" }}
                    >
                      {month}
                    </option>
                  ))}
                </select>
              </div>
              <div
                className="selectionBox"
                style={{
                  display: "flex",
                  width: "80%",
                  justifyContent: "space-around",
                }}
              >
                <label>Select Date:</label>
                <select
                  value={selectedDate}
                  onChange={handleDateChange}
                  style={{ fontSize: "1.8rem" }}
                >
                  {Array.from({ length: 31 }, (_, index) => (
                    <option
                      key={index}
                      value={index + 1}
                      style={{ fontSize: "1rem" }}
                    >
                      {index + 1}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div style={{ fontSize: "2rem" }}>Gregorian date:</div>
            <div
              // className={seasonClass}
              style={{ textAlign: "center", fontSize: "3rem" }}
            >
              {" "}
              {selectedlongMonth} {selectedDate}
            </div>

            <div style={{ fontSize: "2rem" }}>New Calendar date:</div>
            <div
              // className={seasonClass}
              style={{ textAlign: "center", fontSize: "3rem" }}
            >
              {conversion}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Time;
