import React from "react";
import dobj from "./dobj";
import tobj from "./tobj";
import wobj from "./wobj";
import fobj from "./fobj";
import mobj from "./mobj";
import sobj from "./sobj";
import midseaObj from "./midseaObj";

const fot = () => {
  // // // console.log("dobj test:", dobj, tobj, wobj, fobj, mobj, sobj)
  // DETERMINE START TIME:

  // let seconds = 0;
  let startDate = "";
  // const dec21 = 0;
  let jan1 = 864000;
  const gregorian = new Date();
  let gregMonth = gregorian.getMonth();
  // // // console.log(gregMonth)
  let gregDate = gregorian.getDate();
  const monthArray = [
    0, 2678400, 5097600, 7776000, 10368000, 13046400, 15638400, 18316800,
    20995200, 23587200, 26265600, 28857600,
  ];

  const monthSec = (month, array) => {
    for (let i = 0; i < array.length; i++) {
      if (month === 11 && gregDate >= 21) {
        gregMonth = 0;
        jan1 = gregDate - 21 * 86400;
        // // // console.log("hiya", gregDate, gregMonth, jan1)
        return 0;
      } else if (month === i) {
        return array[i];
      }
    }
  };

  // // // console.log("start secs check:", monthSec(0, monthArray) + 21 * 86400);

  // STARTING TIMING:

  startDate = monthSec(gregMonth, monthArray) + gregDate * 86400 + jan1;
  // // // console.log("right now:", startDate)
  // let startHour = gregorian.getHours() * 3600;
  // let startMins = gregorian.getMinutes() * 60;
  // let startSecs = startHour + startMins + gregorian.getSeconds();

  // let startTime = startDate + startSecs;

  // seconds = startTime;

  // mid-season test point: 37th of Summer (june 21)
  // seconds = 14860800 + 950400 - 86400;

  // BEGIN NUM OBJECT:

  // year
  let daysOyear = startDate / 86400; // count starts at 0 on dec 21, 0-364;; startdate+86400 = count starts at 1
  let seasonsOyear = daysOyear / 73;
  let midseasonsOyear = Math.round(seasonsOyear);
  let monthsOyear = (daysOyear - midseasonsOyear) / 36;
  let fortnightsOyear = (daysOyear - midseasonsOyear) / 18;
  let weeksOyear = (daysOyear - midseasonsOyear) / 9;
  let tabsOyear = (daysOyear - midseasonsOyear) / 3;

  let dayPercentY = daysOyear / 365;
  let seasonPercentY = seasonsOyear / 5;
  let midseasonPercentY = midseasonsOyear / 5;
  let monthPercentY = monthsOyear / 10;
  let fortPercentY = fortnightsOyear / 20;
  let weekPercentY = weeksOyear / 40;
  let tabPercentY = tabsOyear / 120;

  // SEASON:
  // days of season
  let daysOseason = daysOyear - Math.floor(seasonsOyear) * 73 + 1;
  if (daysOseason === 0 && daysOyear > 0) {
    daysOseason = 73;
  }
  // midseasons of season
  let midseasonOseason = Math.round(seasonsOyear - midseasonsOyear);
  // months of season
  let monthsOseason = (daysOseason - midseasonOseason) / 36;
  // fortnights of season
  let fortnightsOseason = (daysOseason - midseasonOseason) / 18;
  // weeks of season
  let weeksOseason = (daysOseason - midseasonOseason) / 9;
  // tabs of season
  let tabsOseason = (daysOseason - midseasonOseason) / 3;

  let dayPercentS = (daysOseason - 1) / 73;
  // let midseasonPercentS = midseasonOseason / 1;
  let monthPercentS = monthsOseason / 2;
  let fortPercentS = fortnightsOseason / 4;
  let weekPercentS = weeksOseason / 8;
  let tabPercentS = tabsOseason / 24;

  // MONTH
  // days of month
  let daysOmonth = daysOseason - Math.floor(monthsOseason) * 36;
  // console.log("daysOmonth", daysOmonth);
  if (daysOseason > 36) {
    daysOmonth -= 1;
  }
  if (daysOmonth === 0 && daysOyear > 0) {
    daysOmonth = 36;
    // console.log("hello?", daysOmonth);
  }
  // console.log("daysOmonth", daysOmonth);
  // console.log("daysOmonth", daysOmonth);
  // fortnights of month
  let fortnightsOmonth = daysOmonth / 18;
  // weeks of month
  let weeksOmonth = daysOmonth / 9;
  // tabs of month
  let tabsOmonth = daysOmonth / 3;

  let dayPercentM = (daysOmonth - 1) / 36;
  let fortPercentM = fortnightsOmonth / 2;
  let weekPercentM = weeksOmonth / 4;
  let tabPercentM = tabsOmonth / 12;

  // FORTNIGHT
  // days of fortnight
  let daysOfortnight = daysOmonth - Math.floor(fortnightsOmonth) * 18;
  if (daysOfortnight === 0 && daysOyear > 0) {
    daysOfortnight = 18;
  }
  // else if (daysOfortnight === 0 && daysOyear > 0 && monthsOseason > 1) {
  //   daysOfortnight = 17;
  // }
  // weeks of fornight
  let weeksOfortnight = daysOfortnight / 9;
  // // console.log("WEEKS OF FORTHNIGHT", daysOseason);
  // tabs of fortnight
  let tabsOfortnight = daysOfortnight / 3;

  let dayPercentF = (daysOfortnight - 1) / 18;
  let weekPercentF = weeksOfortnight / 2;
  let tabPercentF = tabsOfortnight / 6;

  // WEEK
  // days of week
  let daysOweek = daysOfortnight - Math.floor(weeksOfortnight) * 9;
  if (daysOweek === 0 && daysOyear > 0) {
    daysOweek = 9;
  }
  // else if (daysOweek === 0 && daysOyear > 0 && monthsOseason > 1) {
  //   daysOweek = 8;
  // }

  // tabs of week
  let tabsOweek = daysOweek / 3;

  let dayPercentW = (daysOweek - 1) / 9;
  let tabPercentW = tabsOweek / 3;

  // TAB
  // days of tab
  let daysOtab = daysOweek - Math.floor(tabsOweek) * 3;
  if (daysOtab === 0 && daysOyear > 0) {
    daysOtab = 3;
  }
  let dayPercentT = (daysOtab - 1) / 3;

  //   // // console.log(`

  //     days o year         : ${daysOyear} / 365
  //     seasons o year      : ${seasonsOyear} / 5
  //     midseasons          : ${midseasonsOyear} / 5
  //     months o year       : ${monthsOyear} / 10
  //     fortnights o year   : ${fortnightsOyear} / 20
  //     weeks o year        : ${weeksOyear} / 40
  //     tab o year        : ${tabsOyear} / 120

  //     days % year         : ${dayPercentY}
  //     seasons % year      : ${seasonPercentY}
  //     middays % year      : ${midseasonPercentY}
  //     mons % year         : ${monthPercentY}
  //     fort % year         : ${fortPercentY}
  //     weks % year         : ${weekPercentY}
  //     tabs % year         : ${tabPercentY}

  //     days o season       : ${daysOseason} / 73
  //     midseasons o season : ${midseasonOseason} / 1
  //     months o season     : ${monthsOseason} / 2
  //     fortnights o season : ${fortnightsOseason} / 4
  //     weeks o season      : ${weeksOseason} / 8
  //     tab o season      : ${tabsOseason} / 24

  //     days % season         : ${dayPercentS}
  //     mons % season         : ${monthPercentS}
  //     fort % season         : ${fortPercentS}
  //     weks % season         : ${weekPercentS}
  //     tabs % season         : ${tabPercentS}

  //     days o month        : ${daysOmonth} / 36
  //     fortnights o month  : ${fortnightsOmonth} / 2
  //     weeks o month       : ${weeksOmonth} / 4
  //     tab o month       : ${tabsOmonth} / 12

  //     days % month         : ${dayPercentM}
  //     fort % month         : ${fortPercentM}
  //     weks % month         : ${weekPercentM}
  //     tabs % month         : ${tabPercentM}

  //     days o fortnight    : ${daysOfortnight} / 18
  //     weeks o fortnight   : ${weeksOfortnight} / 2
  //     tab o fortnight   : ${tabsOfortnight} / 6

  //     days % fort         : ${dayPercentF}
  //     weks % fort         : ${weekPercentF}
  //     tabs % fort         : ${tabPercentF}

  //     days o week         : ${daysOweek} / 9
  //     tab o week        : ${tabsOweek} / 3

  //     days % week         : ${dayPercentW}
  //     tabs % week         : ${tabPercentW}

  //     days o tab        : ${daysOtab} / 3
  //     days % tabs         : ${dayPercentT}
  // `)

  let numObject = {
    daysOyear: daysOyear,
    seasonsOyear: seasonsOyear,
    midseasonsOyear: midseasonsOyear,
    monthsOyear: monthsOyear,
    fortnightsOyear: fortnightsOyear,
    weeksOyear: weeksOyear,
    tabsOyear: tabsOyear,

    dayPercentY: dayPercentY,
    seasonPercentY: seasonPercentY,
    midseasonPercentY: midseasonPercentY,
    monthPercentY: monthPercentY,
    fortPercentY: fortPercentY,
    weekPercentY: weekPercentY,
    tabPercentY: tabPercentY,

    daysOseason: daysOseason,
    midseasonOseason: midseasonOseason,
    monthsOseason: monthsOseason,
    fortnightsOseason: fortnightsOseason,
    weeksOseason: weeksOseason,
    tabsOseason: tabsOseason,

    dayPercentS: dayPercentS,
    monthPercentS: monthPercentS,
    fortPercentS: fortPercentS,
    weekPercentS: weekPercentS,
    tabPercentS: tabPercentS,

    daysOmonth: daysOmonth,
    fortnightsOmonth: fortnightsOmonth,
    weeksOmonth: weeksOmonth,
    tabsOmonth: tabsOmonth,

    dayPercentM: dayPercentM,
    fortPercentM: fortPercentM,
    weekPercentM: weekPercentM,
    tabPercentM: tabPercentM,

    daysOfortnight: daysOfortnight,
    weeksOfortnight: weeksOfortnight,
    tabsOfortnight: tabsOfortnight,

    dayPercentF: dayPercentF,
    weekPercentF: weekPercentF,
    tabPercentF: tabPercentF,

    daysOweek: daysOweek,
    tabsOweek: tabsOweek,

    dayPercentW: dayPercentW,
    tabPercentW: tabPercentW,

    daysOtab: daysOtab,
    dayPercentT: dayPercentT,
  };

  // // // console.log("numobject", numObject)

  // BEGIN INFO OBJECT

  let yearName = "";
  let seasonNameY = "";
  let midseasonNameY = "";
  let monthNameY = "";
  let fortNameY = "";
  let weekNameY = "";
  let tabNameY = "";
  let dayNameY = "";

  let midseasonNameS = "";
  let monthNameS = "";
  let fortNameS = "";
  let weekNameS = "";
  let tabNameS = "";
  let dayNameS = "";

  let fortNameM = "";
  let weekNameM = "";
  let tabNameM = "";
  let dayNameM = "";

  let weekNameF = "";
  let tabNameF = "";
  let dayNameF = "";

  let tabNameW = "";
  let dayNameW = "";

  let dayNameT = "";

  let seasonNameYURL = "";

  let midseasonNameYURL = "";
  let midseasonNameSURL = "";

  let monthNameYURL = "";
  let monthNameSURL = "";

  let fortNameYURL = "";
  let fortNameSURL = "";
  let fortNameMURL = "";

  let weekNameYURL = "";
  let weekNameSURL = "";
  let weekNameMURL = "";
  let weekNameFURL = "";

  let tabNameYURL = "";
  let tabNameSURL = "";
  let tabNameMURL = "";
  let tabNameFURL = "";
  let tabNameWURL = "";

  let dayNameYURL = "";
  let dayNameSURL = "";
  let dayNameMURL = "";
  let dayNameFURL = "";
  let dayNameWURL = "";
  let dayNameTURL = "";

  let seasonNumY = Math.floor(seasonsOyear);
  let midseasonNumY = midseasonsOyear;
  let monthNumY = Math.floor(monthsOyear);
  let fortnightNumY = Math.floor(fortnightsOyear);
  let weekNumY = Math.floor(weeksOyear);
  let tabNumY = Math.floor(tabsOyear);
  let dayNumY = Math.floor(daysOyear);

  let monthNumS = Math.floor(monthsOseason);
  let fortnightNumS = Math.floor(fortnightsOseason);
  let weekNumS = Math.floor(weeksOseason);
  let tabNumS = Math.floor(tabsOseason);
  let dayNumS = daysOseason;

  let fortnightNumM = Math.floor(fortnightsOmonth);
  let weekNumM = Math.floor(weeksOmonth);
  let tabNumM = Math.floor(tabsOmonth);
  let dayNumM = daysOmonth;

  let weekNumF = Math.floor(weeksOfortnight);
  let tabNumF = Math.floor(tabsOfortnight);
  let dayNumF = daysOfortnight;

  let tabNumW = Math.floor(tabsOweek);
  let dayNumW = daysOweek;

  let dayNumT = daysOtab;

  // let dayDate = null;
  // let weekDate = null;
  // let monthDate = null;

  if (daysOseason === 37) {
    monthNumY = -1;
    monthNumS = -1;

    fortnightNumY = -1;
    fortnightNumS = -1;
    fortnightNumM = -1;

    weekNumY = -1;
    weekNumS = -1;
    weekNumM = -1;
    weekNumF = -1;

    tabNumY = -1;
    tabNumS = -1;
    tabNumM = -1;
    tabNumF = -1;
    tabNumW = -1;

    dayNumM = -1;
    dayNumF = -1;
    dayNumW = -1;
    dayNumT = -1;

    numObject.dayPercentF = 0;
    numObject.dayPercentM = 0;
    numObject.dayPercentS = 0.5;
    numObject.dayPercentW = 0;
    numObject.dayPercentT = 0;

    numObject.tabPercentF = 0;
    numObject.tabPercentM = 0;
    numObject.tabPercentS = 0.5;
    numObject.tabPercentW = 0;

    numObject.weekPercentF = 0;
    numObject.weekPercentM = 0;
    numObject.weekPercentS = 0.5;

    numObject.fortPercentM = 0;
    numObject.fortPercentS = 0.5;

    numObject.monthPercentS = 0.5;
  }

  seasonNameY = sobj[seasonNumY].name;
  seasonNameYURL = sobj[seasonNumY].url;

  if (monthNumY === -1) {
    monthNameY = midseaObj[seasonNumY].nameWithinYear;
    monthNameS = midseaObj[seasonNumY].nameWithinYear;
  } else {
    monthNameY = mobj[monthNumY].nameWithinYear;
    monthNameS = mobj[monthNumY].nameWithinSeason;
  }

  if (fortnightNumY === -1) {
    fortNameY = midseaObj[seasonNumY].nameWithinYear;
    fortNameS = midseaObj[seasonNumY].nameWithinYear;
    fortNameM = midseaObj[seasonNumY].nameWithinYear;
  } else {
    fortNameY = fobj[fortnightNumY].nameWithinYear;
    fortNameS = fobj[fortnightNumY].nameWithinSeason;
    fortNameM = fobj[fortnightNumY].nameWithinMonth;
  }

  if (weekNumY === -1) {
    weekNameY = midseaObj[seasonNumY].nameWithinYear;
    weekNameS = midseaObj[seasonNumY].nameWithinYear;
    weekNameM = midseaObj[seasonNumY].nameWithinYear;
    weekNameF = midseaObj[seasonNumY].nameWithinYear;
  } else {
    weekNameY = wobj[weekNumY].nameWithinYear;
    weekNameS = wobj[weekNumY].nameWithinSeason;
    weekNameM = wobj[weekNumY].nameWithinMonth;
    weekNameF = wobj[weekNumY].nameWithinFortnight;
  }

  if (tabNumY === -1) {
    tabNameY = midseaObj[seasonNumY].nameWithinYear;
    tabNameS = midseaObj[seasonNumY].nameWithinYear;
    tabNameM = midseaObj[seasonNumY].nameWithinYear;
    tabNameF = midseaObj[seasonNumY].nameWithinYear;
  } else {
    tabNameY = tobj[tabNumY].nameWithinYear;
    tabNameS = tobj[tabNumY].nameWithinSeason;
    tabNameM = tobj[tabNumY].nameWithinMonth;
    tabNameF = tobj[tabNumY].nameWithinFortnight;
    tabNameW = tobj[tabNumY].nameWithinWeek;
  }
  // if (monthNumS === -1) {
  //   monthNameS = "mid-";
  // } else if (monthNumS === 0) {
  //   monthNameS = "Early";
  // } else {
  //   monthNameS = "Late";
  // }

  // if (dayNumW === -1) {
  //   dayNameW = "Mid-Season Day";
  //   dayNameWURL = "";
  // } else {
  //   dayNameW = dobj[dayNumY].dayNameWithinWeek;
  //   dayNameWURL = dobj[dayNumY].dayOfWeekURL;
  // }

  dayNameY = dobj[dayNumY].dayNameWithinYear;
  dayNameYURL = dobj[dayNumY].dayOfYearURL;

  dayNameS = dobj[dayNumY].dayNameWithinSeason;
  dayNameSURL = dobj[dayNumY].dayOfSeasonURL;

  dayNameM = dobj[dayNumY].dayNameWithinMonth;
  dayNameMURL = dobj[dayNumY].dayOfMonthURL;

  dayNameF = dobj[dayNumY].dayNameWithinFortnight;
  dayNameFURL = dobj[dayNumY].dayOfFortnightURL;

  dayNameW = dobj[dayNumY].dayNameWithinWeek;
  dayNameWURL = dobj[dayNumY].dayOfWeekURL;

  dayNameT = dobj[dayNumY].dayNameWithinTab;
  dayNameTURL = dobj[dayNumY].dayOfTabURL;

  //   // // console.log(
  //       "seasons:", seasonNumY,
  //       "seasonName", seasonName,
  //       "months", monthNumY,
  //       "monthName:", monthNameY,
  //       "weekday num:", dayNumW,
  //       "weekday name:", dayNameW)

  let infoObject = {
    yearName: yearName,
    seasonNameY: seasonNameY,
    midseasonNameY: midseasonNameY,
    monthNameY: monthNameY,
    fortNameY: fortNameY,
    weekNameY: weekNameY,
    tabNameY: tabNameY,
    dayNameY: dayNameY,

    seasonNumY: seasonNumY,
    midseasonNumY: midseasonNumY,
    monthNumY: monthNumY,
    fortnightNumY: fortnightNumY,
    weekNumY: weekNumY,
    tabNumY: tabNumY,
    dayNumY: dayNumY,

    seasonNameYURL: seasonNameYURL,
    midseasonNameYURL: midseasonNameYURL,
    monthNameYURL: monthNameYURL,
    fortNameYURL: fortNameYURL,
    weekNameYURL: weekNameYURL,
    tabNameYURL: tabNameYURL,
    dayNameYURL: dayNameYURL,

    midseasonNameSURL: midseasonNameSURL,
    monthNameSURL: monthNameSURL,
    fortNameSURL: fortNameSURL,
    weekNameSURL: weekNameSURL,
    tabNameSURL: tabNameSURL,

    fortNameMURL: fortNameMURL,
    weekNameMURL: weekNameMURL,
    tabNameMURL: tabNameMURL,

    weekNameFURL: weekNameFURL,
    tabNameFURL: tabNameFURL,

    tabNameWURL: tabNameWURL,

    midseasonNameS: midseasonNameS,
    monthNameS: monthNameS,
    fortNameS: fortNameS,
    weekNameS: weekNameS,
    tabNameS: tabNameS,
    dayNameS: dayNameS,
    dayNameSURL: dayNameSURL,

    // midseasonNumS: midseasonNumS,
    monthNumS: monthNumS,
    fortnightNumS: fortnightNumS,
    weekNumS: weekNumS,
    tabNumS: tabNumS,
    dayNumS: dayNumS,

    fortNameM: fortNameM,
    weekNameM: weekNameM,
    tabNameM: tabNameM,
    dayNameM: dayNameM,
    dayNameMURL: dayNameMURL,

    fortnightNumM: fortnightNumM,
    weekNumM: weekNumM,
    tabNumM: tabNumM,
    dayNumM: dayNumM,

    weekNameF: weekNameF,
    tabNameF: tabNameF,
    dayNameF: dayNameF,
    dayNameFURL: dayNameFURL,

    weekNumF: weekNumF,
    tabNumF: tabNumF,
    dayNumF: dayNumF,

    tabNameW: tabNameW,
    dayNameW: dayNameW,
    dayNameWURL: dayNameWURL,

    tabNumW: tabNumW,
    dayNumW: dayNumW,

    dayNameT: dayNameT,
    dayNameTURL: dayNameTURL,

    dayNumT: dayNumT,

    similarSeasonWeeks: wobj[weekNumS].weekSeasonComps,
    similarMonthWeeks: wobj[weekNumM].weekMonthComps,
    similarFortnightWeeks: wobj[weekNumF].weekFortnightComps,

    similarSeasonTabs: tobj[tabNumS].tabSeasonComps,
    similarMonthTabs: tobj[tabNumM].tabMonthComps,
    similarFortnightTabs: tobj[tabNumF].tabFortnightComps,

    similarSeasonDays: dobj[dayNumS].daySeasonComps,
    similarMonthDays: dobj[dayNumM].dayMonthComps,
    similarFortnightDays: dobj[dayNumF].dayFortnightComps,
    similarWeekDays: dobj[dayNumW - 1].dayWeekComps,
    similarTabDays: dobj[dayNumT].dayTabComps,
  };

  // console.log(
  //   "infoOBject",
  //   dobj,
  //   dayNumW,
  //   daysOweek,
  //   daysOfortnight,
  //   daysOmonth,
  //   daysOseason,
  //   daysOyear
  // );

  //   if (months % 2 === 0) {
  //     monthName = "Early";
  //     monthDate = 0;
  //   } else if (currentSeasonDayCount === 37) {
  //     monthName = "Mid-";
  //     monthDate = 37;
  //   } else if (months % 2 === 1) {
  //     monthName = "Late";
  //     monthDate = 37;
  //   }

  //   // // console.log("week test", currentSeasonDayCount/9)

  //   if (currentSeasonDayCount === 37) {
  //     weekNum = "exact midpoint";
  //     weekDate = 0;
  //   } else if (currentSeasonDayCount/9 <= 1 || 4.2 < currentSeasonDayCount/9 && currentSeasonDayCount/9 < 5.12) {
  //     weekNum = "1st week";
  //     weekDate = 0 * 9;
  //   } else if (currentSeasonDayCount/9 <= 2 || 5.2 < currentSeasonDayCount/9 && currentSeasonDayCount/9 < 6.12) {
  //     weekNum = "2nd week";
  //     weekDate = 1 * 9;
  //   } else if (currentSeasonDayCount/9 <= 3 || 6.2 < currentSeasonDayCount/9 && currentSeasonDayCount/9 < 7.12) {
  //     weekNum = "3rd week";
  //     weekDate = 2 * 9;
  //   } else if (currentSeasonDayCount/9 <= 4 || 7.2 < currentSeasonDayCount/9 && currentSeasonDayCount/9 < 8.12) {
  //     weekNum = "4th week";
  //     weekDate = 3 * 9;
  //   }

  //
  //   let currentDate = currentSeasonDayCount;

  //   let infoObject = {
  //     days: days+1,
  //     currentSeasonDayCount: currentSeasonDayCount,
  //     currentDate: currentDate,
  //     seasonName: seasonName,
  //     monthName: monthName,
  //     monthDate: monthDate,
  //     weekNum: weekNum,
  //     weeks: weeks+1,
  //     weekDate: weekDate,
  //     dayName: dayName,
  //     dayDate: dayDate
  //   }

  //   let suffix = "";

  //   if (
  //     currentDate === 1 ||
  //     currentDate === 21 ||
  //     currentDate === 31 ||
  //     currentDate === 41 ||
  //     currentDate === 51 ||
  //     currentDate === 61 ||
  //     currentDate === 71
  //   ) {
  //     suffix = "st";
  //   } else if (
  //     currentDate === 2 ||
  //     currentDate === 22 ||
  //     currentDate === 32 ||
  //     currentDate === 42 ||
  //     currentDate === 52 ||
  //     currentDate === 62 ||
  //     currentDate === 72
  //   ) {
  //     suffix = "nd";
  //   } else if (
  //     currentDate === 3 ||
  //     currentDate === 23 ||
  //     currentDate === 33 ||
  //     currentDate === 43 ||
  //     currentDate === 53 ||
  //     currentDate === 63 ||
  //     currentDate === 73
  //   ) {
  //     suffix = "rd";
  //   } else {
  //     suffix = "th";
  //   }

  //   let aORan = "";

  //   if (dayName === "Earth Day") {
  //     aORan = "an";
  //   } else {
  //     aORan = "a";
  //   }

  //   let startMidEnd = ""

  //   if (dayName === "Mercury Day" || dayName === "Venus Day" || dayName === "Earth Day"){
  //       startMidEnd = "beginning"
  //   } else if (dayName === "Mars Day" || dayName === "Jupiter Day" || dayName === "Saturn Day"){
  //     startMidEnd = "in the middle of"
  //   } else if (dayName === "Uranus Day" || dayName === "Neptune Day" || dayName === "Pluto Day"){
  //     startMidEnd = "ending"
  //   }

  //   let calendarDisplay = ""

  //   if (dayName === "Mid-Season Day") {
  //     calendarDisplay = `Today is the ${currentDate}${suffix} of ${seasonName}. We are currently exactly halfway through the season of ${seasonName}`
  //   } else {
  //     calendarDisplay = `Today is the ${currentDate}${suffix} day of ${seasonName}, ${aORan} ${dayName}. We are currently ${startMidEnd} the ${weekNum} of ${monthName} ${seasonName}.`;
  //   }
  //   //   let weekDisplay = `We are currently in the ${weekNum} week of ${monthName} ${seasonName}.`;
  //   // return calendarDisplay
  // // }

  //   let birthdayGreeting = `Born on the ${currentDate}${suffix} of ${monthName} ${seasonName} (${aORan} ${dayName}). Very auspicious!`

  return (
    <>
      {numObject}
      {infoObject}
    </>
  );
};

export default fot;
