let midseaObj = [
  {
    midSeasonsOyear: 1,
    nameWithinYear: "mid-Winter",
  },
  {
    midSeasonsOyear: 2,
    nameWithinYear: "mid-Spring",
  },
  {
    midSeasonsOyear: 3,
    nameWithinYear: "mid-Summer",
  },
  {
    midSeasonsOyear: 4,
    nameWithinYear: "mid-Autumn",
  },
  {
    midSeasonsOyear: 5,
    nameWithinYear: "mid-Fall",
  },
];
export default midseaObj;
